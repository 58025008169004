import React, { useEffect } from "react";
import "./Createbrand.css";
import Form from 'react-bootstrap/Form';
import { updateBrandApi } from '../../BackendApi/Api/brandApi'
import { useState, useContext } from "react";
import { BrandContext } from "../../brandContext";
import { getBrandByIdApi } from '../../BackendApi/Api/brandApi';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { API_URL } from "../../BackendApi/Api/config";
import Loader2 from "../Loader/Loader2";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { Button } from "react-bootstrap";

function BrandAssets({ setCompany }) {
    // const { brandDataSaved, setBrandDataSaved, company_name, setCompanyName,description, setDescription, brandEmail, setBrandEmail,
    //     brandPhone, setBrandPhone, logo_light, setLogoLight, logo_dark, setLogoDark,
    //      logo_full, setLogoFull, primary_color, setPrimaryColor, secondary_color, setSecondaryColor, brand_id, setBrandId,
    //     facebookUrl, setFacebookUrl, twitterUrl, setTwitterUrl, instagramUrl, setInstagramUrl, websiteUrl, setWebsiteUrl,
    //         pinterestUrl, setPinterestUrl, tiktokUrl, setTiktokUrl, otherUrl, setOtherUrl} = useContext(BrandContext);
    const userdata = localStorage.getItem("user");
    const user = JSON.parse(userdata);
    const [BrandId, setBrandId] = useState('');
    const [brandIdData, setBrandIdData] = useState('');

    const [brandDataSaved, setBrandDataSaved] = useState(false);
    const [brandEditStatus, setBrandEditStatus] = useState(false);
    const [company_name, setCompanyName] = useState('');
    const [old_slug, setOldSlug] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [description, setDescription] = useState('');
    const [brandEmail, setBrandEmail] = useState('');
    const [brandPhone, setBrandPhone] = useState('');
    const [address, setAddress] = useState('');
    const [logo_light, setLogoLight] = useState('');
    const [logo_dark, setLogoDark] = useState('');
    const [logo_full, setLogoFull] = useState('');
    const [logo_symbolic, setLogoSymbolic] = useState('');
    const [primary_color, setPrimaryColor] = useState('#000000');
    const [secondary_color, setSecondaryColor] = useState('#B9B3F8');
    const [additional_color_1, setAdditionalColor1] = useState('#000000');
    const [additional_color_2, setAdditionalColor2] = useState('#000000');

    const [brandVoice, setBrandVoice] = useState('');
    const [brandMission, setBrandMission] = useState('');
    const [brandVision, setBrandVision] = useState('');
    const [brandValues, setBrandValues] = useState('');

    const [loading, setLoading] = useState(true);

    const [spinnerLoading, setSpinnerLoading] = useState(false);

    const [imageUrls, setImageUrls] = useState({
        logo_light: '',
        logo_dark: '',
        logo_full: '',
        logo_symbolic: '',
    });


    const getBrandData = () => {
        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];

        console.log("brand id ========>", urlbrandId);

        const data = {
            id: urlbrandId
        }

        setLoading(true);

        getBrandByIdApi(data).then((res) => {

            setLoading(false);

            console.log("brand data", res.data.data);

            setBrandId(res.data.data._id);
            setCompanyName(res.data.data.company_name);
            setSubdomain(res.data.data.company_slug);
            setOldSlug(res.data.data.company_slug);
            setCompany(res.data.data.company_name);
            setDescription(res.data.data.description);
            setBrandEmail(res.data.data.email);
            setAddress(res.data.data.address);

            const cleanPhoneNumber = res.data.data.phone.replace(/[^0-9]/g, "");
            setBrandPhone(Number(cleanPhoneNumber));

            console.log("contact numberal", res.data.data.phone);
            setLogoLight(res.data.data.logo_light);
            setLogoDark(res.data.data.logo_dark);
            setLogoFull(res.data.data.logo_full);
            setPrimaryColor(res.data.data.primary_color);
            setSecondaryColor(res.data.data.secondary_color);
            setAdditionalColor1(res.data.data.additional_color_1);
            setAdditionalColor2(res.data.data.additional_color_2);
            setBrandVoice(res.data.data?.brand_voice);
            setBrandMission(res.data.data?.brand_mission);
            setBrandVision(res.data.data?.brand_vision);
            setBrandValues(res.data.data?.brand_values);
            setImageUrls({
                logo_light: API_URL + 'brands/' + res.data.data.logo_light,
                logo_dark: API_URL + 'brands/' + res.data.data.logo_dark,
                logo_full: API_URL + 'brands/' + res.data.data.logo_full,
            })
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }


    const updateBrand = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('company_name', company_name);
        formData.append('description', description);
        formData.append('email', brandEmail);
        formData.append('phone', brandPhone);
        formData.append('id', BrandId);
        // formData.append('company_slug', subdomain?.replace(/\s+/g, ''));
        formData.append("old_slug", old_slug);
        formData.append('address', address);
        formData.append("brand_voice", brandVoice);
        formData.append("brand_mission", brandMission);
        formData.append("brand_vision", brandVision);
        formData.append("brand_values", brandValues);
        formData.append('updator_image', user.profile_image);
        formData.append('updator_name', user.fname+""+user.lname);
        // setLoading(true);
        setSpinnerLoading(true);
        updateBrandApi(formData).then((response) => {
            if (response.code === 200) {
                setBrandEditStatus(!brandEditStatus);
                setLoading(false);
                PNotify.success({
                    title: 'Success',
                    text: 'Brand Updated Successfully',
                });
            }
            else {
                setLoading(false);
                PNotify.error({
                    title: 'Error',
                    text: response.data.message,
                });
            }
        }).catch((error) => {
            setLoading(false);
            PNotify.error({
                title: 'Error',
                text: error,
            });
        }).finally(() => {
            setSpinnerLoading(false);
        })
    }


    const addLogoLight = (e) => {
        setLogoLight(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    logo_light: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };



    const addLogoDark = (e) => {
        setLogoDark(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    logo_dark: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const addLogoFull = (e) => {
        setLogoFull(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    logo_full: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };


    const addLogoSymbolic = (e) => {
        setLogoSymbolic(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    logo_symbolic: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };


    //get brand id from url



    useEffect(() => {

        getBrandData();

    }, []);


    return (
        <div>
            {loading && <Loader2 />}
            <div className='row'>
                <div className='col-md-12'>

                    {/* <Form.Group className="did-floating-label-content" controlId="formBasicName">
                        <Form.Control className="did-floating-input" type="text" placeholder="" value={subdomain} onChange={(e) => setSubdomain(e.target.value)} />
                        <Form.Label className="did-floating-label">Subdomain</Form.Label>
                    </Form.Group> */}
                    <Form.Group className="did-floating-label-content" controlId="formBasicDescription">
                        <Form.Control className="did-floating-textarea" as="textarea" rows={3} placeholder="Describe what does this organization or brand do? What Products and services are offered and who do you serve, work with or interact with?" value={description} onChange={(e) => setDescription(e.target.value)} />
                        <Form.Label className="did-floating-label">Description</Form.Label>
                    </Form.Group>
                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                        <Form.Control className="did-floating-input" type="text" placeholder="" value={brandEmail} onChange={(e) => setBrandEmail(e.target.value)} />
                        <Form.Label className="did-floating-label">Brand Email</Form.Label>
                    </Form.Group>
                    <Form.Group className="did-floating-label-content" controlId="formBasicNumber">
                        <Form.Control className="did-floating-input" type="number" placeholder="" value={brandPhone} onChange={(e) => setBrandPhone(e.target.value)} />
                        <Form.Label className="did-floating-label">Contact Number</Form.Label>
                    </Form.Group>
                    <Form.Group className="did-floating-label-content" controlId="formBasicNumber">
                        <Form.Control className="did-floating-input" type="text" placeholder="" value={address} onChange={(e) => setAddress(e.target.value)} />
                        <Form.Label className="did-floating-label">Address</Form.Label>
                    </Form.Group>
                    <Form.Group className="did-floating-label-content" controlId="formBasicDescription">
                        <Form.Control className="did-floating-textarea" as="textarea" rows={3} placeholder="" value={brandVoice} onChange={(e) => setBrandVoice(e.target.value)} />
                        <Form.Label className="did-floating-label">Brand Voice</Form.Label>
                    </Form.Group>
                    <Form.Group className="did-floating-label-content" controlId="formBasicDescription">
                        <Form.Control className="did-floating-textarea" as="textarea" rows={3} placeholder="" value={brandMission} onChange={(e) => setBrandMission(e.target.value)} />
                        <Form.Label className="did-floating-label">Mission</Form.Label>
                    </Form.Group>
                    <Form.Group className="did-floating-label-content" controlId="formBasicDescription">
                        <Form.Control className="did-floating-textarea" as="textarea" rows={3} placeholder="" value={brandVision} onChange={(e) => setBrandVision(e.target.value)} />
                        <Form.Label className="did-floating-label">Vision</Form.Label>
                    </Form.Group>
                    <Form.Group className="did-floating-label-content" controlId="formBasicDescription">
                        <Form.Control className="did-floating-textarea" as="textarea" rows={3} placeholder="" value={brandValues} onChange={(e) => setBrandValues(e.target.value)} />
                        <Form.Label className="did-floating-label">Values</Form.Label>
                    </Form.Group>
                </div>

            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='brand-button'>

                        {
                            spinnerLoading ? (
                                <SpinnerLoader />
                            ) :
                                (
                                    <button className="btn bg-green-dark action-btn btn-green" type="submit" onClick={updateBrand} > Update </button>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandAssets;