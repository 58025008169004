import React from 'react'
import { useContext } from 'react';

import { socialAccountContext } from "../../contexts/context/socialAccountContext";
import { brandSelectionContext } from "../../contexts/context/brandSelectionContext";
const API_URL = process.env.REACT_APP_API_URL;



const useSocialChannels = () => {

    const { sidebarBrands } = useContext(brandSelectionContext);

    const { connectedAccount, connectedAccountdispatch } = useContext(socialAccountContext);
   
    const user = JSON.parse(localStorage.getItem("user"));
    const getConnectedChannels = async () => {

        connectedAccountdispatch({ type: "FETCHING_SOCIAL_ACCOUNTS", payload: true })

        const data = {

            owner_id: user.owner_id,
            brand_id: sidebarBrands.selected

        }

        const response = await fetch(`${API_URL}/socialposts/getSocialAccounts`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(data)
            
        })

        const result = await response.json();

        if (result.status) {

            console.log("social acccounts : ", result.data);

            connectedAccountdispatch({ type: "FETCHING_SOCIAL_ACCOUNTS", payload: false })

            connectedAccountdispatch({ type: "SET_SOCIAL_ACCOUNTS", payload: result.data })

            
        }
        else
        {
            connectedAccountdispatch({ type: "FETCHING_SOCIAL_ACCOUNTS", payload: false })

            connectedAccountdispatch({ type: "SET_SOCIAL_ACCOUNTS", payload: [] })
        }

        return result ;
   
    }

    return {
        getConnectedChannels
  }
}

export default useSocialChannels