export function brandQrTemplateReducer(state, action) {
    switch (action.type) {
        case "SET_BRAND_QR_TEMPLATES":
            return {
                ...state,
                brandQrTemplates: action.payload
            };
        default:
            return state;
    }
}