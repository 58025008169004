import React, { useContext } from 'react';
import { SocialDisconnectContext } from "../../contexts/context/SocialDisconnectContext";

const API_URL = process.env.REACT_APP_API_URL;

const useSocialChannelsDisconnect = () => {
    const { disConnectedAccountdispatch } = useContext(SocialDisconnectContext);
    const user = JSON.parse(localStorage.getItem("user"));

    const disConnectedChannels = async (type, social_id) => {
        disConnectedAccountdispatch({ type: "FETCHING_SOCIAL_DISCONNECTION", payload: true });

        const data = {
            owner_id: user.owner_id,
            type: type,
            social_id: social_id
        };

        try {
            const response = await fetch(`${API_URL}/socialposts/socialChannelDisconnected`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.message || 'Failed to disconnect social channel');
            }

            console.log("Disconnected accounts: ", result.data);
            disConnectedAccountdispatch({ type: "SOCIAL_DISCONNECTION", payload: result.data });

            return result;
        } catch (error) {
            console.error('Error disconnecting social channel:', error.message);
            // Optionally, dispatch an error state here
        } finally {
            disConnectedAccountdispatch({ type: "FETCHING_SOCIAL_DISCONNECTION", payload: false });
        }
    };

    return {
        disConnectedChannels
    };
};

export default useSocialChannelsDisconnect;
