import SocialSharePage from './SocialShare';

function App() {
  return (
    <div className="App">
      <SocialSharePage 
        url="https://example.com"
        title="Check out this Page"
        description="I found this great website and thought you might like it too."
      />
    </div>
  );
}

export default App;