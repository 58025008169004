import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { error as notifyError, success as notifySuccess } from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { addAudience, addCompetitor, addContemporaries, addProductService, brandsGalleryDelete, deleteAudience, deleteCompetitor, deleteContemporaries, deleteProductService, updateAudience, updateCompetitor, updateContemporaries, updateProductService } from './../../BackendApi/Api/brandApi';
import { TbTrash } from 'react-icons/tb';

const DeleteModel = ({ onClose, initialData, type }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [deleteId, setDelete] = useState(null);

    useEffect(() => {
        if (initialData) {
            setDelete(initialData._id);
        }
    }, [initialData,]);
    const showNotification = (text, type) => {
        if (type === 'error') {
            notifyError({
                title: 'Error',
                text: text,
                delay: 2000,
                modules: {
                    Desktop: {
                        desktop: true
                    }
                },
            });
        } else if (type === 'success') {
            notifySuccess({
                title: 'Success',
                text: text,
                delay: 2000,
                modules: {
                    Desktop: {
                        desktop: true
                    }
                },
            });
        }
    };

    const handleDelete = async (deleteIds) => {
        setIsLoading(true);
        let id = {
            id: deleteIds
        }
        try {
            let response;
            if (type === 'product') {
                response = await deleteProductService(id);
            } else if (type === 'audience') {
                response = await deleteAudience(id);
            } else if (type === 'competitor') {
                response = await deleteCompetitor(id);
            } else if (type === 'contemporaries') {
                response = await deleteContemporaries(id);
            } else if (type === 'galleryBrands') {
                response = await brandsGalleryDelete(id);
            }
            console.log("response", response)
            if (response) {
                showNotification(`${type} deleted successful!`, 'success');
                onClose();
            }
        } catch (error) {
            const errorMessage = error.message;
            showNotification(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    console.log("deleteId", type);
    return (
        <>

            <Modal.Header closeButton>
                <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this?</Modal.Body>
            <Modal.Footer>
                <Button className='delete-Cancel-bttn' onClick={onClose}>
                    Cancel
                </Button>
                <Button className='confirm-Delete-bttn' onClick={() => handleDelete(deleteId)}>
                    Delete
                </Button>
            </Modal.Footer>
            {/* </Modal> */}

        </>
    );
};

export default DeleteModel;
