import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';


const API_URL = process.env.REACT_APP_API_URL;

const CreateTag = ({ onClose, onTagCreated }) => {
    const [formData, setFormData] = useState({
        tag_name: '',
        tag_color: '#7367f0'
    });
    const [isLoading, setIsLoading] = useState(false);

    // PNotify configuration
    const showNotification = (text, type) => {
        PNotify.error({
            title: 'Error',
            delay: 2000,
            text: text,
            modules: {
                Desktop: {
                    desktop: true
                }
            },
        });
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Validate form
    const validateForm = () => {
        if (!formData.tag_name.trim()) {
            showNotification('Tag name is required', 'error');
            return false;
        }
        if (!formData.tag_color) {
            showNotification('Color is required', 'error');
            return false;
        }
        return true;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) return;

        setIsLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await axios.post(`${API_URL}/tags/create`,
               
                {
                    tag_name: formData.tag_name,
                    tag_color: formData.tag_color,
                    owner_id: user?.owner_id
                }
            );

            if (response.data.success) {
                showNotification('Tag created successfully!', 'success');
                onTagCreated?.(response.data.data);
                onClose();
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to create tag';
            showNotification(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Create New Tag</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="tagName">
                                <Form.Control
                                    className="did-floating-input"
                                    type="text"
                                    name="tag_name"
                                    value={formData.tag_name}
                                    onChange={handleChange}
                                    placeholder=""
                                />
                                <Form.Label className="did-floating-label">Tag Name</Form.Label>
                            </Form.Group>
                        </div>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="tagColor">
                                <Form.Control
                                    className="did-floating-color"
                                    type="color"
                                    name="tag_color"
                                    value={formData.tag_color}
                                    onChange={handleChange}
                                    placeholder="theme colors"
                                />
                                <Form.Label className="did-floating-label">Select Color</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button
                        type="submit"
                        className="Active-bttn btn"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Creating...' : 'Create Tag'}
                    </button>
                </Modal.Footer>
            </Form>
        </>
    );
};

export default CreateTag;