import React, { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { informasiList } from './repository';
import pagination from './pagination';
import SearchBox from './SearchBox';
import Button from 'react-bootstrap/Button';
import { RiAddFill } from 'react-icons/ri';
import './table.css';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Modal from 'react-bootstrap/Modal';
import { AiFillAccountBook, AiOutlineDisconnect, AiOutlineStop, AiOutlineSubnode, AiOutlineUserSwitch, } from "react-icons/ai";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TbDisabled, TbShieldLock, TbShieldLockFilled, TbSubscript, TbSwitch2, TbTrash } from "react-icons/tb";
import { Spinner } from "react-bootstrap"
import { API_URL } from "../../BackendApi/Api/config";

const Informasi = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const adminData = JSON.parse(localStorage.getItem("adminData"));
    const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

    const [user_id, setUser_id] = useState(null)
    const [user_email, setUser_email] = useState(null)
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [disableId, setDisableId] = useState(null)
    const [disableStatus, setDisableStatus] = useState(null)
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [userDataSelect, setUserDataSelcet] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = (id, email) => {
        setShow(true);
        setUser_id(id);
        setUser_email(email)
    };




    const [show04, setShow04] = useState(false)
    const handleClose04 = () => setShow04(false);
    const handleShow04 = (id, status) => {
        setShow04(true);
        setDisableId(id)
        setDisableStatus(status)
    };
    const [show03, setShow03] = useState(false)
    const handleClose03 = () => setShow03(false);
    const handleShow03 = (id) => {
        setShow03(true);
        setUser_id(id)
    };
    const [show05, setShow05] = useState(false)
    const handleClose05 = () => setShow05(false);
    const handleShow05 = (item) => {
        setShow05(true);
        setUserDataSelcet(item)
        console.log("userDataSelect", userDataSelect)
        console.log("userDataSelect", item)
    };

    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);             // Current page
    const [sizePerPage, setSizePerPage] = useState(10); // Size per page
    const [totalRecords, setTotalRecords] = useState(0);
    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1;
            }
        },
        {
            dataField: 'fname',
            text: 'Name',
            sort: true,
            formatter: (cell, row) => {
                return `${row.fname} ${row.lname}`;  // Combine first and last names
            }
        },
        {
            dataField: 'email',
            text: 'Email Address',
            sort: true,
            formatter: (cell) => {
                return cell?.length > 30 ? cell.slice(0, 30) + '...' : cell;
            }
        },
        {
            dataField: 'login_startTime',
            text: 'login Time',
            sort: true,
            formatter: (cell) => {
                if (!cell) return '';
                const date = new Date(cell);
                return date.toLocaleDateString();
            }
        },
        {
            dataField: 'subscription_details',  // Use the main object
            text: 'Plan',
            sort: true,
            formatter: (cell) => {

                // Check if the cell exists and is a valid string (not undefined or null)
                if (cell && typeof cell === 'string') {
                    try {
                        // Parse the cell if it's a valid stringified JSON object
                        const subscriptionDetails = JSON.parse(cell);

                        // Check if the parsed object has a status field
                        if (subscriptionDetails && subscriptionDetails.status) {
                            return subscriptionDetails.status;  // Return the status value
                        }
                    } catch (error) {
                        console.error("Error parsing subscription_details:", error);
                    }
                }

                // Default message when parsing fails or status is not found
                return 'not a valid status';
            }
        }
        ,

        {
            dataField: 'phone',
            text: 'Phone',
            sort: true,
            formatter: (cell) => {
                return cell ? cell.replace("undefined", "not a valid phone number") : 'not a valid phone number';
            }
        },

        {
            dataField: 'role',
            text: 'Role',
            sort: true,
            formatter: (cell) => {
                if (cell === "0") return 'User';
                if (cell === "1") return 'Admin';
                if (cell === "2") return 'Staff';
                return 'Unknown';  // अगर कोई अन्य value है तो
            }
        },
        {
            dataField: 'joindate',
            text: 'Join Date',
            sort: true,
            formatter: (cell) => {
                if (!cell) return '';
                const date = new Date(cell);
                return date.toLocaleDateString();
            }
        },
        {
            dataField: 'verified',
            text: 'Verified',
            sort: true,
        },
        {
            dataField: 'profile_image', text: 'Image',
            formatter: (cell) => cell ?
                <img src={`${API_URL}profile/${cell}`} alt=" Logo"
                    style={{ width: '50px', height: "50px", borderRadius: 20 }} />
                : <img src={require('./../../../src/assets/images/logo/Popuplogo.png')} alt=" Logo"
                    style={{ width: '50px', height: "50px", borderRadius: 20 }} />,
            visible: true
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => (
                <>
                    <Button
                        className={`btn btn-sm me-2 ${row.userLoginDisabled ? 'btn-success' : 'btn-primary'}`}
                        onClick={() => handleShow04(row._id, !row.userLoginDisabled)}
                        title={row.userLoginDisabled ? "Enable Login" : "Disable Login"}
                    >
                        <AiOutlineStop /> {/* Disconnect icon */}
                    </Button>

                    <Button
                        className="btn btn-danger btn-sm me-2"
                        onClick={() => handleShow(row._id, row.email)}
                        title="Reset Password"
                    >
                        <TbShieldLockFilled /> {/* Lock icon */}
                    </Button>

                    <Button
                        className="btn btn-info btn-sm me-2" // Changed to btn-info for better visibility
                        onClick={() => handleShow03(row._id)}
                        title="Login as User"
                    >
                        <AiOutlineUserSwitch /> {/* User switch icon */}
                    </Button>

                    <Button
                        className="btn btn-warning btn-sm me-2" // Keep as btn-warning for trial extension
                        onClick={() => handleShow05(row)}
                        title="Extend Trial"
                    >
                        <AiOutlineSubnode color="white" /> {/* Account book icon */}
                    </Button>


                </>

            ),
            visible: true
        },


    ];
    const handleDisAbleUser = async (id) => {
        const userData = await fetch(`${API_URL}user/userDisabled`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: disableId,
                disabledStatus: disableStatus
            })
        })
        const res = await userData.json();
        console.log("User data: ", res.data)
        if (res.status) {
            handleClose04()
            PNotify.success({
                title: 'success',
                text: `User ${disableStatus ? "Disable" : "Enable"} successfully`,
            });
        } else {
            PNotify.error({
                title: 'error',
                text: `failed to enable ${disableStatus ? "Disable" : "Enable"}`,
            });
            console.log('Failed to fetch user');
        }
    }

    const getAlUser = async (page, limit) => {
        if (searchText) {
            limit = totalRecords
        }
        const userData01 = await fetch(`${API_URL}user/getallusers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userData?._id,
                page: page,
                limit: limit,
            })
        })
        const res = await userData01.json();
        if (res.status) {
            setUsers(res.data.users);
            setTotalRecords(res.data.totalPages);
        } else {
            console.log('Failed to fetch user');
        }
    }

    const handleSearch = (searchValue) => {
        setSearchText(searchValue)
    }

    const filteredUsers = users.filter(user =>
        user.fname?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        user.lname?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        user.email?.toLowerCase()?.includes(searchText.toLowerCase())
    );


    useEffect(() => {
        const indexOfLastItem = page * sizePerPage;
        const indexOfFirstItem = indexOfLastItem - sizePerPage;
        const currentData = users.slice(indexOfFirstItem, indexOfLastItem);

        getAlUser(page, sizePerPage);

    }, [page, sizePerPage, users]);

    const handleTableChange = (type, { page, sizePerPage }) => {
        setPage(page);
        setSizePerPage(sizePerPage);
    };


    const paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        page,
        sizePerPage,
        totalSize: totalRecords, // Use total records for pagination
        sizePerPageList: [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            { text: "50", value: 50 },
            { text: "All", value: totalRecords },
        ],
    };


    const changePassword = async () => {

        if (newPassword === '') {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter new password',
            });
            return;
        }

        if (newPassword?.length < 8) {
            PNotify.error({
                title: 'Oh No!',
                text: 'New password must be minimum 8 characters long',
            });
            return;
        }

        if (confirmNewPassword === '') {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter confirm new password',
            });
            return;
        }
        if (newPassword !== confirmNewPassword) {
            PNotify.error({
                title: 'Oh No!',
                text: 'New password and confirm new password does not match',
            });
            return;
        }

        var lowerCaseLetters = /[a-z]/g;
        if (!newPassword.match(lowerCaseLetters)) {
            PNotify.error({
                title: 'Oh No!',
                text: 'New password must contain at least one lowercase character',
            });
            return;
        }

        var numbers = /[0-9]/g;
        if (!newPassword.match(numbers)) {
            PNotify.error({
                title: 'Oh No!',
                text: 'New password must contain at least one number, symbol, or whitespace character',
            });
            return;
        }

        setLoading(true);
        let pass = await fetch(`${API_URL}user/PasswordResetByAdmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: user_id,
                password: newPassword,
                email: user_email
            })
        })
        const userData = await pass.json()
        if (userData.status === true) {
            PNotify.success({
                title: 'Success',
                text: userData.message,
            });
            setNewPassword('');
            setConfirmNewPassword('');
            setLoading(false);
            handleClose();
        } else {
            PNotify.error({
                title: 'error',
                text: userData.message,
            });
            setLoading(false);
        }


    }

    const handleLoginAccount = async () => {
        setLoading(true);
        try {
            const loginAccount = await fetch(`${API_URL}user/loginUserById`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: user_id,
                })
            })
            const userData = await loginAccount.json()
            if (userData.status == true) {
                PNotify.success({
                    title: 'Success',
                    text: userData.message,
                });
                handleClose03();

                localStorage.removeItem("user");
                localStorage.setItem("user", JSON.stringify(userData.data));
                localStorage.setItem("isAdmin", true);
                window.location.href = "/dashboard";
            } else {
                PNotify.error({
                    title: 'Error',
                    text: userData.message,
                });
                setLoading(false);
                handleClose03();
            }
        } catch (error) {
            console.log('error:', error)
            PNotify.error({
                title: 'Error',
                text: 'Failed to fetch user',
            });
            setLoading(false);

        }

    }

    const extendTrialPeriod = async () => {
        try {
            let subscriptionId;

            if (userDataSelect.subscription_details) {
                const subscriptionDetails = JSON.parse(userDataSelect.subscription_details);
                subscriptionId = subscriptionDetails.id;
            } else {
                PNotify.error({
                    title: 'Error',
                    text: 'there was no subscription',
                })
            }

            if (!subscriptionId) {
                PNotify.error({
                    title: 'Error',
                    text: 'subscriptionId is required',
                })
            }

            const response = await fetch(`${API_URL}user/extend-trial`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    subscriptionId, user_id: userDataSelect?._id
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to extend trial');
            }

            const updatedSubscription = await response.json();
            if (updatedSubscription.status) {
                console.log('Trial extended successfully', updatedSubscription);
                PNotify.success({
                    title: 'Success',
                    text: 'Trial period extended successfully',
                });
                handleClose05();
            } else {
                console.error('Failed to extend trial');
                PNotify.error({
                    title: 'Error',
                    text: updatedSubscription.message,
                });
                handleClose05();
            }

        } catch (error) {
            console.error('Error extending trial:', error.message);
            PNotify.error({
                title: 'Error',
                text: error.message,
            });
        }
    };


    return (
        <>

            <Modal show={show03} onHide={handleClose03} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbSwitch2 />Confirm Login This Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to Login this account?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose03}>
                        Cancel
                    </Button>
                    <Button className='Active-btt btn' onClick={handleLoginAccount}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbDisabled />Confirm {disableStatus ? "Disable" : "Enable"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to {disableStatus ? "Disable" : "Enable"} this user?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={handleDisAbleUser}>
                        {disableStatus ? "Disable" : "Enable"}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show05} onHide={handleClose05} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbSubscript />Confirm ExtendTrial Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to subscription extending this user?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose05}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={extendTrialPeriod}>
                        Extend Trial
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="main-card-title">Reset Password</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custum-modal-body">
                    <div className="main-page-body-content-body-container">
                        <div className="tab-content-header">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><TbShieldLock /></div>Password Reset</h5>
                        </div>
                        <div className="setting-tab-content-body">

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-12">
                                    <div className="content-body-form">
                                        <form>
                                            <div className="form-group did-floating-label-content">
                                                <input type="Password" className="form-control did-floating-input" id="exampleFormControlInput2" placeholder="********" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                <label for="exampleFormControlInput2" className="form-label did-floating-label">New Password</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-12">
                                    <div className="content-body-form">
                                        <form>
                                            <div className="form-group did-floating-label-content">
                                                <input type="Password" className="form-control did-floating-input" id="exampleFormControlInput1" placeholder="********" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                                <label for="exampleFormControlInput1" className="form-label did-floating-label">Confirm New Password</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="password-recet-content">
                                <h6>Password Requirements:</h6>
                                <ul>
                                    <li>Minimum 8 characters long - the more, the better</li>
                                    <li>At least one lowercase character</li>
                                    <li>At least one number, symbol, or whitespace character</li>
                                </ul>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <div className='form-btn'>
                                        <div className='form-btn-right'>


                                            {
                                                loading ?
                                                    <button className="btn bg-green-dark action-btn btn-green" >
                                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                        Loading...
                                                    </button> :
                                                    <button type="button" className="btn bg-green-dark action-btn btn-green me-3" onClick={changePassword}>
                                                        Reset Password
                                                    </button>

                                            }

                                            <button type="button" className="btn bg-cancel-btn">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <div className="animated fadeIn" style={{
                padding: '0', margin: '0', width: "1372px"
            }}>
                <div className="container-fluid" >
                    <div className="row" >
                        <div className="col-lg-12" >
                            <div className="card">
                                <div className="card-header d-flex table-card-header">
                                    <SearchBox onSearch={handleSearch} />
                                    {/* <Button disabled className="btn btn-sm float-right add-user-btn"
                                    >
                                        <RiAddFill /> Add New User
                                        //onClick={handleShow02}
                                    </Button> */}
                                </div>
                                <div className="card-body">
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='id'
                                        data={filteredUsers}
                                        columns={columns}
                                        pagination={paginationFactory(paginationOptions)}
                                        onTableChange={handleTableChange}
                                        remote={{ pagination: true }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Informasi;