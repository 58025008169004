import React from 'react';
import useImageTransparency from '../Components/CustomHooks/useImageTransparency';

const ImageChecker = ({ src }) => {
    const { isTransparent, imageUrl } = useImageTransparency(src); // Use the hook to get transparency info

    return (
        <div>
            {imageUrl && (
                <img
                    style={{ width: 40, borderRadius: 5, height: 40, marginRight: 10 }}
                    src={imageUrl}
                    alt=""
                />
            )}
            {/* {isTransparent !== null && (
                <p>
                    {isTransparent
                        ? "This image has a transparent background."
                        : "This image does not have a transparent background."}
                </p>
            )} */}
        </div>
    );
};

export default ImageChecker;
