import React from 'react'
import { useContext } from 'react';
import { completedPostsContext } from '../context/completedPostsContext';
import { brandSelectionContext } from "../../contexts/context/brandSelectionContext";

const API_URL = process.env.REACT_APP_API_URL;


const useCompleteDataActions = () => {
    const { sidebarBrands } = useContext(brandSelectionContext);

    const { completedPostsdispatch } = useContext(completedPostsContext);

    // Your logic here...
    const user = JSON.parse(localStorage.getItem("user"))
    const ownerid = user.owner_id;
    const createdby = user._id;

    const getcompleteddata = async () => {

        try {
            const data = {
                owner_id: ownerid,
                created_by: createdby,
                brand_id: sidebarBrands.selected
            }

            const response = await fetch(`${API_URL}/socialposts/getcompletedposts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();

            if (result.status) {

                console.log("completed posts  posts data", result.data);

                completedPostsdispatch({ type: "SET_COMPLETED_POSTS", payload: result.data })

            }

        }
        catch (err) {
            console.log(err);
        }

        // setscheduledfacebookdata(result.data.data);
    }

    

    return { getcompleteddata }; // Return an object with the functions you want to expose
};

export default useCompleteDataActions;

