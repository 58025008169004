import { API_URL } from "../BackendApi/Api/config";
let chaticons = `${API_URL}flexpagesIcons/chaticons/`;
let socialicons = `${API_URL}flexpagesIcons/social/`;
let paymenticons = `${API_URL}flexpagesIcons/payment/`;
let shoppingicons = `${API_URL}flexpagesIcons/ecommerce/`;
let othericons = `${API_URL}flexpagesIcons/extras/`;
export const icons = {
    social: [
        { name: "Facebook", link: socialicons + "facebook.png" },
        { name: "LinkedIn", link: socialicons + "linkedin.png" },
        { name: "Instagram", link: socialicons + "instagram.png" },
        { name: "TikTok", link: socialicons + "tiktok.png" },
        { name: "X", link: socialicons + "twitter.png" }, // Previously Twitter
        { name: "Pinterest", link: socialicons + "pinterest.png" },
        // { name: "Reddit", link: socialicons + "reddit.png" },
        { name: "Youtube", link: socialicons + "youtube.png" },
        { name: "Twitch", link: socialicons + "twitch.png" },
        { name: "Snap", link: socialicons + "snapchat.png" },
        { name: "Threads", link: socialicons + "threads.png" },
        { name: "Spotify", link: socialicons + "spotify.png" },
        { name: "SoundCloud", link: socialicons + "soundcloud.png" },
        { name: "Apple", link: socialicons + "apple.png" },
        { name: "Google", link: socialicons + "google.png" },
        { name: "youtube", link: socialicons + "youtube.png" },
    ],
    chat: [
        { name: "SMS", link: chaticons + "sms.png" },
        { name: "Discord", link: chaticons + "discord.png" },
        { name: "Slack", link: chaticons + "slack.png" },
        { name: "Whatsapp", link: chaticons + "whatsapp.png" },
        { name: "Messenger", link: chaticons + "messenger.png" },
    ],
    payment: [
        { name: "Paypal", link: paymenticons + "paypal.png" },
        { name: "CashApp", link: paymenticons + "cashapp.png" },
        { name: "Venmo", link: paymenticons + "venmo.png" },
        { name: "Bitcoin", link: paymenticons + "bitcoin.png" },
        { name: "US Dollar Symbol", link: paymenticons + "dollar.png" },
        { name: "Indian Rupee", link: paymenticons + "rupees.png" },
        { name: "British Pound", link: paymenticons + "pound.png" },
        { name: "Euro", link: paymenticons + "euro.png" },
        { name: "Yen", link: paymenticons + "yen.png" },
        { name: "Franc", link: paymenticons + "franc.png" },
        { name: "Peso", link: paymenticons + "peso.png" },
    ],
    shopping: [
        { name: "Generic Store", link: othericons + "genericstore.png" },
        { name: "Generic Shopping Cart", link: othericons + "genericshopingcart.png" },
        { name: "Amazon", link: shoppingicons + "amazon.png" },
        { name: "Ebay", link: shoppingicons + "ebay.png" },
        { name: "Square", link: shoppingicons + "square.png" },
        { name: "Shopify", link: shoppingicons + "shopify.png" },
        { name: "Walmart", link: othericons + "walmart.png" },
        { name: "FlipKart", link: shoppingicons + "flipkart.png" },
    ],
    standard: [
        { name: "Email", link: othericons + "email.png" },
        { name: "Article", link: othericons + "article.png" },
        { name: "File", link: othericons + "file.png" },
        { name: "Calendar", link: othericons + "calander.png" },
        { name: "Walmart", link: othericons + "walmart.png" },
        { name: "Generic", link: othericons + "genericstore.png" },
        { name: "genericShoppingCart", link: othericons + "genericshopingcart.png" },
        { name: "Book", link: othericons + "book.png" },
        { name: "Star", link: othericons + "star.png" },
        { name: "HandShake", link: othericons + "handshake.png" },
    ]

};

