import React, { useContext } from 'react'
import { brandSelectionContext } from "../../contexts/context/brandSelectionContext";

const useSocialPermissions = () => {
    const { sidebarBrands } = useContext(brandSelectionContext);

    const checkForSocialContentPermissoins = () => {
        const selectedBrandData = sidebarBrands.brands.find(brand => brand._id === sidebarBrands.selected);

        if (selectedBrandData) {

            return selectedBrandData.social_access;
        }
    }


    return {
        checkForSocialContentPermissoins
    }
}

export default useSocialPermissions