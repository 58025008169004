import React, { useState } from 'react';
import axios from 'axios';

const CohereChat = () => {
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([{
        message: "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?",
        role: "CHATBOT"
    }]);

    const [message, setMessage] = useState("");

    const handleChatRequest = async () => {
        setLoading(true);
        try {
            const result = await axios.post(
                'https://api.cohere.com/v1/chat',
                {
                    message: message,
                    chat_history: chatHistory
                    
                    // [
                    //     {
                    //         message: "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?",
                    //         role: "CHATBOT"
                    //     },
                    //     {
                    //         message: "I want to go with social stuff.",
                    //         role: "USER"
                    //     },
                    //     {
                    //         message: "Great! Social media is a powerful tool for brands to connect with their audience and build a strong online presence. Let's dive into some creative ideas for social media posts tailored to your brand's needs. \n\nBefore we begin, could you please provide me with some more details about your brand? Here are some questions to get us started: \n\n- What is the name of your brand, and what industry does it operate in? \n- Who is your target audience, and what are their interests and pain points? \n- What are the key products or services your brand offers? \n- What is your brand's unique value proposition, and how do you want to differentiate yourself from competitors? \n\nOnce I have this information, I can generate a list of social media post ideas that will resonate with your audience and help you achieve your brand's goals.",
                    //         role: "CHATBOT"
                    //     },
                    //     {
                    //         message: "I operate an animal husbandry business named Nihal Milk Products. I am organizing an event where people can come and try our products, and there will be interesting games for both children and adults. We will provide health benefits information about our products.",
                    //         role: "USER"
                    //     },
                    //     {
                    //         message: "That sounds like a fantastic event! Here are some social media post ideas to promote your event and engage your audience: [...]",
                    //         role: "CHATBOT"
                    //     }
                    // ]
                    ,
                    model: "command-r-08-2024",
                    preamble: "You are an AI-assistant chatbot. You are trained to assist users with their brands assist users in building and strengthening their brands."
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer fLxSLabc6XoYUnxMV83fVYaz5ZGRzJSO5hA0a7v1`
                    }
                }
            );

            setChatHistory(result.data.chat_history)

            setMessage("")

            setResponse(result.data); // handle the response as needed

        } catch (error) {
            console.error("Error fetching chat response:", error);
            setResponse("Failed to fetch the chat response.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {/* <button onClick={handleChatRequest} disabled={loading}>
                {loading ? 'Loading...' : 'Send Chat Request'}
            </button>
            {response && (
                <div>
                    <h3>Response:</h3>
                    <p>{JSON.stringify(response, null, 2)}</p>
                </div>
            )} */}
            {
                chatHistory && chatHistory.map((message, index) => (
                    <div key={index}>
                        <p>{message.message}</p>
                    </div>
                ))
            }

            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <button disabled={loading} onClick={handleChatRequest}>Send</button>
        </div>
    );
};

export default CohereChat;
