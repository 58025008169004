import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { error as notifyError, success as notifySuccess } from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { TbTrash } from 'react-icons/tb';
import {
    addAudience,
    addCompetitor,
    addContemporaries,
    addProductService,
    updateAudience,
    updateCompetitor,
    updateContemporaries,
    updateGalleryBrand,
    updateProductService,
    AddbrandsGallery
} from '../BackendApi/Api/brandApi';
import "./AdvanceBrands/newCss.css";
import { API_URL } from '../BackendApi/Api/config';

const INITIAL_FORM_STATE = {
    name: '',
    description: ''
};

const CustomModal = ({ onClose, brand_id, type, initialData }) => {
    const [formData, setFormData] = useState(INITIAL_FORM_STATE);
    const [images, setImages] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const submitType = initialData ? 'Update' : 'Create';
    const isGalleryType = type === 'galleryBrands';

    useEffect(() => {
        if (initialData) {
            const typeMapping = {
                contemporaries: { name: 'contemporaries_name', description: 'contemporaries_description' },
                audience: { name: 'audience_name', description: 'audience_description' },
                competitor: { name: 'competitor_name', description: 'competitor_description' },
                product: { name: 'product_name', description: 'product_description' },
            };

            const mapping = typeMapping[type];
            if (mapping) {
                setFormData({
                    name: initialData[mapping.name],
                    description: initialData[mapping.description],
                });
            }
            setImages(initialData.brandingImage || []);
            setPreviews((initialData.brandingImage || []).map((image) => ({ url: image })));
        }
    }, [initialData, type]);

    const showNotification = (text, type) => {
        const notifyConfig = {
            title: type === 'error' ? 'Error' : 'Success',
            text,
            delay: 2000,
            modules: {
                Desktop: { desktop: true },
            },
        };

        type === 'error' ? notifyError(notifyConfig) : notifySuccess(notifyConfig);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        if (!isGalleryType) {
            if (!formData.name.trim()) {
                showNotification(`${type}_name is required`, 'error');
                return false;
            }
            if (!formData.description.trim()) {
                showNotification(`${type}_description is required`, 'error');
                return false;
            }
        } else if (!images.length) {
            showNotification('Please select at least one image', 'error');
            return false;
        }
        return true;
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        // Validate file types
        const invalidFiles = files.filter(file => !file.type.startsWith('image/'));
        if (invalidFiles.length > 0) {
            showNotification('Please upload only image files', 'error');
            return;
        }

        // Create preview URLs and update images
        const newPreviews = files.map(file => ({
            url: URL.createObjectURL(file),
            name: file.name,
            size: file.size,
        }));

        setImages(prev => [...prev, ...files]); // Keep all selected images
        setPreviews(prev => [...prev, ...newPreviews]);
    };

    const removeFile = (index) => {
        setImages((prev) => prev.filter((_, i) => i !== index));
        setPreviews((prev) => {
            URL.revokeObjectURL(prev[index].url);
            return prev.filter((_, i) => i !== index);
        });
    };

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsLoading(true);
        try {
            let response;

            if (isGalleryType) {
                const formDataToSubmit = new FormData();
                formDataToSubmit.append('brand_id', initialData ? initialData._id : brand_id);
                images.forEach(image => formDataToSubmit.append('brandingImage', image));

                response = await (initialData ? updateGalleryBrand(formDataToSubmit) : AddbrandsGallery(formDataToSubmit));
            } else {
                const data = {
                    [`${type}_name`]: formData.name,
                    [`${type}_description`]: formData.description,
                    brand_id,
                    ...(initialData && { id: initialData._id }),
                };

                const apiFunction = initialData ?
                    {
                        product: updateProductService,
                        audience: updateAudience,
                        competitor: updateCompetitor,
                        contemporaries: updateContemporaries,
                    }[type] :
                    {
                        product: addProductService,
                        audience: addAudience,
                        competitor: addCompetitor,
                        contemporaries: addContemporaries,
                    }[type];

                response = await apiFunction(data);
            }
console.log("response111111111111",response)
            if (response.data.status) {
                showNotification(`${submitType} successful!`, 'success');
                onClose();
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to submit';
            showNotification(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    // Cleanup previews on unmount
    useEffect(() => {
        return () => {
            previews.forEach(preview => URL.revokeObjectURL(preview.url));
        };
    }, [previews]);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`${submitType} ${type}`}</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit}>
                <Modal.Body className="custom-modal-body">
                    {isGalleryType ? (
                        <div className="col-md-12">
                            <Form.Group controlId="images">
                                <Form.Label>Upload Images</Form.Label>
                                <Form.Control
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    className="mb-3"
                                />

                                {previews.length > 0 && (
                                    <div className="file-preview-container">
                                        <h6>Selected Files ({previews.length})</h6>
                                        <div className="file-list">
                                            {previews.map((preview, index) => (
                                                <div key={index} className="file-preview-item border rounded p-2 mb-2 d-flex align-items-center">
                                                    <img
                                                        src={
                                                            preview.url && (
                                                                preview.url.startsWith('http') ||
                                                                preview.url.startsWith('https') ||
                                                                preview.url.startsWith('blob:')
                                                            )
                                                                ? preview.url
                                                                : initialData
                                                                    ? API_URL + "brandsGallery/" + preview.url
                                                                    : preview.url
                                                        }
                                                        alt={preview.name}
                                                        className="preview-thumbnail me-2"
                                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                    />

                                                    <div className="flex-grow-1">
                                                        {!initialData && (
                                                            <>
                                                                <div className="text-truncate">{preview.name}</div>
                                                                <small className="text-muted">{formatFileSize(preview.size)}</small>
                                                            </>
                                                        )}
                                                    </div>
                                                    <Button
                                                        variant="link"
                                                        className="text-danger"
                                                        onClick={() => removeFile(index)}
                                                    >
                                                        <TbTrash size={20} />
                                                    </Button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Group className="did-floating-label-content" controlId="name">
                                    <Form.Control
                                        className="did-floating-input"
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Name"
                                    />
                                    <Form.Label className="did-floating-label">
                                        {`${type}_name`}
                                    </Form.Label>
                                </Form.Group>
                            </div>

                            <div className="col-md-12">
                                <Form.Group className="did-floating-label-content" controlId="description">
                                    <Form.Control
                                        className="did-floating-color"
                                        as="textarea"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        placeholder="Description"
                                        rows={3}
                                    />
                                    <Form.Label className="did-floating-label">
                                        {`${type}_description`}
                                    </Form.Label>
                                </Form.Group>
                            </div>
                        </div>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        type="submit"
                        className="Active-bttn"
                        disabled={isLoading}
                    >
                        {isLoading ? `${submitType}ing...` : `${submitType} ${type}`}
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );
};

export default CustomModal;
