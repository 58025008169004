import React from "react";
import { Link } from "react-router-dom";
import { FaChevronRight, FaLongArrowAltLeft } from "react-icons/fa";
import { BsSpotify } from "react-icons/bs";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import *  as PNotify from '@pnotify/core';

import { v4 as uuidv4 } from "uuid";

export function SpotifyList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <BsSpotify />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Spotify</h2>
                            <p className="add-block-btn-text-desc">Add a Spotify to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}






export function SpotifyAdd() {
    const navigate = useHistory();
    const { addComponent } = useBuilderContext();

    const [link, setLink] = React.useState({
        link: "",
    });

    const unique_id = Date.now() + uuidv4();


    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Spotify</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="position-relative">
                        <div className="form_block">
                            <input type="text" value={link.link} className="form-control did-floating-input"
                                onChange={(e) => {

                                    let spotifyLink = e.target.value;
                                   
                                    setLink({ link: spotifyLink });
                                }
                                } placeholder="" />
                            <label htmlFor="exampleInputEmail1" className="gvofes did-floating-label">Spotify URL</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={
                    () => {

                        const spotifyLinkPattern = /https:\/\/open\.spotify\.com\/(playlist|track|album|artist)\/[a-zA-Z0-9]+/;

                        if (!spotifyLinkPattern.test(link.link)) {
                            PNotify.error({
                                title: 'Error',
                                text: 'Please enter a valid Spotify link',
                            });
                        } else {

                            const data = {
                                realLink: link.link,
                                link: link.link.replace('open.spotify.com', 'open.spotify.com/embed'),
                                unique_id: unique_id
                            }

                            addComponent("SPOTIFY", data);
                            navigate.push("/");
                        }


                    }
                }>
                    Add
                </button>
            </div>
        </>
    );
}