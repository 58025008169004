import React from "react";
import { MdTitle } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export function HeadingList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdTitle />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Heading</h2>
                            <p className="add-block-btn-text-desc">Add a heading to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}


export function HeaddingAdd() {
    const navigate = useHistory();
    const { addComponent } = useBuilderContext();
    const unique_id = Date.now() + uuidv4();

  const[headding, setHeadding] = React.useState({
      headding: "Headding",
      unique_id: unique_id
    });

    

    
    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Heading</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group did-floating-label-content">
                        <textarea className="form-control did-floating-textarea" id="headding" rows="3" placeholder=""
                        onChange={(e) => {
                            setHeadding({
                                ...headding,
                                headding: e.target.value,
                            });
                        }}
                        />
                       <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">Heading</label>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={
                    ()=>{
                        addComponent("TITLE",headding);
                        navigate.push("/");
                    }
                }>
                    Add
                </button>
            </div>
        </>
    );
}