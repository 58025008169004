import React, { useState, useRef, useEffect } from "react";
import { BsFillGridFill, BsList } from "react-icons/bs";
import { FaChevronRight, FaLongArrowAltLeft, FaTrash } from "react-icons/fa";
import { Link, useLocation , useHistory} from "react-router-dom";
import { DragDropContext } from '@hello-pangea/dnd';
import List from './ImageGrid';
import { useBuilderContext } from "../../../builderContext";
import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';



export function ImageGridList() {

    
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <BsFillGridFill />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Image Grid</h2>
                            <p className="add-block-btn-text-desc">Add a Image Grid to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}







export function ImageGridAdd() {
    const navigate = useHistory();

    const [blockCount, setBlockCount] = useState(0);
    
    const location = useLocation();
    // const myData = location.state != undefined ? location.state.data : null;
    const frameType = location.state != undefined ? "update" : "add";
    const position = location.state != undefined ? location.state.position : null;
    const showStatus = location.state?.showStatus;
    const [status, setStatus] = useState("")
    const { addComponent, editComponent } = useBuilderContext();

    // Function to handle click event and add a block section
    const addBlockSection = () => {
        setBlockCount((prevCount) => prevCount + 1);
    };

    // Function to remove a block section
    const removeBlockSection = (index) => {
        setBlockCount((prevCount) => prevCount - 1);
    };
    const uniqueId = Date.now() + uuidv4();

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const handleInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        if (onImageChange) {
            onImageChange(file);
        }

        // Create a preview image URL to show the selected image.
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewImage(null);
        }

    };

    const onImageChange = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewImage(null);
        }
    };


    

    useEffect(() => {
        const dropdown = document.querySelector('.dropdown-menu');
        const dropdownItems = document.querySelectorAll('.drop_social_item');
        dropdownItems.forEach((item) => {
            item.addEventListener('click', () => {
                dropdown.classList.remove('show');
            })
        })

        if (showStatus) {
            setStatus(showStatus)
        }
    }, [])



    const [imageGrid, setImageGrid] = useState(
        frameType == "update" ? location.state?.data : {
            lists: {
                'list-1': {
                    id: 'list-1',
                    title: 'List 1',
                },
            },
            items: {

            },
            listOrder: ['list-1'],
            itemOrder: [],
            unique_id: uniqueId
        }
    );

    const { lists, items, listOrder, itemOrder } = imageGrid;


    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };



    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            imageGrid.itemOrder,
            result.source.index,
            result.destination.index
        );


        const newImageGrid = {
            ...imageGrid,
            itemOrder: reorderedItems
        }

        setImageGrid(newImageGrid);

    };
    const addNewGrid = () => {

        const count = imageGrid.itemOrder.length;
        const newId = `grid-${count + 1}`;

     
        // add item to items and itemOrder 
        const newItems = {
            ...items,
            [newId]: {
                unique_id: uniqueId,
                id: newId,
                imageurl: '',
                imagepreview: '',
                alt: '',
                link:'',
            }
        }
        const newItemOrder = [...itemOrder, newId];

        // add newItems and newItemOrder to socialLinks

        const newImageGrid = {
            ...imageGrid,
            items: newItems,
            itemOrder: newItemOrder,
            unique_id: uniqueId
        }

        setImageGrid(newImageGrid);

    }

    useEffect(() => {
        console.log("this is the image grid===>",imageGrid);
    }, [imageGrid])

    const removeImageGrid = (id) => {

        const newId = id

        const newItems = {
            ...items
        }

        delete newItems[`grid-${newId+1}`];
        
        const newItemOrder = itemOrder.filter((item, index) => index !== newId);

        const newImageGrid = {
            ...imageGrid,
            items: newItems,
            itemOrder: newItemOrder,
            unique_id: uniqueId
        }

        setImageGrid(newImageGrid);

    }

    const updateImageGrid = (id, data) => {
        const newItems = {
            ...items,
            [id]: {
                ...items[id],
                ...data
            }
        } 
        const newImageGrid = {
            ...imageGrid,
            items: newItems,
            unique_id: uniqueId
        } 
        setImageGrid(newImageGrid);
    } 

    useEffect(() => {
        if (location.state?.data){
        setImageGrid(location.state?.data);
        }
    }, [])


    // const uploadGridImage = (id) => {
    //     fileInputRef.current.click();
    // }

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Image Grid</label>
                    </div>
                </div>
                <div className="iuEvSC">
                    <button onClick={addNewGrid} className="add_image_grid">Add new Image</button>
                </div>
 


                <div className="imagegrid-list">
                    <DragDropContext onDragEnd={onDragEnd}>
                        {listOrder.map((listId) => {
                            const list = lists[listId];
                            const listItems = itemOrder.map((itemId) => items[itemId]);
                            return <List key={list.id} list={list} items={listItems}  updateimagegrid={updateImageGrid} removeImageGrid={removeImageGrid}/>;
                        })}
                    </DragDropContext>
                </div>
            </div>
            <div className="add-block-btn">
                    {
                        frameType == "update" ?
                            <button className="btn publish-btn" onClick={
                                () => {
                                    editComponent(position, "IMAGEGRID", imageGrid,status);
                                    // navigate.push("/");
                                }
                            }>
                                Update
                            </button>
                            :
                            <button className="btn publish-btn" onClick={
                                () => {

                                    addComponent("IMAGEGRID", imageGrid);
                                    navigate.push("/");


                                }
                            }>
                                Add
                            </button>
                    }

                </div>
        </>
    );
}