// import React from "react";
// import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";
// import YouTubePlayer from "../youtubeEmbeded";

// function YoutubeLink({data,smartlink_id}) {

//     const saveClicks = (smartlink_id, unique_id) => {
// saveComponentsClicks({
//     unique_id: unique_id,
//     smartlink_id: smartlink_id,

// });
//     }
//     console.log("YoutubeIframe",smartlink_id, data.unique_id)
//     const youtubeLinks = Array.isArray(data) ? data : [data];
//     console.log("youtubeLinks",youtubeLinks)

//     return (
//         <>
//             {/* <div className="youtube_video_box mb-3" >
//                 <iframe width="560" height="315" src={props.data.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//             </div> */}
//        {youtubeLinks.map((youtubeData, index) => (
//         <div key={youtubeData.unique_id || index} className="youtube_video_box mb-3" onClick={() => saveClicks(youtubeData.unique_id)}>
//           <YouTubePlayer
//             youtubeEmbedUrl={youtubeData.link}
//             onPlay={() => saveClicks(youtubeData.unique_id)}
//           />
//         </div>
//       ))}
//         </>
//     );
// }


// export default YoutubeLink;


import React, { useEffect, useState, useMemo } from "react";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

function YoutubeLink({ data, smartlink_id, selectedbtn }) {
    const [player, setPlayer] = useState(null);
    const [videoError, setVideoError] = useState(false);
    const youtubeLink = data.link;
    const [channelId, setChannelId] = useState(null);
    const videoId = useMemo(() => {
        const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube.com\/embed\/)([^"&?\/\s]{11})/;
        const match = youtubeLink.match(regex);
        return match ? match[1] : null;
    }, [youtubeLink]);

    const playerId = useMemo(() => `player-${videoId}-${data.unique_id}`, [videoId, data.unique_id]);

    useEffect(() => {
        let iframeApiLoaded = false;
        let playerInstance = null;

        const loadYouTubeApi = () => {
            if (!window.YT) {
                const tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            } else {
                onYouTubeIframeAPIReady();
            }
        };

        const getChannelId = async (videoId) => {
            const apiKey = "AIzaSyAEJRIkuDDb57pTAoqszQ__5gmRKO5h9_0"; // Replace with your API key
            const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${apiKey}`;

            try {
                const response = await fetch(url);
                const data = await response.json();

                if (data.items && data.items.length > 0) {
                    return data.items[0].snippet.channelId;
                } else {
                    console.error("No video data found.");
                }
            } catch (error) {
                console.error("Error fetching channel ID:", error);
            }
            return null; // Return null if no channel ID is found or an error occurs
        };

        const fetchChannelId = async () => {
            if (videoId) {
                const id = await getChannelId(videoId);
                setChannelId(id);
            }
        };

        fetchChannelId();

        const onYouTubeIframeAPIReady = () => {
            iframeApiLoaded = true;
            playerInstance = new window.YT.Player(playerId, {
                height: '390',
                width: '640',
                videoId: videoId,
                playerVars: {
                    autoplay: 0,
                    controls: 1,
                    playsinline: 1,
                },
                events: {
                    onStateChange: onPlayerStateChange,
                    onError: onPlayerError
                }
            });
            setPlayer(playerInstance);
        };

        const onPlayerError = (event) => {
            console.error("YouTube player error:", event);
            setVideoError(true);
        };
        function onPlayerStateChange(event) {
            console.log("window.YT.PlayerState", window.YT.PlayerState)
            switch (event.data) {
                case window.YT.PlayerState.UNSTARTED:
                    saveComponentsClicks({
                        unique_id: data.unique_id,
                        smartlink_id: smartlink_id,
                    });
                    console.log("Video is 00000000000");
                    break;
                case window.YT.PlayerState.PLAYING:
                    console.log("Video is playing");
                    break;
                case window.YT.PlayerState.PAUSED:
                    console.log("Video is paused");
                    break;
                case window.YT.PlayerState.ENDED:
                    console.log("Video has ended");
                    break;
                // You can handle other states such as buffering, video cued, etc.
            }
        }
        if (window.YT && window.YT.Player) {
            onYouTubeIframeAPIReady();
        } else {
            const previousOnYouTubeIframeAPIReady = window.onYouTubeIframeAPIReady;
            window.onYouTubeIframeAPIReady = () => {
                if (previousOnYouTubeIframeAPIReady) {
                    previousOnYouTubeIframeAPIReady();
                }
                onYouTubeIframeAPIReady();
            };
            loadYouTubeApi();
        }

        return () => {
            if (iframeApiLoaded && playerInstance) {
                playerInstance.destroy();
            }
        };
    }, [videoId, playerId, data.unique_id, smartlink_id]);


    if (!videoId) {
        return <div>Invalid YouTube URL</div>;
    }
    console.log("player", selectedbtn)
    return (
        
        <div className="youtube_video_box mb-3" style={{ position: "relative" }} id={data.unique_id}>
            <div id={playerId} ></div>
            {selectedbtn == true && channelId && (
                <div style={{ position: "absolute", top: "182px", left: 5, background: "white", width: "30%", padding: 5, borderRadius: 30, alignItems: "center", justifyItems: "center" }}>
                    <a style={{ textDecoration: "none", color: "black", marginLeft: 13, textAlign: "center" }} href={`https://www.youtube.com/channel/${channelId}`} target="_blank" rel="noopener noreferrer">
                        Subscribe
                    </a>
                </div>
            )}
        </div>
    );
}

export default React.memo(YoutubeLink);
