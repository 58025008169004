import React from 'react';
import { AiFillCloseCircle } from "react-icons/ai"; // Close icon
import { Button, Modal } from 'react-bootstrap';
import { API_URL } from "../../src/BackendApi/Api/config";
import { saveComponentsClicks } from '../BackendApi/Api/flowpageApi';

const SingleImageModal = ({ show, onClose, imagePreview, link, type, unique_id, smartlink_id }) => {
    if (!show) return null;

    const saveClicks = (smartlink_id, unique_id) => {
        saveComponentsClicks({
            unique_id: unique_id,
            smartlink_id: smartlink_id,

        });
    }

    const imageSrc = (imagePreview.startsWith("http://") || imagePreview.startsWith("https://"))
        ? imagePreview
        : `${API_URL}flowpage/${imagePreview}`;

    return (
        <>

            <Modal show={show} onHide={onClose} centered className="qr-code-modal">
                <Modal.Body className="custum-modal-body text-center" style={{ padding: 20, margin: 10 }}>
                    <div className="">

                        <img
                            src={imageSrc}
                            alt=""
                            className="popup-image"
                        />

                        <Button
                            variant="primary"
                            className="bg-green-dark mt-3"
                            onClick={() => {
                                window.open(link, '_blank', 'noopener noreferrer'); // Change 'item.link' to your actual link property
                                { type == "VISIT" && saveClicks(smartlink_id, unique_id); }
                            }}
                        >
                            Visit Link
                        </Button>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SingleImageModal;
