import React, { useContext, useEffect, useState } from 'react';
import "./Dashboard.css";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ColumnChart from '../Components/Chart/ColumnChart';
import ColumnChart2 from '../Components/Chart/Column';
import { TbSmartHome } from "react-icons/tb";
import { getrecentsmartlink, gettrackingdata, getrecientvisits, getDivecevisits, getFlexPagesVisits, getCampaignVisits } from "../BackendApi/Api/smartsLinkApi";
import Loader2 from '../Components/Loader/Loader2';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'react-tooltip'
import CopyCat from '../Components/CopyCat/CopyCat';
import { brandSelectionContext } from '../contexts/context/brandSelectionContext';
import { Form } from 'react-bootstrap';
import Loader from '../Components/Loader/Loader';

function Dashboard() {
  const DEFAULT_FRONTHALF = process.env.REACT_APP_API_URL;
  const [recentSmartlink, setRecentSmartlink] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trackingdata, setTrackingData] = useState([]);
  const [recentsVisits, setRecentsVisits] = useState([]);
  const [findallDiveice, setFindallDiveice] = useState([]);
  const [campaignVisits, setCampaignVisits] = useState([]);
  const [flexPageVisits, setFlexPageVisits] = useState([]);
  const { sidebarBrands } = useContext(brandSelectionContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedOption, setSelectedOption] = useState("Last 7 Days");
  const [selectedOptionTrafficReports, setSelectedOptionTrafficReports] = useState("Last 7 Days");
  const [selectedOptionVisitGrowth, setSelectedOptionVisitGrowth] = useState("Last 7 Days");
  const [selectedOptionRecentFlexlinks, setSelectedOptionRecentFlexlinks] = useState("Last 7 Days");
  const [selectedOptionCampaignVisits, setSelectedOptionCampaignVisits] = useState("Last 7 Days");
  const [selectedOptionFlexPageVisits, setSelectedOptionFlexPageVisits] = useState("Last 7 Days");
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSelectChangeTrafficReports = (event) => {
    setSelectedOptionTrafficReports(event.target.value);
  };
  const handleSelectChangeVisitGrowth = (event) => {
    setSelectedOptionVisitGrowth(event.target.value);
  };

  const handleSelectChangeRecentFlexlinks = (event) => {
    setSelectedOptionRecentFlexlinks(event.target.value);
  };
  const handleSelectChangeCampaignVisits = (event) => {
    setSelectedOptionCampaignVisits(event.target.value);
  };
  const handleSelectChangeFlexPageVisits = (event) => {
    setSelectedOptionFlexPageVisits(event.target.value);
  };








  const data = [

    { name: 'Mon', x: 0, fill: '#beb8f9' },

    { name: 'Tue', x: 0, fill: '#beb8f9' },

    { name: 'Wed', x: 0, fill: '#beb8f9' },

    { name: 'Thu', x: 0, fill: '#beb8f9' },

    { name: 'Fri', x: 0, fill: '#beb8f9' },

    { name: 'Sat', x: 0, fill: '#beb8f9' },

    { name: 'Sun', x: 0, fill: '#beb8f9' }]

  const getTracking = async () => {
    try {
      setLoading(true)
      const id = { owner_id: user.owner_id, selectedOption: selectedOption };
      const res = await gettrackingdata(id);
      const dataFromApi = res.data.data;
      console.log("dataFromApi", dataFromApi)
      if (dataFromApi) {
        if (sidebarBrands.selected == "all") {
          setTrackingData(dataFromApi.length > 0 ? dataFromApi[0] : []);
        } else {
          const filterData = dataFromApi.filter((item) => item.brand_id === sidebarBrands.selected);
          setTrackingData(filterData.length > 0 ? filterData[0] : {
            data: data,
            percentageChange: "0.00%",
            scans: 0,
            clicks: 0,
            startDate: "",
            endDate: "",
          });

        }
      } else {
        PNotify.error({
          title: 'Error',
          text: res.data.message,
        });
      }
    } catch (error) {
      console.error("Error fetching tracking data:", error);
      PNotify.error({
        title: 'Error',
        text: error.message,
        error
      });
    }
  };


  const getdiveceVisits = async () => {
    try {
      const id = { owner_id: user.owner_id, selectedOption: selectedOption };
      const res = await getDivecevisits(id);

      if (res.code === 200 && res.data.status === true) {
        const ApiData = res.data.data;

        if (sidebarBrands.selected === "all") {
          setFindallDiveice(ApiData.length > 0 ? ApiData[0] : []);
        } else {
          const filterData = ApiData.filter((item) => item.brand_id === sidebarBrands.selected);
          setFindallDiveice(filterData.length > 0 ? filterData[0] : { totalVisitors: 0, percentageChange: "0.00%", countryData: { value: 0, percentage: '0.00%', versions: '' } });
        }
      } else {
        const errorMessage = res.code === 200 ? 'No device visits data found' : 'Something went wrong while fetching device visits data';
        PNotify.error({
          title: 'Error',
          text: errorMessage,
        });
        // Set default or empty state
        setFindallDiveice([]);
      }
    } catch (error) {
      console.error("Error fetching device visits data:", error);
      PNotify.error({
        title: 'Error',
        text: ('Error fetching device visits data:', error),
      });
      // Set default or empty state
      setFindallDiveice([]);
    }
  };


  const getRecentsVisits = async () => {
    try {
      const data = { owner_id: user.owner_id, selectedOption: selectedOption };
      const res = await getrecientvisits(data);
      const dataFromApi = res.data.data;
      if (dataFromApi) {
        if (sidebarBrands.selected == "all") {
          setRecentsVisits(dataFromApi.recentVisits);

        } else {
          const filterData = dataFromApi.recentVisits.filter((item) => item.brand_id === sidebarBrands.selected)
          setRecentsVisits(filterData);

        }
      } else {
        PNotify.error({
          title: 'Error',
          text: 'No recents visits data found',
        });
      }
    } catch (error) {
      PNotify.error({
        title: 'Error',
        text: 'Error fetching recents visits data',
        error
      });
    }
  };


  const getRecentSmartlink = async () => {

    const userdata = {
      owner_id: user.owner_id,
      created_by: user._id,
      role: user.role,
      selectedOption: selectedOption,
    }
    const response = await getrecentsmartlink(userdata);
    if (response.code === 200) {
      const dataFromApi = response.data.data
      if (sidebarBrands.selected == "all") {
        setRecentSmartlink(dataFromApi);

      } else {
        const filterData = dataFromApi.filter((item) => item.brand_id === sidebarBrands.selected)
        setRecentSmartlink(filterData);

      }
      setLoading(false)
    } else {
      setLoading(true)
      PNotify.error({
        title: 'Error',
        text: 'No data found',
      });
    }
  };
  const getCampaignVisitsData = async () => {

    const userdata = {
      owner_id: user.owner_id,
      selectedOption: selectedOption,
    }
    const response = await getCampaignVisits(userdata);
    if (response.code === 200) {
      const dataFromApi = response.data.data
      if (sidebarBrands.selected == "all") {
        setCampaignVisits(dataFromApi);

      } else {
        const filterData = dataFromApi.filter((item) => item.brand_id === sidebarBrands.selected)
        setCampaignVisits(filterData);

      }
      setLoading(false)
    } else {
      setLoading(true)
      PNotify.error({
        title: 'Error',
        text: 'No data found',
      });
    }
  };
  const getFlexPageVisits = async () => {

    const userdata = {
      owner_id: user.owner_id,
      selectedOption: selectedOption,
    }
    const response = await getFlexPagesVisits(userdata);
    if (response.code === 200) {
      const dataFromApi = response.data.data
      if (sidebarBrands.selected == "all") {
        setFlexPageVisits(dataFromApi);

      } else {
        const filterData = dataFromApi.filter((item) => item.brand_id === sidebarBrands.selected)
        setFlexPageVisits(filterData);

      }
      setLoading(false)
    } else {
      setLoading(true)
      PNotify.error({
        title: 'Error',
        text: 'No data found',
      });
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("user");
    const dataParse = JSON.parse(data);
    if (dataParse === null) {
      window.location.href = "/";
    } else {
      if (dataParse.token !== dataParse.token) {
        window.location.href = "/";
      }
    }
    Promise.all([getTracking(), getRecentsVisits(), getdiveceVisits(), getRecentSmartlink(), getFlexPageVisits(), getCampaignVisitsData()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));

  }, [
    selectedOption,
    sidebarBrands.selected,
  ]);




  let changeClass;

  const percentageChange = trackingdata ? parseFloat(trackingdata.percentageChange) : 0;

  if (percentageChange > 0) {
    console.log("percentageChange is positive", percentageChange);
    changeClass = 'positive-color';
  } else if (percentageChange < 0) {
    console.log("percentageChange is negative", percentageChange);
    changeClass = 'negative-color';
  } else {
    console.log("percentageChange is zero", percentageChange);
  }
  return (
    <div>
      {loading ? <Loader2 /> : null}
      <>


        <div className="main-container">
          <div className="main-page-body-content">
            <div className="main-page-body-content-body">
              <div className="main-page-body-content-body-container">
                <div className="row">
                  <div className="col-lg-12 pd-26 ps-0 pe-0">
                    <div className="table-card-header add-btn d-flex">
                      <h5 className="main-card-title"><TbSmartHome className="header-icon" />DASHBOARD </h5>
                      <div className='card-date'>
                        <div className="Metrics-form-group">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Select value={selectedOption} onChange={handleSelectChange} as="select" className="Metrics-form-control">
                              <option value="All Time">All Time</option>
                              <option value="Today">Today</option>
                              <option value="Yesterday">Yesterday</option>
                              <option value="Last 7 Days">Last 7 Days</option>
                              <option value="Last 30 Days">Last 30 Days</option>
                              <option value="Last 90 Days">Last 90 Days</option>
                              <option value="Last 6 Months">Last 6 Months</option>
                              <option value="Last 1 Year">Last 1 Year</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 pd-26 ps-0 pe-xl-3 pe-0">
                    <div className="card dashboard-card">
                      <div className="card-body dashboard-card-body">
                        <div className="card-inner-view">
                          <div className="custom-card-header">
                            <div className="card-header-title">
                              <h5 className="card-title">Weekly Traffic Reports</h5>
                              <p className="card-subtitle">View More  <span className="color-text">Analytics</span></p>

                            </div>
                            <div className='card-date'>
                              {/* <div className="Metrics-form-group">
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                  <Form.Select value={selectedOptionTrafficReports} onChange={handleSelectChangeTrafficReports} as="select" className="Metrics-form-control">
                                    <option value="All Time">All Time</option>
                                    <option value="Today">Today</option>
                                    <option value="Yesterday">Yesterday</option>
                                    <option value="Last 7 Days">Last 7 Days</option>
                                    <option value="Last 30 Days">Last 30 Days</option>
                                    <option value="Last 90 Days">Last 90 Days</option>
                                    <option value="Last 6 Months">Last 6 Months</option>
                                    <option value="Last 1 Year">Last 1 Year</option>
                                  </Form.Select>
                                </Form.Group>
                              </div> */}
                              <div className='start-date'>
                                <span>Start Date : {trackingdata.startDate}</span>
                              </div>
                              <div className='end-date'>
                                <span>End Date :{trackingdata.endDate}</span>
                              </div>
                            </div>
                          </div>
                          <div className="custom-card-body">
                            <div className="custom-card-body-inner">
                              <div className="custom-card-body-inner-left">
                                <div className="total-traffic">
                                  <span className="total-traffic-number">{trackingdata.totalCount || 0}</span>
                                  <span className={`total-traffic-text ${changeClass}`}>
                                    {trackingdata.percentageChange}
                                  </span>                                  </div>
                                <div className="total-traffic-text">Flexlinks Visits</div>

                              </div>
                              <div className="custom-card-body-inner-right">
                                <div className="graph-container">
                                  <ColumnChart tracking={trackingdata.data} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="custom-card-footer">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="card-footer-item">
                                  <div className="card-footer-item-title">Total Scans</div>
                                  <div className="card-footer-item-number">{trackingdata.scans}</div>
                                  <ProgressBar className='bg001' now={trackingdata.scans} />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="card-footer-item">
                                  <div className="card-footer-item-title">Total Clicks</div>
                                  <div className="card-footer-item-number">{trackingdata.clicks}</div>
                                  <ProgressBar className='bg002' now={trackingdata.clicks} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 pd-26 ps-xl-2 ps-0 pe-0">
                    <div className="card dashboard-card">
                      <div className="card-body dashboard-card-body">
                        <div className="card-inner-view">
                          <div className="custom-card-header">
                            <div className="card-header-title">
                              <h5 className="card-title">Visit Growth</h5>
                              <p className="card-subtitle">Weekly Report</p>
                            </div>
                            {/* <div className="Metrics-form-group">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Select value={selectedOptionVisitGrowth} onChange={handleSelectChangeVisitGrowth} as="select" className="Metrics-form-control">
                                  <option value="All Time">All Time</option>
                                  <option value="Today">Today</option>
                                  <option value="Yesterday">Yesterday</option>
                                  <option value="Last 7 Days">Last 7 Days</option>
                                  <option value="Last 30 Days">Last 30 Days</option>
                                  <option value="Last 90 Days">Last 90 Days</option>
                                  <option value="Last 6 Months">Last 6 Months</option>
                                  <option value="Last 1 Year">Last 1 Year</option>
                                </Form.Select>
                              </Form.Group>
                            </div> */}
                          </div>
                          <div className="custom-card-body">
                            <div className="custom-card-body-inner">
                              <div className="custom-card-body-inner-left">
                                <div className="total-traffic"><span className="total-traffic-number">{findallDiveice.totalVisitors}</span>
                                  <span className={`total-traffic-text ${findallDiveice.percentageChange > 0 ? 'positive-color' : 'negative-color'}`}>
                                    {findallDiveice.percentageChange}
                                  </span>
                                </div>
                              </div>
                              <div className="custom-card-body-inner-right">
                                <div className="graph-container second-chart">
                                  <ColumnChart2 deviceData={findallDiveice.countryData} loading={Loader2} error={"your device api is not working this time"} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-md-6  pd-26 ps-0 pe-md-3 pe-0">
                    <div className="card dashboard-card">
                      <div className="dashboard-card-body">

                        <div className="card-inner-view">

                          <div className="custom-card-header">

                            <div className="card-header-title">
                              <h5 className="smartlist-title">Recent Flexlinks</h5>

                            </div>
                            {/* <div className="Metrics-form-group">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Select value={selectedOptionRecentFlexlinks} onChange={handleSelectChangeRecentFlexlinks} as="select" className="Metrics-form-control">
                                  <option value="All Time">All Time</option>
                                  <option value="Today">Today</option>
                                  <option value="Yesterday">Yesterday</option>
                                  <option value="Last 7 Days">Last 7 Days</option>
                                  <option value="Last 30 Days">Last 30 Days</option>
                                  <option value="Last 90 Days">Last 90 Days</option>
                                  <option value="Last 6 Months">Last 6 Months</option>
                                  <option value="Last 1 Year">Last 1 Year</option>
                                </Form.Select>
                              </Form.Group>
                            </div> */}
                          </div>
                          {recentSmartlink.map((item, index) => {
                            return (
                              <div key={item.id} className="smartlist-list">
                                <div className="smartlist-list-item">
                                  <div className="smartlist-list-item-left">
                                    <div className="list-item-left-content">
                                      <div className="list-item-icon">
                                        <img src={item.favicon} alt="smartlist-icon" />
                                      </div>
                                      <div className="list-item-text">
                                        <div className="list-item-text-title">{item.title}</div>
                                        <div className="list-item-text-subtitle">{item.description}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="smartlist-list-item-right">
                                    <div className="list-item-right-content" >
                                      <CopyCat text={item.full_short_url} />
                                      {/* <CopyToClipboard text={item.full_short_url}>
                                          <button className="btn btn-sm btn-copy"><FiCopy /></button>
                                        </CopyToClipboard> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pd-26 ps-md-2 ps-0 pe-0">
                    <div className="card dashboard-card">
                      <div className="dashboard-card-body">
                        <div className="card-inner-view">
                          <div className="custom-card-header">
                            <div className="card-header-title">
                              <h5 className="smartlist-title">Recent Visits</h5>
                              <p className="card-subtitle">{recentsVisits.length} Visitors</p>
                            </div>
                            {/* <div className="Metrics-form-group">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Select value={selectedOption} onChange={handleSelectChange} as="select" className="Metrics-form-control">
                                  <option value="All Time">All Time</option>
                                  <option value="Today">Today</option>
                                  <option value="Yesterday">Yesterday</option>
                                  <option value="Last 7 Days">Last 7 Days</option>
                                  <option value="Last 30 Days">Last 30 Days</option>
                                  <option value="Last 90 Days">Last 90 Days</option>
                                  <option value="Last 6 Months">Last 6 Months</option>
                                  <option value="Last 1 Year">Last 1 Year</option>
                                </Form.Select>
                              </Form.Group>
                            </div> */}
                          </div>
                          {Array.isArray(recentsVisits) && recentsVisits.map((item, index) => {
                            return (
                              <>
                                <div key={item._id} className="smartlist-list">
                                  <div className="smartlist-list-item">
                                    <div className="smartlist-list-item-left">
                                      <div className="list-item-left-content">
                                        <div className="list-item-icon">
                                          <img src={item.favicon ? item.favicon : DEFAULT_FRONTHALF + "/mybrand/Popuplogo.png"} alt="icon" />
                                        </div>
                                        <div className="list-item-text">
                                          <div className="list-item-text-title">{item.title}</div>
                                          <div className="list-item-text-subtitle">{item.description ? item.description : "Not Found"}</div>

                                          <span className="visit-detail-label">Device: {item.device ? item.device : "Not Found"}</span><br />
                                          <span className="visit-detail-value">Country: {item.country ? item.country : "Not Found"}</span>

                                        </div>
                                      </div>
                                    </div>
                                    <div className="smartlist-list-item-right">
                                      <div className="list-item-right-content">
                                        <CopyCat text={item.full_short_url} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-md-6  pd-26 ps-0 pe-md-3 pe-0">
                    <div className="card dashboard-card">
                      <div className="dashboard-card-body">

                        <div className="card-inner-view">

                          <div className="custom-card-header">

                            <div className="card-header-title">
                              <h5 className="smartlist-title">FlexPage Visits</h5>
                              <p className="card-subtitle">{flexPageVisits.length} Visitors</p>
                            </div>
                            {/* <div className="Metrics-form-group">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Select value={selectedOptionFlexPageVisits} onChange={handleSelectChangeFlexPageVisits} as="select" className="Metrics-form-control">
                                  <option value="All Time">All Time</option>
                                  <option value="Today">Today</option>
                                  <option value="Yesterday">Yesterday</option>
                                  <option value="Last 7 Days">Last 7 Days</option>
                                  <option value="Last 30 Days">Last 30 Days</option>
                                  <option value="Last 90 Days">Last 90 Days</option>
                                  <option value="Last 6 Months">Last 6 Months</option>
                                  <option value="Last 1 Year">Last 1 Year</option>
                                </Form.Select>
                              </Form.Group>
                            </div> */}
                          </div>
                          {flexPageVisits.map((item, index) => {
                            return (
                              <div key={item._id} className="smartlist-list">
                                <div className="smartlist-list-item">
                                  <div className="smartlist-list-item-left">
                                    <div className="list-item-left-content">
                                      <div className="list-item-icon">
                                        <img src={item.favicon} alt="icon" />
                                      </div>
                                      <div className="list-item-text">
                                        <div className="list-item-text-title">{item.title}</div>
                                        <div className="list-item-text-subtitle">{item.domain_name}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="smartlist-list-item-right">
                                    <div className="list-item-right-content" >
                                      <CopyCat text={item.full_short_url} />
                                      {/* <CopyToClipboard text={item.full_short_url}>
                                          <button className="btn btn-sm btn-copy"><FiCopy /></button>
                                        </CopyToClipboard> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pd-26 ps-md-2 ps-0 pe-0">
                    <div className="card dashboard-card">
                      <div className="dashboard-card-body">
                        <div className="card-inner-view">
                          <div className="custom-card-header">
                            <div className="card-header-title">
                              <h5 className="smartlist-title">Campaign Visits</h5>
                              <p className="card-subtitle">{campaignVisits.length} Visitors</p>
                            </div>
                            {/* <div className="Metrics-form-group">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Select value={selectedOptionCampaignVisits} onChange={handleSelectChangeCampaignVisits} as="select" className="Metrics-form-control">
                                  <option value="All Time">All Time</option>
                                  <option value="Today">Today</option>
                                  <option value="Yesterday">Yesterday</option>
                                  <option value="Last 7 Days">Last 7 Days</option>
                                  <option value="Last 30 Days">Last 30 Days</option>
                                  <option value="Last 90 Days">Last 90 Days</option>
                                  <option value="Last 6 Months">Last 6 Months</option>
                                  <option value="Last 1 Year">Last 1 Year</option>
                                </Form.Select>
                              </Form.Group>
                            </div> */}
                          </div>
                          {Array.isArray(campaignVisits) && campaignVisits.map((item, index) => {
                            return (
                              <>
                                <div key={item.id} className="smartlist-list">
                                  <div className="smartlist-list-item">
                                    <div className="smartlist-list-item-left">
                                      <div className="list-item-left-content">
                                        <div className="list-item-icon">
                                          <img src={item.favicon ? item.favicon : DEFAULT_FRONTHALF + "/mybrand/Popuplogo.png"} alt="icon" />
                                        </div>
                                        <div className="list-item-text">
                                          <div className="list-item-text-title">{item.campaign_name}</div>
                                          <div className="list-item-text-subtitle">{item.campaign_description ? item.campaign_description : "Not Found"}</div>

                                          {/* <span className="visit-detail-label">Device: {item.device ? item.device : "Not Found"}</span><br />
                                          <span className="visit-detail-value">Country: {item.country ? item.country : "Not Found"}</span> */}

                                        </div>
                                      </div>
                                    </div>
                                    <div className="smartlist-list-item-right">
                                      <div className="list-item-right-content">
                                        <CopyCat text={item.destination_url} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>


    </div>
  );
}

export default Dashboard;