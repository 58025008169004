import React, { useContext, useEffect, useState } from 'react';
// import "./Dashboard.css";
import { TbSmartHome } from "react-icons/tb";
import Informasi from '../../Components/DataTable Technician/Informasi';
import Loader2 from '../../Components/Loader/Loader2';
import InformasiBrands from '../../Components/DataTable Technician/InformasiBrands';

function Brands() {
    const DEFAULT_FRONTHALF = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div>
            {loading ? <Loader2 /> : null}
            <>
                <div className="main-container">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <InformasiBrands type="brands" />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        </div>
    );
}

export default Brands;