

import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useBuilderContext } from "../../../builderContext";
import { Form } from "react-bootstrap";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";
import { v4 as uuidv4 } from "uuid";
import { API_URL } from "../../../BackendApi/Api/config";
import { icons } from "../../../Helpers/socialsImages";
import { BsChevronDown } from "react-icons/bs";
import { FaChevronRight, FaTrash } from "react-icons/fa";
import EmojiPicker from 'emoji-picker-react';
import CustomDropdown from "../../../Helpers/CustomDropdown";
export function EditButtonLink() {
    const { editComponent, myComponents } = useBuilderContext();
    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus;

    const [btnProperties, setBtnProperties] = useState({
        unique_id: '',
        label: "",
        link: "",
        thumbnailPosition: "",
        thumbnail: null,
        backgroundColor: "",
        buttonStyle: "",
        buttonShape: "",
        hoverAnimation: "",
        size: "",
        emojis: null,
        animation: "",
        jumpToAnchor: "",
        subtext: "",
        subtextSize: "",
        selectedOption: 0
    });

    const [thumbnail, setThumbnail] = useState(null);
    const [type, setType] = useState("new");
    const profileImageRef = useRef(null);
    const [selectedColor, setSelectedColor] = useState(btnProperties.backgroundColor);
    const [showPicker, setShowPicker] = useState(false);
    const [selectedOption2, setSelectedOption2] = useState(btnProperties.selectedOption);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedIcons, setSelectedIcons] = useState([]);

    useEffect(() => {
        if (mydata) {
            setBtnProperties(prevProps => ({
                ...prevProps,
                ...mydata,
                thumbnail: mydata.thumbnail || null,
            }));
            setSelectedColor(mydata.backgroundColor || '#ACO21');
            setSelectedOption2(mydata.selectedOption || 0);
            setThumbnail(mydata.thumbnail || null);
            setType(mydata.thumbnail ? "existing" : "new");
            setBtnProperties(prevProps => ({ ...prevProps, jumpToAnchor: mydata.jumpToAnchor }));
        }
        console.log("mydata", mydata);
    }, [mydata, selectedOption2, btnProperties.jumpToAnchor]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBtnProperties(prevProps => ({ ...prevProps, [name]: value }));
    };

    const handleSaveClick = async () => {
        try {
            let uploadedImageUrl = btnProperties.thumbnail;

            if (thumbnail && type === "new") {
                const formData = new FormData();
                formData.append("image", btnProperties.thumbnail);
                const res = await uploadSingleImageApi(formData);

                if (res.data.status === true) {
                    uploadedImageUrl = res.data.data.filename;
                } else {
                    console.error("Image upload failed");
                    return;
                }
            }

            const unique_id = btnProperties.unique_id || Date.now() + uuidv4();
            const updatedButtonProps = {
                ...btnProperties,
                unique_id,
                thumbnail: uploadedImageUrl || btnProperties.thumbnail,
            };

            editComponent(position, "BUTTON", updatedButtonProps, showStatus);
        } catch (error) {
            console.error("Save failed", error);
        }
    };

    const handleThumbnailChange = (e) => {
        const selectedThumbnail = e.target.files[0];
        if (selectedThumbnail) {
            const imageURL = URL.createObjectURL(selectedThumbnail);
            setThumbnail(imageURL);
            setType("new");
            setBtnProperties(prev => ({ ...prev, thumbnail: selectedThumbnail }));
        }
    };
    const handleBackgroundColorChange1 = (color) => {
        setSelectedColor(color); // Update the selected color
        setBtnProperties(prev => ({ ...prev, backgroundColor: color }));
    };
    const handleOptionChange2 = (value) => {
        // clearThumbnail();
        setBtnProperties(prev => ({ ...prev, thumbnail: null, emojis: null }));
        setSelectedOption2(value);
    };
    const handleIconClick = (icon) => {
        setThumbnail(icon.link);
        setBtnProperties(prev => ({
            ...prev,
            thumbnail: icon.link,
            emojis: null
        }));
    };
    const presetColors = [
        "#a94002",
        "#d2b43f",
        "#3e734b",
        "#fcd066",
        "#b29038",
        "#4c3a05"
    ];



    // Handle category change
    const handleCategoryChange = (e) => {
        const category = e.target.value;
        setSelectedCategory(category);
        setSelectedIcons(icons[category] || []); // Category ke icons ko set karna
    };


    const getIcons = () => {
        let categories = ["social", "chat", "payment", "shopping", "standard"];
        const thumbnail = btnProperties.thumbnail;

        let foundCategory = null;
        let filteredIcons = [];

        categories.forEach(category => {
            const iconsInCategory = icons[category].filter(icon => icon.link === thumbnail);

            if (iconsInCategory.length > 0) {
                foundCategory = category;
                filteredIcons = iconsInCategory.map(icon => icon.name);
            }
        });


        return { filteredIcons, foundCategory };
    };

    const clearThumbnail = () => {
        if (thumbnail && thumbnail.startsWith('blob:')) {
            URL.revokeObjectURL(thumbnail);
        }
        setThumbnail(null);
        setBtnProperties(prev => ({ ...prev, thumbnail: null, emojis: null }));
        if (profileImageRef.current) {
            profileImageRef.current.value = '';
        }
    };

    const handleEmojiClick = (emojiObject) => {
        const emoji = emojiObject.emoji;
        setBtnProperties(prev => ({
            ...prev,
            emojis: emoji,
            thumbnail: null
        }));
        setThumbnail(null);
    };

    console.log("btnProperties", btnProperties);
    console.log("btnProperties", selectedOption2);

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Button Link</label>
                    </div>
                </div>

                <div className="form_box cvtPmc">
                    {/* Image Upload */}
                    <div className="image-input-container mb-4" style={{ position: 'relative' }}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleThumbnailChange}
                            ref={profileImageRef}
                            style={{ display: 'none' }}
                        />
                        <div className="preview_image_box">
                            {btnProperties.emojis != null && !btnProperties.thumbnail ? (
                                <span style={{
                                    fontSize: '2em',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%'
                                }}>
                                    {btnProperties.emojis}
                                </span>
                            ) : btnProperties.thumbnail ? (
                                <img
                                    src={type === "new" ? thumbnail :
                                        (btnProperties.thumbnail.startsWith("http://") || btnProperties.thumbnail.startsWith("https://")
                                            ? btnProperties.thumbnail : API_URL + "flowpage/" + btnProperties.thumbnail)}
                                    className="preview-image"
                                    alt="Thumbnail preview"
                                />

                            ) : (
                                <img
                                    src={require('../../../assets/images/image_default.png')}
                                    alt="Default"
                                    className="default-image"
                                />
                            )}
                        </div>


                        {(selectedOption2 === 1 || selectedOption2 === 0) && (
                            <div className="image-input" onClick={() => profileImageRef.current.click()}>
                                <span className="icon">📷</span>
                                <span className="text">Upload Thumbnail (PNG, JPG or GIF)</span>

                            </div>
                        )}
                        {selectedOption2 === 2 && (
                            <div className="image-input" onClick={() => { selectedOption2 === 2 ? setShowPicker(prevState => !prevState) : profileImageRef.current.click() }}>
                                {btnProperties.emojis || selectedOption2 == 2 ? (
                                    <span className="text" >
                                        {btnProperties.emojis ? 'Change Emoji' : ' 😚 Choose Profession Emoji'}</span>) :
                                    (<>
                                        <span className="icon">📷</span>
                                        <span className="text">Upload Thumbnail (PNG, JPG or GIF)</span></>)}

                            </div>
                        )}

                        {/* Show delete button if there's any content */}
                        {(btnProperties.thumbnail || btnProperties.emojis) && (
                            <button
                                style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    color: 'red',
                                    cursor: 'pointer',
                                    width: '20px',
                                    height: '50px',
                                }}
                                onClick={clearThumbnail}
                            >
                                <FaTrash size={20} />
                            </button>
                        )}
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="From icon"
                                            type={type}
                                            id={`inline-${type}-1005`}
                                            checked={selectedOption2 === 1}
                                            onChange={() => handleOptionChange2(1)}
                                        />
                                        <Form.Check
                                            inline
                                            label="Emoji"
                                            type={type}
                                            id={`inline-${type}-1006`}
                                            onChange={() => handleOptionChange2(2)}
                                            checked={selectedOption2 === 2}
                                        />

                                    </div>

                                ))}


                            </Form>

                            {selectedOption2 == 2 &&
                                <button
                                    style={{ border: '1px solid lightgray', backgroundColor: 'transparent', color: 'black', cursor: 'pointer', marginBottom: '10px', padding: '5px' }}
                                    onClick={() => selectedOption2 === 2 && setShowPicker(prevState => !prevState)}>
                                    {btnProperties.emojis ? 'Change Emoji' : ' 😚 Choose Emoji'}
                                </button>}
                        </div>
                        {selectedOption2 === 1 &&
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: "row", position: "relative" }}>
                                    <select id="categoryDropdown" value={selectedCategory} className="form-control" onChange={handleCategoryChange}>
                                        <option value={getIcons().foundCategory}>{getIcons().foundCategory ? getIcons().foundCategory.charAt(0).toUpperCase() + getIcons().foundCategory.slice(1) : "Select social icon"}</option>
                                        {Object.keys(icons).map((category, index) => (
                                            <option key={index} value={category}>
                                                {category.charAt(0).toUpperCase() + category.slice(1)}
                                            </option>
                                        ))}
                                    </select>
                                    <BsChevronDown style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }} />
                                </div>
                                {selectedCategory && (
                                    <div style={{ marginTop: '20px' }}>
                                        <p>{selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Icons:</p>
                                        {selectedIcons.map((icon, index) => (
                                            <div style={{ display: 'inline-block', margin: '10px' }} onClick={() => handleIconClick(icon)} key={index}>
                                                <img
                                                    src={icon.link}
                                                    alt={icon.name}
                                                    style={{ width: 50, height: 50 }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        }
                        {selectedOption2 === 2 &&

                            <div className="form-group mb-4" >
                                {showPicker && (
                                    <EmojiPicker
                                        onEmojiClick={(emoji) => {
                                            handleEmojiClick(emoji);
                                            setShowPicker(false); // Close picker after emoji selection
                                        }}
                                    />
                                )}
                            </div>
                        }
                    </div>

                    <div>

                        {selectedCategory && (
                            <div style={{ marginTop: '20px' }}>
                                <p>{selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Icons:</p>
                                {selectedIcons.map((icon, index) => (
                                    <div style={{ display: 'inline-block', margin: '10px' }} onClick={() => handleIconClick(icon)} key={index}>
                                        <img
                                            src={icon.link}
                                            alt={icon.name}
                                            style={{ width: 50, height: 50 }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="form-group did-floating-label-content mt-4">
                        {/* Custom Dropdown */}
                        <CustomDropdown
                            jumpToAnchor={btnProperties.jumpToAnchor}
                            setBtnProperties={setBtnProperties}
                            myComponents={myComponents}
                        />
                    </div>
                    {/* Other form fields */}
                    {Object.entries(btnProperties).map(([key, value]) => {
                        if (["unique_id", "thumbnail", "useEmoji", "emojis", "emoji", "backgroundColor", "selectedOption", "jumpToAnchor"].includes(key)) return null;

                        return (
                            <div key={key} className="form-group did-floating-label-content mt-4">
                                <div className="form-group mb-4">
                                    {["thumbnailPosition", "size", "buttonShape", "buttonStyle", "hoverAnimation", "animation", "subtextSize"].includes(key) ? (
                                        <select
                                            name={key}
                                            value={value}
                                            onChange={handleInputChange}
                                            className="form-control did-floating-input"
                                        >
                                            {key === "thumbnailPosition" && (
                                                <>
                                                    <option value="left">Left</option>
                                                    <option value="right">Right</option>
                                                </>
                                            )}

                                            {key === "size" && (
                                                <>
                                                    <option value="large">Large</option>
                                                    <option value="small">Small</option>
                                                </>
                                            )}

                                            {key === "buttonShape" && (
                                                <>

                                                    <option value="rectangle">Rectangle</option>
                                                    <option value="curvedRectangle">Curved Rectangle</option>
                                                    <option value="rounded">Rounded</option>
                                                </>
                                            )}

                                            {key === "buttonStyle" && (
                                                <>
                                                    <option value="outline">Outline</option>
                                                    <option value="Shadow">Shadow</option>
                                                    <option value="Filled">Filled</option>
                                                </>
                                            )}

                                            {key === "hoverAnimation" && (
                                                <>
                                                    <option value="fill-empty">Fill/Empty</option>
                                                    <option value="grow-shrink">Grow/Shrink</option>
                                                    <option value="shake">Shake</option>
                                                </>
                                            )}
                                            {key === "animation" && (
                                                <>
                                                    <option value="none">No Animation</option>
                                                    <option value="pulsing">Pulsing</option>
                                                    <option value="shake">Shake every 5 seconds</option>
                                                </>
                                            )}
                                            {(key === "subtextSize") && (
                                                <>
                                                    <option value="standard">Standard</option>
                                                    <option value="tall">Tall (Title + Subtext)</option>
                                                </>
                                            )}
                                            {btnProperties.subtextSize === 'tall' && (
                                                <div className="form-group did-floating-label-content mt-4">
                                                    <div className="form-group mb-4">
                                                        <input
                                                            type="text"
                                                            className="form-control did-floating-input"
                                                            value={btnProperties.subtext}
                                                            onChange={(e) => setBtnProperties(prev => ({ ...prev, subtext: e.target.value }))}
                                                            placeholder="Button Subtext"
                                                        />
                                                        <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Subtext</label>
                                                    </div>
                                                </div>

                                            )}



                                            {/* Add similar conditions for other select fields */}
                                        </select>
                                    ) : (
                                        <input
                                            type="text"
                                            name={key}
                                            className="form-control did-floating-input"
                                            placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                                            value={value}
                                            onChange={handleInputChange}
                                        />
                                    )}
                                    <label className="gvofes did-floating-label">
                                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                    </label>
                                </div>

                            </div>
                        );

                    })}
                    <div className="form-group did-floating-label-content">
                        <div className="default-color-picker-list">
                            {presetColors.map((color, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: 40,
                                        height: 40,
                                        margin: "8px",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        border: selectedColor === color ? "2px solid aqua" : 'none', // Highlight selected color
                                    }}
                                >
                                    <button
                                        className="default-color-picker-list-item-inner"
                                        style={{
                                            backgroundColor: color,
                                            borderRadius: '50%', // Make it a circle
                                            width: '100%',
                                            height: '100%',
                                            border: 'none', // Remove default button border
                                        }}
                                        onClick={() => handleBackgroundColorChange1(color)}
                                    ></button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick}>
                    Update
                </button>
            </div>
        </>
    );
}