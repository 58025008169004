
import React, { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import SearchBox from './SearchBox';
import Button from 'react-bootstrap/Button';
import './table.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Profile from '../../assets/images/Avatar2.png';
import { FiCopy } from "react-icons/fi";
import Accordion from "../Accordion/Accordion";
import { API_URL } from "../../BackendApi/Api/config";
import { BiDetail } from "react-icons/bi";
import {  AiOutlineDisconnect,  } from "react-icons/ai";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TbDisabled } from "react-icons/tb";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
const InformasiBrands = () => {
    const userdata = localStorage.getItem("user");
    const user = JSON.parse(userdata);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show02, setShow02] = useState(false);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = () => setShow02(true);
    const [brands, setBrands] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);             // Current page
    const [sizePerPage, setSizePerPage] = useState(10); // Size per page
    const [totalRecords, setTotalRecords] = useState(0);
    const [disableId, setDisableId] = useState(null)
    const [disableStatus, setDisableStatus] = useState(null)
    const datetimeFormatter = (cell, row) => {
        return moment(cell).format('DD MMM YYYY hh:mm');
    }
    const [show04, setShow04] = useState(false)
    const handleClose04 = () => setShow04(false);
    const handleShow04 = (id, status) => {
        setShow04(true);
        setDisableId(id);
        setDisableStatus(status)
    };

    const handleDisableBrand = async (id) => {
        const userData = await fetch(`${API_URL}branding/disbleBrands`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                brand_id: disableId,
                disabledStatus: disableStatus
            })
        })
        const res = await userData.json();
        if (res.status) {

            handleClose04()
            PNotify.success({
                title: 'success',
                text: `Brand ${disableStatus ? "Disable" : "Enable"} successfully`,
            });
        } else {
            PNotify.error({
                title: 'Oh No!',
                text: ` brand ${disableStatus ? "Disable" : "Enable "} failed`,
            });
            console.log('Failed to fetch user');
        }

    }
    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1;
            }
        },
        {
            dataField: 'company_name',
            text: 'Company Name',
            sort: true
        },
        {
            dataField: 'company_slug',
            text: 'Company Slug',
            sort: true
        },
         {
            dataField: 'email',
            text: 'Email',
            sort: true
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,

            formatter: (cell) => {
                if(!cell){
                    return 'not available';
                }
                const words = cell.split(' ');
               
                return words.slice(0, 100).join(' ') + (words.length > 100 ? '...' : '');
            }
        },        
        {
            dataField: 'address',
            text: 'Address',
            sort: true,

            formatter: (cell) => {
                if(!cell){
                    return 'not available';
                }
                const words = cell.split(' ');
               
                return words.slice(0, 100).join(' ') + (words.length > 100 ? '...' : '');
            }
        },        
        {
            dataField: 'phone',
            text: 'phone',
            sort: true,
            formatter: (cell) => {
                if(!cell){
                    return 'not available';
                }
                return  cell
            }
        },
        {
            dataField: 'logo_light', text: 'Logo Light',
            formatter: (cell) => cell ?
                <img src={`${API_URL}brands/${cell}`} alt="Exam Logo"
                    style={{ width: '50px', height: "50px", borderRadius: 20 }} />
                : null,
            visible: true
        },
        {
            dataField: 'logo_dark', text: 'Logo Dark',
            formatter: (cell) => cell ?
                <img src={`${API_URL}brands/${cell}`} alt="Exam Logo"
                    style={{ width: '50px', height: "50px", borderRadius: 20 }} />
                : null,
            visible: true
        },
        {
            dataField: 'logo_full', text: 'Logo Full',
            formatter: (cell) => cell ?
                <img src={`${API_URL}brands/${cell}`} alt="Exam Logo"
                    style={{ width: '50px', height: "50px", borderRadius: 20 }} />
                : null,
            visible: true
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => (
                <>
                    <Button
                        className={`btn btn-sm me-2 ${row.brandsDisabled == true ? 'btn-success' : 'btn-primary'}`}
                        onClick={() => handleShow04(row._id, !row.brandsDisabled)}>
                        <AiOutlineDisconnect />
                    </Button>

                </>
            ),
            visible: true
        },
    ]

    const handleSearch = (searchValue) => {
        setSearchText(searchValue)
    }

    const getAllBrands = async (page, limit) => {
        if (searchText) {
            limit = totalRecords
        }
        const branding = await fetch(`${API_URL}branding/getAllBrand`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({
                user_id: user?._id,
                page: page,
                limit: limit,
            })
        })
        const res = await branding.json();
        if (res.status) {
            setBrands(res.data.brands); // Update the brands with the response data
            setTotalRecords(res.data.totalRecords);
        } else {
            console.log('Failed to fetch user');
        }
    }
    useEffect(() => {
        const indexOfLastItem = page * sizePerPage;
        const indexOfFirstItem = indexOfLastItem - sizePerPage;
        const currentData = brands.slice(indexOfFirstItem, indexOfLastItem);
        getAllBrands(page, sizePerPage);
    }, [page, sizePerPage, brands]);

    const handleTableChange = (type, { page, sizePerPage }) => {
        console.log('changing table ', type, sizePerPage, page);
        setPage(page);
        setSizePerPage(sizePerPage);
    };

    const filteredbrand = brands.filter(brand =>
        brand.email?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        brand.company_name?.toLowerCase()?.includes(searchText.toLowerCase())
    );
    const paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        page,
        sizePerPage,
        totalSize: totalRecords, // Use total records for pagination
        sizePerPageList: [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            { text: "50", value: 50 },
            { text: "All", value: totalRecords },
        ],
    };

    return (
        <>

            {/* Delete Card modal start here */}
            < Modal show={show02} onHide={handleClose02} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title>Create New User</Modal.Title>
                    {/* <CloseButton variant="white" /> */}
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="logo-content">
                                <div className="add-logo">
                                    <div className="image-preview">
                                        <div className="loading-screen"><span className="loader">No logo</span></div>
                                    </div>
                                    <div className="mt-2">
                                        <div className="form-group block-upload">
                                            <div className="upload-logo">
                                                <div className="custom-file"><input type="file" className="custom-file-input" id="customFile" alt="Choose" /><label className="custom-file-label" for="customFile">Choose</label></div>
                                            </div>
                                        </div>
                                        {/* <button className="btn btn-default btn-remove">Remove logo</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row custom-pt-20">
                        <div className="col-md-6">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label for="userpleFormControlInput1" className="form-label">First Name</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="First Name" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Last Name" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">Phone Number</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Phone Number" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">Email Address</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                <Form.Label>Customer Stage</Form.Label>
                                <Form.Select aria-label="Default select example" className="custom-select-box">
                                    <option>Select Customer Stage</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                <Form.Label>Client Owner</Form.Label>
                                <Form.Select aria-label="Default select example" className="custom-select-box">
                                    <option>Select Client Owner</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">Street Address</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Street Address" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">Zip Code</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Zip Code" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                <Form.Label>State</Form.Label>
                                <Form.Select aria-label="Default select example" className="custom-select-box">
                                    <option>Select State</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                <Form.Label>City</Form.Label>
                                <Form.Select aria-label="Default select example" className="custom-select-box">
                                    <option>Select City</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <form>
                                    <div className="form-group mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">Special requests ( Notes )</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose02}> Update </button>
                    <button className="Cancel-bttn btn" onClick={handleClose02}> Cancel </button>
                </Modal.Footer>
            </Modal >
            {/* modal End here */}

            {/* modal Start here */}
            <Modal show={show} onHide={handleClose} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Denial Dev</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="user-profile-details">
                                <div className="user-profile-details-inner">
                                    <div className="user-profile-img">
                                        <img src={Profile} alt="" />
                                    </div>
                                    <div className="user-profile-name">
                                        <h4>Denial Dev</h4>
                                        <p>denialdev@gmail.com <button className="copy-btn" ><FiCopy /></button></p>
                                        <p>+1312654654 <button className="copy-btn" ><FiCopy /></button></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row custom-pt-20">
                        <div className="col-md-12">
                            <Accordion />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose}> Update </button>
                    <button className="Cancel-bttn btn" onClick={handleClose}> Cancel </button>
                </Modal.Footer>
            </Modal>

            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbDisabled />Confirm {disableStatus ? "Disable" : "Enable"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to {disableStatus ? "disable" : "enable"} this Brand?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={handleDisableBrand}>
                        {disableStatus ? "Disable" : "Enable"}
                    </Button>
                </Modal.Footer>
            </Modal>


            <div className="animated fadeIn" style={{
                padding: '0', margin: '0', width: "1372px"
            }}>
                <div className="container-fluid" >
                    <div className="row" >
                        <div className="col-lg-12" >
                            <div className="card">
                                <div className="card-header d-flex table-card-header">
                                    <SearchBox onSearch={handleSearch} />
                                    {/* <Button className="btn btn-sm float-right add-user-btn"
                                        onClick={handleShow02}
                                    >
                                        <RiAddFill /> Add New User

                                    </Button> */}
                                </div>
                                <div className="card-body">
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='id'
                                        data={filteredbrand}
                                        columns={columns}
                                        pagination={paginationFactory(paginationOptions)}
                                        onTableChange={handleTableChange}
                                        remote={{ pagination: true }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default InformasiBrands;