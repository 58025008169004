import React, { useState, useRef, useEffect, useContext } from "react";

import axios from "axios";
import { MdOutlineDeleteForever, MdSchedule } from "react-icons/md";
import { Modal, Col } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import Picker from 'emoji-picker-react';
import { v4 as uuid } from "uuid";
import { DateTime } from 'luxon';
import { FiPlus } from "react-icons/fi";
import AiPosts from "../AiPosts"
import SpinnerLoader from "../../Components/SpinnerLoader/SpinnerLoader";
import { GoFileMedia } from "react-icons/go";
import { ImMagicWand } from "react-icons/im";
import MediaPost from "../MediaPost";
import useScheduleDataActions from "../../contexts/actions/useScheduleDataActions";
import useArchivedDataActions from "./../../contexts/actions/useArchivedDataActions";
import useDraftDataActions from '../../contexts/actions/useDraftDataActions'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import AuthContext from "../../AuthContext";
import { SiCanva } from "react-icons/si";
import { useLocation } from "react-router-dom";



const API_URL = process.env.REACT_APP_API_URL;
const Channels = ({ handleClose, postData }) => {

    const location = useLocation();


    const { setIsAuthenticated, setIsCanvaConnected, setAuthData, isCanvaConnected, isAuthenticated } = useContext(AuthContext)

    const { getscheduleddata } = useScheduleDataActions()
    const { getdrafteddata } = useDraftDataActions();
    const { getarchieveddata } = useArchivedDataActions();
    const user = JSON.parse(localStorage.getItem('user'));
    const [masterPosts, setMasterPosts] = useState([]);
    const [customization, setCustomization] = useState(false)
    const [typeStatus, setTypeStatus] = useState("")
    const textareaRef = useRef(null);
    const [inputStr, setInputStr] = useState('');

    const [show, setShow] = useState(false);


    const [isAiView, setIsAiView] = useState(false);

    const [indexforai, setIndexforai] = useState(null);
    const handleClose1 = () => {
        // alert("i am closing this");
        setShow(false);
    }
    const handleShow = () => setShow(true);
    const [showPicker, setShowPicker] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);



    const adjustHeight = () => {
        const element = textareaRef.current;
        if (element) {
            // Reset the height to a minimum value to ensure it can shrink
            element.style.height = 'auto';
            // Set the height to the scroll height to fit the content
            element.style.height = `${element.scrollHeight}px`;
        }
    };

    const generateVideoThumbnails = async (videoFile, numberOfThumbnails) => {
        try {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(videoFile);
            await video.play();

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const interval = video.duration / numberOfThumbnails;

            const thumbnailPromises = Array.from({ length: numberOfThumbnails }, (_, index) => {
                const time = index * interval;
                video.currentTime = time;
                return new Promise((resolve) => {
                    video.addEventListener('seeked', () => {
                        context.drawImage(video, 0, 0, canvas.width, canvas.height);
                        const thumbnailDataUrl = canvas.toDataURL('image/jpeg');
                        resolve(thumbnailDataUrl);
                    });
                });
            });

            const thumbnails = await Promise.all(thumbnailPromises);

            return thumbnails[0];
            // setThumbnails(thumbnails);
        } catch (error) {
            console.error('Error generating thumbnails:', error);
            return null;
        }
    };


    const handleuploadfile = async (file, index) => {

        try {

            var uniqueId = uuid();

            if (file.type.startsWith('video/')) {

                const updatedPosts = [...masterPosts];
                updatedPosts[index].videos = [...updatedPosts[index].videos, { id: uniqueId, imgUrl: "", isUploading: true }];
                setMasterPosts(updatedPosts);
            }



            if (file.type.startsWith("image/")) {

                const updatedPosts = [...masterPosts];
                updatedPosts[index].images = [...updatedPosts[index].images, { id: uniqueId, imgUrl: "", isUploading: true }];
                setMasterPosts(updatedPosts);
            }

            console.log("masterposts : ", masterPosts);

            const formData = new FormData();
            formData.append('image', file);


            const response = await fetch(`${API_URL}/socialposts/upload`, {
                method: 'POST',
                body: formData
            });

            const result = await response.json();

            if (result.status) {
                console.log("file".file)
                if (file.type.startsWith('video/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                        updatedPosts[index].videos[mediaIndex].imgUrl = result.filename;
                        updatedPosts[index].videos[mediaIndex].isUploading = false;
                        return updatedPosts;
                    });
                }
                if (file.type.startsWith('image/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                        updatedPosts[index].images[mediaIndex].imgUrl = result.filename;
                        updatedPosts[index].images[mediaIndex].isUploading = false;

                        return updatedPosts;
                    });
                }

                console.log("masterposts after : ", masterPosts);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: "Error uploading file",
                })
                if (file.type.startsWith('video/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                        //delete
                        updatedPosts[index].videos.splice(mediaIndex, 1);
                        return updatedPosts;
                    });
                }

                if (file.type.startsWith('image/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                        //delete
                        updatedPosts[index].images.splice(mediaIndex, 1);
                        return updatedPosts;
                    });
                }

            }

        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: "Error uploading file",
            })
            if (file.type.startsWith('video/')) {
                setMasterPosts(prevPosts => {
                    const updatedPosts = [...prevPosts];
                    const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                    //delete
                    updatedPosts[index].videos.splice(mediaIndex, 1);
                    return updatedPosts;
                });
            }

            if (file.type.startsWith('image/')) {
                setMasterPosts(prevPosts => {
                    const updatedPosts = [...prevPosts];
                    const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                    //delete
                    updatedPosts[index].images.splice(mediaIndex, 1);
                    return updatedPosts;
                });
            }

            // Handle error if necessary
        }
    }



    // const onEmojiClick = (emojiObject) => {
    //     setInputStr(prevInput => prevInput + emojiObject.emoji);
    //     setShowPicker(false);
    // };
    function convertToLocalTimezone(dateString) {
        console.log("incoming date string : ", dateString);
        const kolkataDate = DateTime.fromISO(dateString, { zone: 'Asia/Kolkata' });

        if (!kolkataDate.isValid) {
            console.error("Invalid date string");
            return null;
        }

        const localDate = kolkataDate.setZone(DateTime.local().zoneName);
        const localDateString = localDate.toISO({ includeOffset: false, suppressMilliseconds: true }).slice(0, 16);

        console.log("local date string : ", localDateString);
        return localDateString;
    }

    function convertToKolkataTimezone(dateString) {
        const localDate = DateTime.fromISO(dateString, { zone: DateTime.local().zoneName });

        if (!localDate.isValid) {
            console.error("Invalid date string");
            return null;
        }

        const kolkataDate = localDate.setZone('Asia/Kolkata');
        const kolkataDateString = kolkataDate.toISO({ includeOffset: false, suppressMilliseconds: true }).slice(0, 16);

        return kolkataDateString;
    }

    const insertAsset = async (design_id, uniqueId) => {
        const user = JSON.parse(localStorage.getItem("user"));

        try {
            const response = await fetch(`${API_URL}/socialposts/exportCanvaDesign`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    design_id: design_id,
                    user_id: user?.owner_id
                })
            });

            const data = await response.json();
            if (data.success) {
                setMasterPosts((pre) => {
                    const updatedPosts = [...pre];
                    updatedPosts[0] = {
                        ...updatedPosts[0],
                        images: updatedPosts[0].images.map(image =>
                            image.id === uniqueId
                                ? { ...image, imgUrl: data.url, isUploading: false }
                                : image
                        )
                    };
                    return updatedPosts;
                });
                localStorage.removeItem('design_data');
            } else {
                // Remove image with the uniqueId in case of failure
                setMasterPosts((pre) => {
                    const updatedPosts = [...pre];
                    updatedPosts[0] = {
                        ...updatedPosts[0],
                        images: updatedPosts[0].images.filter(image => image.id !== uniqueId)
                    };
                    return updatedPosts;
                });
                console.error('Failed to upload asset:', data.message);
            }
        } catch (error) {
            // Remove image with the uniqueId in case of error
            setMasterPosts((pre) => {
                const updatedPosts = [...pre];
                updatedPosts[0] = {
                    ...updatedPosts[0],
                    images: updatedPosts[0].images.filter(image => image.id !== uniqueId)
                };
                return updatedPosts;
            });
            console.error('Error creating Canva design:', error);
        }
    };



    useEffect(() => {

        const checkCanvaRedirect = () => {
            const urlParams = new URLSearchParams(location.search);
            const source = urlParams.get('source');

            if (source === 'canva_redirect') {


                const localData = JSON.parse(localStorage.getItem("design_data"));

                if (localData?.method && localData?.method === "edit_post") {

                    if (localData) {
                        const design_id = localData?.data?.id;
                        const uniqueId = uuid();

                        setMasterPosts(prevPosts => {
                            const updatedPosts = [...localData?.masterPosts];
                            const firstPost = updatedPosts[0] || {};

                            return [{
                                ...firstPost,
                                images: [
                                    ...firstPost.images,
                                    { id: uniqueId, imgUrl: "", isUploading: true }
                                ],
                                mediaType: "image"
                            }];
                        });

                        insertAsset(design_id, uniqueId);

                    }
                } else {
                    setMasterPosts(prevPosts => [postData]);
                }
            } else {
                setMasterPosts(prevPosts => [postData]);
            }
        };

   
        checkCanvaRedirect();

    }, [postData]);


    const UpdateData = async (type) => {
        if (masterPosts[0].title == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter title',
            })
            // alert("Please enter title");
            setLoading(false);
            setLoading2(false);
            return;
        }

        if (masterPosts[0].caption == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter caption',
            })
            // alert("Please enter caption");
            setLoading(false);
            setLoading2(false);
            return;
        }

        if (masterPosts[0].mediaType == null || (masterPosts[0].mediaType == "image" && masterPosts[0].images.length == 0) || (masterPosts[0].mediaType == "video" && masterPosts[0].videos.length == 0)) {

            PNotify.error({
                title: 'Error',
                text: 'Please select atleast one image or video',
            })
            setLoading(false);
            setLoading2(false);
            return;
        }
        if (type === "DRAFTED") setLoading(true);
        if (type === "SCHEDULED") setLoading2(true);

        try {
            const data = [];
            const refinedMasterPosts = masterPosts.map((post) => {

                if (post.mediaType == "image") {
                    const refinedmedia = post.images && post.images.map((media) => {
                        return {
                            id: media.id, imgUrl: media.imgUrl, isUploading: false,
                        }
                    })

                    return {
                        caption: post.caption, images: refinedmedia, videos: [], mediaType: post.mediaType, schedule_time: post.schedule_time
                    }
                }

                if (post.mediaType == "video") {
                    const refinedmedia = post.videos && post.videos.map((media) => {
                        return {
                            id: media.id, imgUrl: media.imgUrl, isUploading: false,
                        }
                    })

                    return {
                        caption: post.caption, videos: refinedmedia, images: [], mediaType: post.mediaType, media: refinedmedia, schedule_time: post.schedule_time
                    }
                }

            })
            data.push(refinedMasterPosts)
            const response = await fetch(`${API_URL}/socialposts/updateSchedulePost`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: data,
                    postId: postData._id,
                    type: type,

                })
            });

            const result = await response.json();
            if (result.status === true) {
                handleClose();
                PNotify.success({
                    title: 'Success',
                    text: 'Post Updated successfull',
                })
                getdrafteddata();
                getscheduleddata();
                getarchieveddata();
            } else {
                PNotify.error({
                    title: 'Error',
                    text: "Post did not update",
                })
            }
        } catch (err) {
            console.error("Error:", err);
            alert("Internal error occurred");
        } finally {
            setLoading(false);
            setLoading2(false);
        }
    };

    const insertaipost = (daata) => {
        const updatedPosts = [...masterPosts];
        updatedPosts[indexforai].caption = updatedPosts[indexforai].caption + daata;
        setMasterPosts(updatedPosts);
    }
    const insertaipostVideo = (data) => {
        const newData = data.map((item, index) => {
            let uniqueId = uuid();
            return { id: uniqueId, imgUrl: item, isUploading: false }
        })
        setMasterPosts((pre) => {
            const updatedPosts = [...pre];
            updatedPosts[0] = { ...updatedPosts[0], mediaType: "video", videos: [...updatedPosts[0].videos, ...newData] }
            return updatedPosts
        })

    };
    const insertaipostImage = (data) => {
        const newData = data.map((item, index) => {
            let uniqueId = uuid();
            return { id: uniqueId, imgUrl: item, isUploading: false }
        })
        setMasterPosts((pre) => {
            const updatedPosts = [...pre];
            updatedPosts[0] = { ...updatedPosts[0], mediaType: "image", images: [...updatedPosts[0].images, ...newData] }
            return updatedPosts
        })

    };



    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const setShowAiModal = () => {
        setIsOpen(prev => {
            if (!prev && isOpen2) {
                setIsOpen2(false);
            }
            return !prev;
        });
    }

    const setShowMediaModal = () => {
        setIsOpen2(prev => {
            if (!prev && isOpen) {
                setIsOpen(false);
            }
            return !prev;
        });
    }

    const onEmojiClick = (index) => (event, emojiObject) => {
        setMasterPosts(prevPosts => {
            const updatedPosts = [...prevPosts];
            updatedPosts[index].caption += emojiObject.emoji;
            return updatedPosts;
        })
    };

    // console.log("postData",postData.status);


    const uploadAssetAndCreateDesign = async () => {

        const user = JSON.parse(localStorage.getItem("user"));


        const formData = new FormData();
        formData.append('user_id', user?.owner_id);

        try {
            const response = await fetch(`${API_URL}/socialposts/uploadAssetAndCreateDesign`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.success) {

                localStorage.setItem("design_data", JSON.stringify({
                    location: window.location.href,
                    data: data.data,
                    masterPosts: masterPosts,
                    method: "edit_post",
                    postStatus: postData?.status,
                    postId: postData?._id

                }));

                const editUrl = data.data.urls.edit_url;
                window.location.assign(editUrl);

            } else {
                console.error('Failed to upload asset:', data.message);
            }
        } catch (error) {
            console.error('Error creating Canva design:', error);
        }
    };


    const handleCanvaAuth = async () => {
        const authorizationUrl = `${API_URL}/socials/canvaOAuth`;

        const width = 700;
        const height = 700;
        const left = (window.screen.width / 5) - (width / 5);
        const top = (window.screen.height / 3) - (height / 3);

        let authWindow = window.open(
            `${authorizationUrl}?owner_id=${user?.owner_id}`,
            'CANVA_AUTH',
            `width=${width},height=${height},left=${left},top=${top}`
        );

        const windowCheckInterval = setInterval(() => {
            if (authWindow.closed) {
                clearInterval(windowCheckInterval); // Stop checking when window is closed
                // setIsOpen3(prev => !prev); // Update the state when the window is closed
                // alert('Window closed');
                setIsCanvaConnected(true);
                setIsAuthenticated(true);
            }
        }, 500)

    };

    return (

        <>


            <Modal.Body className="post-schedule-body">

                {isOpen && (
                    <div className="ai-modal-new">
                        <div className="ai-modal-inner">
                            <AiPosts insertpost={insertaipost} />
                        </div>
                    </div>
                )}
                {isOpen2 && (
                    <div className="media-modal-new">
                        <div className="ai-modal-inner">
                            <MediaPost insertaipostVideo={insertaipostVideo} insertaipostImage={insertaipostImage} />
                        </div>
                    </div>
                )}
                <div className="post-container-view" style={{ display: customization ? 'none' : 'block' }}>
                    <div className="row">
                        {masterPosts.length > 0 ?
                            masterPosts.map((post, index) => (
                                <div className="col-md-12 mb-3" key={index}>
                                    {/* <div className="page-header add-btn d-flex">
                                                    <div className="sec-title">
                                                    </div>
                                                    <div className="page-header-btn d-flex">
                                                        <button className="btn remove-btn me-0" onClick={() => {

                                                            setMasterPosts(prevPosts => {
                                                                const updatedPosts = [...prevPosts];
                                                                updatedPosts.splice(index, 1);
                                                                return updatedPosts;
                                                            });

                                                        }}><MdOutlineDeleteForever /></button>
                                                    </div>
                                                </div> */}
                                    <div className="post-box mt-2">
                                        <div className="text-area-box">
                                            <div className="mb-3">
                                                <textarea
                                                    ref={textareaRef}
                                                    className="form-control custom-text-area"
                                                    rows="3"
                                                    placeholder="What's on your mind?"
                                                    onInput={adjustHeight}
                                                    style={{ overflowY: 'hidden', resize: 'none' }}
                                                    value={post.caption}
                                                    onChange={(e) => {

                                                        const updatedPosts = [...masterPosts];
                                                        updatedPosts[index].caption = e.target.value;
                                                        setMasterPosts(updatedPosts);

                                                    }
                                                    }
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="image-upload-container">

                                            <div className="upload-sec">
                                                <div className="upload-images-list-2">
                                                    <div style={{ display: post.mediaType === 'image' ? 'block' : 'none' }}>
                                                        <div className="upload-images-list">
                                                            {post.images.map((image, imgindex) => (
                                                                <div key={imgindex}>
                                                                    <div className="image-container-view mb-2">
                                                                        {!image.isUploading ?

                                                                            <img src={image.imgUrl?.startsWith("https://") ? image.imgUrl : `${API_URL}/posts/${image.imgUrl}`} alt="preview" />

                                                                            : <div className="d-flex justify-content-center align-items-center h-100">
                                                                                <div className="text-center">
                                                                                    <div
                                                                                        className="spinner-border text-primary mb-3"
                                                                                        role="status"
                                                                                        style={{
                                                                                            width: '3rem',
                                                                                            height: '3rem',
                                                                                            animation: 'rotate 2s linear infinite',
                                                                                        }}
                                                                                    >
                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                    </div>
                                                                                    <p className="mb-0">Uploading...</p>
                                                                                </div>
                                                                                <style>{`
                                                                                                @keyframes rotate {
                                                                                                from {
                                                                                                    transform: rotate(0deg);
                                                                                                }
                                                                                                to {
                                                                                                    transform: rotate(360deg);
                                                                                                }
                                                                                                }
                                                                                            `}</style>
                                                                            </div>}
                                                                        <button className="remove-image sc-iBbrVh YsbxX _closeButton_1c4j9_57 "
                                                                            onClick={(e) => {

                                                                                if (post.images.length === 1) {
                                                                                    setMasterPosts(prevPosts => {
                                                                                        const updatedPosts = [...prevPosts];
                                                                                        updatedPosts[index].mediaType = null;
                                                                                        updatedPosts[index].images = [];
                                                                                        return updatedPosts;
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    const updatedPosts = [...masterPosts];
                                                                                    updatedPosts[index].images = updatedPosts[index].images.filter(item => item.id !== image.id);


                                                                                    setMasterPosts(updatedPosts)
                                                                                }

                                                                            }}>
                                                                            <IoClose />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {/* <div className="file-input-container">
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => {

                                                                    handleuploadfile(e.target.files[0], index);
                                                                }
                                                                }

                                                                multiple
                                                                className="file-input"
                                                            />
                                                            <label htmlFor="fileInput">
                                                                <i className="input-icon">📁</i>
                                                                <span className="file-input-text">select a file</span>
                                                            </label>
                                                        </div> */}

                                                    </div>

                                                    <div style={{ display: post.mediaType === 'video' ? 'block' : 'none' }}>
                                                        <div className="upload-images-list">
                                                            {post.videos.map((video, videoindex) => (
                                                                <div key={videoindex}>
                                                                    <div className="image-container-view mb-2">
                                                                        <div classsName="upload-images-list">
                                                                            {!video.isUploading ?
                                                                                <video src={video.imgUrl?.startsWith("https://") ? video.imgUrl : `${API_URL}/posts/${video.imgUrl}`} controls />
                                                                                : <div className="d-flex justify-content-center align-items-center h-100">
                                                                                    <div className="text-center">
                                                                                        <div
                                                                                            className="spinner-border text-primary mb-3"
                                                                                            role="status"
                                                                                            style={{
                                                                                                width: '3rem',
                                                                                                height: '3rem',
                                                                                                animation: 'rotate 2s linear infinite',
                                                                                            }}
                                                                                        >
                                                                                            <span className="visually-hidden">Loading...</span>
                                                                                        </div>
                                                                                        <p className="mb-0">Uploading...</p>
                                                                                    </div>
                                                                                    <style>{`@keyframes rotate {from {transform: rotate(0deg);}to {transform: rotate(360deg);}}`}
                                                                                    </style>
                                                                                </div>}
                                                                            <button className="remove-image sc-iBbrVh YsbxX _closeButton_1c4j9_57 "
                                                                                onClick={(e) => {

                                                                                    if (post.videos.length == 1) {

                                                                                        setMasterPosts(prevPosts => {
                                                                                            const updatedPosts = [...prevPosts];
                                                                                            updatedPosts[index].mediaType = null;
                                                                                            updatedPosts[index].videos = [];
                                                                                            return updatedPosts;
                                                                                        })

                                                                                    }
                                                                                    else {

                                                                                        const updatedPosts = [...masterPosts];
                                                                                        updatedPosts[index].videos = updatedPosts[index].videos.filter(item => item.id !== video.id);

                                                                                        setMasterPosts(updatedPosts)

                                                                                    }

                                                                                }}>
                                                                                <IoClose />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>


                                                    </div>

                                                    <div className="file-input-container">
                                                        <input
                                                            type="file"
                                                            accept={post.mediaType === null ? "image/*,video/*" : post.mediaType === "image" ? "image/*" : "video/*"}
                                                            onChange={(e) => {

                                                                if (e.target.files[0].type.startsWith('video/') && post.videos.length === 0) {

                                                                    setMasterPosts(prevPosts => {
                                                                        const updatedPosts = [...prevPosts];
                                                                        updatedPosts[index].mediaType = "video";
                                                                        return updatedPosts;
                                                                    })

                                                                }

                                                                if (e.target.files[0].type.startsWith('image/') && post.images.length === 0) {

                                                                    setMasterPosts(prevPosts => {
                                                                        const updatedPosts = [...prevPosts];
                                                                        updatedPosts[index].mediaType = "image";
                                                                        return updatedPosts;
                                                                    })

                                                                }

                                                                handleuploadfile(e.target.files[0], index);

                                                            }
                                                            }

                                                            multiple
                                                            className="file-input"
                                                        />
                                                        <label htmlFor="fileInput">
                                                            <i className="input-icon">📁</i>
                                                            <span className="file-input-text">select a file</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-extra-sec">
                                        <div className="post-extra-sec-content">
                                            <div className="emoji-box">
                                                <img
                                                    className="emoji-icon"
                                                    src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                                                    onClick={() => setShowPicker(val => !val)} />
                                                {showPicker && <Picker
                                                    pickerStyle={{ width: '100%' }}
                                                    onEmojiClick={(emojiObject) => {
                                                        setMasterPosts(prevPosts => {
                                                            const updatedPosts = [...prevPosts];
                                                            updatedPosts[index].caption += emojiObject.emoji;
                                                            return updatedPosts;
                                                        })

                                                        setShowPicker(false);
                                                    }} />}
                                            </div>
                                            <div className="post-date-time">
                                                <div className="form-group">
                                                    <input
                                                        type="datetime-local"
                                                        value={post.schedule_time ? convertToLocalTimezone(post.schedule_time) : ''}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            const kolkataDateString = convertToKolkataTimezone(e.target.value);
                                                            console.log("kolkata timestring", kolkataDateString);
                                                            setMasterPosts(prevPosts => {
                                                                const updatedPosts = [...prevPosts];
                                                                updatedPosts[index].schedule_time = kolkataDateString;
                                                                return updatedPosts;
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div>

                                                <button className="btn post-btn ai-view-btn"
                                                    onClick={() => {
                                                        setIndexforai(index);
                                                        setShowAiModal(true);
                                                    }}
                                                ><ImMagicWand /></button>
                                            </div>

                                            <div>

                                                <button className="btn post-btn ai-view-btn"
                                                    onClick={() => {
                                                        setShowMediaModal(true);
                                                    }}
                                                ><GoFileMedia /></button>
                                            </div>


                                            <div>



                                                {!isAuthenticated ? (
                                                    <button className="btn post-btn ai-view-btn" onClick={handleCanvaAuth}><SiCanva />Canva</button>
                                                ) : !isCanvaConnected ? (
                                                    <button className="btn post-btn ai-view-btn" onClick={handleCanvaAuth}><SiCanva />Canva</button>
                                                ) : (
                                                    <button className="btn post-btn ai-view-btn"
                                                        onClick={() => {
                                                            uploadAssetAndCreateDesign();
                                                        }}
                                                    ><SiCanva /> canva </button>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))
                            : <div>Please add a post to schedule You can also use Ai to generate posts</div>
                        }
                    </div>
                </div>

                <div className="post-submit-btn mt-4" style={{ textAlign: 'right' }}>
                    {loading ? <SpinnerLoader /> :
                        <button
                            type="submit"
                            className="btn bg-green-dark action-btn btn-green "

                            onClick={() => { UpdateData("DRAFTED"); }}
                        >{postData.status == "SCHEDULED" ? "Move to Drafts" : "Save Draft"}</button>}
                    {loading2 ? <SpinnerLoader /> :
                        <button
                            type="submit"
                            className="btn bg-green-dark action-btn btn-green me-0"
                            onClick={() => { UpdateData("SCHEDULED"); }}
                        >{postData.status == "SCHEDULED" ? "Save" : "Save to Scheduled"}</button>}

                </div>

            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>


        </>
    )
}

export default Channels