// import React, { useContext, useState } from "react";
// import Accordion from 'react-bootstrap/Accordion';
// import Form from 'react-bootstrap/Form';
// import { TbSquareHalf } from "react-icons/tb";
// import Frame1 from "../../assets/images/icons/frame1.webp";
// import Frame2 from "../../assets/images/icons/frame2.webp";
// import Frame3 from "../../assets/images/icons/frame3.webp";
// import Frame4 from "../../assets/images/icons/frame4.webp";
// import Noframe from "../../assets/images/icons/noframe.png";
// import Noframe2 from "../../assets/images/icons/no-frame-2.png";
// import { TemplateContext } from "../../TemplateContext";
// import { brandQrTemplateContext } from "../../contexts/context/BrandQrTemplateContext";
// const API_URL = process.env.REACT_APP_API_URL


// function ChoosePredefinedTemplate() {

//     const { brandQrTemplate, brandQrTemplateDispatch } = useContext(brandQrTemplateContext);

//     const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside, caption, setCaption] = React.useContext(TemplateContext);

//     return (
//         <>
//             <Accordion.Header><TbSquareHalf />Choose Template</Accordion.Header>
//             <Accordion.Body>
//                 <div className="row">
//                     <div className="col-md-12">
//                         <Form.Group className="mb-3" controlId="formBasicEmail">
//                             <Form.Label>Choose Template</Form.Label>
//                             <div className="d-flex align-items-center flex-wrap ">

//                                 {
//                                     brandQrTemplate.brandQrTemplates && brandQrTemplate.brandQrTemplates.map((item, index) => {
//                                         return (
//                                             <div className="  me-4">
//                                                 <button className="Qr-pattern"><img src={`${API_URL}/qrcodes/${item.thumbnail}`} onClick={
//                                                     () => {
//                                                         setFrame(item.frame)
//                                                     }
//                                                 } alt="Qr" className="img-fluid" /></button>
//                                             </div>
//                                         )
//                                     })
//                                 }

//                             </div>
//                         </Form.Group>
//                     </div>

//                 </div>
//             </Accordion.Body>
//         </>
//     )
// }

// export default ChoosePredefinedTemplate;


import React, { useContext, useState } from "react";
import { TemplateContext } from "../../TemplateContext";
import { brandQrTemplateContext } from "../../contexts/context/BrandQrTemplateContext";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { TbSquareHalf } from "react-icons/tb";

const API_URL = process.env.REACT_APP_API_URL


function ChoosePredefinedTemplate() {
    const { brandQrTemplate, brandQrTemplateDispatch } = useContext(brandQrTemplateContext);
    const [
        pattern, setPattern,
        eye, setEye,
        logo, setLogo,
        frame, setFrame,
        patternColorType, setPatternColorType,
        patternDotsColor, setPatternDotsColor,
        patternGradientColor, setPatternGradientColor,
        eyeBallColor, setEyeBallColor,
        EyeCenterColor, setEyeCenterColor,
        backgroundcolortype, setBackgroundcolortype,
        backgroundcolorcode, setBackgroundcolorcode,
        height, setHeight,
        width, setWidth,
        eyeinside, setEyeinside,
        caption, setCaption
    ] = useContext(TemplateContext);

    const handleTemplateSelection = (item) => {
        try {
            // Parse the stringified styling data
            const styling = JSON.parse(item.styling);

            console.log("styling image", styling.image);

            // Update dimensions
            setHeight(styling.height);
            setWidth(styling.width);

            // Update frame
            setFrame(item.frame);

            // Update pattern type and colors
            if (styling.dotsOptions) {
                // Set pattern type
                setPattern(styling.dotsOptions.type || "rounded");

                // Handle pattern colors
                if (styling.dotsOptions.gradient) {
                    setPatternColorType("gradient");

                    setPatternDotsColor(styling.dotsOptions.gradient.colorStops[0].color);
                    setPatternGradientColor(styling.dotsOptions.gradient.colorStops[1].color);
                } else if (styling.dotsOptions.color) {
                    setPatternColorType("color");
                    setPatternDotsColor(styling.dotsOptions.color);
                }
            }

            // Update eye styles
            if (styling.cornersSquareOptions) {
                setEye(styling.cornersSquareOptions.type || "square");
                setEyeBallColor(styling.cornersSquareOptions.color || "#000000");
            }

            if (styling.cornersDotOptions) {
                setEyeinside(styling.cornersDotOptions.type || "square");
                setEyeCenterColor(styling.cornersDotOptions.color || "#000000");
            }

            // Update background
            if (styling.backgroundOptions) {
                setBackgroundcolortype(styling.backgroundOptions.color ? "solid" : "transparent");
                setBackgroundcolorcode(styling.backgroundOptions.color || "#ffffff");
            }

            if (styling.image) {
                console.log("set logo", styling.image);
                setLogo(styling.image); // Direct image URL
            } 
            else {
                setLogo(undefined); // Reset if no image
            }

        } catch (error) {
            console.error("Error parsing template styling:", error);
        }
    };

    return (
        <>
            <Accordion.Header><TbSquareHalf />Choose Template</Accordion.Header>
            <Accordion.Body>
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Choose Template</Form.Label>
                            <div className="d-flex align-items-center flex-wrap">
                                {brandQrTemplate.brandQrTemplates && brandQrTemplate.brandQrTemplates.map((item, index) => (
                                    <div className="me-4" key={index}>
                                        <button className="Qr-pattern">
                                            <img
                                                src={`${API_URL}/qrcodes/${item.thumbnail}`}
                                                onClick={() => handleTemplateSelection(item)}
                                                alt="Qr"
                                                className="img-fluid"
                                            />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </Accordion.Body>
        </>
    );
}

export default ChoosePredefinedTemplate;