// import React, { useState } from "react";
// import { API_URL } from "../../../BackendApi/Api/config";
// import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";
// import SingleImageModal from "../../../Helpers/customImagesModel";
// import { Button, Modal } from "react-bootstrap";
// import Slider from "react-slick";

// function ImageGrid(props) {
 
//     const saveClicks = (smartlink_id, unique_id) => {
//         saveComponentsClicks({
//             unique_id: unique_id,
//             smartlink_id: smartlink_id,

//         });
//     }

//     const items = Object.values(props.data.items);
 
//     console.log("my image grid data", items)
//     const [show, setShow] = useState(false);
//     const [imageUrl, setImageUrl] = useState("");
//     const [currentSlide, setCurrentSlide] = useState(0);

//     const handleClose = () => {
//         setShow(false);
//         setCurrentSlide(0); // Reset current slide when closing the modal
//     };

//     const handleShow = (index) => {
//         setImageUrl(API_URL + "flowpage/" + items[index]?.imagepreview);
//         setCurrentSlide(index);
//         setShow(true);
//     };

//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 200,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         beforeChange: (current, next) => setCurrentSlide(next),
//     };


//     return (
//         <>
//             <div className="image-grid-custom mb-3" onClick={handleShow} id={props.data.unique_id}>
//                 {items.map((item, index) => (
//                     <div key={index} className="imagegrid-item">
//                         <a target="_blank">
//                             <img
//                                 // src={API_URL + "flowpage/" + item.imagepreview}
//                                 src={(item?.imagepreview.startsWith("http://") || item?.imagepreview.startsWith("https://")) ? item?.imagepreview : API_URL + "flowpage/" + item?.imagepreview}

//                                 alt={`Image ${index}`} />
//                         </a>
//                     </div>
//                 ))}
//             </div>
//             <Modal show={show} onHide={handleClose} centered className="qr-code-modal">
//                 <Modal.Body className="custum-modal-body text-center" style={{ padding: 20 ,margin:10}}>
//                     <Slider {...settings} initialSlide={currentSlide}>
//                         {items.map((item, index) => (
//                             <div key={index} className="">

//                                 <img
//                                     src={(item?.imagepreview.startsWith("http://") || item?.imagepreview.startsWith("https://")) ? item?.imagepreview : API_URL + "flowpage/" + item?.imagepreview}
//                                     alt=""
//                                     className="popup-image"
//                                 />
//                                 <Button
//                                     variant="primary"
//                                     className="bg-green-dark mt-3"
//                                     onClick={() => {
//                                         window.location.href = item.link; // Change 'item.link' to your actual link property
//                                     }}
//                                 >
//                                     Visit Link
//                                 </Button>
//                             </div>
//                         ))}
//                     </Slider>
//                 </Modal.Body>
//             </Modal>
//         </>
//     );
// }

// export default ImageGrid;


import React, { useState } from 'react';
import Slider from 'react-slick';
import { Modal, Button } from 'react-bootstrap';
import { 
  FaArrowLeft, 
  FaArrowRight, 
  FaExternalLinkAlt 
} from 'react-icons/fa';
import { API_URL } from "../../../BackendApi/Api/config";
import { saveComponentsClicks } from '../../../BackendApi/Api/flowpageApi';

// Previous arrow components remain the same
const NextArrow = ({ onClick }) => (
  <Button 
    onClick={onClick} 
    variant="dark"
    className="carousel-arrow next-arrow"
    style={{
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      opacity: 0.7,
      borderRadius: '50%',
      padding: '8px 12px'
    }}
  >
    <FaArrowRight />
  </Button>
);

const PrevArrow = ({ onClick }) => (
  <Button 
    onClick={onClick} 
    variant="dark"
    className="carousel-arrow prev-arrow"
    style={{
      position: 'absolute',
      left: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      opacity: 0.7,
      borderRadius: '50%',
      padding: '8px 12px'
    }}
  >
    <FaArrowLeft />
  </Button>
);

const ImageGrid = ({ data, ...props }) => {
  const [show, setShow] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const items = Object.values(data.items || {});

  const handleClose = () => {
    setShow(false);
  };

  const getImageUrl = (item) => {
    if (!item?.imagepreview) return '';
    return (item.imagepreview.startsWith("http://") || 
            item.imagepreview.startsWith("https://")) 
            ? item.imagepreview 
            : `${API_URL}flowpage/${item.imagepreview}`;
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlide,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: false
  };

  const handleImageClick = (index) => {
    setInitialSlide(index);
    setShow(true);
  };

  const saveClicks = (smartlink_id, unique_id) => {
    saveComponentsClicks({
      unique_id: unique_id,
      smartlink_id: smartlink_id,

    });
  }
  return (
    <>
      <div className="image-grid-custom mb-3" id={data.unique_id}>
        {items.map((item, index) => (
          <div key={index} className="imagegrid-item" onClick={() => handleImageClick(index)}>
            <a>
              <img
                src={getImageUrl(item)}
                alt={`Image ${index + 1}`}
              />
            </a>
          </div>
        ))}
      </div>

      <Modal 
        show={show} 
        onHide={handleClose} 
        centered 
        size="lg"
        className="qr-code-modal"
      >
        <Modal.Body className="custum-modal-body text-center p-0">
          <Button
            variant="light"
            className="position-absolute end-0 top-0 m-2"
            onClick={handleClose}
            style={{ zIndex: 2 }}
          >
            ×
          </Button>
          <div className="carousel-container">
            <Slider {...sliderSettings}>
              {items.map((item, index) => (
                <div key={index}>
                  <div className="carousel-slide">
                    <div className="image-wrapper">
                      <img
                        src={getImageUrl(item)}
                        alt={`Slide ${index + 1}`}
                        className="carousel-image"
                      />
                    </div>
                    <div className="navigation-wrapper">
                      {item.link && (
                        <div className="button-wrapper">
                          <Button
                            variant="primary"
                            className="bg-green-dark"
                            onClick={() => {
                                window.open(item.link, '_blank', 'noopener noreferrer');
                              // window.location.href = item.link
                              saveClicks(props.smartlink_id, item.unique_id)
                            }}
                          >
                            Visit Link <FaExternalLinkAlt className="ms-2" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Modal.Body>
      </Modal>

      <style jsx global>{`
        .carousel-container {
          position: relative;
          width: 100%;
          background: #000;
        }

        .carousel-slide {
          position: relative;
          width: 100%;
          height: 70vh;
          display: flex !important;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background: #000;
        }

        .image-wrapper {
          flex: 1;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          min-height: 0; /* Allow wrapper to shrink */
        }

        .carousel-image {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto;
        }

        .navigation-wrapper {
          width: 100%;
          padding: 20px 0 40px; /* Increased bottom padding for dots */
        }

        .button-wrapper {
          margin-bottom: 30px; /* Space between button and dots */
        }

        .slick-dots {
          bottom: 10px;
          background: rgba(0, 0, 0, 0.5);
          padding: 5px 0;
          margin: 0;
        }

        .slick-dots li button:before {
          font-size: 8px;
          color: #fff;
        }

        .slick-dots li.slick-active button:before {
          color: #0d6efd;
        }

        .carousel-arrow {
          z-index: 10;
        }

        .qr-code-modal .modal-content {
          background: #000;
        }

        .qr-code-modal .modal-body {
          padding: 0;
        }

        .qr-code-modal ::-webkit-scrollbar {
          width: 6px;
        }

        .qr-code-modal ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        .qr-code-modal ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 3px;
        }
      `}</style>
    </>
  );
};

export default ImageGrid;