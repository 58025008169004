import React, { useState, useContext } from "react";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { RiWechatChannelsLine } from "react-icons/ri";
import { MdSchedule } from "react-icons/md";
import './SocialStyle.css';
import { FaCalendarAlt } from "react-icons/fa";
import { TbBulb, TbSocial } from "react-icons/tb";

import FullCalendarComponent from "./Calender";
import { FaAngleDown, FaAngleUp, FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube, FaXTwitter, FaLinkedinIn } from "react-icons/fa6";
import ChannelPost from "./ChannelPost";
import ChannelPost2 from "./ChannelPost2";
import Channels from "../SocialContent/channels";
import Kanban from '../SocialContent/postideas/KanbanBoard'

import { API_URL } from "../BackendApi/Api/config";
import useSocialChannels from "../contexts/actions/useSocialChannels";

import { socialAccountContext } from "../contexts/context/socialAccountContext";

import useScheduleDataActions from "../contexts/actions/useScheduleDataActions";

import useCompleteDataActions from "../contexts/actions/useCompleteDataActions";

import useDraftDataActions from "../contexts/actions/useDraftDataActions";
import { brandSelectionContext } from "../contexts/context/brandSelectionContext";

import useArchivedDataActions from "../contexts/actions/useArchivedDataActions";
import useApprovalDataActions from "../contexts/actions/useApprovalDataActions";
import { FcFilingCabinet } from "react-icons/fc";
import PostsBin from "../SocialContent/postsbin/PostsBin";

import { Link, Route, Switch, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom'
import useSocialPermissions from "../Components/CustomHooks/useSocialPermissions";


function SocialPage() {

    const history = useHistory();

    const location = useLocation();

    const { checkForSocialContentPermissoins } = useSocialPermissions();

    const { sidebarBrands,sidebarBrandsDispatch } = useContext(brandSelectionContext);

    if(sidebarBrands.selected == 'all'){

        sidebarBrandsDispatch({ type: "SET_SELECTED_BRAND", payload: sidebarBrands.brands[0]._id });

    }

    const { connectedAccount, connectedAccountdispatch } = useContext(socialAccountContext);


    const { getConnectedChannels } = useSocialChannels();

    const { getscheduleddata } = useScheduleDataActions();

    const { getcompleteddata } = useCompleteDataActions();

    const { getdrafteddata } = useDraftDataActions();

    const { getarchieveddata } = useArchivedDataActions();

    const { getapprovaldata } = useApprovalDataActions();


    React.useEffect(() => {

        getconnectedaccounts();

        getscheduledposts();

        getcompletedposts();

        getdraftedposts();

        getarchievedposts();

        getapprovalposts();

    }, [sidebarBrands])

    const getconnectedaccounts = async () => {
        const data = await getConnectedChannels();

        // Access URL params directly from the global window object
        const urlParams = new URLSearchParams(location.search);
        const hasCorrelationJWT = urlParams.has('correlation_jwt'); // Check if the query param exists

        if (hasCorrelationJWT) {
            setTimeout(() => {
                console.log("run after two seconds");

                const localdata = JSON.parse(localStorage.getItem("design_data"));

                if (localdata) {
                    // history.push("/social_design/calendar?habibi=jhinga" );
                    console.log("localdata", localdata);

                    console.log("localdata.location", localdata.location);

                    console.log("split url", localdata.location.split("/")[4]);

                    const splitUrl = localdata.location.split("/");

                    console.log("split length", splitUrl.length);

                    const splitLength = splitUrl.length;

                    if (splitLength >= 6) {

                        const endpoint = splitUrl[5];

                        if (endpoint.includes("?")) {

                            const finalEndpoint = endpoint.split("?")[0];

                            history.push(`/social_design/channel/${finalEndpoint}?source=canva_redirect`);
                        }
                        else {

                            history.push(`/social_design/channel/${endpoint}?source=canva_redirect`);
                        }

                    }
                    else if (splitUrl.length >= 5) {

                        const endpoint = splitUrl[4];

                        console.log("endpoint", endpoint);

                        if (endpoint.includes("?")) {

                            const finalEndpoint = endpoint.split("?")[0];

                            history.push(`/social_design/${finalEndpoint}?source=canva_redirect`);
                        }
                        else {

                            history.push(`/social_design/${endpoint}?source=canva_redirect`);
                        }
                    }
                    else {

                        history.push(`/social_design/calendar`);
                    }
                }



            }, 2000); // 2000 milliseconds = 2 seconds
        }
    }


    const getscheduledposts = async () => {

        getscheduleddata();
    }

    const getcompletedposts = async () => {

        getcompleteddata();
    }

    const getdraftedposts = async () => {

        getdrafteddata();

    }

    const getarchievedposts = async () => {

        getarchieveddata()

    }


    const getapprovalposts = async () => {

        getapprovaldata();
    }


    const [isOpen, setIsOpen] = useState(true); // Set the default state to open

    const toggleDropdown = () => {
        setIsOpen(!isOpen); // Function to toggle the dropdown state
    };

    const isActive = (path) => location.pathname === path;

    return (
        <>

            {
                sidebarBrands.selected === "all" ?


                    <div className="main-container">
                        <div className="main-page-body-content">

                            <div>Please select a brand to view it social contents.</div>

                        </div>

                    </div> :

                    checkForSocialContentPermissoins() == 'no-access' ?

                        <div className="main-container">
                            <div className="main-page-body-content">

                                <div>You don't have access to social content of this brand.</div>

                            </div>

                        </div> :

                        <div className="main-container">
                            <div className="main-page-body-content">
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="tab-content-body mobile-view-hide p-0">
                                            <Tab.Container id="left-tabs-example" >
                                                <div className="content-generation-enabled">
                                                    <div className="cwfpBk">
                                                        <div className="sc-eLtQCx fLziUH">
                                                            <div className="tab-card-body">
                                                                <div className="tab-content">
                                                                    <Nav variant="pills" className="flex-column">
                                                                        <Nav.Item >

                                                                            <Nav.Link eventKey="first"
                                                                                onClick={() => history.push('/social_design/calendar')}
                                                                                className={`technician-tab-link ${isActive('/social_design/calendar') ? 'active' : ''}`}
                                                                            >
                                                                                <div className="user-profile-box"><FaCalendarAlt /></div>
                                                                                <span className="tab-user-name" >Content Calendar</span>
                                                                            </Nav.Link>

                                                                            <Nav.Link eventKey="second"
                                                                                onClick={() => history.push('/social_design/post_ideas')}

                                                                                className={`technician-tab-link ${isActive('/social_design/post_ideas') ? 'active' : ''}`}
                                                                            // TbBulb, TbSocial
                                                                            >
                                                                                <div className="user-profile-box"><TbBulb />
                                                                                </div>
                                                                                <span className="tab-user-name" >Post Ideas </span>
                                                                            </Nav.Link>

                                                                            <Nav.Link eventKey="five"
                                                                                onClick={() => history.push('/social_design/connect-channels')}

                                                                                className={`technician-tab-link ${isActive('/social_design/connect-channels') ? 'active' : ''}`}
                                                                            >
                                                                                <div className="user-profile-box"><TbSocial /></div>
                                                                                <span className="tab-user-name" >Connect Channels</span>
                                                                            </Nav.Link>
                                                                            <div className="divider"></div>
                                                                            <div className="tab-inner-dropdown">
                                                                                <div className="drop-design">
                                                                                    <button className="tab-inner-dropdown-button" onClick={toggleDropdown}>
                                                                                        <div className="tab-inner-dropdown-icon">{isOpen ? <FaAngleUp /> : <FaAngleDown />}</div>
                                                                                        <div className="tab-inner-dropdown-text">Channels</div>
                                                                                    </button>
                                                                                </div>
                                                                                {/* Render dropdown content based on isOpen state */}
                                                                                {isOpen && (

                                                                                    <>

                                                                                        {
                                                                                            connectedAccount.isFetching &&

                                                                                            <p>Loading...</p>
                                                                                        }

                                                                                        <div className="tab-inner-dropdown-content">
                                                                                            {connectedAccount.accounts.length > 0 ? (
                                                                                                <>
                                                                                                    {[{ platform: "ALL", _id: "all" }, ...connectedAccount.accounts].map((account, index) => (
                                                                                                        <Nav.Link

                                                                                                            onClick={() => history.push(`/social_design/channel/${account._id}`)}

                                                                                                            className={`social-tab-link ${isActive(`/social_design/${account._id}`) ? 'active' : ''}`}


                                                                                                            eventKey={account._id}
                                                                                                            key={index}

                                                                                                        >
                                                                                                            <div className="channel-list-box-icon facebook-profile">
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        account.platform === 'ALL' ? "https://i.pinimg.com/originals/4b/fb/68/4bfb68a64d7dbe8b867d6a7991ce3d49.jpg" :
                                                                                                                            account.platform === 'FACEBOOK'
                                                                                                                                ? API_URL + "/socialProfile/" + account?.localProfilePath
                                                                                                                                : account.platform === 'TWITTER'
                                                                                                                                    ? API_URL + "/socialProfile/" + account?.localProfilePath
                                                                                                                                    : account.platform === 'LINKEDIN'
                                                                                                                                        ? API_URL + "/socialProfile/" + account?.localProfilePath
                                                                                                                                        : account.platform === 'INSTAGRAM' ? API_URL + "/socialProfile/" + account?.localProfilePath :
                                                                                                                                            account.platform === 'YOUTUBE' ? API_URL + "/socialProfile/" + account?.localProfilePath : ""
                                                                                                                    }
                                                                                                                    alt={account.platform}
                                                                                                                />
                                                                                                                <div className="bdyGBn social-icon-view">
                                                                                                                    {account.platform === 'FACEBOOK' && <FaFacebook />}
                                                                                                                    {account.platform === 'TWITTER' && <FaXTwitter />}
                                                                                                                    {account.platform === 'LINKEDIN' && <FaLinkedinIn />}
                                                                                                                    {account.platform === 'INSTAGRAM' && <FaInstagram />}
                                                                                                                    {account.platform === 'YOUTUBE' && <FaYoutube />}

                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <span className="tab-user-name">
                                                                                                                {account.platform === 'FACEBOOK'
                                                                                                                    ? account.facebook_pageName
                                                                                                                    : account.platform === 'TWITTER'
                                                                                                                        ? account.Twitter_screenName
                                                                                                                        : account.platform === 'LINKEDIN'
                                                                                                                            ? account.LinkedIn_name
                                                                                                                            :
                                                                                                                            account.platform === 'INSTAGRAM' ? account.instagram_business_account_name :
                                                                                                                                account.platform === 'YOUTUBE' ? account.youtube_name :
                                                                                                                                    account.platform === 'ALL' ? "All Channels" : ""}
                                                                                                            </span>
                                                                                                        </Nav.Link>
                                                                                                    ))}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>Please connect channels to see their posts</>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </Nav.Item>
                                                                    </Nav>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="social-tab-content">
                                                        <div className="hPOmAZ">
                                                            <div className="cJMQxa">
                                                                <Switch>
                                                                    {/* <Route path="/social_design" component={Kanban} exact /> */}

                                                                    <Route path="/social_design/calendar" component={FullCalendarComponent} exact />
                                                                    <Route path="/social_design/connect-channels" component={Channels} exact />
                                                                    <Route path="/social_design/channel/:id" component={ChannelPost2} exact />
                                                                    <Route path="/social_design/post_ideas" component={Kanban} exact />
                                                                </Switch>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

            }


        </>
    )
}

export default SocialPage;

export const Jhingaman = () => {

    return (
        <div>jhingalala man</div>
    )
}
