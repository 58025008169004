import React from "react";

function BuilderTitle(props) {
    console.log("my title props ssssssss", props);
    return (
        <>
            <div className="text_content_box" id={props.data.unique_id}> 
                <h2 className="m-0">{props.data.headding}</h2>
            </div>
        </>
    );
}

export default BuilderTitle;