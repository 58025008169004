
import React, { useEffect, useState } from "react";
import { AiFillClockCircle, AiFillX, AiFillCheckSquare, AiFillWarning } from 'react-icons/ai';
import { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'react-bootstrap';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { API_URL } from "../../BackendApi/Api/config";
import paginationFactory from "react-bootstrap-table2-paginator";
import SearchBox from './SearchBox';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import Modal from 'react-bootstrap/Modal';
import './table.css';
import "./model.css";
import { RxCross2 } from "react-icons/rx";

const InformasiVanity = () => {
    const [vanity, setVanity] = useState([]);
    const [vanity_id, setVanity_id] = useState(null);
    const [status, setStatus] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1); // Current page
    const [sizePerPage, setSizePerPage] = useState(10); // Size per page
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentData, setCurrentData] = useState([]);
    const [show04, setShow04] = useState(false)
    const handleClose04 = () => setShow04(false);
    const handleShow04 = (id, status) => {
        setShow04(true);
        setVanity_id(id)
        setStatus(status)
    };
 

    const handleVanityReview = async (type) => {
        const userData = await fetch(`${API_URL}vanity/updateVanityReview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _id: vanity_id,
                type: type
            })
        });
        const res = await userData.json();
        if (res.status) {
            setVanity(prevVanity =>
                prevVanity.map(item =>
                    item._id === res.data._id ? res.data : item
                )
            );
            handleClose04()
            PNotify.success({
                title: 'Success',
                text: `Vanity ${status === "Rejected" ? "Rejected" : "Approved"} successfully`,
            });
        } else {
            PNotify.error({
                title: 'Error',
                text: `Failed to ${status === "Rejected" ? "Reject" : "Approve"}`,
            });
        }
    };

    const columns = [
        { dataField: 'id', text: 'Id', sort: true, formatter: (cell, row, rowIndex) => rowIndex + 1 },
        { dataField: 'vanity_name', text: 'Vanity Name', sort: true, formatter: (cell, row) => `${row.fname} ${row.lname}` },
        { dataField: 'brandData[0].company_name', text: 'Brand Name', sort: true },
        { dataField: 'brandData[0].email', text: 'Brand Email', sort: true },
        { dataField: 'userData[0]', text: 'Owner Name', sort: true, formatter: (cell, row) => `${row.first_name} ${row.last_name}` },
        {
            dataField: 'AdminReview',
            text: 'Vanity Status',
            sort: true,
            formatter: (cell) => {
                switch (cell) {
                    case 0:
                        return (
                            <Fragment>
                                <AiFillClockCircle style={{ color: 'gray', marginRight: 8 }} />
                                Pending
                            </Fragment>
                        );
                    case 1:
                        return (
                            <Fragment>
                                <RxCross2 style={{ color: 'red', marginRight: 8 }} />
                                Rejected
                            </Fragment>
                        );
                    case 2:
                        return (
                            <Fragment>
                                <AiFillCheckSquare style={{ color: 'green', marginRight: 8 }} />
                                Universal Approval
                            </Fragment>
                        );
                    case 3:
                        return (
                            <Fragment>
                                <AiFillWarning style={{ color: 'orange', marginRight: 8 }} />
                                Conditional Approval
                            </Fragment>
                        );
                    default:
                        return 'Unknown';
                }
            }
        },
        {
            dataField: 'Logo', text: 'Vanity Logo',
            formatter: (cell) => (
                <img src={cell ? `${API_URL}profile/${cell}` : require('./../../../src/assets/images/logo/Popuplogo.png')} alt="Logo"
                    style={{ width: '50px', height: "50px", borderRadius: 20 }} />
            ),
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => (
                <>
                    <Button className="btn btn-danger btn-sm me-2" onClick={() => handleShow04(row._id, "Approved")}>
                        <AiOutlineCheckCircle className="approved-icon" />
                    </Button>
                    <Button className="btn btn-warning btn-sm me-2" onClick={() => handleShow04(row._id, "Rejected")}>
                        <AiOutlineCloseCircle className="rejected-icon" />
                    </Button>
                </>
            ),
        },
    ];

    const getAllVanity = async (page, limit) => {
        const vanityData = await fetch(`${API_URL}vanity/getAllVanityByAdmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                page: page,
                limit: searchText ? totalRecords : limit, // If searching, get all records
            })
        });
        const res = await vanityData.json();
        if (res.status) {
            setVanity(res.data.result);
            setTotalRecords(res.data.totalRecords);
        } else {
            console.log('Failed to fetch user');
        }
    };

    const handleSearch = (searchValue) => {
        setSearchText(searchValue);
        setPage(1); // Reset to first page when searching
    };

    useEffect(() => {
        getAllVanity(page, sizePerPage);
    }, [page, sizePerPage, searchText]); // Fetch data on page, size, and search text changes

    useEffect(() => {
        const filteredExams = vanity.filter(user =>
            user.vanity_name?.toLowerCase().includes(searchText.toLowerCase())
        );

        // Calculate the indices for slicing the filtered array
        const indexOfLastItem = page * sizePerPage;
        const indexOfFirstItem = indexOfLastItem - sizePerPage;

        // Update currentData based on the filtered array and current page
        const currentDataSlice = filteredExams.slice(indexOfFirstItem, indexOfLastItem);
        setCurrentData(currentDataSlice); // Set the current data for display

    }, [vanity, searchText, page, sizePerPage]);

    const handleTableChange = (type, { page, sizePerPage }) => {
        setPage(page);
        setSizePerPage(sizePerPage);
    };

    const paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        totalSize: totalRecords,
        sizePerPageList: [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            { text: "50", value: 50 },
            { text: "All", value: totalRecords },
        ],
    };

    return (
        <>
           <Modal show={show04} onHide={handleClose04} centered className="Confirm-Delete">
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title">
                        {status === "Rejected" ? <AiOutlineCloseCircle /> : <AiOutlineCheckCircle />}
                        Confirm {status === "Rejected" ? "Rejected" : "Approved"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to {status === "Rejected" ? "Reject" : "Approve"} this Vanity ?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="delete-Cancel-bttn" onClick={handleClose04}>
                        Cancel
                    </Button>
                    {status === "Approved" && (
                        <>
                            <Button className="universal-approval Approved" onClick={() => { handleVanityReview("Universal_Approval") }}>
                                {/* <AiOutlineCheckCircle className="approved-icon" />  */}
                                Universal Approval
                            </Button>
                            <Button className="confirm-Delete-bttn Approved" onClick={() => { handleVanityReview("Conditional_Approval") }}>
                                {/* <AiOutlineCheckCircle className="approved-icon" />  */}
                                Conditional Approval
                            </Button>
                        </>
                    )}
                    {status === "Rejected" && (
                        <Button className="confirm-Delete-bttn.Rejected" onClick={() => { handleVanityReview("Rejected") }}>
                            {/* <AiOutlineCloseCircle className="rejected-icon" />  */}
                            Rejected
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <div className="animated fadeIn" style={{ padding: '0', margin: '0', width: "1372px" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header d-flex table-card-header">
                                    <SearchBox onSearch={handleSearch} />
                                </div>
                                <div className="card-body">
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='id'
                                        data={currentData}
                                        columns={columns}
                                        pagination={paginationFactory(paginationOptions)}
                                        onTableChange={handleTableChange}
                                        remote={{ pagination: true }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InformasiVanity;
