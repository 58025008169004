import { createContext, useReducer, useMemo } from "react";
import { socialDisconnectionReducer } from '../reducers/socialDisconnectionReducer';

const SocialDisconnectContext = createContext();

const SocialConnectionProvider = ({ children }) => {
    const initialState = {
        isFetching: false,
        accounts: []
    };

    const [state, disConnectedAccountdispatch] = useReducer(socialDisconnectionReducer, initialState);
    const disConnectedAccount = useMemo(() => state, [state]);

    return (
        <SocialDisconnectContext.Provider value={{ disConnectedAccount, disConnectedAccountdispatch }}>
            {children}
        </SocialDisconnectContext.Provider>
    );
};

export { SocialDisconnectContext, SocialConnectionProvider };
