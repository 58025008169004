import React, { useState, useEffect, useContext, useRef } from 'react';
import Sidebar from "../Components/Sidebar/Sidebar";
import Header from '../Components/Header/Header';
import './Event.css';
import { Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { TbEdit, TbTrash, TbChartBar } from 'react-icons/tb';
import { BiDuplicate } from 'react-icons/bi';
import { MdPages, MdQrCode, MdEdit } from 'react-icons/md';
import { Form } from 'react-bootstrap';
import { brandSmartCampApi, brandSmartTempApi, getdefaultflexApi } from "../BackendApi/Api/brandApi";
import { verifybackhalf } from "../BackendApi/Api/smartsLinkApi";
import { NotificationManager } from 'react-notifications';
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { nanoid } from 'nanoid';
import { AutoGenerate, getEventsPages, getFlexTemplateData, getFlexbyuser, } from "../BackendApi/Api/flowpageApi";
import { API_URL } from '../BackendApi/Api/config';
import qrOptiions from "../Components/Login/defaultQr.json";
import { useHistory } from 'react-router-dom';
import { deleteUserMiniPage, updateTitle } from "../BackendApi/Api/flowpageApi";
import { useBuilderContext } from '../builderContext';
import axios from 'axios';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { FiCopy } from "react-icons/fi";
import { handleCopy } from "../Helpers/copyText";
import Loader2 from '../Components/Loader/Loader2';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import CopyCat from '../Components/CopyCat/CopyCat';
import { saveAs } from 'file-saver';
import TwitterShareButton from '../Components/share/SocialShare';
import { FaSearch } from 'react-icons/fa';
import { RiCloseFill } from 'react-icons/ri';
import { UserContext } from "../UserContext.js";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../Setting/Setting.css";
import "../../src/FlexpageTemplate/PageBuilder.css"
import { toJpeg, toBlob, toPng } from 'html-to-image';
import AutoGeneratedFlex from './../FlexpageTemplate/Components/AutoGeneratedFlex.jsx';
import { brandSelectionContext } from '../contexts/context/brandSelectionContext.jsx';
import { SubscribsationBackhalfValid } from '../Helpers/SubscribsationBackhalfValid';
import SocialSharePage from '../Components/share/SocialShare';
import { FaExchangeAlt } from "react-icons/fa";
import { LiaExchangeAltSolid } from "react-icons/lia";
import Select, { components } from "react-select";




const DEFAULT_FRONTHALF = process.env.REACT_APP_API_URL;
const REACT_APP_WEBSITE = process.env.REACT_APP_WEBSITE;

function EventPage() {
    const imageRef = useRef(null);
    const { subscriptionStatus, subscriptionDetails } = useContext(UserContext);
    const { sidebarBrands } = useContext(brandSelectionContext);


    let { color, buttonColor, fontFamily } = useBuilderContext();

    const history = useHistory();

    const userdata = JSON.parse(localStorage.getItem("user"));

    const role = userdata.role;

    const [useFlexPages, setUseFlexPages] = useState([]);

    const [brandData, setBrandData] = useState([]);
    const [templateData, setTemplateData] = useState([]);

    const [flexTemplateData, setFlexTemplateData] = useState([]);

    const [currentSmartlinkId, setCurrentSmartlinkId] = useState("");
    const [templateImage, settemplateImage] = useState("");

    const [BrandId, setBrandId] = useState("");
    const [selectTemp, setSelectTemp] = useState("");
    const [uniqueId, setUniqueId] = useState('123');
    const [frontHalf, setFrontHalf] = useState(REACT_APP_WEBSITE);
    // const [loading, setLoading] = useState(false);

    const [targetedBrands, setTargetedBrands] = useState([]);
    const [targetedBrand, setTargetedBrand] = useState(null);
    const [movableBrandId, setMovableBrandId] = useState("");
    const [targetFlexPageId, setTargetFlexPageId] = useState("");

    const [backhalfVerfied, setBackhalfVerfied] = useState(false);

    const [page_thumbnail, setPage_thumbnail] = useState("");
    const [page_json, setPage_json] = useState(null);

    const [title, setTitle] = useState("");

    const [allFlexTemplates, setAllFlexTemplates] = useState([]);
    const [campaignSearch, setCampaignSearch] = useState("");
    const [filteredCampaignData, setFilteredCampaignData] = useState([]);

    const [domainData, setDomainData] = useState([]);

    const [activeIndex, setActiveIndex] = useState(null);

    const [activeIndex2, setActiveIndex2] = useState(null);

    const [activeIndex3, setActiveIndex3] = useState(null);

    const [defaultTemplates, setDefaultTemplates] = useState([]);

    const [showAipage, setShowAiPage] = useState(false)

    const [show07, setShow07] = useState(false);



    const handleClose07 = () => setShow07(false);
    const handleShow07 = (id, index) => {
        setShow07(true);
        setDeletedIndex(index);
        setDeletedId(id)
    };

    const [showMoveModal, setShowMoveModal] = useState(false);

    const handleCloseMoveModal = () => setShowMoveModal(false);


    const handleShowMoveModal = (BrandId, flexPageId) => {

        console.log("BrandId: ", BrandId);
        // Find the owner_id for the brand with the specified BrandId

        console.log("sidebarBrands: ", sidebarBrands.brands);
        const brandToMove = sidebarBrands.brands.find((brand) => brand._id === BrandId);

        console.log("brandToMove: ", brandToMove);

        // If the brand is found, get other brands with the same owner_id, excluding the original brand
        if (brandToMove) {
            const filterBrands = sidebarBrands.brands.filter((brand) => brand.owner_id === brandToMove.owner_id && brand._id !== BrandId);

            const modifiedBrands = filterBrands.map(brand => ({
                value: brand._id,
                label: brand.company_name,
                icon: brand.logo_light.startsWith("https://") ? brand.logo_light : `${API_URL}brands/${brand.logo_light}`
            }));



            setMovableBrandId(BrandId);
            setTargetedBrand(modifiedBrands[0]);
            setTargetedBrands(modifiedBrands);
            setShowMoveModal(true);
            setTargetFlexPageId(flexPageId);

            console.log("Filtered Brands:", filterBrands); // Optional: To check filtered brands in console
        } else {
            console.warn(`Brand with ID ${BrandId} not found.`);
        }
    };

    const handleBrandChange = (selectedOption) => {


        setTargetedBrand(targetedBrands.find((brand) => brand.value === selectedOption.value));

    };

    const moveBrand = async () => {

        try {

            const result = await fetch(`${API_URL}flowpage/moveflexpage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    target_brand_id: targetedBrand.value,
                    flexPage_id: targetFlexPageId
                })
            })

            const data = await result.json();

            if (data.status) {
                console.log('Brand moved successfully');
                handleCloseMoveModal();
                getFlexPages();
            } else {
                console.error('Error:', data.message);
            }

        } catch (error) {
            console.error('Error:', error);
        }


    }

    const Option = (props) => (
        <components.Option {...props} className="country-option">
            <img src={props.data.icon} alt="logo" className="brand-logo" />
            <p className="brand-name">{props.data.label}</p>
        </components.Option>
    );

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <img src={props.data.icon} alt="logo" className="selected-logo" />
            {children}
        </components.SingleValue>
    );


    const [show03, setShow03] = useState(false);

    const handleClose03 = () => setShow03(false);
    const handleShow03 = () => setShow03(true);
    const [show01, setShow01] = useState(false);

    const handleClose01 = () => setShow01(false);
    const handleShow01 = () => setShow01(true);
    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show05, setShow05] = useState(false);

    const [show02, setShow02] = useState(false);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = () => setShow02(true);

    const [show06, setShow06] = useState(false);

    const handleClose06 = () => setShow06(false);
    const handleShow06 = () => setShow06(true);


    const [downloadQrImage, setDownloadQrImage] = useState(false);


    const [duplicateData, setDuplicateData] = useState({});


    const [editableTitle, setEditableTitle] = useState("");
    const handleClose05 = () => setShow05(false);
    const handleShow05 = (data) => {
        setShow05(true);
        setDownloadQrImage(data);

    }

    const [loading, setLoading] = React.useState(true);

    const [flex_id, setFlex_id] = useState("");
    const [indexs, setIndex] = useState(0);
    const [domain_name, setDomain_name] = useState("");
    const [background_colors, setBackground_color] = useState("");
    const [button_colors, setButton_color] = useState("");
    const [font_familys, setFont_family] = useState("");
    const [pageThumbnail, setPageThumbnail] = useState("");
    const [brandsOwner_id, setBrandsOwner_id] = useState("");
    const [deletedId, setDeletedId] = useState(null);
    const [deletedIndex, setDeletedIndex] = useState(0);
    React.useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    const [autoBrand, setAutoGenerateData] = useState([]);
    const [loaderAuto, setloaderAuto] = useState(false);
    const [updateAutoPage, setUpdateAutoPage] = useState(null);

    const [loadingcomponent, setLoadingcomponent] = useState(false);


    useEffect(() => {

        getFlexPages();

    }, [])

    const getFlexPages = async () => {

        setLoadingcomponent(true);

        const user = JSON.parse(localStorage.getItem('user'));

        const data = {
            owner_id: user.owner_id,
            created_by: user._id,
            role: user.role
        };

        getEventsPages(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    if (sidebarBrands.selected === "all") {
                        setUseFlexPages(res.data.data);
                        setFilteredCampaignData(res.data.data);
                        const responceData = res.data.data;
                        //setQrData(responceData[0].styling);
                        console.log("flwex by user", res.data.data);
                        setLoadingcomponent(false);
                    } else {

                        const filterData = res.data.data.filter((item) => item.brand_id === sidebarBrands.selected);
                        setUseFlexPages(filterData);
                        setFilteredCampaignData(filterData);
                        const responceData = filterData;
                        //setQrData(responceData[0].styling);
                        console.log("flwex by user", res.data.data);
                        setLoadingcomponent(false);
                    }
                    // setUseFlexPages(res.data.data);
                    // setFilteredCampaignData(res.data.data);
                    // const responceData = res.data.data;
                    // //setQrData(responceData[0].styling);
                    // console.log("flwex by user", res.data.data);
                    // setLoadingcomponent(false);
                }
            } else {
                console.log("Something went wrong");
                setLoadingcomponent(false);
            }


            console.log("flwex by user hey man", res.data.data);
        }).catch((err) => {
            console.log("err", err);
            setLoadingcomponent(false);
        }
        );
    }






    const deleteuserflexpage = (id, index) => {

        const data = {
            id: deletedId
        }

        deleteUserMiniPage(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: "success",
                        text: "FlexLink Delete Successfully",
                    });
                    const newUserFlexPages = useFlexPages.filter((item, i) => i !== deletedIndex);
                    setFilteredCampaignData(newUserFlexPages);
                }
                else {
                    PNotify.error({
                        title: "error",
                        text: "Something went wrong",
                    });
                }

            }
            else {
                PNotify.error({
                    title: "error",
                    text: "Something went wrong",
                });
            }
        }).catch((err) => {
            PNotify.error({
                title: "error",
                text: err?.message,
            });
        }).finally((err) => {
            PNotify.error({
                title: "error",
                text: err?.message,
            });
        });

    }



    const handleVerifyBackhalf = (e) => {
        const data = {
            backhalf: uniqueId
        }

        verifybackhalf(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    setBackhalfVerfied(true);
                }
                else {
                    setBackhalfVerfied(false);
                }
            }
            else {
                PNotify.error({
                    title: "error",
                    text: "Something went wrong",
                });
            }
        }
        ).catch((err) => {
            console.log("err", err);
        }
        );

    }



    const toggleActive = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
        setActiveIndex2(null);
        setActiveIndex3(null);
    };

    const toggleactive2 = (index) => {
        setActiveIndex2(activeIndex2 === index ? null : index);
        setActiveIndex(null);
        setActiveIndex3(null);
    }

    const toggleactive3 = (index) => {
        setActiveIndex3(activeIndex3 === index ? null : index);
        setActiveIndex(null);
        setActiveIndex2(null);
    }



    const handleDomainSelect = (e) => {
        setFrontHalf(e.target.value);

    };


    const generateUniqueId = () => {
        const id = nanoid(8);
        setUniqueId(id);
    };


    const getBrandData = () => {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            owner_id: user.owner_id,
        };

        brandSmartTempApi(data)
            .then((res) => {
                if (res.code === 200) {

                    if (res.data.status == true && res.data.data.length > 0) {

                        if (sidebarBrands.selected === "all") {
                            setBrandData(res.data.data);
                            //setSelectTemp(res.data.data);
                            setBrandId(res.data.data[0].brand_id);

                        }
                        else {

                            const filterData = res.data.data.filter((item) => item.brand_id === sidebarBrands.selected);
                            setBrandData(filterData);
                            //setSelectTemp(filterData);
                            setBrandId(filterData[0].brand_id);
                        }

                    }

                } else {
                    // alert("Something went wrong");
                    PNotify.error({
                        title: "error",
                        text: "Something went wrong",
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                // PNotify.error({
                //     title: "error",
                //     text: err.message,
                // });
            })
            .finally((err) => {
                // PNotify.error({
                //     title: "error",
                //     text: err.message,
                // });
                setLoading(false);

            });
    };

    const getDefaultFlex = async () => {
        try {
            setLoading(true);

            const response = await axios.post(`${API_URL}branding/getdefaultflextemplate`, {});

            if (response.data.status === true) {
                setDefaultTemplates(response.data.data);
            } else {
                PNotify.error({
                    text: "oH No!",
                    title: "Something went wrong",

                })
            }

        } catch (error) {
            PNotify.error({
                text: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const [brandForAuto, setBrandForAuto] = useState("")


    const handleSelectBrand = () => {
        if (BrandId !== "") {
            const selectedOption = brandData.filter((item) => item.brand_id === BrandId);

            const templateData = selectedOption[0]?.template;
            const domainData = selectedOption[0]?.domain;
            const flexData = selectedOption[0]?.FlexTemplate;

            // If domainData is empty, set it to an empty array
            const domain = domainData?.length > 0 ? domainData : [];

            // Get domain URLs from the .env variable
            const domainUrls = process.env.REACT_APP_DOMAIN_URLS.split(',');

            // Create an array from domain URLs
            const newArray = domainUrls.map((url) => ({ domain_name: url }));

            // Combine newArray with existing domainData
            const finalArray = [...newArray, ...domain];

            // Set the domain data and front half
            setDomainData(finalArray);
            setFrontHalf(finalArray[0]?.domain_name); // Use optional chaining for safety

            // Set the template data and image
            setTemplateData(templateData);
            settemplateImage(templateData[0]?.thumbnail); // Use optional chaining for safety

            // Set the FlexTemplate data
            setFlexTemplateData(flexData);

            // Get brand names and set them for auto-completion
            const brandName = selectedOption.map((item) => item.brand_name);
            setBrandForAuto(brandName);
            setBrandsOwner_id(selectedOption[0].owner_id);
        }
    };



    const [saveflexpage, setSaveFlexpage] = useState(false)

    const saveFlexData = async (type, duplicateData) => {
        const startdate = new Date();
        const enddate = new Date(startdate.getTime() + 24 * 60 * 60 * 1000);
        let page_json =
            [
                {
                    "type": "EVENT",
                    "properties":
                    {
                        "eventType": "Public",
                        "eventTitle": "Your Event Title",
                        "description": "Your Event Description",
                        "startDate": "2023-06-01T00:00:00",
                        "endDate": "2023-06-01T00:00:00",
                        "selectedTimezone": "America/New_York",
                        "location": "Your Event Location",
                    }
                },

            ]

        //long url should be a url
        setSaveFlexpage(true)
        let backhalf; let domainName; let full_short_url; let pageThumbnail;

        let Title; let brandId; let page; let background_color; let button_color; let font_family;

        const styling = qrOptiions;

        if (type === "new") {
            const validationResult = SubscribsationBackhalfValid(uniqueId, subscriptionDetails);

            if (!validationResult.isValid) {
                PNotify.error({
                    title: 'Error',
                    text: validationResult.message,
                });
                setSaveFlexpage(false);
                return false;
            }
            if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && useFlexPages.length >= 1) {
                PNotify.error({
                    title: 'Error',
                    text: 'Please upgrade your plan to continue',
                });

                setSaveFlexpage(false)
                return false;
            }
            else if (BrandId === "" || BrandId === null || BrandId === undefined) {
                PNotify.error({
                    title: 'Error',
                    text: 'please select brand',

                });
                setSaveFlexpage(false)
                return false;
            }
            else if (frontHalf === "" || frontHalf === null || frontHalf === undefined) {
                PNotify.error({
                    title: 'Error',
                    text: 'please select domain',

                });
                setSaveFlexpage(false)
                return false;
            }

            else if (title === "" || title === null || title === undefined) {
                PNotify.error({
                    title: 'Error',
                    text: 'title is required',

                });
                setSaveFlexpage(false)
                return false;
            } else if (page_json == "" || page_json == null || page_json == undefined) {
                PNotify.error({
                    title: 'Error',
                    text: 'select at list one Template',

                });
                setSaveFlexpage(false)
                return false;
            }
            else {
                

                backhalf = uniqueId;
                domainName = frontHalf;
                full_short_url = frontHalf + "/" + backhalf;
                styling.data = full_short_url + "?method=scan";
                pageThumbnail = page_thumbnail;
                page = JSON.stringify(page_json);
                Title = title;
                background_color = color;
                button_color = buttonColor;
                font_family = fontFamily;
                brandId = BrandId;


            }
        }


        if (type === "duplicate") {

            // const id = nanoid(8);
            const validationResult = SubscribsationBackhalfValid(uniqueId, subscriptionDetails);

            if (!validationResult.isValid) {
                PNotify.error({
                    title: 'Error',
                    text: validationResult.message,
                });
                setSaveFlexpage(false);
                return false;
            }
            backhalf = uniqueId;

            domainName = domain_name;

            full_short_url = domainName + "/" + backhalf;

            styling.data = full_short_url + "?method=scan";

            pageThumbnail = duplicateData.page_thumbnail;

            page = duplicateData.page_json;

            Title = title;

            background_color = background_colors;

            button_color = button_colors;

            font_family = font_familys;

            brandId = BrandId;

        }

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user.owner_id;
        const created_by = user._id;

        // const url = API_URL + updateAutoPage == 1 ? "flowpage/saveflexpagebyauto" : "flowpage/saveminipage";

        const url = API_URL + "flowpage/saveflexpagebyauto";

        const data = new URLSearchParams();
        data.append("full_short_url", full_short_url);
        data.append("domain_name", domainName);
        data.append('styling', JSON.stringify(styling));
        data.append("backhalf", backhalf);
        data.append("title", Title);
        data.append("created_by", created_by);
        data.append("owner_id", brandsOwner_id);
        data.append("brand_id", brandId);
        data.append("page_json", page);
        data.append("page_thumbnail", pageThumbnail);
        data.append("type", 2);
        data.append("long_url", full_short_url);
        data.append("background_color", background_color);
        data.append("button_color", button_color);
        data.append("font_family", font_family);
        data.append("favicon", DEFAULT_FRONTHALF + "/mybrand/Popuplogo.png");
        data.append("master_id", owner_id);


        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        setSaveFlexpage(true)
        axios.post(url, data, { headers })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status == true) {

                        const smartlink_id = res.data.data.smartlinkdata[0]._id;
                        history.push("/editEvent/" + smartlink_id);
                        handleClose03();

                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: res.data.message,
                        })
                    }
                }
                else {

                    PNotify.error({
                        title: 'Error',
                        text: res.data.message,
                    })
                }
            })
            .catch(error => {
                console.error('error', error);
                PNotify.error({
                    title: 'Error',
                    text: error.message,
                })
            }).finally(() => {
                setSaveFlexpage(false)
            });;
    }


    useEffect(() => {

        getBrandData();
        getDefaultFlex();


    }, [])

    useEffect(() => {

        handleSelectBrand()

    }, [BrandId, selectTemp, brandsOwner_id])


    const [updateTitleLoading, setUpdateTitle] = useState(false)
    const handleUpdateTitle = (e) => {
        setUpdateTitle(true)
        const data = {
            flex_id: flex_id,
            title: editableTitle
        }

        updateTitle(data).then((res) => {
            if (res.code === 200) {

                const updatedTitle = res.data.data.title;

                if (useFlexPages[indexs]) {
                    useFlexPages[indexs].title = updatedTitle;
                    setEditableTitle([...useFlexPages]);

                    PNotify.success({
                        title: 'Success',
                        text: 'Updated Successfully',
                    });
                }

                return true;
            } else {
                PNotify.error({
                    title: 'Error',
                    text: res.data.message,
                });
                return false;
            }
        }).catch((err) => {
            PNotify.error({
                title: 'Error',
                text: err.message,
            });
            return false;
        }).finally((ee) => {
            setUpdateTitle(false)
        })
    }

    const handleCampaignSearch = (e) => {
        const searchValue = e.target.value;
        setCampaignSearch(searchValue);
        const newFilter = useFlexPages.filter((value) => {
            return value.title.toLowerCase().includes(campaignSearch.toLowerCase());
        });

        if (searchValue === "" || searchValue.trim() === "") {
            setFilteredCampaignData(useFlexPages);
        }
        else {
            setFilteredCampaignData(newFilter);
        }
    }
    const handleClearFilter = () => {
        setCampaignSearch("");
        setFilteredCampaignData(useFlexPages);
    }
    const switchToModal04 = () => {
        setShow04(true);
        setShow01(false);
    }


    const autogeneratePage = () => {

        setloaderAuto(true);
        AutoGenerate({ brand_name: brandForAuto, brand_id: BrandId }).then((res) => {
            setloaderAuto(true);
            if (res.code === 200) {

                setAutoGenerateData(res.data.data[0].template_json);

                setloaderAuto(false);
                setShowAiPage(true)
            } else {
                PNotify.error({
                    text: "oH No!",
                    title: res.data.message,
                })
                setloaderAuto(false);


            }
        }).catch((err) => {
            PNotify.error({
                text: err.message,

            })
            setloaderAuto(false);
        })

    }

    return (

        <>
            {loadingcomponent && <Loader2 />}

            <>
                <Modal show={show01} onHide={handleClose01} centered className="mini-page-modal Sidebar-Modal-end">
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><MdPages />Create FlexPage</Modal.Title>
                        {/* <Modal.Title>Create New SmartLink</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <div className='col-lg-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicTemplate">
                                <input type="text" value={title} className="did-floating-input form-control" placeholder=""
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Form.Label className="did-floating-label">Page Title</Form.Label>
                            </Form.Group>
                        </div>

                        <div className="col-md-12">
                            <Form.Group className="did-floating-label-content" controlId="formBasic">
                                <Form.Select className="did-floating-select" aria-label="Default select example" onChange={
                                    (e) => {
                                        const value = e.target.value;
                                        const selectedOpt = e.target.templateData;
                                        setBrandId(value);
                                        const selectedOption = e.target.selectedOptions[0];
                                        const Brandsowner_id = selectedOption.getAttribute('Brandsowner_id');
                                        setBrandsOwner_id(Brandsowner_id)
                                        console.log("ooooooooo", Brandsowner_id);

                                    }
                                }>

                                    {brandData && brandData.map((item, index) => {
                                        return (
                                            <option key={index} selected={BrandId === item.brand_id} Brandsowner_id={item.owner_id} value={item.brand_id} templateData={JSON.stringify(item.template)} domainData={JSON.stringify(item.domain)}>{item.brand_name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Label className="did-floating-label">Brand Template</Form.Label>
                            </Form.Group>
                        </div>

                        <div className='col-lg-12'>
                            {/* <label id="input-field--label" class="input-field--label">Update Vanity Tag</label> */}
                            <div className="input-field--CUSTOM2 error">
                                <div className="d-flex align-items-center justify-content-around">
                                    <Form.Select aria-label="Default select example" className="custom-drop-downbtn" onChange={handleDomainSelect}>

                                        {domainData.map((item, index) => {

                                            return (
                                                <option key={index} value={item.domain_name}>{item.domain_name}</option>
                                            )
                                        })
                                        }
                                    </Form.Select>

                                    <div className="input-field--input-wrapper">
                                        <input aria-labelledby="input-field--label" type="text" class="input-field--input2" value={uniqueId}
                                            onChange={(e) => setUniqueId(e.target.value)}
                                            // onFocus={handleVerifyBackhalf}
                                            onBlur={handleVerifyBackhalf}
                                        />
                                    </div>
                                    <div className="input-field--static-value">
                                        {backhalfVerfied ? <div className="icon-check" style={{ color: "green" }}>
                                            <BsCheckCircleFill />
                                        </div> : <div className="icon-cross" style={{ color: "red" }}>
                                            <BsXCircleFill />
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Link to="/minipage"> */}
                        <button className="Active-bttn btn" onClick={() => {
                            setDuplicateData({
                                title: title,
                                uniqueId: uniqueId,
                                activeIndex: activeIndex,
                                brand_id: BrandId,
                                page_json: page_json,
                                page_thumbnail: page_thumbnail,
                                domain_name: domain_name,
                                background_color: background_colors,
                                button_color: button_colors,
                                font_family: font_familys,
                            })
                            handleShow04()
                            switchToModal04()
                        }} > Create duplicate FlexPage </button>
                        {/* </Link> */}
                    </Modal.Footer>
                </Modal>
                {/* mini page modal start here  */}


                <Modal show={show06} onHide={handleClose06} centered className='Confirm-Delete'>
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><TbTrash />Subscription not active !</Modal.Title>
                    </Modal.Header>
                    {role == "0" ? (<>
                        <Modal.Body>Please Upgrade to  an active plan to continue using this feature</Modal.Body>
                        <Modal.Footer>
                            <Button className='delete-Cancel-bttn' onClick={() => { history.push("/setting/price") }}>
                                Upgrade
                            </Button>
                        </Modal.Footer>
                    </>) : (
                        <Modal.Body>Please contact ypur admin and ask them to upgrade to  an active plan.</Modal.Body>
                    )}
                </Modal>

                <Modal show={show03} onHide={handleClose03} centered className="mini-page-modal Sidebar-Modal-end">
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><MdPages />Create Events</Modal.Title>
                        {/* <Modal.Title>Create New SmartLink</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <div className='col-lg-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicTemplate">
                                <input type="text" value={title} className="did-floating-input form-control" placeholder=""
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Form.Label className="did-floating-label">Page Title</Form.Label>
                            </Form.Group>
                        </div>

                        <div className="col-md-12">
                            <Form.Group className="did-floating-label-content" controlId="formBasic">
                                <Form.Select className="did-floating-select" aria-label="Default select example" onChange={
                                    (e) => {
                                        const value = e.target.value;
                                        const selectedOpt = e.target.templateData;
                                        setBrandId(value);
                                        const selectedOption = e.target.selectedOptions[0];
                                        const Brandsowner_id = selectedOption.getAttribute('Brandsowner_id');
                                        setBrandsOwner_id(Brandsowner_id)

                                    }
                                }>

                                    {brandData && brandData.map((item, index) => {
                                        return (
                                            <option key={index} selected={BrandId === item.brand_id} Brandsowner_id={item.owner_id} value={item.brand_id} templateData={JSON.stringify(item.template)} domainData={JSON.stringify(item.domain)}>{item.brand_name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Label className="did-floating-label">Brand Template</Form.Label>
                            </Form.Group>
                        </div>

                        <div className='col-lg-12'>
                            {/* <label id="input-field--label" class="input-field--label">Update Vanity Tag</label> */}
                            <div className="input-field--CUSTOM2 error">
                                <div className="d-flex align-items-center justify-content-around">
                                    <Form.Select aria-label="Default select example" className="custom-drop-downbtn" onChange={handleDomainSelect}>

                                        {domainData.map((item, index) => {

                                            return (
                                                <option key={index} value={item.domain_name}>{item.domain_name}</option>
                                            )
                                        })
                                        }
                                    </Form.Select>

                                    <div className="input-field--input-wrapper">
                                        <input aria-labelledby="input-field--label" type="text" class="input-field--input2" value={uniqueId}
                                            onChange={(e) => setUniqueId(e.target.value)}
                                            // onFocus={handleVerifyBackhalf}
                                            onBlur={handleVerifyBackhalf}
                                        />
                                    </div>
                                    <div className="input-field--static-value">
                                        {backhalfVerfied ? <div className="icon-check" style={{ color: "green" }}>
                                            <BsCheckCircleFill />
                                        </div> : <div className="icon-cross" style={{ color: "red" }}>
                                            <BsXCircleFill />
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className=" col-lg-12">
                            <div className="col-md-12">
                                <Tabs
                                    defaultActiveKey="profile"
                                    id="justify-tab-example"
                                    className="mb-3  justify-content-around flex-page-tabs"
                                >
                                    <Tab eventKey="profile" title="Templates" >
                                        <div class="styles__TabContent__Header themes_sec_header"><h3 className='ms-0'>Default Templates</h3></div>

                                        <div className="choose-template-list-inner row">
                                            {defaultTemplates && defaultTemplates.map((template, index) => {

                                                return (
                                                    <div className='col-md-4 col-sm-6' key={index}>
                                                        <div
                                                            className={`choose-template-list-inner-item ${activeIndex === index ? 'active' : ''}`}
                                                            onClick={() => {
                                                                setPage_thumbnail(template.template_thumbnail);
                                                                setPage_json(JSON.parse(template.template_json));
                                                                toggleActive(index)
                                                                setUpdateAutoPage(0)
                                                            }}
                                                        >
                                                            <div className="choose-template-list-inner-item-img">
                                                                <img src={API_URL + "flextemplates/" + template.template_thumbnail} alt="flexpage" />
                                                            </div>
                                                            <div className="choose-template-list-inner-item-content">
                                                                <div className="choose-template-list-inner-item-content-title">
                                                                    <h5 className="page-title">{template.template_name}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>

                                        <div class="styles__TabContent__Header themes_sec_header"><h3 className='ms-0'>Brand Templates</h3></div>
                                        <div className="choose-template-list-inner row">
                                            {flexTemplateData.length > 0 && flexTemplateData && flexTemplateData.map((template, index) => {
                                                return (
                                                    <div className='col-md-4 col-sm-6' key={index}>
                                                        <div
                                                            key={index}
                                                            className={`choose-template-list-inner-item ${activeIndex2 === index ? 'active' : ''}`}
                                                            onClick={() => {
                                                                setPage_thumbnail(template.template_thumbnail);
                                                                setPage_json(JSON.parse(template.template_json));
                                                                toggleactive2(index)
                                                                setUpdateAutoPage(0)
                                                            }}
                                                        >
                                                            <div className="choose-template-list-inner-item-img">
                                                                <img src={API_URL + "flextemplates/" + template.template_thumbnail} alt="flexpage" />
                                                            </div>
                                                            <div className="choose-template-list-inner-item-content">
                                                                <div className="choose-template-list-inner-item-content-title">
                                                                    <h5 className="page-title">{template.template_name}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}

                                            {flexTemplateData.length == 0 &&
                                                <div className=''>
                                                    <div className="mt-3 text-center actioin-text-res">
                                                        <h4>You don't have any template for this Brand</h4>
                                                    </div>
                                                    <div className="mt-5 text-center btn-class-right">
                                                        <Link to={`/brandedit/${BrandId}`}><button className="btn bg-green-dark action-btn btn-green me-0">Create Template</button></Link>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="longer-tab" title="Auto Generated" >
                                        <div className="choose-template-list-inner row">

                                            <Button
                                                onClick={() => {
                                                    autogeneratePage();
                                                }}
                                            >Create with AI</Button>
                                            {loaderAuto &&
                                                (
                                                    <div style={{ display: "flex", height: '60vh', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: "10px" }}>
                                                        <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                                            <h5>Wait for a few seconds, your data is generating...</h5>
                                                            <Spinner animation="border" variant="primary" style={{ alignSelf: 'center' }} />
                                                        </div>
                                                    </div>

                                                )
                                            }

                                            {showAipage &&
                                                (
                                                    <>
                                                        {autoBrand &&

                                                            autoBrand.map((component, index) => {

                                                                return (
                                                                    <>
                                                                        {
                                                                            component.type === "LANDING" ?
                                                                                (
                                                                                    <>
                                                                                        <div className='col-md-6 col-sm-6' key={index} >
                                                                                            <div
                                                                                                className={`choose-template-list-inner-item ${activeIndex3 === index ? 'active' : ''}`}
                                                                                                onClick={() => {
                                                                                                    setPage_json(component.data)
                                                                                                    setUpdateAutoPage(1)
                                                                                                    toggleactive3(index)
                                                                                                }}
                                                                                            >
                                                                                                <AutoGeneratedFlex myref={imageRef} myComponents={component.data} />

                                                                                                <div className="choose-template-list-inner-item-content">
                                                                                                    <div className="choose-template-list-inner-item-content-title">
                                                                                                        <h5 className="page-title">Landing</h5>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </>
                                                                                )
                                                                                :
                                                                                component.type === "CONTACT" ? (
                                                                                    <>
                                                                                        <div className='col-md-6 col-sm-6' key={index} >
                                                                                            <div
                                                                                                className={`choose-template-list-inner-item ${activeIndex3 === index ? 'active' : ''}`}
                                                                                                onClick={() => {
                                                                                                    setPage_json(component.data)
                                                                                                    setUpdateAutoPage(1)
                                                                                                    toggleactive3(index)
                                                                                                }}
                                                                                            >
                                                                                                <AutoGeneratedFlex myref={imageRef} myComponents={component.data} />

                                                                                                <div className="choose-template-list-inner-item-content">
                                                                                                    <div className="choose-template-list-inner-item-content-title">
                                                                                                        <h5 className="page-title">contact</h5>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </>
                                                                                ) :
                                                                                    component.type === "ABOUT" ? (
                                                                                        <>
                                                                                            <div className='col-md-6 col-sm-6' key={index} >
                                                                                                <div
                                                                                                    className={`choose-template-list-inner-item ${activeIndex3 === index ? 'active' : ''}`}
                                                                                                    onClick={() => {
                                                                                                        setPage_json(component.data)
                                                                                                        setUpdateAutoPage(1)
                                                                                                        toggleactive3(index)
                                                                                                    }}
                                                                                                >
                                                                                                    <AutoGeneratedFlex myref={imageRef} myComponents={component.data} />

                                                                                                    <div className="choose-template-list-inner-item-content">
                                                                                                        <div className="choose-template-list-inner-item-content-title">

                                                                                                            <h5 className="page-title">About</h5>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </>
                                                                                    ) :
                                                                                        null

                                                                        }
                                                                    </>

                                                                )

                                                            })

                                                        }


                                                    </>
                                                )
                                            }
                                        </div>
                                    </Tab>

                                </Tabs>
                            </div>
                        </div> */}

                    </Modal.Body>
                    <Modal.Footer>
                        {(defaultTemplates.length >= 1 || flexTemplateData.length >= 1) && <button
                            disabled={(subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                                || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && useFlexPages.length >= 1 ? true : false}
                            className="Active-bttn btn" onClick={() => {
                                saveFlexData("new", {});
                            }} >{saveflexpage ? <Spinner /> : " Create Events"} </button>}
                    </Modal.Footer>
                </Modal>

                <Modal show={show05} onHide={handleClose05} centered className="qr-code-modal"  >
                    <Modal.Body className='custum-modal-body'>
                        <div className='row'>
                            <img src={downloadQrImage} alt="" className="img-fluid popup-image" />
                            <div className="qr-btn-list">
                                <button className="btn qr-modal-btn me-2 " onClick={handleClose05}>Share Link</button>
                                <button className="btn qr-modal-btn" onClick={() => {
                                    saveAs(downloadQrImage, "qr.png");
                                }}>Download QR</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={show04} onHide={handleClose04} centered className="qr-code-modal"  >
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title">Confirm Duplicate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <div className='row'>
                            <div className='inner-description'>
                                <p>Are you sure you want to duplicate this page?</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn Active-bttn" onClick={() => {
                            saveFlexData("duplicate", duplicateData);
                            handleClose04();
                        }}>Confirm</button>
                        <button className="btn Cancel-bttn" onClick={handleClose04}>Cancel</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={show02} onHide={handleClose02} centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit FlexPage Title</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Flex Title</Form.Label>
                                    <Form.Control type="text" placeholder="Flex Title" value={editableTitle}
                                        onChange={(e) => setEditableTitle(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="Active-bttn btn" onClick={(e) => { handleClose02(e); handleUpdateTitle() }}>{updateTitleLoading ? <Spinner /> : "Update Title"} </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={show07} onHide={handleClose07} centered className='Confirm-Delete'>
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                    <Modal.Footer>
                        <Button className='delete-Cancel-bttn' onClick={handleClose07}>
                            Cancel
                        </Button>
                        <Button className='confirm-Delete-bttn' onClick={() => {
                            handleClose07();
                            deleteuserflexpage();
                        }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showMoveModal} onHide={handleCloseMoveModal} centered className='Confirm-Delete'>
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><TbTrash />Move Flexpage</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div>
                            Please select targerted brand where you want to move current brand ?
                        </div>

                        <Select
                            // menuIsOpen={true}
                            className="brand-select mt-3"
                            value={targetedBrand}
                            options={targetedBrands}
                            onChange={handleBrandChange}
                            components={{ Option, SingleValue }}
                            styles={{
                                singleValue: (base) => ({
                                    ...base,
                                    display: "flex",
                                    alignItems: "center",
                                }),
                            }}
                        />



                    </Modal.Body>
                    <Modal.Footer>

                        <Button className='confirm-Delete-bttn'

                            onClick={() => {
                                // handleClose07();
                                // deleteuserflexpage();
                                moveBrand();


                            }

                            }>
                            Move
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Header />

                <div className="main-container">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="container-fulid">
                                    <div className="row pd-26">
                                        <div className="ps-0 pe-0">

                                            <div className="card-body table-card-body">
                                                <div className="table-card-header add-btn d-flex">
                                                    <h5 className="main-card-title"><MdPages className="header-icon" />EVENT PAGES</h5>
                                                    <div className="card-content-header-right">
                                                        <Button className="btn btn-sm float-right add-user-btn" onClick={() => {

                                                            if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                                                                || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && useFlexPages.length >= 1) {
                                                                handleShow06();
                                                            } else {

                                                                handleShow03();
                                                                generateUniqueId();
                                                            }

                                                        }}> <MdPages /> New Events</Button>
                                                    </div>
                                                </div>
                                                <div className="mini-page-list">
                                                    <div className="mini-page-list-inner row">
                                                        {filteredCampaignData && filteredCampaignData.map((item, index) => {
                                                            const scans = item.scans;
                                                            const clicks = item.clicks;
                                                            const views = scans + clicks;

                                                            const subdomain = item.company_slug;
                                                            const url = new URL(item.full_short_url);

                                                            url.hostname = `${subdomain}.${url.hostname}`;

                                                            if (url.pathname.startsWith('/u/')) {
                                                                // Remove "/u/" from the pathname
                                                                url.pathname = url.pathname.slice(3);
                                                            }

                                                            let modifiedUrl = url.toString();
                                                            let urlWithoutProtocol = modifiedUrl.split('://')[1];

                                                            return (
                                                                <div key={index} className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6'>
                                                                    <div className="mini-page-list-inner-item">
                                                                        <div className="mini-page-list-inner-item-img">
                                                                            <img
                                                                                src={API_URL + "flextemplates/" + item.page_thumbnail}
                                                                                alt="Image preview not available"
                                                                                onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/d/dc/No_Preview_image_2.png'}
                                                                            />
                                                                        </div>
                                                                        <div className="mini-page-list-inner-item-content">
                                                                            <div className="mini-page-list-inner-item-content-title">
                                                                                <h5 className="page-title">
                                                                                    <div className='edit-title' onClick={() => {
                                                                                        setEditableTitle(item.title);
                                                                                        setIndex(index);
                                                                                        setFlex_id(item._id);
                                                                                        handleShow02();
                                                                                    }}>
                                                                                        <MdEdit />
                                                                                    </div>{item.title}
                                                                                </h5>
                                                                                <div className='d-flex align-items-center gap-2'>
                                                                                    <p>{urlWithoutProtocol}</p>
                                                                                    <CopyCat text={modifiedUrl} />
                                                                                    <SocialSharePage
                                                                                        url={modifiedUrl}
                                                                                        title="Check out this Page"
                                                                                        description="I found this great website and thought you might like it too."
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='inline-box'>
                                                                                <div className="SmartLinks-table-view">
                                                                                    <div className="views04">
                                                                                        <h6>{views} <span> Visits</span></h6>
                                                                                        <h6>{clicks} Clicks</h6>
                                                                                        <h6>{scans} Scans</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='mini-page-list-inner-item-content-btn'>
                                                                                    <div className="d-flex new-M">
                                                                                        <button aria-label="Duplicate" tooltip-position="top" type='button' className="btn duplicate-btn me-3"
                                                                                            onClick={() => {
                                                                                                setPage_json(item.page_json);
                                                                                                setPage_thumbnail(item.page_thumbnail);
                                                                                                setDomain_name(item.domain_name);
                                                                                                setTitle(item.title);
                                                                                                setBackground_color(item.background_color);
                                                                                                setButton_color(item.button_color);
                                                                                                setFont_family(item.font_family);
                                                                                                setBrandId(item.brand_id);
                                                                                                handleShow01();
                                                                                                generateUniqueId();
                                                                                            }}>
                                                                                            <BiDuplicate />
                                                                                        </button>
                                                                                        <button aria-label='Qr Code' tooltip-position="top" type="button" className="btn btn-layout me-3" onClick={() => { handleShow05(API_URL + "qrcodes/" + item.template_image) }}>
                                                                                            <MdQrCode />
                                                                                        </button>
                                                                                        <button aria-label='Metrics' tooltip-position="top" type="button" className="btn btn-chart me-3">
                                                                                            <Link to={`/flexpage/metrics/` + item.backhalf}>
                                                                                                <TbChartBar />
                                                                                            </Link>
                                                                                        </button>
                                                                                        <button aria-label="Edit" tooltip-position="top" type="button" className="btn btn-edit me-3"
                                                                                            onClick={() => {
                                                                                                history.push("/editEvent/" + item._id);
                                                                                            }}>
                                                                                            <TbEdit />
                                                                                        </button>
                                                                                        <button aria-label='Delete' tooltip-position="top" type="button" className="btn btn-delete"
                                                                                            onClick={() => { handleShow07(item._id, index) }}>
                                                                                            <TbTrash />
                                                                                        </button>
                                                                                        {/* <button aria-label='Move' tooltip-position="top" type="button" className="btn move-btn"
                                                                                            onClick={() => { handleShowMoveModal(item.brand_id, item._id) }}>
                                                                                            <LiaExchangeAltSolid />
                                                                                        </button> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        </>
    );
}

export default EventPage;