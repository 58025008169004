import React, { useState, useEffect } from "react";
import { useBuilderContext } from "../../../builderContext";
import { API_URL } from "../../../BackendApi/Api/config";

function BuilderHeader() {
    const { myComponents } = useBuilderContext();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (myComponents && myComponents.length > 0) {
            setData(myComponents[0].properties);
        }
    }, [myComponents]);

    if (!data) {
        return <div>Loading...</div>;
    }
    return (
        <>
            {data.headerFormat === "H1" ? (
                <div className="builder_content_header horizontal-header" style={{ display: 'block' }}>
                    <div className="d-flex gap-3 mb-3">
                        <img
                            src={(data.profileImage.startsWith("http://") || data.profileImage.startsWith("https://")) ? data.profileImage : API_URL + "flowpage/" + data.profileImage}
                            alt=""
                            className="user-set-profile"
                        />
                        <div>
                            <h1 className="builder_content_header_title tit_clor" style={{ color: "#232323" }}>{data.title}</h1>
                            <p className="builder_content_header_subtitle des_class" style={{ color: "#232323" }}>{data.description}</p>
                        </div>
                    </div>
                </div>
            ) : data.headerFormat === "H2" ? (
                <div className="builder_content_header vertical-header" style={{ display: 'block' }}>
                    <div className="builder_content_header_next mb-3">
                        <img
                            src={(data.profileImage.startsWith("http://") || data.profileImage.startsWith("https://")) ? data.profileImage : API_URL + "flowpage/" + data.profileImage}
                            alt=""
                            className="user-set-profile"
                        />
                        <h1 className="builder_content_header_title tit_clor" style={{ color: "#232323" }}>{data.title}</h1>
                        <p className="builder_content_header_subtitle des_class" style={{ color: "#232323" }}>{data.description}</p>
                    </div>
                </div>
            ) : data.headerFormat === "H3" ? (
                <div style={{ display: 'block' }}>
                    <div
                        className="builder_content_header vertical-header addBgclass cGOoFD"
                        style={{ backgroundImage: `url(${(data.backgroundImage.startsWith("http://") || data.backgroundImage.startsWith("https://")) ? data.backgroundImage : API_URL + "flowpage/" + data.backgroundImage})` }}
                    >
                        <div className="builder_content_header_next mb-3">
                            <img
                                src={(data.profileImage.startsWith("http://") || data.profileImage.startsWith("https://")) ? data.profileImage : API_URL + "flowpage/" + data.profileImage}
                                alt={data.altText}
                                className="user-set-profile"
                            />
                            <h1 className="builder_content_header_title tit_clor" style={{ color: "#232323" }}>{data.title}</h1>
                            <p className="builder_content_header_subtitle des_class" style={{ color: "#232323" }}>{data.description}</p>
                        </div>
                    </div>
                </div>
            ) : data.headerFormat === "H4" ? (
                <div className="builder_content_header" style={{ display: 'block' }}>
                    <img
                        src={(data.backgroundImage.startsWith("http://") || data.backgroundImage.startsWith("https://")) ? data.backgroundImage : API_URL + "flowpage/" + data.backgroundImage}
                        alt=""
                        className="header-bg-img"
                    />
                    <div className="builder_content_header_next mb-3">
                        <img
                            src={(data.profileImage.startsWith("http://") || data.profileImage.startsWith("https://")) ? data.profileImage : API_URL + "flowpage/" + data.profileImage}
                            alt=""
                            className="user-set-profile Qwedr"
                        />
                        <h1 className="builder_content_header_title tit_clor" style={{ color: "#232323" }}>{data.title}</h1>
                        <p className="builder_content_header_subtitle des_class" style={{ color: "#232323" }}>{data.description}</p>
                    </div>
                </div>
            ) : (
                <div className="builder_content_header" style={{ display: 'block' }}>
                    <img
                        src={(data.backgroundImage.startsWith("http://") || data.backgroundImage.startsWith("https://")) ? data.backgroundImage : API_URL + "flowpage/" + data.backgroundImage}
                        alt=""
                        className="header-bg-img"
                    />
                    <div className="builder_content_header_next mb-3">
                        <img
                            src={(data.profileImage.startsWith("http://") || data.profileImage.startsWith("https://")) ? data.profileImage : API_URL + "flowpage/" + data.profileImage}
                            alt=""
                            className="user-set-profile Qwedr"
                        />
                        <h1 className="builder_content_header_title tit_clor" style={{ color: "#232323" }}>{data.title}</h1>
                        <p className="builder_content_header_subtitle des_class" style={{ color: "#232323" }}>{data.description}</p>
                    </div>
                </div>
            )}
        </>
    );
}

export default BuilderHeader;
