import { createContext, useState, useReducer, useMemo } from "react";

import { approvalPostsReducer } from "../reducers/approvalPostsReducer";

export const approvalPostsContext = createContext();


export const ApprovalPostsProvider = ({ children }) => {

    const initialState = {
        isFetching: true,
        posts: []
    };

    const [approvalPosts, approvalPostsdispatch] = useReducer(approvalPostsReducer, initialState);

    const approvalPostsData = useMemo(() => approvalPosts, [approvalPosts]);

    return (
        <approvalPostsContext.Provider value={{
            approvalPostsData, approvalPostsdispatch
        }}>
            {children}
        </approvalPostsContext.Provider>
    );
}