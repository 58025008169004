import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { CgUserList } from 'react-icons/cg'
import { RiAddFill } from 'react-icons/ri'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Link } from 'react-scroll';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { getAllContemporaries } from '../../BackendApi/Api/brandApi';

const Contemporaries = ({ handleShow14, show14, brand_id, handleShow15, show15 }) => {
    const [contemporaries, setContemporaries] = useState([])

    const getData = async () => {
        try {
            const response = await getAllContemporaries({ brand_id: brand_id });
            if (response.data.status === true) {
                setContemporaries(response.data.data);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: response.data.message,
                });
            }

        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: error.message,
            });
        }
    }
    useEffect(() => {
        if (brand_id !== undefined) {
            getData()
        }
    }, [show14, brand_id, show15])

    if (contemporaries.length === 0) {
        return (
            <div className="tab-content-header add-btn d-flex ms-0">
                <h5 className="tab-content-title" ><div className="tab-content-icon"><CgUserList /></div>Contemporaries List</h5>


                <div className="tab-content-header-right">
                    <Button className="btn btn-sm float-right add-user-btn" onClick={(e) => handleShow14("contemporaries")} > <RiAddFill /> Add Contemporaries</Button>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className="tab-content-header add-btn d-flex ms-0">
                <h5 className="tab-content-title" ><div className="tab-content-icon"><CgUserList /></div>Contemporaries List</h5>


                <div className="tab-content-header-right">
                    <Button className="btn btn-sm float-right add-user-btn" onClick={(e) => handleShow14("contemporaries")} > <RiAddFill /> Add Contemporaries</Button>
                </div>
            </div>
            <div className="row">
                <div className="Member-list">
                    <ul className="list-group">
                        {contemporaries.map((item, index) => {
                            return (
                                <li key={index} className="list-group-item" style={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <h5>{item.contemporaries_name}</h5>
                                        <h>{item.contemporaries_description}</h>
                                    </div>

                                    <div className="button-group d-flex align-items-center justify-content-end">
                                        <button type="button" className="btn btn-edit me-3" onClick={(e) => handleShow14("contemporaries", item)}>
                                            <TbEdit />
                                        </button>
                                        <button type="button" className="btn btn-delete" onClick={(e) => handleShow15("contemporaries", item)} >
                                            <TbTrash />
                                        </button>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default Contemporaries