import React, { useRef, useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { EffectCreative, Navigation } from 'swiper/modules';

import { archivedPostsContext } from '../../contexts/context/archivedPostsContext';
import { postTimeLocale } from '../../Helpers/postTimeLocale';
import moment from 'moment'
import { socialAccountContext } from '../../contexts/context/socialAccountContext';
import { BiReset, BiStore } from 'react-icons/bi';
import { TbTrash } from 'react-icons/tb';
import useDraftDataActions from "./../../contexts/actions/useDraftDataActions";
import useArchivedDataActions from "./../../contexts/actions/useArchivedDataActions";
import { Button, Modal } from 'react-bootstrap';
import EditPost from "./../EditPost/EditPost"
import { MdSchedule } from 'react-icons/md';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import useSocialPermissions from '../../Components/CustomHooks/useSocialPermissions';


const API_URL = process.env.REACT_APP_API_URL;
const ArchivedPosts = ({ accountdata, type }) => {

    const { checkForSocialContentPermissoins } = useSocialPermissions();


    const [archivedPosts, setSchedulePosts] = React.useState([]);
    const { connectedAccount, connectedAccountdispatch } = useContext(socialAccountContext);
    const { archivedPostsData } = useContext(archivedPostsContext);
    const { getdrafteddata } = useDraftDataActions();
    const { getarchieveddata } = useArchivedDataActions();
    const [Ar_id, setAr_id] = useState(null);
    const [show, setShow] = useState(false);
    const [show02, setShow02] = useState(false);
    const [postData, setpostData] = React.useState('');
    const handleClose = () => {
        setShow(false);
    }
    const handleClose02 = () => setShow02(false);
    const handleShow02 = (ID, data) => {
        setAr_id(ID);
        setShow02(true)
    };
    const handleShow = (ID, data) => {
        setpostData(data)
        setShow(true)
    };
    React.useEffect(() => {

        filterScheduledPosts()

        console.log("scheduled posts statae changes")


    }, [archivedPostsData, , accountdata, type])

    const filterScheduledPosts = () => {
        switch (type) {
            case "ALL":
                if (archivedPostsData.posts) {
                    setSchedulePosts(archivedPostsData.posts);
                }
                break;
            case "FACEBOOK":
                if (archivedPostsData.posts) {
                    const newschedulePosts = archivedPostsData.posts.filter(
                        (post) => post.facebook_pageID === accountdata.facebook_pageID
                    );
                    console.log("new freshbook schedule posts", newschedulePosts);
                    setSchedulePosts(newschedulePosts);
                }
                break;
            case "TWITTER":
                if (archivedPostsData.posts) {
                    const newschedulePosts = archivedPostsData.posts.filter(
                        (post) => post.Twitter_userID === accountdata.Twitter_userID
                    );
                    setSchedulePosts(newschedulePosts);
                }
                break;
            case "INSTAGRAM":
                if (archivedPostsData.posts) {
                    const newschedulePosts = archivedPostsData.posts.filter(
                        (post) => post.instagram_business_account_id === accountdata.instagram_business_account_id
                    );
                    setSchedulePosts(newschedulePosts);
                }
                break;
            case "LINKEDIN":
                if (archivedPostsData.posts) {
                    const newschedulePosts = archivedPostsData.posts.filter(
                        (post) => post.LinkedIn_userID === accountdata.LinkedIn_userID
                    );
                    setSchedulePosts(newschedulePosts);
                }
                break;
            case "YOUTUBE":
                if (archivedPostsData.posts) {
                    const newschedulePosts = archivedPostsData.posts.filter(
                        (post) => post.youtube_userID === accountdata.youtube_userID
                    );
                    setSchedulePosts(newschedulePosts);
                }
            default:
                break;
        }
    };
    const handleArichedDelete = async () => {
        try {
            const response = await fetch(`${API_URL}/socialposts/PostsArchiDelete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    docid: Ar_id,


                })
            });

            const result = await response.json();
            if (result.status === true) {
                const res = result.data;
                getdrafteddata();
                getarchieveddata();
                handleClose02();
                PNotify.success({
                    title: 'Success',
                    text: 'Post Deleted successfully',
                })
                // alert("Post Deleted successfully");
                // setSchedulePosts(result.data);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: "Post did not Delete",
                })
                // alert("Post didnot Delete updated");
            }
        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: "Something went wrong, Please try again",
            })
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered size="lg" className='Sidebar-Modal-end' >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Edit Post</Modal.Title>
                </Modal.Header>
                <EditPost handleClose={handleClose} postData={postData} />
            </Modal>
            <Modal show={show02} onHide={handleClose02} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose02}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={handleArichedDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="post-lists" >

                <div className="all-post-list">
                    {archivedPosts && archivedPosts.map((item, index) => {
                        const result = postTimeLocale(item.schedule_time);
                        const localDateString = result?.localDateString;
                        const timeZoneAbbreviation = result?.timeZoneAbbreviation;

                        if (!localDateString) {
                            console.error(`Invalid date for item: ${JSON.stringify(item)}`);
                            return null; // Skip rendering this item
                        }

                        const formattedScheduleDate = moment(localDateString).format("MMMM Do YYYY @ h:mma ") + (timeZoneAbbreviation || '');

                        return (

                            <div className="post-box-design" key={index}>

                                <div className="bg-white rounded-lg shadow-sm">
                                    <div className="post-header-details d-flex justify-content-between">
                                        <div className="post-header">

                                            <img src={

                                                type === "ALL" ?
                                                    (item.platform === "FACEBOOK" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.facebook_pageID === item.facebook_pageID)?.localProfilePath :
                                                        item.platform === "TWITTER" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.Twitter_userID === item.Twitter_userID)?.localProfilePath :
                                                            item.platform === "INSTAGRAM" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.instagram_business_account_id === item.instagram_business_account_id)?.localProfilePath :
                                                                item.platform === "LINKEDIN" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.LinkedIn_userID === item.LinkedIn_userID)?.localProfilePath :
                                                                    item.platform === "YOUTUBE" ? API_URL + "/socialProfile/" + connectedAccount.accounts.find((post) => post.youtube_userID === item.youtube_userID)?.localProfilePath :
                                                                        null) :
                                                    type === "FACEBOOK" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                        type === "TWITTER" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                            type === "INSTAGRAM" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                type === "LINKEDIN" ? API_URL + "/socialProfile/" + accountdata.localProfilePath :
                                                                    type === "YOUTUBE" ? API_URL + "/socialProfile/" + accountdata.localProfilePath : null
                                            }

                                                alt="Profile Picture" className="user-profile-img" />
                                            <div>
                                                <div className="text-lg font-semibold user-name-text">{

                                                    type == "FACEBOOK" ? accountdata.facebook_pageName :
                                                        type == "TWITTER" ? accountdata.Twitter_displayName :
                                                            type == "INSTAGRAM" ? accountdata.instagram_business_account_name :
                                                                type == "LINKEDIN" ? accountdata.LinkedIn_name :
                                                                    type == "YOUTUBE" ? accountdata.youtube_name :
                                                                        null
                                                }</div>
                                                <p className="post-date">Scheduled: {formattedScheduleDate}</p>
                                            </div>
                                        </div>
                                        <div className='post-header-btn'>
                                            <button disabled={checkForSocialContentPermissoins() === "view-only"} className='btn btn-primary btn-sm post-edit-btn' onClick={() => { handleShow(item._id, item) }}><BiReset /></button>
                                            <button disabled={checkForSocialContentPermissoins() === "view-only"} className='btn btn-primary btn-sm post-edit-btn' onClick={() => { handleShow02(item._id, item) }}><TbTrash /></button>
                                        </div>

                                    </div>

                                    <div className="post-body">
                                        <div className="post-body-text">
                                            <p>{item.caption}</p>
                                        </div>
                                        <div className="post-image-grid-custom">
                                            <div className="social-image-grid-custom">
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={10}
                                                    navigation={true}
                                                    modules={[EffectCreative, Navigation]}
                                                    className="mySwiper2"
                                                >{
                                                        item.mediaType == "image" ?
                                                            <>
                                                                {item.images.map((image, index) => (
                                                                    <SwiperSlide key={image.id}>
                                                                        <div className='image-grid-item'>
                                                                            <img src={image.imgUrl.startsWith('https') ? image.imgUrl : API_URL + '/posts/' + image.imgUrl} alt="Post Image" />
                                                                            {/* {index === 3 && images.length > 4 && (
                                                                <div className="view-more">
                                                                    <button>
                                                                        +{images.length - 4}
                                                                    </button>
                                                                </div>
                                                            )} */}
                                                                        </div>
                                                                    </SwiperSlide>
                                                                ))}
                                                            </>

                                                            :
                                                            <>
                                                                {item.videos.map((video, index) => (

                                                                    <SwiperSlide key={video.id}>
                                                                        <div className='video-grid-item'>
                                                                            <video src={video.imgUrl.startsWith('https') ? video.imgUrl : `${API_URL}/posts/${video.imgUrl}`} controls />                                                                        </div>
                                                                    </SwiperSlide>

                                                                ))}
                                                            </>
                                                    }
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}

export default ArchivedPosts