import React, { useState, useRef, useEffect } from "react";
import { BsFillGridFill, BsStars } from "react-icons/bs";
import { FaChevronRight, FaLongArrowAltLeft, FaTrash } from "react-icons/fa";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CiGlobe } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import TimezoneSelect from 'react-timezone-select'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { MdLocationOn } from "react-icons/md";
import { useBuilderContext } from "../../../builderContext";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";


const AnyReactComponent = ({ text }) => <div>{text}</div>;
export function EventGridList1() {


    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <BsFillGridFill />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Event</h2>
                            <p className="add-block-btn-text-desc">Add a Event Grid to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}



export function EditEvent() {

    const locrationation = useLocation();
    const { addComponent, editComponent, myComponents } = useBuilderContext();

    const myComponent = myComponents.filter(item => item.type === "EVENT");
    let newMyComponent = myComponent[0]?.properties || {};
    const [hostedImages, setHostedImage] = useState(null);
    const [eventProperties, setEventProperties] = useState({
        eventType: newMyComponent.eventType || 'Public',
        eventTitle: newMyComponent.eventTitle || '',
        startDate: newMyComponent.startDate || '',
        endDate: newMyComponent.endDate || '',
        selectedTimezone: newMyComponent.selectedTimezone || '',
        location: newMyComponent.location || '',
        description: newMyComponent.description || '',
        hostName: newMyComponent.hostName || "",
        email: newMyComponent.email || "",
        hostedImage: newMyComponent.hostedImage || "",
    });

    useEffect(() => {
        setEventProperties({
            eventType: newMyComponent.eventType || 'Public',
            eventTitle: newMyComponent.eventTitle || '',
            startDate: newMyComponent.startDate || '',
            endDate: newMyComponent.endDate || '',
            selectedTimezone: newMyComponent.selectedTimezone || '',
            location: newMyComponent.location || '',
            description: newMyComponent.description || '',
            hostName: newMyComponent.hostName || "",
            email: newMyComponent.email || "",
            hostedImage: newMyComponent.hostedImage || "",
        });
    }, [myComponents]);


    const handleSelection = () => {
        setEventProperties(prevProperties => ({
            ...prevProperties,
            eventType: prevProperties.eventType === 'Public' ? 'Private' : 'Public'
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventProperties(prevProperties => ({
            ...prevProperties,
            [name]: value
        }));
    };

    const handleUpdateEvent = () => {
        if (hostedImages === null) {
            editComponent(0, "EVENT", eventProperties, true);
        }
        if (hostedImages !== null) {
            const data = new FormData();
            data.append('image', hostedImages);
            uploadSingleImageApi(data).then((res) => {
                console.log("res============================================================", res);
                if (res.data.status == true) {
                    eventProperties.hostedImage = res.data.data.filename;
                    editComponent(0, "EVENT", eventProperties, true);
                } else {
                    editComponent(0, "EVENT", eventProperties, true);
                }
            })
        }
    }
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file); // Create a URL for the image
            setHostedImage(file);
        }
    };

    const {
        eventType,
        eventTitle,
        startDate,
        endDate,
        selectedTimezone,
        location,
        description,
        hostName,
        email,
        hostedImage
    } = eventProperties;

    return (
        <div className="add_block">
            <div className="styles__Header-sc-82jpo5-0 cDIdp">
                <div>
                    {/* <Link to="/">
                        <button type="button" aria-label="Go back to layout view">
                            <FaLongArrowAltLeft />
                        </button>
                    </Link> */}
                    <label htmlFor="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Update Event Grid</label>
                </div>
            </div>

            <div className="icons_list_item cvtPmc">
                <div className="event-top d-flex align-items-center justify-content-end">
                    <div className="event-type">
                        <button
                            type="button"
                            aria-label="Toggle event type"
                            className="event-type-btn"
                            onClick={handleSelection}
                        >
                            {eventType === 'Public' ? <CiGlobe /> : <BsStars />} {eventType} <IoIosArrowDown />
                        </button>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">Event Title</div>
                    </div>
                    <div className="form_block">
                        <input
                            type="text"
                            className="form-control"
                            name="eventTitle"
                            value={eventTitle}
                            onChange={handleInputChange}
                            placeholder="Event title"
                        />
                    </div>
                </div>
                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">Start Date & Time</div>
                    </div>
                    <div className="form_block">
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="startDate"
                            value={startDate}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">End Date & Time</div>
                    </div>
                    <div className="form_block">
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="endDate"
                            value={endDate}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">Time Zone</div>
                    </div>
                    <div className='select-wrapper form_block_group'>
                        <TimezoneSelect
                            value={selectedTimezone}
                            onChange={(timezone) => setEventProperties(prevProperties => ({
                                ...prevProperties,
                                selectedTimezone: timezone.value
                            }))}
                        />
                    </div>
                </div>
                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">Location</div>
                    </div>
                    <div className="form_block_group">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><MdLocationOn /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Location"
                                aria-label="Location"
                                aria-describedby="basic-addon1"
                                name="location"
                                value={location}
                                onChange={handleInputChange}
                            />
                        </InputGroup>
                    </div>
                </div>
                <div className="position-relative mt-2">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">Add description</div>
                    </div>
                    <div className="form_block">
                        <textarea
                            className="form-control"
                            placeholder="Add description"
                            rows="5"
                            name="description"
                            value={description}
                            onChange={handleInputChange}
                        ></textarea>
                    </div>
                </div>

                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">Event Host Name</div>
                    </div>
                    <div className="form_block">
                        <input
                            type="text"
                            className="form-control"
                            name="hostName"  // Changed to camelCase
                            value={hostName}
                            onChange={handleInputChange}
                            placeholder="Event Host Name"
                        />
                    </div>
                </div>

                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">Event Host Email</div>
                    </div>
                    <div className="form_block">
                        <input
                            type="text"
                            className="form-control"
                            name="email"  // Changed to camelCase
                            value={email}
                            onChange={handleInputChange}
                            placeholder="Event Host Email"
                        />
                    </div>
                </div>

                <div>
                    <div className="position-relative mt-2">
                        <div className="icons_list_item_text">
                            <div className="icons_list_item_text_title">Upload Hosted Image</div>
                        </div>
                        <div className="form_block">
                            <input
                                type="file"
                                className="form-control"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </div>
                    </div>

                    {(hostedImage || hostedImages) && (
                        <div className="position-relative mt-2">
                            <div className="icons_list_item_text">
                                <div className="icons_list_item_text_title">Preview:</div>
                            </div>
                            <div className="form_block" style={{ textAlign: "center", padding: 10, border: "2px solid lightgray", width: "140px", height: "auto" }}>
                                <img
                                    src={hostedImages instanceof File ? URL.createObjectURL(hostedImages) : hostedImage}
                                    alt="Uploaded"
                                    style={{ width: "120px", height: "120px" }} // Ensure responsive design
                                />
                            </div>
                        </div>
                    )}
                </div>


                <div className="create-btn-wrapper">
                    <button className="create-btn" onClick={handleUpdateEvent}>Update Event</button>
                </div>

            </div>
        </div>
    );
}

