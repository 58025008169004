// import React from "react";
// import { FaLocationDot } from "react-icons/fa6";
// import { IoCall } from "react-icons/io5";
// import { IoMdMail } from "react-icons/io";
// import { CiGlobe } from "react-icons/ci";

// function ContactUs(props) {
//     return (
//         <>
//             <div className="contact_list">
//                 <div className="contact-card">
//                     <div className="contact-card-inner">
//                         <div className="contact-us-title">Contact Us</div>
//                         <div className="cont-xys">
//                             <div className="cont-icon">
//                                 <FaLocationDot />
//                             </div>
//                             <div className="contact-details-text">
//                                 {/* GANPATI PLAZA, No 109, Mirza Ismail Rd, Sindhi Camp, Jaipur, Rajasthan 302001 */}
//                                 {props.data.address}
//                             </div>
//                         </div>
//                         <div className="cont-xys">
//                             <div className="cont-icon">
//                             <IoCall />
//                             </div>
//                             <div className="contact-details-text">
//                                 {/* 9874563210 / 7896541230 */}
//                                 {props.data.phone}
//                             </div>
//                         </div>
//                         <div className="cont-xys">
//                             <div className="cont-icon">
//                             <IoMdMail />
//                             </div>
//                             <div className="contact-details-text">
//                                 {/* info@companyname.in */}
//                                 {props.data.email}
//                             </div>
//                         </div>
//                         <div className="cont-xys">
//                             <div className="cont-icon">
//                             <CiGlobe />
//                             </div>
//                             <div className="contact-details-text">
//                                 {/* www.userwebsite.com */}
//                                 {props.data.website}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default ContactUs;



import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { CiGlobe } from "react-icons/ci";

function ContactUs(props) {
    return (
        <>
            <div className="contact_list" id={props.data.unique_id}>
                <div className="contact-card">
                    <div className="contact-card-inner">
                        <div className="contact-us-title">Contact Us</div>
                        <div className="cont-xys">
                            <div className="cont-icon">
                                <FaLocationDot />
                            </div>
                            <div className="contact-details-text">
                                <a 
                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(props.data.address)}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    {props.data.address}
                                </a>
                            </div>
                        </div>
                        <div className="cont-xys">
                            <div className="cont-icon">
                                <IoCall />
                            </div>
                            <div className="contact-details-text">
                                <a href={`tel:${props.data.phone}`}>
                                    {props.data.phone}
                                </a>
                            </div>
                        </div>
                        <div className="cont-xys">
                            <div className="cont-icon">
                                <IoMdMail />
                            </div>
                            <div className="contact-details-text">
                            <a 
                                    href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${props.data.email}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    {props.data.email}
                                </a>
                            </div>
                        </div>
                        <div className="cont-xys">
                            <div className="cont-icon">
                                <CiGlobe />
                            </div>
                            <div className="contact-details-text">
                                <a 
                                    href={props.data.website} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    {props.data.website}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;
