
export function approvalPostsReducer(state, action) {
    switch (action.type) {
        case 'SET_APPROVAL_POSTS':
            return {
                ...state,
                posts: [...action.payload],
            }

        default:

            return state

    }
}
