import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronRight, FaLongArrowAltLeft, FaExternalLinkAlt } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

export function YoutubeList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <BsYoutube />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Youtube Video</h2>
                            <p className="add-block-btn-text-desc">Add a Youtube to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}





export function YoutubeAdd() {
    const navigate = useHistory();
    const { addComponent } = useBuilderContext();
    const unique_id = Date.now() + uuidv4();
    const [link, setLink] = React.useState({
        link: "",
    });
    const [
        selectedValue,
        setSelectedValue,
    ] = useState(false);

    const handleRadioChange = (
        value
    ) => {
        setSelectedValue(value);
    };
    // const embededLink = link.link.replace("watch?v=", "embed/");

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Youtube</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="position-relative">

                        <div className="form_block">
                            <input type="text" value={link.link} className="form-control did-floating-input" placeholder=""
                                onChange={(e) => {

                                    let youtubeLink = e.target.value;

                                    const url = new URL(e.target.value);

                                    if (url.searchParams.has("si")) {

                                        const urlParts = youtubeLink.split('/');
                                        const videoId = urlParts[urlParts.length - 1].split('?')[0];

                                        youtubeLink = `https://www.youtube.com/watch?v=${videoId}`

                                    }


                                    setLink({ link: youtubeLink });
                                }
                                }

                            />
                            <label htmlFor="exampleInputEmail1" className="gvofes did-floating-label">YouTube Video URL</label>
                        </div>
                        <div className="link_target">
                            <button type="button" className="btn btn-sm">
                                <FaExternalLinkAlt />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={
                    () => {

                        const regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/g;
                        if (!regex.test(link.link)) {
                            PNotify.error({
                                title: 'Error',
                                text: 'Please enter a valid youtube link',
                            });
                        } else {
                            const data = {
                                realLink: link.link,
                                link: link.link.replace("watch?v=", "embed/"),
                                unique_id: unique_id
                            }
                            addComponent("YOUTUBELINK", data, selectedValue);
                            navigate.push("/");
                        }

                        // addComponent("YOUTUBELINK", link);
                        //     navigate.push("/");



                    }
                }>
                    Add
                </button>
            </div>
            <div>


                <Form>
                    {['radio'].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="Add Subscribe Button"
                                name="group9"
                                checked={selectedValue === true}
                                type={type}
                                id={`inline-${type}-8`}
                                onChange={() => handleRadioChange(true)}
                            />
                            <Form.Check
                                inline
                                label="Remove Subscribe Button"
                                name="group9"
                                checked={selectedValue === false}
                                type={type}
                                id={`inline-${type}-9`}
                                onChange={() => handleRadioChange(false)}
                            />

                        </div>
                    ))}
                </Form>


            </div>
        </>
    );
}