import React, { useState } from "react";
import { API_URL } from "../../../BackendApi/Api/config";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

function ImageGrid(props) {
  const items = Object.values(props.data.items);

  const [show, setShow] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleClose = () => {
    setShow(false);
    setCurrentSlide(0); // Reset current slide when closing the modal
  };

  const handleShow = (index) => {
    setImageUrl(API_URL + "flowpage/" + items[index].imagepreview);
    setCurrentSlide(index);
    setShow(true);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
  };


  return (
    <>
      <div className="image-grid-custom mb-3" id={props.data.unique_id}>
        {items.map((item, index) => (
          <div key={index} className="imagegrid-item">
            <button
              onClick={() => {
                handleShow(index);
              }}
              style={{ padding: "0px", border: "none", background: "none" }}
            >
              <img

                // src={API_URL + "flowpage/" + item.imagepreview}
                src={(item.imagepreview.startsWith("http://") || item.imagepreview.startsWith("https://")) ? item.imagepreview : API_URL + "flowpage/" + item.imagepreview}
                alt={`Image ${index}`}
              // style={{ objectFit: props.imagePosition, maxWidth: "100%", height: "auto" }}
              />
            </button>
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose} centered className="qr-code-modal">
        <Modal.Body className="custum-modal-body text-center">
          <Slider {...settings} initialSlide={currentSlide}>
            {items.map((item, index) => (
              <div key={index} className="">
                {/* <img
                  className="popup-image"
                  src={API_URL + "flowpage/" + item.imagepreview}
                  alt={`Image ${index}`}
                //   style={{ objectFit: props.imagePosition, maxWidth: "100%", height: "auto" }}

                /> */}

                <img
                  src={(item.imagepreview.startsWith("http://") || item.imagepreview.startsWith("https://")) ? item.imagepreview : API_URL + "flowpage/" + item.imagepreview}
                  alt=""
                  className="popup-image"
                  style={{ objectFit: props.imagePosition}}
                />
                <Button
                  variant="primary"
                  className="bg-green-dark mt-3"
                  onClick={() => {
                    // window.location.href = item.link; // Change 'item.link' to your actual link property
                     window.open(item.link, '_blank', 'noopener noreferrer');
                  }}
                >
                  Visit Link
                </Button>
              </div>
            ))}
          </Slider>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageGrid;
