import React, { useEffect, useState } from 'react';
import { FaPlus, FaTrash, FaCopy, FaCheck, FaTwitter, FaFacebook, FaLinkedin, FaShareAlt } from 'react-icons/fa';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
const API_URL = process.env.REACT_APP_API_URL;
const ReferralCodeManager = () => {
  const [referralCodes, setReferralCodes] = useState({});
  const [newCode, setNewCode] = useState('');
  const [alert, setAlert] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const [shareModal, setShareModal] = useState({ isOpen: false, code: '' });

  const handleAddCode = () => {
    if (newCode.trim() !== '') {
      setReferralCodes([...referralCodes, { id: Date.now(), code: newCode.trim() }]);
      setNewCode('');
      showAlert('success', 'Referral code added successfully!');
    }
  };

  const handleDeleteCode = (id) => {
    setReferralCodes(referralCodes.filter(code => code.id !== id));
    showAlert('danger', 'Referral code deleted.');
  };

  const handleCopyCode = (id, code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000);
      showAlert('success', 'Referral code copied to clipboard!');
    });
  };

  const handleShare = (platform) => {
    let url = '';
    const message = encodeURIComponent(`Check out my referral code: ${shareModal.code}`);

    switch (platform) {
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${message}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${message}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${message}`;
        break;
      default:
        return;
    }

    window.open(url, '_blank');
    showAlert('success', `Shared on ${platform}!`);
    setShareModal({ isOpen: false, code: '' });
  };

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => setAlert(null), 3000);
  };

  useEffect(() => {
    getRefCodeFunc()
  }, [])

  const getRefCodeFunc = async () => {
    const userData = localStorage.getItem("user");
    const owner_id = JSON.parse(userData)._id;
    try {
      const result = await fetch(`${API_URL}/user/getReferralCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          owner_id: owner_id
        }),
      });

      const res = await result.json();
      console.log("myDATA", res)
      if (res.status) {
        setReferralCodes(res.data);
      } else {
        PNotify.error({
          title: 'Oh No!',
          text: ' referral not found',
        });
      }
    } catch (error) {
      console.log(error);
      PNotify.error({
        title: 'Oh No!',
        text: error.message,
      })

    }
  }
  return (
    <div className="container ">


      {alert && (
        <div className={`alert alert-${alert.type}`} role="alert">
          {alert.message}
        </div>
      )}

      {
        referralCodes &&
        <div>
          <h3>Referral Code : {referralCodes?.referral_code}</h3>
          <p>total points Earned : {referralCodes?.total_referral_points}</p>
        </div>
      }

      {/* <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter new referral code"
          value={newCode}
          onChange={(e) => setNewCode(e.target.value)}
        />
        <button className="btn btn-primary" type="button" onClick={handleAddCode}>
          <FaPlus className="me-2" /> Add Code
        </button>
      </div> */}

      <ul className="list-group">
        {referralCodes?.trackings && referralCodes.trackings.map(code => (
          <li key={code._id} className="list-group-item d-flex justify-content-between align-items-center">
            <span>{code.user_details.email}</span>

            <p>points Earned : {code.refferral_point}</p>
            <p>date & time : {new Date(code.createdAt).toLocaleString()}</p>
            <div>
              {/* <button
                className="btn btn-outline-secondary btn-sm me-2"
                onClick={() => handleCopyCode(code._id, code.referral_code)}
              >
                {copiedId === code._id ? <FaCheck /> : <FaCopy />}
              </button>
              <button
                className="btn btn-outline-primary btn-sm me-2"
                onClick={() => setShareModal({ isOpen: true, code: code.referral_code })}
              >
                <FaShareAlt />
              </button> */}
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => handleDeleteCode(code.id)}
              >
                <FaTrash />
              </button> */}
            </div>
          </li>
        ))}
      </ul>

      {/* {referralCodes.length === 0 && (
        <p className="text-muted mt-3">No referral codes yet. Add some above!</p>
      )} */}

      {/* Share Modal */}
      {shareModal.isOpen && (
        <div className="modal d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Share Referral Code</h5>
                <button type="button" className="btn-close" onClick={() => setShareModal({ isOpen: false, code: '' })} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Share your referral code: <strong>{shareModal.code}</strong></p>
                <div className="d-flex justify-content-around">
                  <button className="btn btn-outline-primary" onClick={() => handleShare('twitter')}>
                    <FaTwitter className="me-2" /> Twitter
                  </button>
                  <button className="btn btn-outline-primary" onClick={() => handleShare('facebook')}>
                    <FaFacebook className="me-2" /> Facebook
                  </button>
                  <button className="btn btn-outline-primary" onClick={() => handleShare('linkedin')}>
                    <FaLinkedin className="me-2" /> LinkedIn
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferralCodeManager;