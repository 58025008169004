import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { CgUserList } from 'react-icons/cg'
import { RiAddFill } from 'react-icons/ri'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Link } from 'react-scroll';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { brandsGalleryList } from '../../BackendApi/Api/brandApi';
import { API_URL } from '../../BackendApi/Api/config';
import { BsFillImageFill } from 'react-icons/bs';

const GalleryBrands = ({ handleShow14, show14, brand_id, handleShow15, show15 }) => {
    const [galleryBrands, setGalleryBrands] = useState([])

    const getData = async () => {
        try {

            const response = await brandsGalleryList({ brand_id: brand_id });
            if (response.data.status === true) {
                setGalleryBrands(response.data.data);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: response.data.message,
                });
            }

        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: error.message,
            });
        }
    }
    useEffect(() => {
        if (brand_id !== undefined) {
            getData()
        }
    }, [show14, brand_id, show15])

    if (galleryBrands.length === 0) {
        return (
            <div className="tab-content-header add-btn d-flex ms-0">
                <h5 className="tab-content-title" ><div className="tab-content-icon"><BsFillImageFill /></div>Gallery Brands List</h5>


                <div className="tab-content-header-right">
                    <Button className="btn btn-sm float-right add-user-btn" onClick={(e) => handleShow14("galleryBrands")} > <RiAddFill /> Add GalleryBrands</Button>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className="tab-content-header add-btn d-flex ms-0">
                <h5 className="tab-content-title" ><div className="tab-content-icon"><BsFillImageFill /></div>Gallery Brands List</h5>


                <div className="tab-content-header-right">
                    <Button className="btn btn-sm float-right add-user-btn" onClick={(e) => handleShow14("galleryBrands")} > <RiAddFill /> Add GalleryBrands</Button>
                </div>
            </div>
            <div className="row">
                <div className="Member-list">
                    <ul className="list-group">
                        {galleryBrands.map((item, index) => (
                            <li key={index} className="list-group-item" style={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    {item?.brandingImage.map((image, imgIndex) => (
                                        <img
                                            key={imgIndex}
                                            src={`${API_URL}brandsGallery/${image}`}
                                            alt="brand image"
                                            style={{ width: "60px", height: "60px",margin:10, borderRadius: 10 }}
                                        />
                                    ))}
                                </div>

                                <div className="button-group d-flex align-items-center justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn-edit me-3"
                                        onClick={() => handleShow14("galleryBrands", item)}
                                    >
                                        <TbEdit />
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-delete"
                                        onClick={() => handleShow15("galleryBrands", item)}
                                    >
                                        <TbTrash />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>

                </div>
            </div>
        </div>
    )
}
export default GalleryBrands