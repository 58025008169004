import React from "react";

function YoutubeLink(props) {
    return (
        <>
            <div className="youtube_video_box mb-3" id={props.data.unique_id}>
                <iframe width="560" height="315" src={props.data.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </>
    );
}


export default YoutubeLink;