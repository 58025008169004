import React, { useEffect, useState, useCallback, useRef } from 'react';
import './Comments.css';
import { error } from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

const API_URL = process.env.REACT_APP_API_URL;

const Comments = ({ post_id, }) => {
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [expandedComments, setExpandedComments] = useState({});
  const [seenComments, setSeenComments] = useState({});
  const [unseenCount, setUnseenCount] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const commentsContainerRef = useRef(null);

  const toggleReadMore = useCallback((id) => {
    setExpandedComments(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  }, []);

  const fetchComments = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/socialposts/getIdeasCommentsByPostId/${post_id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setComments(data.getComments);
        
        // Initialize seenComments based on the default isSeen value from API
        const initialSeenComments = {};
        data.getComments.map(comment => {
          
          initialSeenComments[comment._id] = comment.isSeen !== undefined ? comment.isSeen : false;
        });
        setSeenComments(initialSeenComments);
        
        // Calculate unseen comments
        const unseenComments = data.getComments.filter(comment => !initialSeenComments[comment._id]);
        setUnseenCount(unseenComments.length);
      } else {
        error({
          title: 'Error',
          text: data.message || 'Failed to fetch comments',
        });
      }
    } catch (err) {
      error({
        title: 'Error',
        text: err.message,
      });
    }
  }, [post_id]);
  

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) {
      error({
        title: "Error",
        text: "Please enter a comment",
      });
      return;
    }
    try {
      const response = await fetch(`${API_URL}/socialposts/addCommentsIdeasPost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: user?._id,
          post_Id: post_id,
          content: newComment.trim()
        })
      });

      const result = await response.json();

      if (result.success) {
        await fetchComments();
        setNewComment('');
      } else {
        error({
          title: 'Error',
          text: result.data.message,
        });
      }
    } catch (err) {
      error({
        title: 'Error',
        text: err.message,
      });
    }
  };

  const handleCommentSeen = useCallback(async (commentId) => {
    if (seenComments[commentId]) return;

    try {
      const response = await fetch(`${API_URL}/socialposts/updateIdeasComments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: user?._id,
          comments_id: commentId
        })
      });

      const result = await response.json();

      if (result.success) {
        setSeenComments(prev => ({ ...prev, [commentId]: true }));
        setUnseenCount(prevCount => Math.max(0, prevCount - 1));
      } else {
        console.error('Failed to update comment seen status:', result.data.message);
      }
    } catch (err) {
      console.error('Error updating comment seen status:', err.message);
    }
  }, [user?._id, seenComments]);

  const handleScroll = useCallback(() => {
    if (!commentsContainerRef.current) return;

    const containerRect = commentsContainerRef.current.getBoundingClientRect();
    const commentElements = commentsContainerRef.current.querySelectorAll('.comment-item');

    commentElements.forEach(comment => {
      const commentRect = comment.getBoundingClientRect();
      const commentId = comment.id;

      if (
        commentRect.top >= containerRect.top &&
        commentRect.bottom <= containerRect.bottom &&
        !seenComments[commentId]
      ) {
        handleCommentSeen(commentId);
      }
    });
  }, [seenComments]);

  useEffect(() => {
    const container = commentsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check for visible comments
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const timeSince = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    if (seconds < 0) return "in the future";
    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
    ];

    for (let i = 0; i < intervals.length; i++) {
      const interval = Math.floor(seconds / intervals[i].seconds);
      if (interval >= 1) {
        return interval === 1 ? `1 ${intervals[i].label} ago` : `${interval} ${intervals[i].label}s ago`;
      }
    }
    return "just now";
  };

  return (
    <div className="comments-container">
      
        <h2>Comments</h2>
      {/* <p>{unseenCount} unseen comment{unseenCount !== 1 ? 's' : ''}</p> */}
      <div ref={commentsContainerRef} className="comments-list">
        <ul>
          {comments.map(comment => (
            <li key={comment._id} className={`comment-item ${seenComments[comment._id] ? 'seen' : ''}`} id={comment._id}>
              <div className="comment-header">
                <div className="user-info">
                  <img
                    src={comment.userDetail?.profile_image
                      ? `${API_URL}/profile/${comment.userDetail.profile_image}`
                      : "/default-profile-image.jpg"}
                    alt={`${comment.userDetail?.username || 'User'}'s profile`}
                    className="profile-image"
                  />
                  <div className="user-details">
                    <strong className="username">{comment.userDetail?.username || 'Unknown User'}</strong>
                    <span className="email">{comment.userDetail?.email || 'Unknown Email'}</span>
                  </div>
                </div>
                <span className="date">{timeSince(comment.createdAt)}</span>
              </div>
              <p className="comment-text" style={{ maxHeight: expandedComments[comment._id] ? 'none' : '100px' }}>
                {comment.content?.length > 200
                  ? `${expandedComments[comment._id] ? comment.content : comment.content.substring(0, 120) + '...'}` 
                  : comment.content
                }
              </p>
              {comment.content?.length > 200 && (
                <button className="read-more" onClick={() => toggleReadMore(comment._id)}>
                  {expandedComments[comment._id] ? 'Read less' : 'Read more'}
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
      
      <form  className="comment-form" onSubmit={handleCommentSubmit}>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Write a comment..."
          rows="4"
        />
        <button type="submit" disabled={!newComment.trim()}>Comment</button>
      </form>
    </div>
  );
  
};

export default Comments;
