import React from "react";

function BuilderText(props) {
    console.log("my text display props", props.data.id);
    return (
        <>
            <div className="text_content_box" id={props.data.unique_id}>
                
                <div dangerouslySetInnerHTML={{ __html: props.data.html }} />
            </div>
        </>
    );
}

export default BuilderText;