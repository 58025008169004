// import React from "react";
// import { useVoiceToText } from "react-speakup";

// const VoiceToTextComponent = () => {
//   const { startListening, stopListening, transcript, reset } = useVoiceToText();

//   return (
//     <div>
//       <button onClick={startListening}>Start Listening</button>
//       <button onClick={stopListening}>Stop Listening</button>
//       <button onClick={reset}>Reset Transcript</button>
//       <span>{transcript}</span>
//     </div>
//   );
// };

// export default VoiceToTextComponent; 


import React, { useState } from "react";
import { useVoiceToText } from "react-speakup";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";

const VoiceToTextComponent = () => {
  const { startListening, stopListening, transcript, reset } = useVoiceToText();
  const [isListening, setIsListening] = useState(false);

  const handleStartListening = () => {
    startListening();
    setIsListening(true);
  };

  const handleStopListening = () => {
    stopListening();
    setIsListening(false);
  };

  const handleReset = () => {
    reset();
    setIsListening(false);
  };

  return (
    <div className="flex items-center space-x-4">
      <button
        className={`p-4 rounded-full ${
          isListening
            ? "bg-blue-500 text-white animate-pulse"
            : "bg-gray-200 hover:bg-gray-300"
        }`}
        onClick={isListening ? handleStopListening : handleStartListening}
      >
        {isListening ? <FaMicrophoneSlash size={24} /> : <FaMicrophone size={24} />}
      </button>
      <span className={isListening ? "text-blue-500" : ""}>{transcript}</span>
      <button
        className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
        onClick={handleReset}
      >
        Reset
      </button>
    </div>
  );
};

export default VoiceToTextComponent;