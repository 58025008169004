
import React, { useEffect, useState } from "react";
import './TestimonialSlider.css'
import { GrFormPreviousLink } from "react-icons/gr";
import { GrFormNextLink } from "react-icons/gr";
import { API_URL } from "../../../BackendApi/Api/config";

function Testimonials({ data, unique_id }) {
    const [testimonials, setTestimonials] = useState([]);


    useEffect(() => {
        if (data.length > 0) {
            console.log("data janaman", data);
            setTestimonials(data);
        }
    }, [data]);

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === testimonials.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? testimonials.length - 1 : prevSlide - 1));
    };

    return (
        <>
            <div className="testimonial-slider" id={unique_id}>
                <div className="testimonial">

                    <button className="prevClick" onClick={prevSlide}><GrFormPreviousLink /></button>
                    <img src={
                        testimonials[currentSlide]?.imagePreview.startsWith('http') || testimonials[currentSlide]?.imagePreview.startsWith('https') ? testimonials[currentSlide]?.imagePreview : API_URL + "flowpage/" + testimonials[currentSlide]?.imagePreview
                        } alt={`Portrait of ${testimonials[currentSlide]?.name}`} className="testimonial-image" />
                    <button className="nextClick" onClick={nextSlide}><GrFormNextLink /></button>
                    <p className="comment">{testimonials[currentSlide]?.review}</p>
                    <p className="name">{testimonials[currentSlide]?.name}</p>
                </div>
              
            </div>
            
        </>
    );
}


export default Testimonials;