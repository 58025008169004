import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { AreaChart } from "../Components/Chart/AreaChart";
import ChartData from "../Components/Chart/Chart";
import "./Metrics.css";
import Header from "../Components/Header/Header";
import { TbChartBar, TbHandClick, TbLiveView, TbScan } from "react-icons/tb";
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { getsmartlinkVisitors, getsmartlinkcountery, matricksDivice, matricksVisits, getflexMatricks } from "../BackendApi/Api/smartsLinkApi";
import { useParams } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Loader2 from "../Components/Loader/Loader2";
import ColumnChart2 from '../Components/Chart/Column';
import { Tab, Tabs } from 'react-bootstrap'
import { API_URL } from "../BackendApi/Api/config";
function Metrics() {

    const params = useParams();

    const type = params.type;

    const navigate = (direction) => {
        window.history.go(direction);
    }
    const [loader, setLoader] = useState(false)
    const [smartlinkVistors, setsmartlinkVistors] = useState([]);
    const [selectedOption, setSelectedOption] = useState("All Time");
    const [smartlinkcountery, setsmartlinkcountery] = React.useState([]);
    const [counterySelectedOption, setCounterySelectedOption] = React.useState("All Time");
    const [matricksdivice, setMatricksdivice] = useState([])
    const [diviceSelectedOption, setdiviceSelectedOption] = React.useState("All Time");
    const [matrickVisits, setMatricksVisits] = useState([])
    const [visitsSelectedOption, setVisitsSelectedOption] = React.useState("All Time");
    const [flexMatricks, setFlexMatricks] = useState([])
    const [ctaMatricks, setCtaMatricks] = useState('')
    const [passwordAttemp, setPasswordAttemp] = useState('')
    const [names, setNames] = useState([])
    const [values, setValues] = useState([])
    const [percentage, setpercentage] = useState([])
    useEffect(() => {
        smartlinkVistor();
        getcounteries();
        matricksDiviceData()
        matricksVisitsData()
        handleflexMatricks()
    }, [selectedOption, counterySelectedOption, diviceSelectedOption, visitsSelectedOption]);

    const smartlinkVistor = async () => {
        setLoader(true);
        var id = {};
        if (type === "campaign") {
            id = {
                "backhalf": params.id,
                "selectedOption": selectedOption,
                "search_By": "campaign_id"
            };
        } else {
            id = {
                "backhalf": params.id,
                "selectedOption": selectedOption,
                "search_By": "backhalf"
            };
        }
        try {
            const res = await getsmartlinkVisitors(id);
            const dataFromApi = res.data.data;
            if (dataFromApi) {
                // console.log("dataFromApi", dataFromApi);
                setsmartlinkVistors(dataFromApi);
                setLoader(false)
            } else {
                setLoader(true)
                PNotify.error({
                    title: "Error",
                    text: "No Data Found",
                });
            }
        } catch (error) {
            setLoader(true)
            PNotify.error({
                title: 'Error',
                text: ('Error fetching data:', error),
            });
            console.error("Error fetching data:", error);
        }
    };
    let viste = smartlinkVistors.smartLink

    const matricksDiviceData = async () => {
        setLoader(true);
        var id = {};
        if (type === "campaign") {
            id = {
                "backhalf": params.id,
                "selectedOption": diviceSelectedOption,
                "search_By": "campaign_id"
            };
        } else {
            id = {
                "backhalf": params.id,
                "selectedOption": diviceSelectedOption,
                "search_By": "backhalf"
            };
        }
        try {
            const res = await matricksDivice(id);
            const dataFromApi = res.data.data;
            // console.log("res", dataFromApi)
            if (dataFromApi) {
                setMatricksdivice(dataFromApi);
                setLoader(false)
            } else {
                setLoader(true)
                PNotify.error({
                    title: "Error",
                    text: "No Data Found",
                });
            }
        } catch (error) {
            setLoader(true)
            PNotify.error({
                title: 'Error',
                text: ('Error fetching data:', error),
            });
            console.error("Error fetching data:", error);
        }
    };

    const deviceData = matricksdivice.countryData
    const matricksVisitsData = async () => {
        setLoader(true);
        var id = {};
        if (type === "campaign") {
            id = {
                "backhalf": params.id,
                "selectedOption": visitsSelectedOption,
                "search_By": "campaign_id"
            };
        } else {
            id = {
                "backhalf": params.id,
                "selectedOption": visitsSelectedOption,
                "search_By": "backhalf"
            };
        }
        try {
            const res = await matricksVisits(id);
            const dataFromApi = res.data.data;
            // console.log("matricksVisits", dataFromApi)
            if (dataFromApi) {
                setMatricksVisits(dataFromApi);
                setLoader(false)
            } else {
                setLoader(true)
                PNotify.error({
                    title: "Error",
                    text: "No Data Found",
                });
            }
        } catch (error) {
            setLoader(true)
            PNotify.error({
                title: 'Error',
                text: ('Error fetching data:', error),
            });
            console.error("Error fetching data:", error);
        }
    };

    const getcounteries = async () => {
        setLoader(true);
        var id = {};

        if (type === "campaign") {
            id = {
                "backhalf": params.id,
                "selectedOption": counterySelectedOption,
                "search_By": "campaign_id"
            };
        } else {
            id = {
                "backhalf": params.id,
                "selectedOption": counterySelectedOption,
                "search_By": "backhalf"
            };
        }


        try {
            const res = await getsmartlinkcountery(id);
            const dataFromApi = res.data.data
            if (dataFromApi) {
                setsmartlinkcountery(dataFromApi.countryData);
                setNames(dataFromApi.name)
                setValues(dataFromApi.value)
                setpercentage(dataFromApi.percentages)
                setLoader(false)
            } else {
                setLoader(true)
                PNotify.error({
                    title: 'Error',
                    text: 'No Data Found',
                });

            }

        } catch (error) {
            setLoader(true)
            console.error("Error fetching data:", error);
            PNotify.error({
                title: 'Error',
                text: ('Error fetching data:', error),
            });
        }
    };

    const handleflexMatricks = () => {
        const data = {
            "backhalf": params.id,
        }

        getflexMatricks(data)
            .then((res) => {
                const dataFromApi = res.data.data;
                console.log("dataFromApi2", dataFromApi);
                if (dataFromApi) {
                    setFlexMatricks(dataFromApi?.flexData);
                    setCtaMatricks(dataFromApi?.ctaResult);
                    setPasswordAttemp(dataFromApi?.getpasswordAttemped);
                    setLoader(false);
                } else {
                    setLoader(true);
                    PNotify.error({
                        title: 'Error',
                        text: res.data.message,
                    });
                }
            })
            .catch((error) => {
                setLoader(false);
                console.error("Error fetching data:", error);
                PNotify.error({
                    title: 'Error',
                    text: 'Error fetching data: ' + error.message,
                });
            });
    }

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const handleSelectCountery = (event) => {
        setCounterySelectedOption(event.target.value);
    };
    const handleSelectdivece = (event) => {
        setdiviceSelectedOption(event.target.value);
    };
    const handleSelectvisits = (event) => {
        setVisitsSelectedOption(event.target.value);
    };

    const nameDta = names !== null && names.map((item, i) => item)
    const valueDta = values !== null && values.map((item) => item)
    const percentageDta = percentage !== null && percentage.map((item) => item)
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', " aqua", '#FFBB90'];
    let color;
    let backgroundColor;

    switch (smartlinkVistors.goal) {
        case "Page Views":
            color = "#00DFF7";
            backgroundColor = "#D6F2F5";
            break;
        case "Product Sales":
            color = "#3b5999"
            backgroundColor = "#acc7ff";
            break;
        case "Affiliate Referrals":
            color = "#E5B700";
            backgroundColor = "#EEE4BC";
            break;
        case "Newsletter Subscribers":
            color = "#EB01FA";
            backgroundColor = "#F5D1F7";
            break;
        case "SaaS Subscribers":
            color = "#f5771f";
            backgroundColor = "#ffd4ba";
            break;
        case "Software Downloads":
            color = "#00F6B0";
            backgroundColor = "#C8F6E9";
            break;
        case "Brand Awareness":
            color = "#d68500";
            backgroundColor = "#ffedca";
            break;
            case "Event Ticket Sales":
                color = "#FF5733";  
                backgroundColor = "#FFD1C1";
                break;
        case "Other":
            color = "#29a71a";
            backgroundColor = "#b3ffaa";
            break;
        default:
            color = "#29a71a";
            backgroundColor = "#b3ffaa";
    }


    const validTypes = ["campaign", "channel", "flexpage"];
    const truncatedUrl = smartlinkVistors.destination_url?.length > 100
        ? `${smartlinkVistors.destination_url.substring(0, 120)}...`
        : smartlinkVistors.destination_url;
    
    return (
        <>
            {loader && <Loader2 />}
            <Header />

            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="col-md-12">
                                <div className="card dashboard-card">
                                    <div className="card-header Metrics-title p-0">
                                        <div className="Back-btn p-0 mb-0">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <button className="Back-btn-icon" onClick={() =>
                                                        navigate(-1)}>
                                                        <IoArrowBackCircleOutline />
                                                    </button>
                                                    <div className="Back-btn-text">
                                                        <h5>Back to {(type === "campaign" && type) || (type === "channel" && "Campaign Channels") || (type === "flexlink" && type) || (type === "flexpage" && type)} </h5>
                                                    </div>
                                                </div>
                                                {/* <button className="Back-btn-icon" onClick={() => navigate(-1)}><IoArrowBackCircleOutline /> Back to {type}</button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-page-body-content-body-container-header">
                                        <div className="campain-view-header">
                                            <div>
                                                <div className="campain-view-header-title">
                                                    <h3>{smartlinkVistors.title}</h3>
                                                </div>
                                                <div>
                                                    {(() => {
                                                        if (smartlinkVistors.goal === "Other") {
                                                            return (
                                                                <div className="campain-view-header-subtitle">
                                                                    <h6>Goal {smartlinkVistors.goal}</h6>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div className="campain-view-header-subtitle d-flex align-items-center mb-3">
                                                                    {validTypes.includes(type) && <h6 className="text-dark mb-0">Goal</h6>}
                                                                    <div className="vanity-tag smartlink-destination ms-3 d-flex align-items-center"   >
                                                                        {smartlinkVistors && type === "flexpage" && (
                                                                            <h4 style={{ color: '#0261ff', backgroundColor: '#dee7ff' }}>Increase Views</h4>
                                                                        )}
                                                                        {smartlinkVistors.goal && (
                                                                            <>
                                                                                <h4 style={{ color: color, backgroundColor: backgroundColor }}>
                                                                                    {smartlinkVistors.goal}
                                                                                </h4>{smartlinkVistors.channel_name != "" && <h4> via {smartlinkVistors.channel_name}</h4>}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })()}
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <h6 className="text-dark mb-0">Destination </h6>
                                                    <div className="vanity-tag smartlink-destination ms-3"   >
                                                        {/* <h4 style={{ color: '#0f28d8', backgroundColor: '#dee5ff' }}>{type === "Flexpage" ? type : "Static-Url"}</h4> */}
                                                        {smartlinkVistors.destination_url && (
                                                            <h4 style={{ color: color, backgroundColor: backgroundColor }}>
                                                                {type === "flexpage" ? type : "Static-Url"}
                                                            </h4>
                                                        )}
                                                    </div>


                                                    <div className="vanity smartlink-destination ">
                                                        <a href={smartlinkVistors.destination_url} target="_blank" style={{ color: '#0f28d8', backgroundColor: '#dee5ff' }}><h6 className="text-dark mb-0 ms-3">{truncatedUrl}</h6></a>
                                                        <div className="long-url-tooltip">
                                                            <p style={{color:"black"}}>{smartlinkVistors.destination_url}</p>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="campain-view-header-subtitle" >
                                                    <p><TbLiveView />{smartlinkVistors?.totalScans + smartlinkVistors?.totalClicks || 0} Visits</p>
                                                    <p><TbHandClick /> {smartlinkVistors?.totalClicks} Clicks</p>
                                                    <p><TbScan />{smartlinkVistors?.totalScans} Scans</p>
                                                </div>
                                            </div>
                                            <div>
                                                {/* <div className="campain-view-details">
                                                            <img src={statusImage} />
                                                            {statusText}
                                                        </div> */}


                                                {/* {currentDate >= smartlinkVistors.start_date && currentDate <= smartlinkVistors.end_date ? (
                                                        <img src={Power} alt="" className="img-fluid" />
                                                    ) : smartlinkVistors.start_date > currentDate && smartlinkVistors.end_date > currentDate ? (

                                                        <img src={Scheduled} alt="" className="img-fluid" />
                                                    ) : smartlinkVistors.end_date < currentDate && smartlinkVistors.start_date < currentDate ? (

                                                        <img src={Completed} alt="" className="img-fluid" />
                                                    ) : null} */}



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content-body mt-3">
                                <Tabs
                                    defaultActiveKey="Traffic-Data"
                                    id="justify-tab-example"
                                    className="mb-3 justify-content-center"
                                    justify
                                >
                                    <Tab eventKey="Traffic-Data" title="Traffic Data">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
                                                <div className="card-title mb-0">
                                                    <h4 className="metrics-title-inner mb-0">
                                                        {/* <TbChartBar className="header-icon" /> */}
                                                        Metrics: Traffic Data
                                                    </h4>
                                                </div>

                                                <div className="Metrics-form-group">
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Select value={selectedOption} onChange={handleSelectChange} as="select" className="Metrics-form-control">
                                                            <option value="Last 7 Days">All Time</option>
                                                            <option value="Today">Today</option>
                                                            <option value="Yesterday">Yesterday</option>
                                                            <option value="Last 7 Days">Last 7 Days</option>
                                                            <option value="Last 30 Days">Last 30 Days</option>
                                                            <option value="Last 90 Days">Last 90 Days</option>
                                                            <option value="Last 6 Months">Last 6 Months</option>
                                                            <option value="Last 1 Year">Last 1 Year</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <AreaChart smartlinkcountery={viste} />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Geo-Tracking" title="Geo Tracking">
                                        <div className="col-md-12">
                                            <div className="card-header Metrics-title p-0 mb-3">
                                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                    <h4 className="card-title metrics-title-inner mb-0">
                                                        Geo Tracking
                                                    </h4>
                                                    <div className="Metrics-form-group">
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Select value={counterySelectedOption} onChange={handleSelectCountery} as="select" className="Metrics-form-control">
                                                                <option value="All Time">All Time</option>
                                                                <option value="Today">Today</option>
                                                                <option value="Yesterday">Yesterday</option>
                                                                <option value="Last 7 Days">Last 7 Days</option>
                                                                <option value="Last 30 Days">Last 30 Days</option>
                                                                <option value="Last 90 Days">Last 90 Days</option>
                                                                <option value="Last 6 Months">Last 6 Months</option>
                                                                <option value="Last 1 Year">Last 1 Year</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="w-60">

                                                    <ChartData smartlinkcountery={smartlinkcountery} />
                                                </div>
                                                <div class="table-container w-40 align-items-center align-self-left">
                                                    <table class="custom-table">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Country Name</th>
                                                                <th>Visits</th>
                                                                <th>Percentage</th>
                                                            </tr>

                                                        </thead>
                                                        {Array.isArray(nameDta) && nameDta.map((name, index) => (
                                                            <tbody key={index}>
                                                                <tr>
                                                                    <td data-label="#">{index + 1}</td>
                                                                    <td data-label="Country Name">{name}</td>
                                                                    <td data-label="Visits">{valueDta[index]}</td>
                                                                    <td data-label="Percentage">{percentageDta[index]}</td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Device-Tracking" title="Device Tracking">
                                        <div className="card-header Metrics-title p-0 mb-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <h4 className="metrics-title-inner">
                                                    Device Tracking
                                                </h4>
                                                <div className="Metrics-form-group">
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Select value={diviceSelectedOption} onChange={handleSelectdivece} as="select" className="Metrics-form-control">
                                                            <option value="All Time">All Time</option>
                                                            <option value="Today">Today</option>
                                                            <option value="Yesterday">Yesterday</option>
                                                            <option value="Last 7 Days">Last 7 Days</option>
                                                            <option value="Last 30 Days">Last 30 Days</option>
                                                            <option value="Last 90 Days">Last 90 Days</option>
                                                            <option value="Last 6 Months">Last 6 Months</option>
                                                            <option value="Last 1 Year">Last 1 Year</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-card-body-inner-right d-flex align-items-center justify-content-center w-100">
                                            <div className="graph-container second-chart w-50 mt-5">
                                                <ColumnChart2 deviceData={deviceData} loading={Loader2} error={"your device api is not working this time"} />
                                            </div>
                                            <div class="table-container w-50 mb-5">
                                                <table class="custom-table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>OS Name</th>
                                                            <th>Percentage</th>
                                                            <th>OS Version</th>
                                                        </tr>
                                                    </thead>
                                                    {deviceData &&
                                                        deviceData.map((visits, index) => (
                                                            <tbody style={{ width: "100%", overflow: "auto", backgroundColor: COLORS[index % COLORS.length], color: "black" }}>
                                                                <tr key={index}>
                                                                    <td data-label="#">{index + 1}</td>
                                                                    <td data-label="Username">{visits._id ? visits._id : "Others"}</td>
                                                                    <td data-label="First Name">{`${visits.value} (${visits.percentage})` ? `${visits.value} (${visits.percentage})` : ""}</td>
                                                                    <td data-label="Last Name">{visits._id ? visits.versions.join(', ') : "10"}</td>
                                                                </tr>
                                                            </tbody>
                                                        ))}

                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Visitor-Log" title="Visitor Log">
                                        <div className="card-header Metrics-title p-0">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <h4 className="metrics-title-inner">
                                                    Visits Tracking
                                                </h4>
                                                <div className="Metrics-form-group">
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Select value={visitsSelectedOption} onChange={handleSelectvisits} as="select" className="Metrics-form-control">
                                                            <option value="All Time">All Time</option>
                                                            <option value="Today">Today</option>
                                                            <option value="Yesterday">Yesterday</option>
                                                            <option value="Last 7 Days">Last 7 Days</option>
                                                            <option value="Last 30 Days">Last 30 Days</option>
                                                            <option value="Last 90 Days">Last 90 Days</option>
                                                            <option value="Last 6 Months">Last 6 Months</option>
                                                            <option value="Last 1 Year">Last 1 Year</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div class="table-container">
                                                <table class="custom-table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>IP Address</th>
                                                            <th>OS Name</th>
                                                            <th>OS Version</th>
                                                            <th>Geo Location</th>
                                                            <th>Date</th>
                                                            <th>Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {matrickVisits && matrickVisits.length > 0 &&
                                                            matrickVisits?.map((visits, index) => ( // Added "index" parameter to track the index
                                                                <tr key={index}>
                                                                    <td data-label="#">{index + 1}</td> {/* Adjusted the index to start from 1 */}
                                                                    <td data-label="IP Address">{visits.ip ? visits.ip : "not available"}</td>
                                                                    <td data-label="OS Name">{visits.os ? visits.os : "not available"}</td>
                                                                    <td data-label="OS Version">{visits.os_Versions ? visits.os_Versions : "not available"}</td>
                                                                    <td data-label="Geo Location">{`${visits.country ? visits.country : "not available"}-${visits.city ? visits.city : "not available"}`}</td>
                                                                    <td data-label="Date">{new Date(visits.createdAt).toDateString()}</td>
                                                                    <td data-label="Time">{new Date(visits.createdAt).toLocaleTimeString()}</td>
                                                                </tr>
                                                            ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                    {flexMatricks && flexMatricks.length > 0 && (
                                        <Tab eventKey="Content-Metrics" title="Content Metrics">
                                            <div className="table-container">
                                                <table className="custom-table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Type</th>
                                                            <th>Link</th>
                                                            <th>Image</th>
                                                            <th>Clicks</th>
                                                            <th>Scans</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {flexMatricks.map((visits, index) => {
                                                            console.log("visis", visits);
                                                            let social_type;
                                                            switch (visits.type) {
                                                                case "ICONS":
                                                                    social_type = `social (${visits.social_type})`;
                                                                    break;
                                                                case "IMAGETEXT":
                                                                    social_type = "IMAGE";
                                                                    break;
                                                                case "IMAGEGRID":
                                                                    social_type = "IMAGEGRID";
                                                                    break;
                                                                case "BUTTON":
                                                                    social_type = "BUTTON";
                                                                    break;
                                                                default:
                                                                    social_type = "Unknown";
                                                            }
                                                            return (
                                                                <tr key={index}>
                                                                    <td data-label="#">{index + 1}</td>
                                                                    <td data-label="Type">{social_type}</td>
                                                                    <td data-label="Link">
                                                                        {
                                                                            visits.link ? (
                                                                                visits.link.length > 30 ? (
                                                                                    <>
                                                                                        {visits.link.slice(0, 30)}...
                                                                                        <a href={visits.link} target="_blank" rel="noopener noreferrer">Open Link</a>
                                                                                    </>
                                                                                ) : (
                                                                                    visits.link
                                                                                )
                                                                            ) : (
                                                                                <span className="no-link">Not available</span>
                                                                            )
                                                                        }
                                                                    </td>

                                                                    <td style={{ textAlign: "center" }} data-label="Clicks">{visits.image_url && (social_type === "IMAGEGRID" || social_type === "IMAGE"|| social_type === "BUTTON") &&
                                                                        <img src={`${API_URL}flowpage/${visits.image_url}`} alt="" style={{ width: "50px",height:"50px", resize: "contain" ,borderRadius:40}} />
                                                                        || "Not available"}</td>
                                                                    <td data-label="Clicks">{visits.clicks || "0"}</td>
                                                                    <td data-label="Scans">{visits.scans || "0"}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab>
                                    )}
                                    {type === "flexlink" && (ctaMatricks !== "" || passwordAttemp !== "") && (
                                        <Tab eventKey="Content-Metrics" title="Content Metrics">

                                            <div className="metrics-smartlink-data">
                                                <h4>Component: CTA button</h4>
                                                <div className="justify-content-between align-items-center flex-wrap">
                                                    <h5>Title: {ctaMatricks?.brand_title}</h5>
                                                    <h6>Link: {ctaMatricks?.destination_url}</h6>
                                                    <h6>Clicks: {ctaMatricks?.button_clicks || 0}</h6>
                                                </div>
                                                <h4>Component: Password Waypoint</h4>
                                                <div className="justify-content-between align-items-center flex-wrap">
                                                    <h6>Successful Attempts: {passwordAttemp?.password_attemped_successfull||0}</h6>
                                                    <h6>Failed Attempts: {passwordAttemp?.password_attemped_failed||0}</h6>
                                                </div>
                                            </div>
                                        </Tab>
                                    )}

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Metrics;