// import React, { useState } from 'react';
// import { Button, Modal } from 'react-bootstrap';
// import { FaFacebookF, FaTwitter, FaShareAlt, FaLinkedinIn, FaEnvelope, FaWhatsapp, FaInstagram, FaSnapchatGhost, FaRedditAlien, FaPinterestP, FaDiscord, FaTelegramPlane, FaTwitch, FaViber, FaSkype } from 'react-icons/fa';
// import { MdMessage } from 'react-icons/md';
// import { FaXTwitter } from "react-icons/fa6";

// // Make sure to import the Bootstrap CSS in your main app file:
// // import 'bootstrap/dist/css/bootstrap.min.css';

// const SocialShareModal = ({ url, title, description }) => {
//   const [show, setShow] = useState(false);
//   const [hovered, setHovered] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const encodedUrl = encodeURIComponent(url);
//   const encodedTitle = encodeURIComponent(title);
//   const encodedDescription = encodeURIComponent(description);

//   const shareLinks = [
//     { name: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, icon: <FaFacebookF size={24} />, color: '#1877f2' },
//     { name: 'X (Twitter)', url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`, icon: <FaXTwitter size={24} />, color: '#000000' },
//     { name: 'LinkedIn', url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`, icon: <FaLinkedinIn size={24} />, color: '#0a66c2' },
//     { name: 'Email', url: `mailto:?subject=${encodedTitle}&body=${encodedDescription}%0A%0A${encodedUrl}`, icon: <FaEnvelope size={24} />, color: '#ea4335' },
//     { name: 'WhatsApp', url: `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`, icon: <FaWhatsapp size={24} />, color: '#25D366' },
//     { name: 'Instagram', url: 'https://www.instagram.com/', icon: <FaInstagram size={24} />, color: '#E4405F' },
//     { name: 'Snapchat', url: 'https://www.snapchat.com/', icon: <FaSnapchatGhost size={24} />, color: '#FFFC00' },
//     { name: 'Reddit', url: `https://reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`, icon: <FaRedditAlien size={24} />, color: '#FF4500' },
//     { name: 'Pinterest', url: `https://pinterest.com/pin/create/button/?url=${encodedUrl}&media=&description=${encodedTitle}`, icon: <FaPinterestP size={24} />, color: '#E60023' },
//     { name: 'Telegram', url: `https://t.me/share/url?url=${encodedUrl}&text=${encodedTitle}`, icon: <FaTelegramPlane size={24} />, color: '#0088cc' },
//     { name: 'Discord', url: 'https://discord.com/', icon: <FaDiscord size={24} />, color: '#5865F2' },
//     { name: 'Twitch', url: 'https://www.twitch.tv/', icon: <FaTwitch size={24} />, color: '#9146FF' },
//     { name: 'Skype', url: 'https://web.skype.com/', icon: <FaSkype size={24} />, color: '#00AFF0' },
//     { name: 'Viber', url: 'https://www.viber.com/', icon: <FaViber size={24} />, color: '#665CAC' },
//     { name: 'Teams', url: 'https://teams.microsoft.com/', icon: <MdMessage size={24} />, color: '#6264A7' },
//   ];

//   return (
//     <>
//       <Button
//         variant="outline-primary"
//         onClick={handleShow}
//         className="d-flex align-items-center"
//         onMouseEnter={() => setHovered(true)}
//         onMouseLeave={() => setHovered(false)}
//       >
//         <FaShareAlt size={16} className="me-2" />
//         {hovered && <span>Share</span>}
//       </Button>

//       <Modal show={show} onHide={handleClose} centered size="lg">
//         <Modal.Body>
//           <div className="d-flex flex-wrap justify-content-center">
//             {shareLinks.map((link) => (
//               <a
//                 key={link.name}
//                 href={link.url}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="m-2 d-flex flex-column align-items-center"
//                 style={{ color: link.color, textDecoration: 'none', width: '60px' }}
//               >
//                 {link.icon}
//                 <span className="mt-1 text-center" style={{ fontSize: '0.7rem', color: '#333' }}>{link.name}</span>
//               </a>
//             ))}
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default SocialShareModal;


import React, { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaShareAlt, FaLinkedinIn, FaEnvelope, FaWhatsapp, FaInstagram, FaSnapchatGhost, FaRedditAlien, FaPinterestP, FaDiscord, FaTelegramPlane, FaTwitch, FaViber, FaSkype } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { MdMessage } from 'react-icons/md';


// Make sure to import the Bootstrap CSS in your main app file:
// import 'bootstrap/dist/css/bootstrap.min.css';

const SocialShareModal = ({ url, title, description }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);

  const shareLinks = [
        { name: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, icon: <FaFacebookF size={24} />, color: '#1877f2' },
        { name: 'X (Twitter)', url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`, icon: <FaXTwitter size={24} />, color: '#000000' },
        { name: 'LinkedIn', url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`, icon: <FaLinkedinIn size={24} />, color: '#0a66c2' },
        { name: 'Email', url: `mailto:?subject=${encodedTitle}&body=${encodedDescription}%0A%0A${encodedUrl}`, icon: <FaEnvelope size={24} />, color: '#ea4335' },
        { name: 'WhatsApp', url: `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`, icon: <FaWhatsapp size={24} />, color: '#25D366' },
        { name: 'Instagram', url: 'https://www.instagram.com/', icon: <FaInstagram size={24} />, color: '#E4405F' },
        { name: 'Snapchat', url: 'https://www.snapchat.com/', icon: <FaSnapchatGhost size={24} />, color: '#FFFC00' },
        { name: 'Reddit', url: `https://reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`, icon: <FaRedditAlien size={24} />, color: '#FF4500' },
        { name: 'Pinterest', url: `https://pinterest.com/pin/create/button/?url=${encodedUrl}&media=&description=${encodedTitle}`, icon: <FaPinterestP size={24} />, color: '#E60023' },
        { name: 'Telegram', url: `https://t.me/share/url?url=${encodedUrl}&text=${encodedTitle}`, icon: <FaTelegramPlane size={24} />, color: '#0088cc' },
        { name: 'Discord', url: 'https://discord.com/', icon: <FaDiscord size={24} />, color: '#5865F2' },
        { name: 'Twitch', url: 'https://www.twitch.tv/', icon: <FaTwitch size={24} />, color: '#9146FF' },
        { name: 'Skype', url: 'https://web.skype.com/', icon: <FaSkype size={24} />, color: '#00AFF0' },
        { name: 'Viber', url: 'https://www.viber.com/', icon: <FaViber size={24} />, color: '#665CAC' },
        { name: 'Teams', url: 'https://teams.microsoft.com/', icon: <MdMessage size={24} />, color: '#6264A7' },
      ];
    

  return (
    <>
      {/* Share button with tooltip */}
      <div className="d-flex align-items-center">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Share</Tooltip>}
        >
          <Button variant="outline-primary" onClick={handleShow} className="d-flex align-items-center">
            <FaShareAlt size={20} />
          </Button>
        </OverlayTrigger>
      </div>

      {/* Modal for social media links */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <div className="d-flex flex-wrap justify-content-center">
            {shareLinks.map((link) => (
              <a
                key={link.name}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="m-2 d-flex flex-column align-items-center"
                style={{ color: link.color, textDecoration: 'none', width: '60px' }}
              >
                {link.icon}
                <span className="mt-1 text-center" style={{ fontSize: '0.7rem', color: '#333' }}>{link.name}</span>
              </a>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SocialShareModal;

