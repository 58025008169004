import { createContext, useState, useReducer, useMemo } from "react";

import { brandQrTemplateReducer } from '../reducers/brandQrTemplateReducer'


export const brandQrTemplateContext = createContext();


export function BrandQrTemplateProvider({ children }) {

    const initialState = {
        brandQrTemplates: [],
    };


    const [brandQrTemplate, brandQrTemplateDispatch] = useReducer(brandQrTemplateReducer,initialState);

    return (
        <brandQrTemplateContext.Provider value={{brandQrTemplate,brandQrTemplateDispatch}}>
            {children}
        </brandQrTemplateContext.Provider>
    );
}

