import React, { useRef, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { MdReviews } from "react-icons/md";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { IoIosContacts } from "react-icons/io";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
export function CountactList() {

    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <IoIosContacts />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Contact Us</h2>
                            <p className="add-block-btn-text-desc">Add a Contact Us to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}


export function ContactAdd() {

    const location = useLocation();

    const frameType = location.state != undefined ? "update" : "add";
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus

    const navigate = useHistory();
    const { addComponent, editComponent } = useBuilderContext();
    const unique_id = Date.now() + uuidv4();

    const [contactus, setContactUs] = useState({
        address: "",
        phone: "",
        email: "",
        website: "",
        unique_id: unique_id

    });

    React.useEffect(() => {


        if (frameType === "update") {
            setContactUs({
                address: mydata.address,
                phone: parseInt(mydata.phone),
                email: mydata.email,
                website: mydata.website,
                unique_id: mydata.unique_id
            })
        }

    }, [frameType]);



    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Contact</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="did-floating-label-content form-group">
                        <input type="text"
                            value={contactus.address}
                            onChange={(e) => setContactUs({ ...contactus, address: e.target.value })}

                            className="did-floating-input form-control" placeholder="" />
                        <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">Address</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="did-floating-label-content form-group">
                        <input type="number"
                            value={contactus.phone}
                            onChange={(e) => setContactUs({ ...contactus, phone: e.target.value })}
                            className="did-floating-input form-control" placeholder="" />
                        <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">Contact Number</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="did-floating-label-content form-group">
                        <input type="email"
                            value={contactus.email}
                            onChange={(e) => setContactUs({ ...contactus, email: e.target.value })}
                            className="did-floating-input form-control" placeholder="" />
                        <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">Email</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="did-floating-label-content form-group">
                        <input type="text"
                            value={contactus.website}
                            onChange={(e) => setContactUs({ ...contactus, website: e.target.value })} className="did-floating-input form-control" placeholder="" />

                        <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">Website Link</label>
                    </div>
                </div>
                <div className="add-block-btn">
                    {
                        frameType === "update" ?
                            <button className="btn publish-btn" onClick={
                                () => {
                                    editComponent(position, "CONTACTUS", contactus, showStatus);
                                    navigate.push("/");
                                }
                            }>
                                Update

                            </button>
                            :
                            <button className="btn publish-btn" onClick={
                                () => {
                                    addComponent("CONTACTUS", contactus);
                                    navigate.push("/");
                                }
                            }>
                                Add
                            </button>
                    }

                </div>
            </div>
        </>
    );
}