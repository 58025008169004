import React from "react";
import { SiInstagram } from "react-icons/si";
import { BsWhatsapp } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsTiktok } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { BsPaypal } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsPinterest } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";


import { FaYoutube, FaTwitter } from "react-icons/fa";

function BuilderIcon(props) {

    const saveClicks = (smartlink_id, unique_id) => {
        saveComponentsClicks({
            unique_id: unique_id,
            smartlink_id: smartlink_id,

        });
    }


    const socials = Object.values(props.data.items);

    return (
        <>
            <div className="social_item_list" id={props.unique_id} >
               {socials.map((item, index) => (
                <>
                {
                    item.type== "FACEBOOK" ?<a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"> <BsFacebook /></a> :

                    item.type== "INSTAGRAM" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><SiInstagram /></a> :

                    item.type== "WHATSAPP" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><BsWhatsapp /></a> :

                    item.type== "TWITTER" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><FaTwitter /></a> :

                    item.type== "LINKEDIN" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><BsLinkedin /></a> :

                    item.type== "YOUTUBE" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><FaYoutube /></a> :

                    item.type== "TIKTOK" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><BsTiktok /></a> :

                    item.type== "SNAPCHAT" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><BsSnapchat /></a> :

                    item.type== "PAYPAL" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><BsPaypal /></a> :

                    item.type== "TELEGRAM" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><BsTelegram /></a> :

                    item.type== "PINTEREST" ? <a href={item.url} onClick={() => {saveClicks(props.smartlink_id, item.unique_id)}} target="_blank"><BsPinterest /></a> :""

               }
               </>
               ))}
            </div>
        </>
    );
}

export default BuilderIcon;