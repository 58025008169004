import { useState, useEffect } from 'react';

const { API_URL } = require("../../../src/BackendApi/Api/config");

const useImageTransparency = (src) => {
    const [isTransparent, setIsTransparent] = useState(null); // Initialize as null to indicate loading state
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const checkTransparency = (imageSrc) => {
            const img = new Image();
            img.crossOrigin = 'anonymous'; // Enable cross-origin access for the image
            img.src = imageSrc;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0); // Draw the image onto the canvas

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                // Check for transparency in the image
                for (let i = 3; i < data.length; i += 4) {
                    if (data[i] < 255) { // The alpha value
                        setIsTransparent(true); // Found a transparent pixel
                        return;
                    }
                }
                setIsTransparent(false); // No transparent pixels found
            };

            img.onerror = () => {
                console.error("Error loading image");
            };
        };

        if (src) {
            if (src instanceof File) {
                const url = URL.createObjectURL(src); // Create a blob URL for file input
                setImageUrl(url);
                checkTransparency(url); // Check transparency for the blob URL

                return () => URL.revokeObjectURL(url); // Clean up the blob URL
            } else if (typeof src === 'string') {
                console.log("srcsrc", src);

                setImageUrl(src); // Set the image URL if it's a string
                checkTransparency(src); // Check transparency for the image URL
            }
        }
    }, [src]); // Run effect when src changes
    console.log("image url", imageUrl);
    console.log("isTransparent", isTransparent);
    
    
    return { isTransparent, imageUrl }; // Return transparency status and image URL
};

export default useImageTransparency;
