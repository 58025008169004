import React, { useEffect, useRef, useState } from "react";
import { MdSmartButton } from "react-icons/md";
import { FaChevronRight, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useBuilderContext } from "../../../builderContext";
import { useHistory, } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { API_URL } from "../../../BackendApi/Api/config";
import { Form } from "react-bootstrap";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";
import EmojiPicker from 'emoji-picker-react';
import { icons } from "./../../../Helpers/socialsImages"
import { BsChevronDown } from "react-icons/bs";
import CustomDropdown from "../../../Helpers/CustomDropdown";
export function ButtonLinkList() {

    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdSmartButton />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Button Link</h2>
                            <p className="add-block-btn-text-desc">Add a button to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}



export function ButtonLinkAdd() {
    const navigate = useHistory();
    const { addComponent, myComponents } = useBuilderContext();
    const profileImageRef = useRef(null);


    const [btnProperties, setBtnProperties] = useState({
        label: "Button",
        link: "#",
        thumbnailPosition: "left",
        thumbnail: null,
        backgroundColor: "#ACO21",
        buttonStyle: "filled",
        buttonShape: "rectangle",
        hoverAnimation: "fill",
        size: "standard",
        emojis: null,
        animation: "none",
        jumpToAnchor: "",
        subtext: "",
        selectedOption: 0,
        subtextSize: "",
        unique_id: uuidv4(),
    });
    const [selectedColor, setSelectedColor] = useState(btnProperties?.backgroundColor);
    const [thumbnail, setThumbnail] = useState(null);
    const [showPicker, setShowPicker] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedIcons, setSelectedIcons] = useState([]);
    const [selectedOption2, setSelectedOption2] = useState(0);

    const handleOptionChange2 = (value) => {
        setBtnProperties(prev => ({ ...prev, selectedOption: value }));
        clearThumbnail();
        setBtnProperties(prev => ({ ...prev, thumbnail: null, emojis: null }));
        setSelectedOption2(value);
    };
    const handleThumbnailChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setThumbnail(imageUrl);
            setBtnProperties(prev => ({
                ...prev,
                thumbnail: file,
                emojis: null
            }));
        }
    };


    const clearThumbnail = () => {
        if (thumbnail && thumbnail.startsWith('blob:')) {
            URL.revokeObjectURL(thumbnail);
        }
        setThumbnail(null);
        setBtnProperties(prev => ({ ...prev, thumbnail: null, emojis: null }));
        if (profileImageRef.current) {
            profileImageRef.current.value = '';
        }
    };

    const handleEmojiClick = (emojiObject) => {
        const emoji = emojiObject.emoji;
        setBtnProperties(prev => ({
            ...prev,
            emojis: emoji,
            thumbnail: null
        }));
        setThumbnail(null);
    };

    const handleBtnsave = async () => {
        if (thumbnail || btnProperties?.thumbnail || btnProperties?.emojis) {
            try {
                const formData = new FormData();
                formData.append("image", btnProperties.thumbnail);
                const res = await uploadSingleImageApi(formData);

                if (res.data.status === true) {
                    const uploadedImageUrl = res.data.data.filename;

                    const unique_id = Date.now() + uuidv4();
                    const buttonProps = {
                        ...btnProperties,
                        unique_id,
                        size: btnProperties.size,
                        buttonShape: btnProperties.buttonShape,
                        buttonStyle: btnProperties.buttonStyle,
                        hoverAnimation: btnProperties.hoverAnimation,
                        emoji: btnProperties?.emojis,
                        backgroundColor: btnProperties?.backgroundColor,
                        thumbnailPosition: btnProperties.thumbnailPosition,
                        link: btnProperties.link,
                        label: btnProperties.label,
                        thumbnail: uploadedImageUrl || btnProperties.thumbnail,
                        subtext: btnProperties.subtext,
                        subtextSize: btnProperties.subtextSize,
                        animation: btnProperties.animation,
                        jumpToAnchor: btnProperties.jumpToAnchor
                    };

                    addComponent("BUTTON", buttonProps);
                    navigate.push("/");
                } else {
                    addComponent("BUTTON", btnProperties);
                }
            } catch (error) {
                console.error("Image upload failed", error);
            }
        }
    };
    const handleBackgroundColorChange1 = (color) => {
        setSelectedColor(color);
        setBtnProperties(prev => ({ ...prev, backgroundColor: color }));
    };

    const presetColors = [
        "#a94002",
        "#d2b43f",
        "#3e734b",
        "#fcd066",
        "#b29038",
        "#4c3a05"
    ];



    const handleCategoryChange = (e) => {
        const category = e.target.value;
        setSelectedCategory(category);
        setSelectedIcons(icons[category] || []);
    };
    const handleIconClick = (icon) => {
        setThumbnail(icon.link);
        setBtnProperties(prev => ({
            ...prev,
            thumbnail: icon.link,
            emojis: null
        }));
    };


    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor="" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Button Link</label>
                    </div>
                </div>

                <div className="form_box cvtPmc">
                    <div className="position-relative">
                        <div className="image-input-container mb-4" style={{ position: 'relative' }}>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleThumbnailChange}
                                ref={profileImageRef}
                                style={{ display: 'none' }}
                            />
                            <div className="preview_image_box">
                                {btnProperties?.emojis ? (
                                    <span style={{
                                        fontSize: '2em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%'
                                    }}>
                                        {btnProperties?.emojis}
                                    </span>
                                ) : btnProperties?.thumbnail ? (
                                    <img
                                        src={thumbnail}
                                        alt="Selected"
                                        className="preview-image"
                                    />
                                ) : (
                                    <img
                                        src={require('../../../assets/images/image_default.png')}
                                        alt="Default"
                                        className="default-image"
                                    />
                                )}
                            </div>


                            {(selectedOption2 === 1 || selectedOption2 === 0) && (
                                <div className="image-input" onClick={() => profileImageRef.current.click()}>
                                    <span className="icon">📷</span>
                                    <span className="text">Upload Thumbnail (PNG, JPG or GIF)</span>

                                </div>
                            )}
                            {selectedOption2 === 2 && (
                                <div className="image-input" onClick={() => { selectedOption2 === 2 ? setShowPicker(prevState => !prevState) : profileImageRef.current.click() }}>
                                    {btnProperties.emojis || selectedOption2 == 2 ? (
                                        <span className="text" >
                                            {btnProperties.emojis ? 'Change Emoji' : ' 😚 Choose Profession Emoji'}</span>) :
                                        (<>
                                            <span className="icon">📷</span>
                                            <span className="text">Upload Thumbnail (PNG, JPG or GIF)</span></>)}

                                </div>
                            )}

                            {/* Show delete button if there's any content */}
                            {(btnProperties.thumbnail || btnProperties.emojis) && (
                                <button
                                    style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        color: 'red',
                                        cursor: 'pointer',
                                        width: '20px',
                                        height: '50px',
                                    }}
                                    onClick={clearThumbnail}
                                >
                                    <FaTrash size={20} />
                                </button>
                            )}
                        </div>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Form>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="From icon"
                                                type={type}
                                                id={`inline-${type}-1005`}
                                                checked={selectedOption2 === 1}
                                                onChange={() => handleOptionChange2(1)}
                                            />
                                            <Form.Check
                                                inline
                                                label="Emoji"
                                                type={type}
                                                id={`inline-${type}-1006`}
                                                onChange={() => handleOptionChange2(2)}
                                                checked={selectedOption2 === 2}
                                            />

                                        </div>

                                    ))}


                                </Form>

                                {selectedOption2 == 2 &&
                                    <button
                                        style={{ border: '1px solid lightgray', backgroundColor: 'transparent', color: 'black', cursor: 'pointer', marginBottom: '10px', padding: '5px' }}
                                        onClick={() => selectedOption2 === 2 && setShowPicker(prevState => !prevState)}>
                                        {btnProperties.emojis ? 'Change Emoji' : ' 😚 Choose Emoji'}
                                    </button>}
                            </div>
                            {selectedOption2 === 1 &&
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: "row", position: "relative" }}>
                                        <select id="categoryDropdown" value={selectedCategory} className="form-control" onChange={handleCategoryChange}>
                                            <option value="">Select Category</option>
                                            {Object.keys(icons).map((category, index) => (
                                                <option key={index} value={category}>
                                                    {category.charAt(0).toUpperCase() + category.slice(1)}
                                                </option>
                                            ))}
                                        </select>
                                        <BsChevronDown style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }} />
                                    </div>
                                    {selectedCategory && (
                                        <div style={{ marginTop: '20px' }}>
                                            <p>{selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Icons:</p>
                                            {selectedIcons.map((icon, index) => (
                                                <div style={{ display: 'inline-block', margin: '10px' }} onClick={() => handleIconClick(icon)} key={index}>
                                                    <img
                                                        src={icon.link}
                                                        alt={icon.name}
                                                        style={{ width: 50, height: 50 }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            }
                            {selectedOption2 === 2 &&

                                <div className="form-group mb-4" >


                                    {showPicker && (
                                        <EmojiPicker
                                            onEmojiClick={(emoji) => {
                                                handleEmojiClick(emoji);
                                                setShowPicker(false); // Close picker after emoji selection
                                            }}
                                        />
                                    )}

                                    {btnProperties.thumbnail && (
                                        <div className="emoji-thumbnail mt-2">
                                            <span style={{ fontSize: '2em' }}>{btnProperties.thumbnail}</span>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>

                        <div className="form-group did-floating-label-content mt-4">
                            <div className="form-group mb-4">
                                <select
                                    value={btnProperties.animation}
                                    onChange={(e) => {
                                        setBtnProperties(prev => ({ ...prev, animation: e.target.value }));
                                    }}
                                    className="form-control did-floating-input"
                                >
                                    <option value="none">No Animation</option>
                                    <option value="pulsing">Pulsing</option>
                                    <option value="shake">Shake every 5 seconds</option>
                                </select>
                                <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Animation</label>
                            </div>
                        </div>
                        <div className="form-group did-floating-label-content">
                            <div className="form-group mb-4">
                                <select
                                    value={btnProperties.subtextSize}
                                    onChange={(e) => setBtnProperties(prev => ({ ...prev, subtextSize: e.target.value }))}
                                    className="form-control did-floating-input"
                                >
                                    <option value="standard">Standard</option>
                                    <option value="tall">Tall (Title + Subtext)</option>
                                </select>
                                <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label"> subtext size </label>
                            </div>
                        </div>

                        {btnProperties.subtextSize === 'tall' && (
                            <div className="form-group did-floating-label-content mt-4">
                                <div className="form-group mb-4">
                                    <input
                                        type="text"
                                        className="form-control did-floating-input"
                                        value={btnProperties.subtext}
                                        onChange={(e) => setBtnProperties(prev => ({ ...prev, subtext: e.target.value }))}
                                        placeholder="Button Subtext"
                                    />
                                    <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Subtext</label>
                                </div>
                            </div>

                        )}
                        <div className="form-group did-floating-label-content mt-4">
                            {/* Custom Dropdown */}
                            <CustomDropdown
                                jumpToAnchor={btnProperties.jumpToAnchor}
                                setBtnProperties={setBtnProperties}
                                myComponents={myComponents}
                            />



                            {/* Label Input */}
                            <div className="form-group did-floating-label-content mt-4">
                                <div className="form-group mb-4">
                                    <input
                                        type="text"
                                        className="form-control did-floating-input"
                                        placeholder="Button Label"
                                        value={btnProperties.label}
                                        onChange={(e) => setBtnProperties(prev => ({ ...prev, label: e.target.value }))}
                                    />
                                    <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Label</label>
                                </div>
                            </div>
                            {/* Link Input */}
                            <div className="form-group did-floating-label-content">
                                <div className="form-group mb-4">
                                    <input
                                        type="text"
                                        className="form-control did-floating-input"
                                        placeholder="Button Link"
                                        value={btnProperties.link}
                                        onChange={(e) => setBtnProperties(prev => ({ ...prev, link: e.target.value }))}
                                    />
                                    <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Link</label>
                                </div>
                            </div>
                            {/* Thumbnail Position */}
                            <div className="form-group did-floating-label-content">
                                <div className="form-group mb-4">
                                    <select
                                        value={btnProperties.thumbnailPosition}
                                        onChange={(e) => {
                                            setBtnProperties(prev => ({ ...prev, thumbnailPosition: e.target.value }));
                                        }}
                                        className="form-control did-floating-input"
                                    >
                                        <option value="left">Left</option>
                                        <option value="right">Right</option>
                                    </select>
                                    <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Thumbnail Position</label>
                                </div>
                            </div>
                            <div className="form-group did-floating-label-content">
                                {/* Thumbnail Size */}
                                <div className="form-group mb-4">
                                    <select
                                        value={btnProperties.size}
                                        onChange={(e) => { setBtnProperties(prev => ({ ...prev, size: e.target.value })); }}
                                        className="form-control did-floating-input"
                                    >
                                        <option value="small">Small</option>
                                        <option value="large">Large</option>
                                    </select>
                                    <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Thumbnail Size</label>
                                </div>
                            </div>
                            {/* Button Shape */}
                            <div className="form-group did-floating-label-content">
                                <div className="form-group mb-4">
                                    <select
                                        value={btnProperties.buttonShape}
                                        onChange={(e) => { setBtnProperties(prev => ({ ...prev, buttonShape: e.target.value })); }}
                                        className="form-control did-floating-input"
                                    >
                                        <option value="rectangle">Rectangle</option>
                                        <option value="curvedRectangle">Curved Rectangle</option>
                                        <option value="rounded">Rounded</option>
                                    </select>
                                    <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Button Shape</label>
                                </div>
                            </div>
                            {/* Button Style */}
                            <div className="form-group did-floating-label-content">
                                <div className="form-group mb-4">
                                    <select
                                        value={btnProperties.buttonStyle}
                                        onChange={(e) => { setBtnProperties(prev => ({ ...prev, buttonStyle: e.target.value })); }}
                                        className="form-control did-floating-input"
                                    >
                                        <option value="standard">Standard</option>
                                        <option value="outline">Outline</option>
                                        <option value="shadow">Shadow</option>
                                    </select>
                                    <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Button Style</label>
                                </div>
                            </div>
                            {/* On Hover Animation */}
                            <div className="form-group did-floating-label-content">
                                <div className="form-group mb-4">
                                    <select
                                        value={btnProperties.hoverAnimation}
                                        onChange={(e) => { setBtnProperties(prev => ({ ...prev, hoverAnimation: e.target.value })); }}
                                        className="form-control did-floating-input"
                                    >
                                        <option value="fill-empty">Fill/Empty</option>
                                        <option value="grow-shrink">Grow/Shrink</option>
                                        <option value="shake">Shake</option>
                                    </select>
                                    <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">On Hover Animation</label>
                                </div>
                            </div>
                            <div className="default-color-picker-list">
                                {presetColors.map((color, index) => (
                                    <div key={index} style={{
                                        width: 40,
                                        height: 40,
                                        margin: "8px",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                        border: selectedColor === color ? '2px solid aqua' : 'none' // Highlight selected color
                                    }}>
                                        <button
                                            className="default-color-picker-list-item-inner"
                                            style={{
                                                backgroundColor: color,
                                                borderRadius: '50%', // Make it a circle
                                                width: '100%',
                                                height: '100%',
                                                border: 'none', // Remove default button border
                                            }}
                                            onClick={() => handleBackgroundColorChange1(color)}
                                        ></button>
                                    </div>
                                ))}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleBtnsave}>
                    Add
                </button>
            </div>
        </>
    );
}
