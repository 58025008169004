import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { MdPages } from 'react-icons/md';
import { API_URL } from '../../BackendApi/Api/config';

const UserInputModal = ({ show, handleClose, Event_id }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        image: null,
        imagePreview: null,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setFormData((prev) => ({
                ...prev,
                image: file,
                imagePreview: imageUrl,
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDataToSubmit = new FormData();
        formDataToSubmit.append('userName', formData.name);
        formDataToSubmit.append('email', formData.email);
        formDataToSubmit.append('event_image', formData.image);
        formDataToSubmit.append('Event_id', Event_id);

        try {
            const response = await fetch(`${API_URL}event/applyEvent`, {
                method: 'POST',
                body: formDataToSubmit,
            });
            const result = await response.json();
            console.log("response", result);
            if (result.status) {
                handleClose();
            } else {
                alert(result.message);
            }
        } catch (error) {
            alert('Error: ' + error.message);
        }
    };
    

    return (
        <Modal show={show} onHide={handleClose} centered className="mini-page-modal Sidebar-Modal">
            <Modal.Header closeButton style={{
                background: "linear-gradient(90deg, #0b10e7 0%, #2adf63 100%)",
                color: "#fff",
                padding: "14px 22px",
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5
            }}>
                <Modal.Title className="delete-modal-title"><MdPages /> User Apply</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custum-modal-body" style={{ padding: 20, margin: 10 }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className='col-lg-12 did-floating-label-content' controlId="formBasicName">
                        <input type="text" className="did-floating-input form-control" placeholder="Enter your name"
                            value={formData.name}
                            name="name"
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Label className="did-floating-label">Name</Form.Label>
                    </Form.Group>
                    <Form.Group className='col-lg-12 did-floating-label-content' controlId="formBasicEmail">
                        <input type="text" className="did-floating-input form-control" placeholder="Enter your email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Label className="did-floating-label">Email</Form.Label>
                    </Form.Group>
                    <Form.Group controlId="formImage">
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </Form.Group>
                    {formData.imagePreview && (
                        <div className="mt-3">
                            <img
                                src={formData.imagePreview}
                                alt="Preview"
                                style={{ width:"200px", height: 'auto' }}
                            />
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="Active-bttn btn" onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserInputModal;
