import React, { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import GoogleMapReact from 'google-map-react';
import UserInputModal from "../../../EventBuilder/Components/UserInputModal";
import { API_URL } from "../../../BackendApi/Api/config";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Event(props) {
    const [showModal, setShowModal] = useState(false);
    const [userEvents, setUserEvents] = useState([]);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };
    const getEvents = async () => {
        try {
            const response = await fetch(`${API_URL}event/getEvent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ Event_id: props.smartlink_id }),
            });
            const data = await response.json();
            if (data.status) {
                setUserEvents(data.data);
            } else {
                alert(data.message);
            }
        } catch (error) {
            alert(error);
        }
    };

    useEffect(() => {
        if (props.smartlink_id) {
            getEvents(props.smartlink_id);
        }
    }, [props, showModal]);
    let eventUserdata = userEvents?.userEvents
    console.log("userEvents", eventUserdata)
    const renderUserNames = (userData) => {
        if(!userData) return '';
        const maxVisible = 2; // Number of names to display
        const totalUsers = userData?.length;

        if (totalUsers === 0) return ''; // No users to display
        if (totalUsers <= maxVisible) {
            return userData.join(', '); // Show all names
        }

        // Display the first two names and the count of remaining users
        const displayedNames = userData.slice(0, maxVisible).join(', ');
        const remainingCount = totalUsers - maxVisible;

        return `${displayedNames} and ${remainingCount} others`;
    };
    return (
        <>
            <UserInputModal Event_id={props.smartlink_id} show={showModal} handleClose={handleClose} />

            <div className="event-section" id={props.data.unique_id}>
                <div className="event-section-inner">
                    <div className="event-tper-sec">
                        <div className="event-type-box">
                            <div className="event-type-icon">
                                <BsStars />
                            </div>
                            <div className="event-type-text">
                                {props.data.eventType} Event
                            </div>
                        </div>
                    </div>
                    <div className="event-title">
                        <h2 className="m-0">{props.data.eventTitle}</h2>
                    </div>
                    <div className="event-date-sec">
                        <div className="event-date-inner">
                            <div className="date-icon">
                                <div className="month-name">
                                    MAR
                                </div>
                                <div className="day-name">
                                    20
                                </div>
                            </div>
                            <div className="event-date-text">
                                <div className="event-date">
                                    Wednesday, March 20
                                </div>
                                <div className="event-time">
                                    10:00 AM - 11:00 AM
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="event-location-sec mt-2">
                        <div className="event-date-inner">
                            <div className="date-icon d-flex align-items-center justify-content-center">
                                <MdLocationOn />
                            </div>
                            <div className="event-date-text">
                                {/* <div className="event-date">
                                    Mel's Drive-In
                                </div>
                                <div className="event-time">
                                    San Francisco, California
                                </div> */}
                                <div className="event-date">
                                    {props.data.location}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="Registration-sec">
                        <div className="registration-inner">
                            <div className="registration-header">
                                Registration
                            </div>
                            <div className="inner">
                                <div className="jsx-3830652295">
                                    <div className="jsx-3830652295 flex-column content">
                                        <div className="jsx-1768947162 info-rows flex-column one">
                                            <div className="jsx-1768947162 d-flex align-items-center flex-align one">
                                                <div className="icon-box">
                                                    <BsStars />
                                                </div>
                                                <div className="event-date-text">
                                                    <div className="event-date">
                                                        Approval Required
                                                    </div>
                                                    <div className="event-time">
                                                        Your registration is subject to approval by the host.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="jsx-3363824362 message">Welcome! To join the event, please register below. </div>
                                        {/* <div className="jsx-1768947162 d-flex align-items-center flex-align one">
                                            <div className="icon-box-img">
                                                <div className="bg-avtar"></div>
                                            </div>
                                            <div className="event-date-text">
                                                <div className="event-date">
                                                    Your Name
                                                </div>
                                                <div className="event-time">
                                                    emp2022opti@gmail.com
                                                </div>
                                            </div>
                                        </div> */}
                                        <button className="btn one-click" onClick={handleShow}>One Click Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="event-description-sec mt-3">
                        <div className="event-description-title event-xyt">
                            About Event
                        </div>
                        <div className="event-description-text">
                            {props.data.description}
                        </div>
                    </div>
                    <div className="event-description-sec location mt-3">
                        <div className="event-description-title event-xyt">
                            Location
                        </div>
                        <div className="event-description-text">

                            <p>{props.data.location}</p>
                            {/* <h5>Mel's Drive-In</h5>
                            <p className="">801 Mission St, San Francisco, CA 94103, USA
                                We will try and meet Mel's Diner on Mission St. Look for Bobby Torres or Spencer Kersch. Text this number if you are lost (954) 558-4754</p> */}
                        </div>
                        <div style={{ height: '200px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "" }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                className="map-container"
                            >
                                <AnyReactComponent
                                    lat={59.955413}
                                    lng={30.337844}
                                    text="My Marker"
                                />
                            </GoogleMapReact>
                        </div>
                    </div>
                    <div className="event-description-sec mt-3">
                        <div className="event-description-title event-xyt">
                            Hosted By
                        </div>
                        <div className="hosted-by-sec">
                            <div className="hosted-inner">
                                <div className="host-left">
                                    <div className="hosted-img">
                                        {props.data.
                                            hostedImage ? <img src={API_URL + "flowpage/" + props.data.hostedImage} alt="" /> : <img src={require("../../../assets/images/Avatar.webp")} alt="" />}
                                        {/* <img src={require("../../../assets/images/Avatar.webp")} alt="" /> */}
                                    </div>
                                    <div className="hosted-text">
                                        <div className="hosted-name">
                                            {props.data.hostName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="event-description-sec mt-4">
                        <div className="event-description-title event-xyt">
                            {userEvents?.totalUserLength} Going
                        </div>
                        <div className="total-people-sec">
                            <div className="total-people-inner pb-2">
                                {Array.isArray(eventUserdata) && eventUserdata.map((item, index) => (
                                    <div className="total-people-img "  key={item._id || index}> {/* Use a unique identifier if available */}
                                        {item.user_image ? (
                                            <img src={`${API_URL}events/${item.user_image}`} alt="" />
                                        ) : (
                                            <img src={require("../../../assets/images/Avatar.webp")} alt="" />
                                        )}
                                    </div>
                                ))}

                            </div>
                            <div className="guests-string animated">
                                {renderUserNames(eventUserdata?.map(item => item.userName))}
                            </div>
                            {/* <div class="jsx-2911588165 text-tinted fs-sm guests-string animated">Phoebe Elefante, Sean Pinnock and 12 others</div> */}
                            <br />
                            <div className="contact-host">
                                <button className="btn contact-host-btn btn-event-page">Contact the Host</button>
                            </div>
                            <div className="report-host">
                                <button className="btn report-host-btn btn-event-page">Report the Host</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Event;