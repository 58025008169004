import React, { useState, useRef, useEffect } from "react";
import { FaTrash, FaExternalLinkAlt } from "react-icons/fa";
import { BsFacebook, BsList } from "react-icons/bs";
import { API_URL } from "../../../BackendApi/Api/config";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";
import { useBuilderContext } from "../../../builderContext";

function ListItem({ handledatachange, item, index, removeImageGrid }) {

    const { imagePosition, setImagePosition } = useBuilderContext();

    console.log("itemwa reee", item.alt);


    const [gridData, setGridData] = useState({
        imageurl: item?.imageurl || null,
        imagepreview: item?.imagepreview || null,
        alt: item?.alt || '',
        link: item?.link || '',
        unique_id: item?.unique_id || '',
    });

    const fileInputRef = useRef(null);
    const [imageurl, setSelectedFile] = useState(null);
    const [imagepreview, setPreviewImage] = useState(null);

    useEffect(() => {
        handledatachange(item.id, gridData);
    }, [gridData]);

    const handleInputChange = (event) => {
        const file = event.target.files[0];

        const data = new FormData();
        data.append('image', file);

        uploadSingleImageApi(data).then((res) => {

            if (res.code === 200) {

                if (res.data.status == true) {
                    setGridData({
                        ...gridData,
                        imageurl: res.data.data.filename,
                        imagepreview: res.data.data.filename,

                    });

                }
            }

        });

        if (onImageChange) {
            onImageChange(file);
        }
    };

    const onImageChange = (file) => {
        if (file) {
            const reader = new FileReader();
            // convert file into binary string format
            reader.onloadend = () => {
                setGridData({
                    ...gridData,
                    imagepreview: reader.result,
                });
            };
            reader.readAsDataURL(file);
        } else {
            setGridData({
                ...gridData,
                imagepreview: null,
            });
        }
    };

    const handleImagePositionChange = (e) => {
        const selectedPosition = e.target.value;
        setImagePosition(selectedPosition);
    }





    return (
        <>
            <div className="icons_list_item cvtPmc">
                <div className="icons_list_item_icon">
                    <div className="icons_list_left">
                        <BsList />
                    </div>
                    <div className="icons_list_right">
                        <button type="button" className="delete_btn" onClick={() => removeImageGrid(index)}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="image-input-container mb-4">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleInputChange}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                        <div className="preview_image_box">
                            {gridData.imagepreview ? (
                                <img src={API_URL + "flowpage/" + gridData.imagepreview} alt="Selected" className="preview-image" />
                            ) : (
                                <img
                                    src={require('../../../assets/images/image_default.png')} // Replace this with the URL or path to your default image
                                    alt="Default"
                                    className="default-image"
                                />
                            )}
                        </div>
                        <div className="image-input" onClick={() => fileInputRef.current.click()}>
                            <span className="icon">📷</span>
                            <span className="text">
                                Upload Image (PNG, JPG or GIF)
                            </span>
                        </div>
                    </div>
                    <div className="form-group did-floating-label-content">
                        <input type="text" className="form-control did-floating-input" value={gridData.alt} placeholder=""
                            onChange={
                                (e) => {
                                    setGridData({
                                        ...gridData,
                                        alt: e.target.value,
                                    });
                                }
                            } />
                        <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label ">Alt Text</label>
                    </div>
                    <div className="form-group did-floating-label-content">
                        <input type="text" className="form-control did-floating-input" value={gridData.link} placeholder=""
                            onChange={
                                (e) => {
                                    setGridData({
                                        ...gridData,
                                        link: e.target.value,
                                    });
                                }
                            } />
                        <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Link</label>
                    </div>
                    <div className="form-group did-floating-label-content">
                        <select value={imagePosition} onChange={handleImagePositionChange} className="form-control did-floating-select">
                            <option value="contain">Contain</option>
                            <option value="cover">Cover</option>
                            <option value="fill">Fill</option>
                        </select>
                        <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Image Position</label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListItem;