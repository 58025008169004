export const socialDisconnectionReducer = (state, action) => {
    switch (action.type) {
        case 'SOCIAL_DISCONNECTION':
            return {
                ...state,
                accounts: action.payload,
            };

        case 'FETCHING_SOCIAL_DISCONNECTION':
            return {
                ...state,
                isFetching: action.payload,
            };

        default:
            return state; // Return current state for unhandled actions
    }
};
