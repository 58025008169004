import { useState, useEffect } from 'react';
import { API_URL } from "../../src/BackendApi/Api/config";

const useYouTubeTitle = (iframeString) => {
    const [pageTitle, setPageTitle] = useState('');
    const [link, setLink] = useState('');

    // Extract YouTube link from the iframe string
    const extractYouTubeLink = (iframeString) => {
        const srcMatch = iframeString.match(/src="([^"]*)"/);
        if (srcMatch && srcMatch[1]) {
            const videoLink = srcMatch[1].replace("/embed/", "/watch?v="); // Convert to watch URL
            setLink(videoLink);  // Update state with the extracted link
        } else {
            const videoLink = iframeString.replace("/embed/", "/watch?v="); // Convert to watch URL
            setLink(videoLink); 
        }
    };

    // Fetch the title from the YouTube API based on the link
    const fetchYouTubeTitle = async (link) => {
        try {
            const response = await fetch(`${API_URL}flowpage/getyoutubeurl?keyword=${link}`);
            if (response.status === 200) {
                const result = await response.json();
                if (result.status) {
                    setPageTitle(result.data.title || ''); // Set title or empty string if not found
                } else {
                    setPageTitle(''); // If no title found, set to empty string
                }
            }
        } catch (error) {
            console.error("Error:", error.message);
            setPageTitle('');  // Fallback to empty title on error
        }
    };

    // Run side effect to extract link and fetch title
    useEffect(() => {
        if (iframeString) {
            extractYouTubeLink(iframeString);  // Extract the YouTube link
        }
    }, [iframeString]);  // Run when iframeString changes

    useEffect(() => {
        if (link) {
            fetchYouTubeTitle(link);  // Fetch the title once the link is extracted
        }
    }, [link]);  // Run when link changes

    return pageTitle;  // Return the title
};

export default useYouTubeTitle;
