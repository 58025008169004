import React, { useState, useRef, useEffect, useContext } from "react";
import { Modal, Container } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import Picker from 'emoji-picker-react';
import { ImMagicWand } from "react-icons/im";
import { GoFileMedia } from "react-icons/go";
import { v4 as uuid } from "uuid";

import AiPosts from "../AiPosts"
import SpinnerLoader from "../../Components/SpinnerLoader/SpinnerLoader";
import MediaPost from "../MediaPost";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import AuthContext from "../../AuthContext";
import { useLocation } from "react-router-dom";
import { SiCanva } from "react-icons/si";
const API_URL = process.env.REACT_APP_API_URL;
const EditPostIdea = ({ closemodal, columns, addpostwa, updatepostwa, editIdeaData }) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const location = useLocation();
    const { setIsAuthenticated, setIsCanvaConnected, setAuthData, isCanvaConnected, isAuthenticated } = useContext(AuthContext)
    const [masterPosts, setMasterPosts] = useState([]);
    const textareaRef = useRef(null);
    const [showPicker, setShowPicker] = useState(false);
    const [indexforai, setIndexforai] = useState(null);
    const [newloading, setNewLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const [columnsData, setColumnsData] = useState(columns);

    const [postIdeaId, setPostIdeaID] = useState()

    const [selectedColumn, setSelectedColumn] = useState(null);
    const [orignalColumn, setOrignalColumn] = useState(null);

    const options = [
        { value: 'INDUSTRY_EDUCATION', label: 'Industry Education' },
        { value: 'THOUGHT_LEADERSHIP', label: 'Thought Leadership' },
        { value: 'BRAND_BUILDING', label: 'Brand Building' },
        { value: 'PRODUCT_CONTENT', label: 'Product Content' },
        { value: 'COMPANY_CULTURE', label: 'Company Culture' },
        { value: 'NEWS_AND_EVENTS', label: 'News & Events' }
    ];

    const adjustHeight = () => {
        const element = textareaRef.current;
        if (element) {
            element.style.height = 'auto';
            element.style.height = `${element.scrollHeight}px`;
        }
    };
    useEffect(() => {
        if (columns) {
            setColumnsData(columns);
            // setSelectedColumn(columns[0]._id);
        }
    }, [columns]);

    const handleSave2 = async () => {
        setNewLoading(true)
        if (masterPosts[0].title == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter title',
            })
            // alert("Please enter title");
            setNewLoading(false)
            return;
        }
        if (selectedColumn == null) {
            PNotify.error({
                title: 'Error',
                text: 'Please select a Group',
            })
            setNewLoading(false)
            return;
        }

        if (masterPosts[0].caption == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter caption',
            })
            // alert("Please enter caption");
            setNewLoading(false)
            return;
        }

        if (masterPosts[0].mediaType == null || (masterPosts[0].mediaType == "image" && masterPosts[0].images.length == 0) || (masterPosts[0].mediaType == "video" && masterPosts[0].videos.length == 0)) {
            // alert("Please select atleast one image or video");
            PNotify.error({
                title: 'Error',
                text: 'Please select atleast one image or video',
            })
            setNewLoading(false)
            return;
        }

        if (masterPosts[0].mediaType != null) {

            let upload = false

            if (masterPosts[0].mediaType == "image") {

                for (let i = 0; i < masterPosts[0].images.length; i++) {
                    if (masterPosts[0].images[i].isUploading == true) {
                        upload = true
                        break;
                    }
                }


            }

            if (masterPosts[0].mediaType == "video") {

                for (let i = 0; i < masterPosts[0].videos.length; i++) {
                    if (masterPosts[0].videos[i].isUploading == true) {
                        upload = true
                        break;
                    }
                }
            }

            if (upload == true) {
                PNotify.error({
                    title: 'Error',
                    text: 'Please wait for image or video to upload',
                })
                // alert("Please wait for image or video to upload");
                setNewLoading(false)
                return;
            }

        }

        const data = masterPosts[0];


        const selectedColumnData = columnsData.find(column => column._id === selectedColumn);

        if (selectedColumnData) {
            // Get the maximum order value from the postIdeas array
            const maxOrder = selectedColumnData.postIdeas.reduce((max, idea) => Math.max(max, idea.order || 0), 0);
            // Set data.order to maxOrder + 1
            data.column_id = selectedColumn;
            data.order = maxOrder + 1;
        }


        try {

            const response = await fetch(`${API_URL}/socialposts/addPostIdea`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: data,
                    created_by: user._id,
                    owner_id: user.owner_id,
                })
            })

            const result = await response.json();

            if (result.status == true) {
                addbackpost(result.data);
                closemodal();

            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong, Please try again',
                })
                // alert("Something went wrong, Please try again");
            }
        } catch (error) {
            console.log(error);
            PNotify.error({
                title: 'Error',
                text: 'Something went wrong, Please try again',
            })
            // alert("Something went wrong, Please try again");
        } finally {
            setNewLoading(false)
        }

    }




    const handleUpdate = async () => {
        setNewLoading(true)
        if (masterPosts[0].title == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter title',
            })
            // alert("Please enter title");
            setNewLoading(false)
            return;
        }
        if (selectedColumn == null) {
            PNotify.error({
                title: 'Error',
                text: 'Please select a Group',
            })
            setNewLoading(false)
            return;
        }

        if (masterPosts[0].caption == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter caption',
            })
            // alert("Please enter caption");
            setNewLoading(false)
            return;
        }

        if (masterPosts[0].mediaType == null || (masterPosts[0].mediaType == "image" && masterPosts[0].images.length == 0) || (masterPosts[0].mediaType == "video" && masterPosts[0].videos.length == 0)) {
            // alert("Please select atleast one image or video");
            PNotify.error({
                title: 'Error',
                text: 'Please select atleast one image or video',
            })
            setNewLoading(false)
            return;
        }

        if (masterPosts[0].mediaType != null) {

            let upload = false

            if (masterPosts[0].mediaType == "image") {

                for (let i = 0; i < masterPosts[0].images.length; i++) {
                    if (masterPosts[0].images[i].isUploading == true) {
                        upload = true
                        break;
                    }
                }


            }

            if (masterPosts[0].mediaType == "video") {

                for (let i = 0; i < masterPosts[0].videos.length; i++) {
                    if (masterPosts[0].videos[i].isUploading == true) {
                        upload = true
                        break;
                    }
                }
            }

            if (upload == true) {
                PNotify.error({
                    title: 'Error',
                    text: 'Please wait for image or video to upload',
                })
                // alert("Please wait for image or video to upload");
                setNewLoading(false)
                return;
            }

        }

        const data = masterPosts[0];

        data.column_id = selectedColumn;



        try {

            const response = await fetch(`${API_URL}/socialposts/updatePostIdea`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: data,
                    created_by: user._id,
                    idea_id: postIdeaId
                })
            })

            const result = await response.json();

            if (result.status == true) {

                updatebackpost(orignalColumn, selectedColumn, result.data);
                closemodal();

            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong, Please try again',
                })
                // alert("Something went wrong, Please try again");
            }
        } catch (error) {
            console.log(error);
            PNotify.error({
                title: 'Error',
                text: 'Something went wrong, Please try again',
            })
            // alert("Something went wrong, Please try again");
        } finally {
            setNewLoading(false)
        }

    }

    const addbackpost = (duta) => {
        addpostwa(duta);

    }

    const updatebackpost = (sourceColumn, targetColumn, duta) => {
        updatepostwa(sourceColumn, targetColumn, duta);
    }

    useEffect(() => {
        if (editIdeaData) {

            const newPost = {
                caption: editIdeaData.caption,
                mediaType: editIdeaData.mediaType,
                images: editIdeaData.images,
                videos: editIdeaData.videos,
                title: editIdeaData.title,
                category: editIdeaData.category
            };

            setMasterPosts([newPost]);
            setPostIdeaID(editIdeaData._id);
            setSelectedColumn(editIdeaData.column_id);
            setOrignalColumn(editIdeaData.column_id);
        }

    }, [editIdeaData]);
console.log("editIdeaData",editIdeaData)
    const handleuploadfile = async (file, index) => {
        try {

            var uniqueId = uuid();

            // var thumbnail;

            if (file.type.startsWith('video/')) {

                const updatedPosts = [...masterPosts];
                updatedPosts[index].videos = [...updatedPosts[index].videos, { id: uniqueId, imgUrl: "", isUploading: true }];
                setMasterPosts(updatedPosts);
            }



            if (file.type.startsWith("image/")) {

                const updatedPosts = [...masterPosts];
                updatedPosts[index].images = [...updatedPosts[index].images, { id: uniqueId, imgUrl: "", isUploading: true }];
                setMasterPosts(updatedPosts);
            }



            console.log("masterposts : ", masterPosts);

            const formData = new FormData();
            formData.append('image', file);


            const response = await fetch(`${API_URL}/socialposts/upload`, {
                method: 'POST',
                body: formData
            });

            const result = await response.json();

            if (result.status) {
                console.log("file".file)
                if (file.type.startsWith('video/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                        updatedPosts[index].videos[mediaIndex].imgUrl = result.filename;
                        updatedPosts[index].videos[mediaIndex].isUploading = false;
                        return updatedPosts;
                    });
                }
                if (file.type.startsWith('image/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                        updatedPosts[index].images[mediaIndex].imgUrl = result.filename;
                        updatedPosts[index].images[mediaIndex].isUploading = false;

                        return updatedPosts;
                    });
                }

                console.log("masterposts after : ", masterPosts);
            } else {
                // alert("Error uploading file");
                PNotify.error({
                    title: 'Error',
                    text: 'Error uploading file',
                })
                if (file.type.startsWith('video/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                        //delete
                        updatedPosts[index].videos.splice(mediaIndex, 1);
                        return updatedPosts;
                    });
                }

                if (file.type.startsWith('image/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                        //delete
                        updatedPosts[index].images.splice(mediaIndex, 1);
                        return updatedPosts;
                    });
                }

            }

        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: 'Error uploading file',
            })
            if (file && file.type.startsWith('video/')) {
                setMasterPosts(prevPosts => {
                    const updatedPosts = [...prevPosts];
                    const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                    //delete
                    updatedPosts[index].videos.splice(mediaIndex, 1);
                    return updatedPosts;
                });
            }

            if (file && file.type.startsWith('image/')) {
                setMasterPosts(prevPosts => {
                    const updatedPosts = [...prevPosts];
                    const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                    //delete
                    updatedPosts[index].images.splice(mediaIndex, 1);
                    return updatedPosts;
                });
            }

            // Handle error if necessary
        }
    }

    const insertaipost = (data) => {
        const updatedPosts = [...masterPosts];
        updatedPosts[indexforai].caption = updatedPosts[indexforai].caption + data;
        setMasterPosts(updatedPosts);
    }

    const insertaipostVideo = (data) => {
        const newData = data.map((item, index) => {
            let uniqueId = uuid();
            return { id: uniqueId, imgUrl: item, isUploading: false }
        })
        setMasterPosts((pre) => {
            const updatedPosts = [...pre];
            updatedPosts[0] = { ...updatedPosts[0], mediaType: "video", videos: [...updatedPosts[0].videos, ...newData] }
            return updatedPosts
        })
        console.log("videos", data)
    };

    const insertaipostImage = (data) => {
        const newData = data.map((item, index) => {
            let uniqueId = uuid();
            return { id: uniqueId, imgUrl: item, isUploading: false }
        })
        setMasterPosts((pre) => {
            const updatedPosts = [...pre];
            updatedPosts[0] = { ...updatedPosts[0], mediaType: "image", images: [...updatedPosts[0].images, ...newData] }
            return updatedPosts
        })
        console.log("images", data)
    };

    const setShowAiModal = () => {
        setIsOpen(prev => !prev);
        if (isOpen2) setIsOpen2(false);
    }

    const setShowMediaModal = () => {
        setIsOpen2(prev => !prev);
        if (isOpen) setIsOpen(false);
    }
  
    const insertAsset = async (design_id, uniqueId) => {
        const user = JSON.parse(localStorage.getItem("user"));

        try {
            const response = await fetch(`${API_URL}/socialposts/exportCanvaDesign`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    design_id: design_id,
                    user_id: user?.owner_id
                })
            });

            const data = await response.json();
            if (data.success) {
                setMasterPosts((pre) => {
                    const updatedPosts = [...pre];
                    updatedPosts[0] = {
                        ...updatedPosts[0],
                        images: updatedPosts[0].images.map(image =>
                            image.id === uniqueId
                                ? { ...image, imgUrl: data.url, isUploading: false }
                                : image
                        )
                    };
                    return updatedPosts;
                });
                localStorage.removeItem('design_data');
                
            } else {
                // Remove image with the uniqueId in case of failure
                setMasterPosts((pre) => {
                    const updatedPosts = [...pre];
                    updatedPosts[0] = {
                        ...updatedPosts[0],
                        images: updatedPosts[0].images.filter(image => image.id !== uniqueId)
                    };
                    return updatedPosts;
                });
                console.error('Failed to upload asset:', data.message);
            }
        } catch (error) {
            // Remove image with the uniqueId in case of error
            setMasterPosts((pre) => {
                const updatedPosts = [...pre];
                updatedPosts[0] = {
                    ...updatedPosts[0],
                    images: updatedPosts[0].images.filter(image => image.id !== uniqueId)
                };
                return updatedPosts;
            });
            console.error('Error creating Canva design:', error);
        }
    };


    const handleCanvaAuth = async () => {
        const authorizationUrl = `${API_URL}/socials/canvaOAuth`;

        const width = 700;
        const height = 700;
        const left = (window.screen.width / 5) - (width / 5);
        const top = (window.screen.height / 3) - (height / 3);

        let authWindow = window.open(
            `${authorizationUrl}?owner_id=${user?.owner_id}`,
            'CANVA_AUTH',
            `width=${width},height=${height},left=${left},top=${top}`
        );

        const windowCheckInterval = setInterval(() => {
            if (authWindow.closed) {
                clearInterval(windowCheckInterval); // Stop checking when window is closed
                // setIsOpen3(prev => !prev); // Update the state when the window is closed
                // alert('Window closed');
                setIsCanvaConnected(true);
                setIsAuthenticated(true);
            }
        }, 500)

    };

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;
            const { type, message } = event.data;
            if (type === 'CANVA_AUTH') {
                setAuthData(message);
                setIsCanvaConnected(true);
                setIsAuthenticated(true);
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    useEffect(() => {
        const checkCanvaRedirect = () => {
            const urlParams = new URLSearchParams(location.search);
            const source = urlParams.get('source');
    
            if (source === 'canva_redirect') {
                const localData = JSON.parse(localStorage.getItem("design_data"));
    
                if (localData) {
                    const design_id = localData?.data?.id;
                    const uniqueId = uuid();
    
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...localData?.masterPosts];
                        const firstPost = updatedPosts[0] || {};
                        
                        return [{
                            ...firstPost,
                            images: [
                                ...firstPost.images ,
                                { id: uniqueId, imgUrl: "", isUploading: true }
                            ],
                            mediaType: "image"
                        }];
                    });
    
                    insertAsset(design_id, uniqueId);
                } else {
                    initializeNewPost();
                }
            } else {
                initializeNewPost();
            }
        };
    
        const initializeNewPost = () => {
            const ddate = new Date();
            ddate.setMinutes(ddate.getMinutes() + 5);
            // const formattedDateTime = ddate.toISOString().slice(0, 19);
            // const kolkata = convertToKolkataTimezone(formattedDateTime);
    
            setMasterPosts([{
                caption: editIdeaData.caption ||"",
                mediaType: editIdeaData.mediaType ||null,
                images: editIdeaData.images||[],
                videos: editIdeaData.videos||[],
            }]);
        };
    
        checkCanvaRedirect();
    }, [ location.search]);

    useEffect(() => { },
        [masterPosts]);
    const uploadAssetAndCreateDesign = async () => {

        const user = JSON.parse(localStorage.getItem("user"));


        const formData = new FormData();
        formData.append('user_id', user?.owner_id);

        try {
            const response = await fetch(`${API_URL}/socialposts/uploadAssetAndCreateDesign`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                
                localStorage.setItem("design_data", JSON.stringify({
                    location: window.location.href,
                    data: data.data,
                    masterPosts: masterPosts,
                    method: "edit_idea",
                    selectedColumnId: selectedColumn                   

                }));

                const editUrl = data.data.urls.edit_url;
                window.location.assign(editUrl);

            } else {
                console.error('Failed to upload asset:', data.message);
            }
        } catch (error) {
            console.error('Error creating Canva design:', error);
        }
    };
    return (
        <>
            <Modal.Body className="post-schedule-body">
                <Container>

                    {isOpen && (
                        <div className="ai-modal-new">
                            <div className="ai-modal-inner">
                                <AiPosts insertpost={insertaipost} />
                            </div>
                        </div>
                    )}

                    {isOpen2 && (
                        <div className="media-modal-new">
                            <div className="ai-modal-inner">
                                <MediaPost insertaipostVideo={insertaipostVideo} insertaipostImage={insertaipostImage} />
                            </div>
                        </div>
                    )}


                    {masterPosts.length > 0 ?
                        masterPosts.map((post, index) => (
                            <div className="col-md-12 mb-3" key={index}>
                                <div>
                                    <select
                                        className="form-select mb-3"
                                        value={selectedColumn}
                                        onChange={

                                            (e) => {
                                                setSelectedColumn(e.target.value)
                                            }

                                        }
                                    >
                                        {columnsData.map((option) => (
                                            <option key={option._id} value={option._id}>
                                                {option.column_title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Title"
                                        value={post.title}
                                        onChange={(e) => {
                                            const updatedPosts = [...masterPosts];
                                            updatedPosts[index].title = e.target.value;
                                            setMasterPosts(updatedPosts);
                                        }}
                                    />
                                </div>
                                <div className="post-box mt-2">
                                    <div className="text-area-box">
                                        <div className="mb-3">
                                            <textarea
                                                ref={textareaRef}
                                                className="form-control custom-text-area"
                                                rows="3"
                                                placeholder="What's on your mind?"
                                                onInput={adjustHeight}
                                                style={{ overflowY: 'hidden', resize: 'none' }}
                                                value={post.caption}
                                                onChange={(e) => {
                                                    const updatedPosts = [...masterPosts];
                                                    updatedPosts[index].caption = e.target.value;
                                                    setMasterPosts(updatedPosts);
                                                }}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="image-upload-container">
                                        <div className="upload-sec">
                                            <div className="upload-images-list-2">
                                                <div style={{ display: post.mediaType === 'image' ? 'block' : 'none' }}>
                                                    <div className="upload-images-list">
                                                        {post.images.map((image, imgindex) => (
                                                            <div key={imgindex}>
                                                                <div className="image-container-view mb-2">
                                                                    {!image.isUploading ?
                                                                        <img src={image.imgUrl?.startsWith("https://") ? image.imgUrl : `${API_URL}/posts/${image.imgUrl}`} alt="preview" />
                                                                        : <div className="d-flex justify-content-center align-items-center h-100">
                                                                            <div className="text-center">
                                                                                <div
                                                                                    className="spinner-border text-primary mb-3"
                                                                                    role="status"
                                                                                    style={{
                                                                                        width: '3rem',
                                                                                        height: '3rem',
                                                                                        animation: 'rotate 2s linear infinite',
                                                                                    }}
                                                                                >
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </div>
                                                                                <p className="mb-0">Uploading...</p>
                                                                            </div>
                                                                            <style>{`
                                                                                @keyframes rotate {
                                                                                from {
                                                                                    transform: rotate(0deg);
                                                                                }
                                                                                to {
                                                                                    transform: rotate(360deg);
                                                                                }
                                                                                }
                                                                            `}</style>
                                                                        </div>}
                                                                    <button className="remove-image sc-iBbrVh YsbxX _closeButton_1c4j9_57"
                                                                        onClick={() => {
                                                                            if (post.images.length === 1) {
                                                                                setMasterPosts(prevPosts => {
                                                                                    const updatedPosts = [...prevPosts];
                                                                                    updatedPosts[index].mediaType = null;
                                                                                    updatedPosts[index].images = [];
                                                                                    return updatedPosts;
                                                                                })
                                                                            } else {
                                                                                const updatedPosts = [...masterPosts];
                                                                                updatedPosts[index].images = updatedPosts[index].images.filter(item => item.id !== image.id);


                                                                                setMasterPosts(updatedPosts)
                                                                            }
                                                                        }}>
                                                                        <IoClose />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div style={{ display: post.mediaType === 'video' ? 'block' : 'none' }}>
                                                    <div className="upload-images-list">
                                                        {post.videos.map((video, videoindex) => (
                                                            <div key={videoindex}>
                                                                <div className="image-container-view mb-2">
                                                                    <div className="upload-images-list">
                                                                        {!video.isUploading ?
                                                                            <video src={video.imgUrl?.startsWith("https://") ? video.imgUrl : `${API_URL}/posts/${video.imgUrl}`} controls />
                                                                            : <div className="d-flex justify-content-center align-items-center h-100">
                                                                                <div className="text-center">
                                                                                    <div
                                                                                        className="spinner-border text-primary mb-3"
                                                                                        role="status"
                                                                                        style={{
                                                                                            width: '3rem',
                                                                                            height: '3rem',
                                                                                            animation: 'rotate 2s linear infinite',
                                                                                        }}
                                                                                    >
                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                    </div>
                                                                                    <p className="mb-0">Uploading...</p>
                                                                                </div>
                                                                                <style>{`@keyframes rotate {from {transform: rotate(0deg);}to {transform: rotate(360deg);}}`}
                                                                                </style>
                                                                            </div>}
                                                                        <button className="remove-image sc-iBbrVh YsbxX _closeButton_1c4j9_57"
                                                                            onClick={() => {
                                                                                if (post.videos.length === 1) {
                                                                                    setMasterPosts(prevPosts => {
                                                                                        const updatedPosts = [...prevPosts];
                                                                                        updatedPosts[index].mediaType = null;
                                                                                        updatedPosts[index].videos = [];
                                                                                        return updatedPosts;
                                                                                    })
                                                                                } else {

                                                                                    const updatedPosts = [...masterPosts];
                                                                                    updatedPosts[index].videos = updatedPosts[index].videos.filter(item => item.id !== video.id);

                                                                                    setMasterPosts(updatedPosts)
                                                                                }
                                                                            }}>
                                                                            <IoClose />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="file-input-container">
                                                    <input
                                                        type="file"
                                                        accept={post.mediaType === null ? "image/*,video/*" : post.mediaType === "image" ? "image/*" : "video/*"}
                                                        onChange={(e) => {
                                                            if (e.target.files[0] && e.target.files[0].type.startsWith('video/') && post.videos.length === 0) {
                                                                setMasterPosts(prevPosts => {
                                                                    const updatedPosts = [...prevPosts];
                                                                    updatedPosts[index].mediaType = "video";
                                                                    return updatedPosts;
                                                                })
                                                            }
                                                            if (e.target.files[0] && e.target.files[0].type.startsWith('image/') && post.images.length === 0) {
                                                                setMasterPosts(prevPosts => {
                                                                    const updatedPosts = [...prevPosts];
                                                                    updatedPosts[index].mediaType = "image";
                                                                    return updatedPosts;
                                                                })
                                                            }
                                                            handleuploadfile(e.target.files[0], index);
                                                        }}
                                                        multiple
                                                        className="file-input"
                                                    />
                                                    <label htmlFor="fileInput">
                                                        <i className="input-icon">📁</i>
                                                        <span className="file-input-text">select a file</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-extra-sec">
                                    <div className="post-extra-sec-content justify-content-between">
                                        <div className="left-side d-flex align-items-center gap-3">
                                            <div className="emoji-box">
                                                <img
                                                    className="emoji-icon"
                                                    src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                                                    onClick={() => setShowPicker(val => !val)}
                                                />
                                                {showPicker && <Picker
                                                    pickerStyle={{ width: '100%' }}
                                                    onEmojiClick={(emojiObject) => {
                                                        setMasterPosts(prevPosts => {
                                                            const updatedPosts = [...prevPosts];
                                                            updatedPosts[index].caption += emojiObject.emoji;
                                                            return updatedPosts;
                                                        })
                                                        setShowPicker(false);
                                                    }}
                                                />}
                                            </div>
                                            <div>
                                                <button className="btn post-btn ai-view-btn"
                                                    onClick={() => {
                                                        setIndexforai(index);
                                                        setShowAiModal(true);
                                                    }}
                                                ><ImMagicWand /></button>
                                            </div>
                                            <div>
                                                <button className="btn post-btn ai-view-btn"
                                                    onClick={() => {
                                                        setShowMediaModal(true);
                                                    }}
                                                ><GoFileMedia /></button>
                                            </div>
                                            <div>
                                                <select
                                                    value={post.category}
                                                    onChange={

                                                        (e) => {
                                                            setMasterPosts(prevPosts => {
                                                                const updatedPosts = [...prevPosts];
                                                                updatedPosts[index].category = e.target.value;
                                                                return updatedPosts;
                                                            })
                                                        }

                                                    }
                                                >
                                                    {options.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>



                                                {!isAuthenticated ? (
                                                    <button className="btn post-btn ai-view-btn" onClick={handleCanvaAuth}><SiCanva />Canva</button>
                                                ) : !isCanvaConnected ? (
                                                    <button className="btn post-btn ai-view-btn" onClick={handleCanvaAuth}><SiCanva />Canva</button>
                                                ) : (
                                                    <button className="btn post-btn ai-view-btn"
                                                        onClick={() => {
                                                            uploadAssetAndCreateDesign();
                                                        }}
                                                    ><SiCanva /> canva </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : <div>Please add a post to schedule You can also use Ai to generate posts</div>
                    }
                </Container>
                <div className="post-submit-btn mt-4" style={{ textAlign: 'right' }}>
                    {newloading ? <SpinnerLoader /> :
                        <button
                            type="submit"
                            className="btn bg-green-dark action-btn btn-green"
                            onClick={handleSave2}
                        >Duplicate</button>}


                    {newloading ? <SpinnerLoader /> :
                        <button
                            type="submit"
                            className="btn bg-green-dark action-btn btn-green"
                            onClick={handleUpdate}
                        >Update</button>}

                </div>
            </Modal.Body>
        </>
    )
}

export default EditPostIdea;