import React, { useState } from "react";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";
import SingleImageModal from "../../../Helpers/customImagesModel";
const { API_URL } = require("../../../BackendApi/Api/config");


function ImageText(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 

   
    return (
        <>
            <div className="image_with_text_content color_bg_text mb-3" onClick={handleShow} id={props.data.unique_id}>
                <div className="brand_img_set" style={{ backgroundColor: props.data.backgroundColor }}>
                    <a  target="_blank">
                        <img
                            // src={API_URL + "flowpage/" + props.data.imageLink} 
                            src={(props.data.imageLink.startsWith("http://") || props.data.imageLink.startsWith("https://")) ? props.data.imageLink : API_URL + "flowpage/" + props.data.imageLink}
                            alt={props.data.imageAltText} />
                    </a>
                </div>
                <div className="image_text_content">
                    <p className="image_text_content_title m-0">{props.data.imageCaption}</p>
                </div>
            </div>
            <SingleImageModal type="VISIT" show={show} onClose={handleClose} imagePreview={props.data.imageLink} link={props.data.link} unique_id={props.data.unique_id}  smartlink_id={props.smartlink_id} />
        </>
    );
}

export default ImageText;