import { API_URL } from "./config.js";
import {apiCall} from "./auth";
import {multipartApiCall} from "./auth";


export const uploadSingleImageApi = (formData) => multipartApiCall(`${API_URL}flowpage/uploadsingle`, "POST", formData);

export const uploadHeaderImage = (formData) => multipartApiCall(`${API_URL}flowpage/addheaderimage`, "POST", formData);    

export const getFlexTemplateData = (data) => apiCall(`${API_URL}flowpage/getflextemplate`, "POST", data);

export const getFlowPageData = (data) => apiCall(`${API_URL}flowpage/getminipagebyid`, "POST", data);

export const getFlowpageByBackhalf = (data) => apiCall(`${API_URL}flowpage/getminipagebybackhalf`, "POST", data);

export const publishMinipage = (data) => multipartApiCall(`${API_URL}flowpage/pblishminipage`, "POST", data);

export const getFlexbyuser = (data) => apiCall(`${API_URL}flowpage/getflexbyuser`, "POST", data);

export const getEventsPages = (data) => apiCall(`${API_URL}flowpage/getEventsPages`, "POST", data);

export const deleteUserMiniPage = (data) => apiCall(`${API_URL}flowpage/deleteuserminipage`, "POST", data);

export const createMiniPageApi = (formData) => multipartApiCall(`${API_URL}flowpage/saveminipage`, "POST", formData);

export const saveComponentsClicks = (data) => apiCall(`${API_URL}flowpage/saveComponentsClicks`, "POST", data);

export const updateTitle = (data) => apiCall(`${API_URL}flowpage/updateTitle`, "POST", data);

export const updateSocialPreview = (data) => apiCall(`${API_URL}flowpage/updateSocialPreview`, "POST", data);

export const getsocialpreview = (data) => apiCall(`${API_URL}flowpage/getsocialpreview`, "POST", data);
export const AutoGenerate = (data) => apiCall(`${API_URL}flowpage/AutoGenerate`, "POST", data);
export const getFlexComponnetsdata = (data) => apiCall(`${API_URL}flowpage/getFlexComponnetsdata`, "POST", data);
