import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { TbHelp } from 'react-icons/tb'
import { Button } from 'react-bootstrap'
import { FaTags, FaEdit, FaTrash } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import CreateTag from './CreateTag'
import axios from 'axios'
import * as PNotify from '@pnotify/core'
import '@pnotify/core/dist/PNotify.css'
import '@pnotify/core/dist/BrightTheme.css'

const API_URL = process.env.REACT_APP_API_URL

const Tags = () => {
    const [showCreateTagModal, setShowCreateTagModal] = useState(false)
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(true)

    // PNotify configuration
    const showNotification = (text, type) => {
        PNotify.alert({
            title: type.charAt(0).toUpperCase() + type.slice(1),
            text: text,
            type: type,
            delay: 3000
        });
    };

    // Fetch tags
    const fetchTags = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await axios.post(`${API_URL}/tags/getusertags`, 
            {
                owner_id: user?.owner_id
            })
            setTags(response.data.data)
        } catch (error) {
            showNotification(
                error.response?.data?.message || 'Failed to fetch tags',
                'error'
            )
        } finally {
            setLoading(false)
        }
    }

    // Load tags on component mount
    useEffect(() => {
        fetchTags()
    }, [])

    const handleCloseCreateTagModal = () => setShowCreateTagModal(false)

    // Callback for when a new tag is created
    const handleTagCreated = (newTag) => {
        setTags(prevTags => [...prevTags, newTag])
    }

    // Handle tag deletion
    const handleDeleteTag = async (tagId) => {
        try {
            const token = localStorage.getItem('token')
            await axios.delete(`/api/tags/${tagId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            
            showNotification('Tag deleted successfully', 'success')
            setTags(prevTags => prevTags.filter(tag => tag._id !== tagId))
        } catch (error) {
            showNotification(
                error.response?.data?.message || 'Failed to delete tag',
                'error'
            )
        }
    }

    return (
        <>
            <Modal 
                show={showCreateTagModal} 
                onHide={handleCloseCreateTagModal} 
                className="Sidebar-Modal-end"
                backdrop="static"
                keyboard={false}
            >
                <CreateTag 
                    onClose={handleCloseCreateTagModal}
                    onTagCreated={handleTagCreated}
                />
            </Modal>

            <div className="tab-content-header add-btn d-flex Add-margin">
                <h5 className="tab-content-title">
                    <div className="tab-content-icon"><FaTags /></div>
                    Tags
                </h5>
                <div className="tab-content-header-right d-flex">
                    <Button 
                        className="btn btn-sm float-right add-user-btn" 
                        onClick={() => setShowCreateTagModal(true)}
                    >
                        New Tag
                    </Button>
                </div>
            </div>

            <div className="setting-tab-content-body">
                {loading ? (
                    <div className="text-center p-4">Loading tags...</div>
                ) : tags.length === 0 ? (
                    <div className="text-center p-4">No tags found. Create your first tag!</div>
                ) : (
                    <div className="row">
                        {tags.map(tag => (
                            <div key={tag._id} className="col-md-4 mb-3">
                                <div className="card h-100">
                                    <div className="card-body d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div 
                                                className="tag-color-preview"
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    borderRadius: '50%',
                                                    backgroundColor: tag.tag_color,
                                                    marginRight: '10px'
                                                }}
                                            />
                                            <span>{tag.tag_name}</span>
                                        </div>
                                        <div className="tag-actions">
                                            <Button 
                                                variant="link" 
                                                className="text-primary p-1"
                                                onClick={() => 
                                                {

                                                    // handleEditTag(tag._id)
                                                }
                                                    }
                                            >
                                                <FaEdit />
                                            </Button>
                                            <Button 
                                                variant="link" 
                                                className="text-danger p-1"
                                                onClick={() => {
                                                    if (window.confirm('Are you sure you want to delete this tag?')) {
                                                        handleDeleteTag(tag._id)
                                                    }
                                                }}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default Tags