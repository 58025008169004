import React, { useEffect } from "react";
import { FiCopy } from "react-icons/fi";
import { useState, useRef } from "react";
import { BsArrowsFullscreen } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { MdOutlineDesktopMac } from "react-icons/md";
import { FaTabletAlt, FaMobileAlt } from "react-icons/fa";

import { useBuilderContext } from "../builderContext";
import BuilderHeader from "./Components/TemplateView/BuilderHeader";

import { publishMinipage } from "../BackendApi/Api/flowpageApi";

import { toJpeg, toBlob, toPng } from 'html-to-image';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { v4 as uuidv4 } from "uuid";


import html2pdf from 'html2pdf.js';

import domToImage from 'dom-to-image';


// import domtoimage from 'dom-to-image';

import html2canvas from 'html2canvas';

import { saveAs } from 'file-saver';

// import { useScreenshot } from 'use-react-screenshot'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import CopyCat from "../Components/CopyCat/CopyCat";
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import 'suneditor/dist/css/suneditor.min.css';
import Event from "./Components/TemplateView/Event";

import { useParams } from 'react-router-dom';



function EventBuilderLeft({ color, buttonColor, fontFamily }) {

    const { id } = useParams();

    const { myComponents, smartlink_url, backgroundType, BackgroundColor, backgroundColor2, colorPosition, imagePosition, smartlinkeditdata, company_slug, setCompain_slug } = useBuilderContext();

    const [deviceView, setDeviceView] = useState('view-desktop');


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loding, setLoding] = useState(false);
    const imageRef = useRef(null);

    const [flexpageLink, setFlexpageLink] = useState('');

    const captureWebpageAsImage = () => {
        const elementToCapture = document.querySelector('.builder-box-design');
        const scrollY = window.scrollY;

        // Save the current style of the body's scrollbar
        const originalBodyOverflowStyle = document.body.style.overflow;

        // Hide the scrollbar by setting body overflow to 'hidden'

        elementToCapture.style.overflow = 'hidden';
        // document.body.style.overflow = 'hidden';

        // Create a new div element to act as a wrapper for the content
        const wrapper = document.createElement('div');
        wrapper.style.overflow = 'hidden';
        wrapper.style.position = 'absolute';
        wrapper.style.top = `${-scrollY}px`;
        wrapper.appendChild(elementToCapture.cloneNode(true));

        // Append the wrapper to the body
        document.body.appendChild(wrapper);

        toPng(elementToCapture, { scrollY, quality: 2.0 })
            .then((dataUrl) => {
                // Restore the original body's overflow style
                document.body.style.overflow = originalBodyOverflowStyle;

                // Remove the wrapper element
                document.body.removeChild(wrapper);

                // Generate a unique filename for the screenshot
                const fileName = `screenshot_${new Date().toISOString()}.png`;

                // Create a link element to download the image
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = fileName;

                // Trigger the download
                link.click();
            })
            .catch((error) => {
                console.error('Error capturing screenshot:', error);
                // Make sure to restore the original body's overflow style even in case of an error
                document.body.style.overflow = originalBodyOverflowStyle;
                // Remove the wrapper element if there's an error
                document.body.removeChild(wrapper);
            });
    };






    const publish = () => {
        var element = document.getElementById("capture");
        element.classList.remove("page_builder_design_body");
        setLoding(true);
        const elementToCapture = imageRef.current;

        // Check if elementToCapture exists
        if (!elementToCapture) {
            PNotify.error({
                title: 'Error',
                text: 'Unable to publish page. Double check if any images are broken',
            });
            setLoding(false);
            return;
        }

        const scrollY = window.scrollY;
        const originalOverflow = elementToCapture.style.overflow;

        elementToCapture.style.overflow = 'hidden';

        // create image from toPng
        toJpeg(elementToCapture, { scrollY, quality: 3.0 })
            .then(dataUrl => {
                if (!dataUrl) {
                    throw new Error('Unable to publish page. Double check if any images are broken');
                }

                // Restore the UI state
                element.classList.add("page_builder_design_body");
                elementToCapture.style.overflow = originalOverflow;

                const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

                // Convert base64 string to Blob object
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], { type: 'image/jpeg' });

                console.log("blob", blob.type);



                const jsonData = myComponents;
                const componentsData = [];

            

                const formData = new FormData();
                formData.append('id', id);
                formData.append('page_json', JSON.stringify(jsonData));
                formData.append('page_thumbnail', blob);
                formData.append('background_color', color);
                formData.append('button_color', buttonColor);
                formData.append('font_family', fontFamily);
                formData.append('components', JSON.stringify(componentsData));
                formData.append('background_type', backgroundType);
                formData.append('background_color2', backgroundColor2);
                formData.append('background_color1', BackgroundColor);
                formData.append("background_Position", colorPosition);

                publishMinipage(formData).then((res) => {
                    if (res.data.status === true) {
                        PNotify.success({
                            title: 'Success',
                            text: 'Page Published Successfully',
                        });
                    } else {
                        PNotify.error({
                            title: 'Error',
                            text: res.data.message
                        });
                    }
                    setLoding(false);
                }).catch((err) => {
                    PNotify.error({
                        title: 'Error',
                        text: err.message
                    });
                    setLoding(false);
                });
            })
            .catch((error) => {
                console.error('Error capturing screenshot:', error);

                // Restore the UI state
                element.classList.add("page_builder_design_body");
                elementToCapture.style.overflow = originalOverflow;

                PNotify.error({
                    title: 'Error',
                    text: 'Unable to publish page. Double check if any images are broken',
                });
                setLoding(false);
            });
    };




    const gradientDirectionMap = {
        leftright: 'to right',
        rightleft: 'to left',
        topbottom: 'to bottom',
        bottomtop: 'to top',
    };

    let urlWithoutProtocol = company_slug.split('://')[1];

    return (
        <>

            <Modal
                centered
                show={show}
                onHide={handleClose}
                className="modal-center-show"
            >
                <Modal.Body>
                    <div className="show-preview-header">
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-desktop');
                                    }
                                }
                            ><MdOutlineDesktopMac /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-tablet');
                                    }
                                }
                            ><FaTabletAlt /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-mobile');
                                    }
                                }
                            ><FaMobileAlt /></button>
                        </div>
                    </div>
                    <div className="show-preview-body">
                        <div className="show-preview-content-2">
                            <div className={`preview-box-background ${deviceView}`}>
                                <div class="oEUMCt"></div>
                                <div className={`page_builder_design_body ${color}`}  >
                                    <div className={`builder-box-design goWHMr ${color}`} style={{ fontFamily: fontFamily }} ref={imageRef}>
                                        <div className="builder_content"  >



                                            {myComponents && myComponents.map((component, index) => (
                                                <>
                                                    {
                                                        component.type === "HEADER" ? <BuilderHeader data={component.properties} /> :
                                                            null
                                                    }
                                                </>
                                            ))}
                                            <div className="company_name">
                                                <h5>Powered with Astral</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="oEUMCterd"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="page_builder_design_header">
                <div className="page_builder_design_header_container">
                    <div className="publish_btn_container">
                        <div className="d-flex align-items-center justify-content-between ms-4">

                            <p className="m-0">{urlWithoutProtocol}</p>
                            <CopyCat text={company_slug} />
                        </div>
                        {loding ? <SpinnerLoader /> : <button className="publish-btn" onClick={publish}>Publish Changes</button>}
                    </div>
                </div>
            </div>



            <div className="page_builder_design_body" id="capture"
                style={{
                    ...(backgroundType === 1 ? { backgroundColor: BackgroundColor } : {}),
                    ...(backgroundType !== 1
                        ? {
                            backgroundImage: `linear-gradient(${gradientDirectionMap[colorPosition]}, ${BackgroundColor}, ${backgroundColor2})`,
                        }
                        : {}),
                }}
            >
                <div className="xmpr">
                    <div className="fill-screen-btn form-btn-right mb-3">
                        <button type="button" className="btn full-screen-btn bg-green-dark" onClick={handleShow}><BsArrowsFullscreen /> Device Preview</button>
                    </div>
                    <div className={`builder-box-design goWHMr ${color}`} style={{ fontFamily: fontFamily }} ref={imageRef}>
                        <div className="builder_content"  >



                            {myComponents && myComponents.map((component, index) => (
                                <>
                                    {
                                        component.type === "EVENT" &&
                                            component?.showStatus === true ?
                                            <Event data={component.properties} /> :  null
                                    }
                                </>
                            ))}
                            {/* {myComponents && myComponents.map((component, index) => (
                                <React.Fragment key={index}>
                                    {renderComponent(component, index)}
                                </React.Fragment>
                            ))} */}

                            <div className="company_name">
                                <h5>Powered with Astral</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default EventBuilderLeft;