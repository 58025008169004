import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

const API_URL = process.env.REACT_APP_API_URL + "/";

const SocialChannelsList = ({ channelCategory, socialChannels }) => {
    const url = window.location.href;
    const urlSplit = url.split('/');
    const urlbrandId = urlSplit[urlSplit.length - 1];

    const userdata = localStorage.getItem("user");
    const user = JSON.parse(userdata);
    const [channels, setChannels] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        const filtered = socialChannels.filter((item) => item.channel_category === channelCategory);
        setChannels(filtered);
    }, [socialChannels, channelCategory]);

    const updateChannelStatus = async (id, currentStatus, index) => {
        if (isUpdating) return; // Prevent multiple simultaneous updates
        setIsUpdating(true);

        try {
            // Optimistically update UI
            setChannels(prev => {
                const newChannels = [...prev];
                newChannels[index] = {
                    ...newChannels[index],
                    isActive: !currentStatus
                };
                return newChannels;
            });

            const data = {
                social_channel_id: id,
                status: currentStatus, // Send current status before toggle
                brand_id: urlbrandId,
                updator_name: `${user.fname} ${user.lname}`,
                updator_image: user.profile_image
            };

            const config = {
                method: 'post',
                url: `${API_URL}branding/updatesocialchannelstatus`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);

            if (!response.data.status) {
                // Revert the change if API call fails
                setChannels(prev => {
                    const newChannels = [...prev];
                    newChannels[index] = {
                        ...newChannels[index],
                        isActive: currentStatus
                    };
                    return newChannels;
                });
                
                PNotify.error({
                    title: 'Error',
                    text: 'Failed to update channel status',
                    delay: 2000,
                });
            }
        } catch (error) {
            console.error('Update channel status error:', error);
            
            // Revert the change on error
            setChannels(prev => {
                const newChannels = [...prev];
                newChannels[index] = {
                    ...newChannels[index],
                    isActive: currentStatus
                };
                return newChannels;
            });

            PNotify.error({
                title: 'Error',
                text: 'Failed to update channel status',
                delay: 2000,
            });
        } finally {
            setIsUpdating(false);
        }
    };

    const updateChannelUrl = async (id, url) => {
        if (!url.trim()) {
            PNotify.error({
                title: 'Error',
                text: 'Please enter url',
                delay: 2000,
            });
            return;
        }

        try {
            const data = {
                social_channel_id: id,
                url: url.trim(),
                brand_id: urlbrandId,
                updator_name: `${user.fname} ${user.lname}`,
                updator_image: user.profile_image
            };

            const config = {
                method: 'post',
                url: `${API_URL}branding/updatesocialchannelsurl`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);

            if (response.data.status) {
                PNotify.success({
                    title: 'Success',
                    text: 'Channel URL updated successfully',
                    delay: 2000,
                });
            } else {
                PNotify.error({
                    title: 'Error',
                    text: 'Failed to update channel URL',
                    delay: 2000,
                });
            }
        } catch (error) {
            console.error('Update channel URL error:', error);
            PNotify.error({
                title: 'Error',
                text: 'Failed to update channel URL',
                delay: 2000,
            });
        }
    };

    return (
        <>
            {channels.map((item, index) => (
                <li key={item._id} style={{
                    listStyleType: 'none',
                    marginBottom: '15px'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#f8f9fa',
                        borderRadius: '8px',
                        padding: '15px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexGrow: 1,
                            marginRight: '15px'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '15px',
                                width: '150px'
                            }}>
                                <div style={{
                                    width: '40px',
                                    height: '40px',
                                    marginRight: '10px'
                                }}>
                                    <img
                                        src={`${API_URL}channels/${item.channel_icon}`}
                                        alt={`${item.channel_name} icon`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                        }}
                                    />
                                </div>
                                <span style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {item.channel_name}
                                </span>
                            </div>
                            <input
                                type="text"
                                placeholder="Enter URL"
                                value={item.url}
                                onChange={(e) => {
                                    setChannels(prev => {
                                        const newChannels = [...prev];
                                        newChannels[index] = {
                                            ...newChannels[index],
                                            url: e.target.value
                                        };
                                        return newChannels;
                                    });
                                }}
                                style={{
                                    width: '300px',
                                    padding: '10px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '4px',
                                    fontSize: '14px'
                                }}
                            />
                            <button
                                onClick={() => updateChannelUrl(item._id, item.url)}
                                style={{
                                    backgroundColor: '#007bff',
                                    color: 'white',
                                    border: 'none',
                                    padding: '10px 15px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s',
                                    marginLeft: '10px'
                                }}
                            >
                                Update
                            </button>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 10px'
                        }}>
                            <span style={{
                                marginRight: '10px',
                                fontSize: '14px',
                                color: item.isActive ? '#4CAF50' : '#757575'
                            }}>
                                {item.isActive ? 'Active' : 'Inactive'}
                            </span>
                            <label style={{
                                position: 'relative',
                                display: 'inline-block',
                                width: '60px',
                                height: '34px'
                            }}>
                                <input
                                    type="checkbox"
                                    checked={item.isActive}
                                    onChange={() => updateChannelStatus(item._id, item.isActive, index)}
                                    disabled={isUpdating}
                                    style={{
                                        opacity: 0,
                                        width: 0,
                                        height: 0
                                    }}
                                />
                                <span style={{
                                    position: 'absolute',
                                    cursor: isUpdating ? 'not-allowed' : 'pointer',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: item.isActive ? '#4CAF50' : '#ccc',
                                    transition: '.4s',
                                    borderRadius: '34px',
                                }}>
                                    <span style={{
                                        position: 'absolute',
                                        content: '""',
                                        height: '26px',
                                        width: '26px',
                                        left: item.isActive ? '30px' : '4px',
                                        bottom: '4px',
                                        backgroundColor: 'white',
                                        transition: '.4s',
                                        borderRadius: '50%',
                                    }}></span>
                                </span>
                            </label>
                        </div>
                    </div>
                </li>
            ))}
        </>
    );
};

export default SocialChannelsList;