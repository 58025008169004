import { API_URL } from "./config.js";
import { apiCall, apiCall2, multipartApiCall } from "./auth";


export const createBrandApi = (formData) => apiCall(`${API_URL}branding/add`, "POST", formData);
export const getBrandApi = (data) => apiCall(`${API_URL}branding/get`, "POST", data);
export const updateBrandApi = (formData) => apiCall(`${API_URL}branding/update`, "POST", formData);
export const deleteBrandApi = (formData) => apiCall(`${API_URL}branding/delete`, "DELETE", formData);
export const getmemberbranding = (data) => apiCall(`${API_URL}branding/getmemberbranding`, "POST", data);
export const brandSmartCampApi = (data) => apiCall(`${API_URL}branding/brandsmartcamp`, "POST", data);
export const brandSmartTempApi = (data) => apiCall(`${API_URL}branding/brandsmarttemp`, "POST", data);
export const getBrandBySmartlinkIdApi = (data) => apiCall(`${API_URL}branding/brandbysmartlink`, "POST", data);
export const getBrandByIdApi = (data) => apiCall(`${API_URL}branding/getbyid`, "POST", data);
export const createFlexTemplateApi = (data) => multipartApiCall(`${API_URL}branding/addflextemplate`, "POST", data);
export const FlexByBrandId = (data) => apiCall(`${API_URL}branding/flexbybrandid`, "POST", data);
export const updateFlexTemplateApi = (data) => apiCall(`${API_URL}branding/updateflextemplate`, "POST", data);
export const deleteFlexTemplateApi = (data) => apiCall(`${API_URL}branding/deleteflextemplate`, "POST", data);
export const deleteVanityApi = (data) => apiCall(`${API_URL}branding/deletevanity`, "POST", data);
export const updateTeamplateName = (formData) => apiCall(`${API_URL}branding/updatetamplatename`, "POST", formData);
export const getdefaultflexApi = (data) => apiCall(`${API_URL}branding/getdefaultflextemplate`, "POST", data);
export const cohereBrandingDeatails = (data) => apiCall(`${API_URL}branding/cohereBrandingDeatails`, "POST", data);

export const addProductService = (data) => apiCall2(`${API_URL}branding/addProductService`, "POST", data);
export const updateProductService = (data) => apiCall2(`${API_URL}branding/updateProductService`, "POST", data);
export const getAllProductService = (data) => apiCall2(`${API_URL}branding/getAllProductService`, "POST", data);
export const deleteProductService = (data) => apiCall2(`${API_URL}branding/deleteProductService`, "POST", data);

export const addAudience = (formData) => apiCall2(`${API_URL}branding/addAudience`, "POST", formData);
export const updateAudience = (formData) => apiCall2(`${API_URL}branding/updateAudience`, "POST", formData);
export const getAllAudience = (formData) => apiCall2(`${API_URL}branding/getAllAudience`, "POST", formData);
export const deleteAudience = (formData) => apiCall2(`${API_URL}branding/deleteAudience`, "POST", formData);

export const addCompetitor = (formData) => apiCall2(`${API_URL}branding/addCompetitor`, "POST", formData);
export const updateCompetitor = (formData) => apiCall2(`${API_URL}branding/updateCompetitor`, "POST", formData);
export const getAllCompetitor = (formData) => apiCall2(`${API_URL}branding/getAllCompetitor`, "POST", formData);
export const deleteCompetitor = (formData) => apiCall2(`${API_URL}branding/deleteCompetitor`, "POST", formData);

export const addContemporaries = (formData) => apiCall2(`${API_URL}branding/addContemporaries`, "POST", formData);
export const updateContemporaries = (formData) => apiCall2(`${API_URL}branding/updateContemporaries`, "POST", formData);
export const getAllContemporaries = (formData) => apiCall2(`${API_URL}branding/getAllContemporaries`, "POST", formData);
export const deleteContemporaries = (formData) => apiCall2(`${API_URL}branding/deleteContemporaries`, "POST", formData);

export const AddbrandsGallery = (formData) => apiCall2(`${API_URL}branding/brandsGallery`, "POST", formData);
export const updateGalleryBrand = (formData) => apiCall2(`${API_URL}branding/brandsGalleryUpdate`, "POST", formData);
export const brandsGalleryList = (formData) => apiCall2(`${API_URL}branding/brandsGalleryList`, "POST", formData);
export const brandsGalleryDelete = (formData) => apiCall2(`${API_URL}branding/brandsGalleryDelete`, "POST", formData);


