import { createContext, useState, useEffect } from "react";

const UserContext = createContext();

// create a context for the user provider


const UserProvider = ({ children }) => {
  const [groupdata, setGroupData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [groupaddstatus, setGroupAddStatus] = useState(false);
  const [memberaddstatus, setMemberAddStatus] = useState(false);
  const [ownerData, setOwnerData] = useState({});
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [userLoginDisabled, setUserLoginDisabled] = useState(null);
  const [stripe_customer_id, setStripe_customer_id] = useState("");
  const [owner_email, setOwnerEmail] = useState("");


  return (
    <UserContext.Provider value={{
      groupdata, setGroupData, groupaddstatus, setGroupAddStatus,
      memberData, setMemberData, memberaddstatus, setMemberAddStatus, ownerData,
      setOwnerData, subscriptionStatus, setSubscriptionStatus, stripe_customer_id, setStripe_customer_id,
      owner_email, setOwnerEmail, subscriptionDetails, setSubscriptionDetails,userLoginDisabled, setUserLoginDisabled
    }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
