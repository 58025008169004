import React from "react";
import { Link } from "react-router-dom";
import { MdImage } from "react-icons/md";
import { FaChevronRight, FaLongArrowAltLeft } from "react-icons/fa";
import axios from "axios";
import { API_URL } from "../../../BackendApi/Api/config";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
// import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import { v4 as uuidv4 } from "uuid";
import SpinnerLoader from "../../../Components/SpinnerLoader/SpinnerLoader";
import { Form } from "react-bootstrap";
import ImageChecker from "../../../Helpers/ImageChecker";
import useImageTransparency from "../../../Components/CustomHooks/useImageTransparency";



export function ImageList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdImage />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Image</h2>
                            <p className="add-block-btn-text-desc">Add a Image to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}






export function ImageAdd() {

    const navigate = useHistory();
    const [singleImagePosition, setSingleImagePosition] = React.useState("cover");
    const [backgroundColor, setBackgroundColor] = React.useState("#ACO21");

    const { addComponent } = useBuilderContext();

    const [loading, setLoading] = React.useState(false);
    const [image, setImage] = React.useState(null);

    const { isTransparent, imageUrl } = useImageTransparency(image);
    
    const [imageProperties, setImageProperties] = React.useState({
        imageLink: "",
        imageCaption: "",
        imageAltText: "",
        imagePosition: "cover",
        backgroundColor: "#ffffff",
        link: "",
        imagefile: null

    });

    const addImageData = async (e) => {
        const data = new FormData();
        data.append('image', image);
        setLoading(true);
        uploadSingleImageApi(data).then((res) => {
            console.log(res.data);
            if (res.code == 200) {

                const uniqueId = Date.now() + uuidv4();

                const imageproerty = {
                    imageLink: "",
                    imageCaption: imageProperties.imageCaption,
                    imageAltText: imageProperties.imageAltText,
                    link: imageProperties.link,
                    imagePosition: singleImagePosition,
                    backgroundColor: backgroundColor,
                    images: image
                }
                if (res.data.status == true) {
                    imageproerty.imageLink = res.data.data.filename;
                }

                imageproerty.unique_id = uniqueId;
                PNotify.success({
                    title: 'Success',
                    delay: 3000,
                    text: 'Image added successfully',
                })
                addComponent("IMAGETEXT", imageproerty);
                setLoading(false);
            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'try again',
                });
                setLoading(false);
            }

        }).catch((err) => {
            PNotify.error({
                title: 'Error',
                text: 'try again',
            });
            setLoading(false);
        })
    }
    const handleImagePositionChange = (e) => {
        const selectedPosition = e.target.value;
        setSingleImagePosition(selectedPosition);
    }

    const handleBackgroundColorChange = (e) => {
        const selectedColor = e.target.value;
        setBackgroundColor(selectedColor);
    }

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Image</label>
                    </div>
                </div>
                <div className="form_block iuEvSC">
                    <ImageChecker src={image} />

                    <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => {
                            setImage(e.target.files[0]);
                        }}
                    />
                </div>

                <div className="form_box cvtPmc" >
                    <div className="form-group mb-2">

                        <div className="form_block">
                            <textarea className="form-control did-floating-textarea" placeholder=""
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, imageCaption: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputEmail1" className="gvofes did-floating-label">Image caption</label>
                        </div>
                    </div>
                    <div className="form-group">

                        <div className="form_block">
                            <input type="text" className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, link: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputPassword1" className="gvofes did-floating-label">Link</label>
                        </div>
                    </div>
                    <div className="form-group">

                        <div className="form_block">
                            <input type="text" className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, imageAltText: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputPassword1" className="gvofes did-floating-label">Alt text</label>
                        </div>
                    </div>
                    <div className="form-group">

                        {/* <div className="form-group did-floating-label-content"> */}
                        <select value={singleImagePosition} onChange={handleImagePositionChange} className="form-control did-floating-select">
                            <option value="contain">Contain</option>
                            <option value="cover">Cover</option>
                            <option value="fill">Fill</option>
                        </select>
                        <label htmlFor="exampleFormControlTextarea1" className="gvofes did-floating-label">Image Position</label>
                        {/* </div> */}
                    </div>
                    {isTransparent &&
                        <div className="form-group">

                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail" style={{ marginTop: 20 }}>
                                <Form.Control className="did-floating-color" type="color" placeholder="theme colors" defaultValue={backgroundColor} value={backgroundColor} onChange={(e) => handleBackgroundColorChange(e)} />
                                <Form.Label className="did-floating-label">Page Color</Form.Label>
                            </Form.Group>
                        </div>}

                </div>

            </div>
            <div className="add-block-btn">
                {loading ? (
                    <SpinnerLoader />
                ) :
                    (<button className="btn publish-btn" onClick={
                        () => {
                            addImageData()
                        }
                    }>
                        Add
                    </button>)}
            </div>
        </>
    );
}