import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { MdTitle } from "react-icons/md";
import { FaChevronRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useBuilderContext } from "../../../builderContext";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';
import { v4 as uuidv4 } from "uuid";
export function TextList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdTitle />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Text</h2>
                            <p className="add-block-btn-text-desc">Add a Text to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}



export function TextAdd() {
    const navigate = useHistory();
    const { addComponent } = useBuilderContext();
    const [html, setHtml] = useState("");
    const editorInstance = useRef(null);

    const getSunEditorInstance = (sunEditor) => {
        editorInstance.current = sunEditor;
        if (html && sunEditor.getContents() !== html) {
            sunEditor.setContents(html);
        }
    };
    const uniqueId = Date.now() + uuidv4();
    const handleEditorChange = (content) => {
        if (content !== html) {
            setHtml(content);
        }
    };

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor="text" color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Text</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group mb-2">
                        <label htmlFor="text">Text</label>
                        <SunEditor
                            setAllPlugins={true}
                            getSunEditorInstance={getSunEditorInstance}
                            setContents={html}
                            onChange={handleEditorChange}
                            setOptions={{
                                height: "150px",
                                plugins: plugins,
                                buttonList: [
                                    ['undo', 'redo'],
                                    ['font', 'fontSize', 'formatBlock'],
                                    ['paragraphStyle', 'blockquote'],
                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                    ['fontColor', 'hiliteColor'],
                                    ['removeFormat', 'link'],
                                    ['outdent', 'indent'],
                                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                                    ['fullScreen', 'showBlocks', 'codeView'],
                                    ['preview', 'print'],
                                ],
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={() => {
                    const content = editorInstance.current.getContents();
                    addComponent("TEXT", { html: content, unique_id: uniqueId },);
                    navigate.push("/");
                }}>
                    Add
                </button>
            </div>
        </>
    );
}