import React, { useState, useContext, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import "./Setting.css";
import { FiUsers, FiServer } from "react-icons/fi";
import { TbShieldLock, TbKey, TbHierarchy, TbMessage2, TbHelp, TbEye } from "react-icons/tb";
import { FaSun, FaMoon } from "react-icons/fa";
import { BiUserCircle } from "react-icons/bi";
import TabData1 from "../Components/SettingTabs/Profile";
import TabData2 from "../Components/SettingTabs/Password";
import { RiAddFill } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import Tabteam from "../Components/SettingTabs/Teams";
import TabData7 from "../Components/SettingTabs/Authentications";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import TabData5 from "../Components/SettingTabs/Tabapi";
import TabData6 from "../Components/SettingTabs/Tabbrand";
import Header from "../Components/Header/Header";
import Accordion from 'react-bootstrap/Accordion';
import TabData8 from "../Components/SettingTabs/MyDomain";
import MyChannels from "../Components/SettingTabs/MyChannels";
import { Link } from 'react-router-dom';
import { AiOutlineSetting } from "react-icons/ai";
import { addGroupApi, getGroupDataApi } from "../BackendApi/Api/groupApi";
import { addDomainApi, getDomainDataApi } from '../BackendApi/Api/domainApi'
import { getApiApi, updateApiApi } from '../BackendApi/Api/api'
import { UserContext } from "../UserContext";
import { createApiApi } from '../BackendApi/Api/api'
import md5 from 'md5';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { BiLinkAlt, BiLink } from 'react-icons/bi';
import { MdStorage, MdPayment } from 'react-icons/md';
import { TbArrowsShuffle2 } from 'react-icons/tb';
import { BsCursor } from 'react-icons/bs';
import { deleteApiApi } from '../BackendApi/Api/api'
import Spinner from 'react-bootstrap/Spinner';
import { addChannelApi } from '../BackendApi/Api/channels';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import PriceTable from '../priceTable/PriceTable';
import { useParams, useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { sendOtpByEmail, verifyOtpByEmail, } from '../BackendApi/Api/userApi';
import { logout } from '../BackendApi/Api/auth';
import { VscReferences } from "react-icons/vsc";
import Referral from '../Components/SettingTabs/Referral/referrral';
import { FaTags } from "react-icons/fa";
import Tags from "../Components/SettingTabs/Tags/Tags";


function Setting() {
    const { groupdata, setGroupData, groupaddstatus, setGroupAddStatus } = useContext(UserContext);

    const [loading2, setLoading2] = useState(false);
    const role = JSON.parse(localStorage.getItem("user")).role;

    const [show03, setShow03] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose03 = () => setShow03(false);
    const handleShow03 = () => setShow03(true);


    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);


    const [show08, setShow08] = useState(false);

    const handleClose08 = () => setShow08(false);
    const handleShow08 = () => setShow08(true);

    const [show09, setShow09] = useState(false);

    const handleClose09 = () => setShow09(false);
    const handleShow09 = () => setShow09(true);

    const [show10, setShow10] = useState(false);

    const handleClose10 = () => setShow10(false);
    const handleShow10 = () => setShow10(true);

    const [show11, setShow11] = useState(false);

    const handleClose11 = () => setShow11(false);
    const handleShow11 = () => setShow11(true);

    const [show13, setShow13] = useState(false);

    const handleClose13 = () => setShow13(false);
    const handleShow13 = () => setShow13(true);

    const user = JSON.parse(localStorage.getItem('user'));

    const [group_name, setGroupName] = useState("");
    const [color_code, setColorCode] = useState("#000000");
    const [created_by, setCreatedBy] = useState(user.fname + " " + user.lname);
    const [created_on, setCreatedOn] = useState(Date.now());

    const data = localStorage.getItem("user");
    const blah = JSON.parse(data)._id;

    const [domainName, setDomainName] = useState('');
    const [creatorId, setCreaterId] = useState(blah);
    const [domainDatastatus, setDomainDataStatus] = useState(false);

    const [apiName, setApiName] = useState('');
    const [apiCreateStatus, setApiCreateStatus] = useState(false)
    const [apiData, setApiData] = useState([])
    const [apiAccess, setApiAccess] = useState(false);

    const [channelName, setChannelName] = useState('');
    const [channelicon, setChannelIcon] = useState(null);

    const [sessionStatus, setSessionStatus] = useState(false);


    const [channelCategory, setChannelCategory] = useState('');

    const saveThemeType = localStorage.getItem('theme');
    const [isDarkMode, setIsDarkMode] = useState(
        saveThemeType === 'dark' ? true : false
    ); // Initialize as false for light theme

    const handleshowApiAccess = () => {
        setApiAccess(!apiAccess);
    }


    const [activeDetails, setActiveDetails] = useState({}); // State to manage the active state for each item

    const handleViewButtonClick = (itemId) => {
        setActiveDetails((prevActiveDetails) => ({
            ...prevActiveDetails,
            [itemId]: !prevActiveDetails[itemId],
        }));
    };

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        console.log("savedTheme", savedTheme);
        if (savedTheme == null) {
            setIsDarkMode('light')
            localStorage.setItem('theme', 'light'); // Store the theme preference
        }
    }, []);

    const toggleTheme = () => {
        const newTheme = !isDarkMode;
        setIsDarkMode(newTheme);
        localStorage.setItem('theme', newTheme ? 'dark' : 'light'); // Store the theme preference

    };
    const textTheme = !isDarkMode ? 'Light' : 'Dark';


    useEffect(() => {
        // Toggle the dark-theme class on the body when isDarkMode changes
        const savedTheme = localStorage.getItem('theme');
        // if(savedTheme != null){
        while (document.body.classList.length > 0) {
            document.body.classList.remove(document.body.classList.item(0));
        }
        document.body.classList.toggle(savedTheme, true);
        // }
    }, [isDarkMode]);

    const handleChannelCategoryChange = (e) => {
        setChannelCategory(e.target.value);
    };

    const handleChannelIcon = (e) => {
        setChannelIcon(e.target.files[0])
    }

    const addChannel = (e) => {
        setLoading2(true)
        e.preventDefault();
        const data = localStorage.getItem("user");
        const user_id = JSON.parse(data)._id;
        const owner_id = JSON.parse(data).owner_id;

        const formData = new FormData();
        formData.append('channel_icon', channelicon);
        formData.append('channel_name', channelName);
        formData.append('created_by', user_id);
        formData.append('owner_id', owner_id);
        formData.append('channel_category', channelCategory);
        if (channelName == '') {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Please enter channel name',
                modules: {
                    Desktop: {
                        desktop: true
                    }
                },
            });
            setLoading2(false)
        }
        else if (channelicon == null || channelicon == '') {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Please select channel icon',
                modules: {
                    Desktop: {
                        desktop: true
                    }
                },
            });
            setLoading2(false)
        } else if (channelCategory == '') {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Please select channel category',
                modules: {
                    Desktop: {
                        desktop: true
                    }
                },
            });
            setLoading2(false)
        }
        else {

            addChannelApi(formData).then((response) => {

                if (response.code == 200) {
                    if (response.data.status == true) {
                        setSessionStatus(!sessionStatus)
                        PNotify.success({
                            title: 'Success',
                            delay: 2000,
                            text: 'Channel Added Successfully',
                            modules: {
                                Desktop: {
                                    desktop: true
                                }
                            },
                        });
                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            delay: 2000,
                            text: 'Something went wrong',
                            modules: {
                                Desktop: {
                                    desktop: true
                                }
                            },
                        });
                    }
                }
            }).catch((error) => {
                console.log(error)
            }
            ).finally((Err) => {
                setLoading2(false)
            })
            handleClose13();
        }
    }

    //verify domain string
    const verifyDomainString = (domainString) => {
        // verify domain string
        const domainRegex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/);
        return !!domainRegex.test(domainString);

    }


    const handleAddGroup = async (e) => {
        setLoading(true);
        e.preventDefault();

        const groupData = {
            group_name: group_name,
            color_code: color_code,
            owner_id: user._id,
            created_by: user._id,
        }

        try {
            const response = await addGroupApi(groupData);

            console.log("Response", response);

            if (response.code === 200) {
                setGroupAddStatus(!groupaddstatus);
                setShow08(false);
                PNotify.success({
                    title: 'Success',
                    delay: 2000,
                    text: response.data.message,
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
            } else {
                PNotify.error({
                    title: 'Error',
                    delay: 1000,
                    text: response.data.message,
                });
            }
        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: error,
            });
            console.log("juuuuuuu", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateDomain = (e) => {
        e.preventDefault();

        const data = localStorage.getItem("user");
        const user_id = JSON.parse(data)._id;

        const domainData = {
            domain_name: domainName,
            owner_id: user_id,
            created_by: user_id
        }

        if (!verifyDomainString(domainName)) {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Please enter valid domain name',
                modules: {
                    Desktop: {
                        desktop: true
                    }
                },
            });
            return false;
        }
        else {

            addDomainApi(domainData).then(response => {
                if (response.code === 200) {
                    setDomainDataStatus(!domainDatastatus);
                    PNotify.success({
                        title: 'Success',
                        delay: 2000,
                        text: 'Domain Added Successfully',
                        modules: {
                            Desktop: {
                                desktop: true
                            }
                        },
                    });
                    handleClose04();
                }
                else {
                    PNotify.error({
                        title: 'Error',
                        delay: 2000,
                        text: 'Something went wrong',
                    });
                    handleClose04();
                }
            }
            )
                .catch(error => {
                    PNotify.error({
                        title: 'Error',
                        delay: 2000,
                        text: 'Something went wrong',
                    });
                })
            handleClose04();
        }
    }



    //get data from local storage
    const local_data = JSON.parse(localStorage.getItem("user"));
    const owner_email = local_data.email;
    const owner_id = local_data._id;

    const encryption_string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    //generate random api key with combination of owner_email,current time string and encryption_string
    const generate_api_key = () => {
        const current_time = new Date().getTime().toString();
        const random_string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        const random_encryption_string = encryption_string[Math.floor(Math.random() * encryption_string.length)];
        const api_key = md5(owner_email + current_time + random_string + random_encryption_string);
        console.log('api_key', api_key);
        return api_key;
    }


    const addApi = async (e) => {
        setLoading2(true)
        e.preventDefault();
        const api_key = generate_api_key();
        const data = {
            owner_id: owner_id,
            api_key: api_key,
            api_name: apiName
        }
        createApiApi(data).then((res) => {
            console.log(res);
            if (res.code === 200) {
                setApiCreateStatus(!apiCreateStatus);
                PNotify.success({
                    title: 'Success',
                    delay: 2000,
                    text: 'API Added Successfully',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
            }
            else {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: res.data.message,
                });
            }
        }).catch((err) => {
            console.log(err);
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: err.message,
            });
        }).finally((EE) => {
            setLoading2(false)
        })


    }

    const getApiData = () => {

        getApiApi({ id: owner_id }).then((res) => {
            if (res.code == 200) {
                console.log('apidata', res)
                setApiData(res.data.data)
            }
            else {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: 'Something went wrong',
                });
            }
        }
        ).catch((error) => {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Something went wrong',
            });
        })
    }

    useEffect(() => {
        getApiData();
    }, [apiCreateStatus])

    const [editapiId, seteditapiId] = useState('');
    const [editapiName, setEditApiName] = useState('');
    const [editnote, setEditNote] = useState('');
    const editApi = (e) => {
        e.preventDefault();
        setLoading2(true)
        const data = {
            api_id: editapiId,
            api_name: editapiName,
            notes: editnote
        }


        updateApiApi(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: 'Success',
                        delay: 2000,
                        text: 'API Updated Successfully',
                    });
                    handleClose11();
                    getApiData();
                }
            }
            else {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: 'Something went wrong',
                });
            }
        }
        ).catch((error) => {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Something went wrong',
            });
        }
        ).finally((eRR) => {
            setLoading2(false)
        })
    }

    const [show12, setShow12] = useState(false);

    const handleClose12 = () => setShow12(false);
    const handleShow12 = () => setShow12(true);

    const deleteapidata = () => {
        const data = {
            api_id: editapiId
        }
        deleteApiApi(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: 'Success',
                        text: 'API Deleted Successfully',
                    });
                    handleClose12();
                    getApiData();
                }
            }
            else {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: 'Something went wrong',
                });
            }
        }
        ).catch((error) => {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: error,
            });
        }
        )
    }

    const DEFAULT_ACTIVE_TAB = "profile"; // Change this to the default tab you want

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [expire, setExpire] = useState(0); // Initialize expire to 0
    const [confirmNewEmail, setConfirmNewEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [hideInput, setHideInput] = useState(true);
    const [remainingTime, setRemainingTime] = useState(0);
    const [formattedTime, setFormattedTime] = useState("02:00");

    function formatCountdown(timeInSeconds) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    }
    useEffect(() => {
        if (!hideInput && remainingTime > 0) {
            const countdownInterval = setInterval(() => {
                setRemainingTime((prevTime) => {
                    const newTime = prevTime - 1;
                    setFormattedTime(formatCountdown(newTime)); // Update formatted time
                    if (newTime <= 0) {
                        clearInterval(countdownInterval); // Stop the interval when time is up
                    }
                    return newTime;
                });
            }, 1000);
            return () => {
                clearInterval(countdownInterval); // Cleanup interval on component unmount or dependency change
            };
        }

    }, [hideInput, remainingTime]);

    const handleSendEmail = (e) => {
        e.preventDefault();
        if (!email || !confirmNewEmail) {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Please enter both old and new email',
            });
            return;
        }

        if (newEmail !== confirmNewEmail) {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'The confirmation email does not match the entered email',
            });
            return;
        }

        if (email === newEmail) {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'The new email must be different from the current email',
            });
            return;
        }

        const data = {
            user_id: owner_id,
            email: email,
            newEmail: newEmail
        };
        sendOtpByEmail(data).then((res) => {
            if (res.code === 200) {
                setExpire(res.data.data?.otp_startTime);
                setOtp("");
                setHideInput(false);
                setRemainingTime(res.data.data?.otp_startTime);
                formatCountdown(res.data.data?.otp_startTime)
                PNotify.success({
                    title: 'Success',
                    delay: 2000,
                    text: 'OTP sent successfully',
                });
            } else {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: res.data.message,
                });
            }
        })
            .catch((error) => {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: error,
                });
            });
    }

    const handleVerifyEmail = (e) => {
        e.preventDefault();
        if (remainingTime === 0) {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'OTP Expired',
            });
            return;
        }
        if (otp !== otp) {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Please enter the correct OTP',
            });
            return;
        }
        if (!otp) {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Please enter the OTP',
            });
            return;
        }

        const data = {
            email: email,
            newEmail: newEmail,
            otp: otp,
            otp_startTime: expire,
        };
        verifyOtpByEmail(data).then((res) => {
            if (res.code === 200) {
                PNotify.success({
                    title: 'Success',
                    delay: 2000,
                    text: res.data.message,
                });
                logout();
                // window.location.href = "/login";
            } else {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: res.data.message,
                });
            }
        }).catch((error) => {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: error,
            });
        });
    }

    if (role == '0') {
        var tabs = {
            "profile": {
                title: "Profile",
                icon: (<BiUserCircle />),
                content: (
                    <>
                        <div className="tab-content-header">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><BiUserCircle /></div>Profile</h5>
                        </div>
                        <div className="setting-tab-content-body">
                            <TabData1 />
                        </div>
                    </>
                )
            },
            "password": {
                title: "Login & Passwords",
                icon: (<TbShieldLock />),
                content: (
                    <>
                        <div className="tab-content-header">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><TbShieldLock /></div>Login & Passwords</h5>
                        </div>
                        <div className="setting-tab-content-body">
                            <Tabs
                                defaultActiveKey="password"
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                            >
                                <Tab eventKey="password" title="Password">
                                    <TabData2 />
                                </Tab>
                                <Tab eventKey="email" title="Email">
                                    {hideInput ? (
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-12">
                                                <div className="content-body-form">
                                                    <form>
                                                        <div className="form-group did-floating-label-content">
                                                            <input
                                                                type="email"
                                                                className="form-control did-floating-input"
                                                                id="exampleFormControlInput1"
                                                                placeholder=""
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                            <label for="exampleFormControlInput1" className="form-label did-floating-label">
                                                                Current Email
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-12">
                                                <div className="content-body-form">
                                                    <form>
                                                        <div className="form-group did-floating-label-content">
                                                            <input
                                                                type="email"
                                                                className="form-control did-floating-input"
                                                                id="exampleFormControlInput1"
                                                                placeholder=""
                                                                value={newEmail}
                                                                onChange={(e) => setNewEmail(e.target.value)}
                                                            />
                                                            <label for="exampleFormControlInput1" className="form-label did-floating-label">
                                                                New Email
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-12">
                                                <div className="content-body-form">
                                                    <form>
                                                        <div className="form-group did-floating-label-content">
                                                            <input
                                                                type="email"
                                                                className="form-control did-floating-input"
                                                                id="exampleFormControlInput1"
                                                                placeholder=""
                                                                value={confirmNewEmail}
                                                                onChange={(e) => setConfirmNewEmail(e.target.value)}
                                                            />
                                                            <label for="exampleFormControlInput1" className="form-label did-floating-label">
                                                                Confirm New Email
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-12 justify-content-end d-flex">
                                                <button
                                                    type="button"
                                                    onClick={handleSendEmail}
                                                    className="btn bg-green-dark action-btn btn-green"
                                                >
                                                    Send Email
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn bg-danger-dark action-btn btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-12">
                                                <div className="content-body-form">
                                                    <form>
                                                        <div className="form-group did-floating-label-content">
                                                            <input
                                                                type="text"
                                                                className="form-control did-floating-input"
                                                                id="exampleFormControlInput1"
                                                                placeholder=""
                                                                value={otp}
                                                                onChange={(e) => setOtp(e.target.value)}
                                                            />
                                                            <label for="exampleFormControlInput1" className="form-label did-floating-label">
                                                                Enter OTP
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-12">
                                                <p>
                                                    {remainingTime > 0 ? `OTP will expire in :- ${formattedTime} seconds` : "Time's up!"}
                                                </p>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-12 justify-content-end d-flex">
                                                <button
                                                    type="button"
                                                    onClick={handleVerifyEmail}
                                                    className="btn bg-green-dark action-btn btn-green"

                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={handleSendEmail}
                                                    disabled={remainingTime > 0 && true}
                                                    className="btn bg-info-dark action-btn btn-info"
                                                >
                                                    Resend OTP
                                                </button>
                                            </div>
                                        </div>
                                    )}


                                </Tab>
                                {/* <Tab eventKey="advanced_auth" title="Advanced Auth">
                                    <TabData7 />
                                </Tab> */}
                            </Tabs>
                        </div>
                    </>
                )
            },

            "teams": {
                title: "Teams",
                icon: (<FiUsers />),
                content: (
                    <>
                        <div className="tab-content-header add-btn d-flex">
                            <h5 className="tab-content-title" ><div className="tab-content-icon"><FiUsers /></div>Teams</h5>
                            {/* <div className="tab-content-header-right">
                                <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow08} > <RiAddFill />Add Group</Button>
                            </div> */}
                        </div>
                        <div className="setting-tab-content-body">
                            <Tabteam />
                        </div>
                    </>
                )
            },
            "api_setting": {
                title: "API Access",
                icon: (<TbKey />),
                content: (
                    <>
                        <div className="tab-content-header add-btn d-flex Add-margin">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><TbKey /></div>API Access</h5>
                            {/* <Button className="btn btn-sm help-btn-view" >Views <TbEye /></Button> */}

                            <div className="tab-content-header-right d-flex">
                                <Link to="/api">
                                    <Button className="btn btn-sm help-btn-view">Help <TbHelp /></Button>
                                </Link>
                                <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow09} >Generate API Key</Button>
                            </div>
                        </div>
                        <div className="setting-tab-content-body">
                            {/* <TabData5 /> */}

                            <div className="API-key-list">
                                <ul>
                                    {apiData.map((item) => {

                                        return (

                                            <li className="API-key-list-item-bg">
                                                <div className="API-key-list-item d-flex">
                                                    <div className="API-key-list-item-left">
                                                        <h3>{item.api_name}</h3>
                                                        <h4>Created Date : {new Date(item.created_on).toISOString().slice(0, 10)}</h4>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <div className="d-flex align-items-center">
                                                            <div className="button-group">
                                                                <button type="button" className="btn btn-layout-view me-2" onClick={() => handleViewButtonClick(item._id)}>
                                                                    <TbEye />
                                                                </button>
                                                                <button type="button" className="btn btn-edit me-2" onClick={
                                                                    (e) => {
                                                                        handleShow11();
                                                                        setEditApiName(item.api_name)
                                                                        setEditNote(item.notes)
                                                                        seteditapiId(item._id)
                                                                    }
                                                                } >
                                                                    <TbEdit />
                                                                </button>
                                                                <button type="button" className="btn btn-delete m-0" onClick={
                                                                    (e) => {
                                                                        handleShow12();
                                                                        seteditapiId(item._id)
                                                                    }
                                                                } >
                                                                    <TbTrash />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`api-details-list ${activeDetails[item._id] ? 'active' : ''}`}>
                                                    <div className={`API-key-details d-flex justify-mob01 ${activeDetails[item._id] ? 'active' : ''}`}>
                                                        <div className="API-key-details-left w-50">
                                                            <h5> <BiLinkAlt />  Dynamic Links <span> </span></h5>
                                                            <h5> <BiLink />  Simple Links<span> </span></h5>
                                                            <h5> <TbArrowsShuffle2 /> Redirections<span> </span></h5>
                                                            <h5> <BsCursor />  Visits<span> </span></h5>
                                                            <h5> <MdStorage /> Storage Used<span> </span></h5>
                                                        </div>
                                                        <div className="API-key-details-right">
                                                            <h5> {/* dynamic Link */} 05</h5>
                                                            <h5> {/* Simple Links */} 2.5 GB</h5>
                                                            <h5> {/* Redirections */} 18</h5>
                                                            <h5> {/* Visits */} 76</h5>
                                                            <h5> {/* Storage Used */} 08 GB</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>


                                        )
                                    })
                                    }


                                </ul>

                            </div>


                        </div>
                    </>
                )
            },
            "referral": {
                title: "Referral",
                icon: (<VscReferences />
                ),
                content: (
                    <>
                        <div className="tab-content-header add-btn d-flex Add-margin">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><VscReferences />
                            </div>Referrals</h5>
                            <div className="tab-content-header-right">
                                {/* <Button className="btn btn-sm float-right add-usar-btn me-3 margin-imp" onClick={handleShow03} > <TbMessage2 />Instructions</Button> */}

                            </div>
                        </div>
                        <div className="setting-tab-content-body">
                            <Referral />
                        </div>
                    </>
                )
            },
            "tags": {
                title: "Tags",
                icon: (<FaTags />),
                content: (
                    <>
                        <Tags />
                    </>
                )
            },
            // "authentications": {
            //     title: "Authentications",
            //     icon: (<TbHierarchy />),
            //     content: (
            //         <>
            //             <div className="tab-content-header Add-margin">
            //                 <h5 className="tab-content-title"><div className="tab-content-icon"><TbHierarchy /></div>Authentications</h5>
            //             </div>
            //             <div className="setting-tab-content-body">
            //                 <div className="table-card-body-inner">
            //                     <TabData7 />
            //                 </div>
            //             </div>
            //         </>
            //     )

            // },
            // "my_channels": {
            //     title: "My Channels",
            //     icon: (<FiServer />),
            //     content: (
            //         <>
            //             <div className="tab-content-header add-btn d-flex Add-margin">
            //                 <h5 className="tab-content-title"><div className="tab-content-icon"><FiServer /></div></h5>
            //                 <div className="tab-content-header-right">
            //                     {/* <Button className="btn btn-sm float-right add-usar-btn me-3 margin-imp" onClick={handleShow03} > <TbMessage2 />Instructions</Button> */}
            //                     <Button className="btn btn-sm float-right add-usar-btn" onClick={handleShow13} > <FiServer />New Channel</Button>
            //                 </div>
            //             </div>
            //             <div className="setting-tab-content-body">
            //                 <MyChannels sessionStatus={sessionStatus} setSessionStatus={setSessionStatus} />
            //             </div>
            //         </>
            //     )
            // },
            "price": {
                title: "Subscription & Billing",
                icon: (<MdPayment />),
                content: (
                    <>
                        <div className="tab-content-header add-btn d-flex Add-margin">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><MdPayment /></div>Subscriptions</h5>
                            <div className="tab-content-header-right">
                                {/* <Button className="btn btn-sm float-right add-usar-btn me-3 margin-imp" onClick={handleShow03} > <TbMessage2 />Instructions</Button> */}

                            </div>
                        </div>
                        <div className="setting-tab-content-body">
                            <PriceTable />
                        </div>
                    </>
                )
            },
        }
    };

    if (role == '2') {
        var tabs = {
            "profile": {
                title: "Profile",
                icon: (<BiUserCircle />),
                content: (
                    <>
                        <div className="tab-content-header">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><BiUserCircle /></div>Profile</h5>
                        </div>
                        <div className="setting-tab-content-body">
                            <TabData1 />
                        </div>
                    </>
                )
            },
            "password": {
                title: "Password Reset",
                icon: (<TbShieldLock />),
                content: (
                    <>
                        <div className="tab-content-header">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><TbShieldLock /></div>Password Reset</h5>
                        </div>
                        <div className="setting-tab-content-body">
                            <TabData2 />
                        </div>
                    </>
                )
            },
            "authentications": {
                title: "Authentications",
                icon: (<TbHierarchy />),
                content: (
                    <>
                        <div className="tab-content-header Add-margin">
                            <h5 className="tab-content-title"><div className="tab-content-icon"><TbHierarchy /></div>Authentications</h5>
                        </div>
                        <div className="setting-tab-content-body">
                            <div className="table-card-body-inner">
                                <TabData7 />
                            </div>
                        </div>
                    </>
                )

            }
        }
    }

    // const { active_tab } = useParams();
    const location = useLocation();

    // Split the pathname by "/" to get an array of segments
    const segments = location.pathname.split('/');

    const active_tab = segments[2]; // Adjust the index as needed



    const history = useHistory();

    useEffect(() => {
        if (!active_tab) {
            history.push(`/setting/${DEFAULT_ACTIVE_TAB}`);
        }
    }, [active_tab, history]);

    const toggle = tab => {
        if (active_tab !== tab) {
            history.push(`/setting/${tab}`);
        }
    };

    return (
        <>


            <Modal show={show04} onHide={handleClose04} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Domain</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Domain Name (Enter domain name without http or https or www)</Form.Label>
                                <Form.Control type="text" placeholder="Domain Name" value={domainName} onChange={(e) => setDomainName(e.target.value)} />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={(e) => { handleCreateDomain(e) }}> Create Domain </button>
                </Modal.Footer>
            </Modal>
            {/* modal end here */}

            <Modal show={show13} onHide={handleClose13} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Channel</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-input" type="text" placeholder="channel Name" onChange={(e) => setChannelName(e.target.value)} />
                                <Form.Label className="did-floating-label">Channel Name</Form.Label>
                            </Form.Group>
                            <Form.Group controlId="formBasicChannel" className="did-floating-label-content">
                                <Form.Control as="select" value={channelCategory} onChange={handleChannelCategoryChange} className="did-floating-select">
                                    <option selected disabled>Select a Channel</option>
                                    <option value="social">Social Media</option>
                                    <option value="chat">Chat Platforms</option>
                                    <option value="advertising">Advertising</option>
                                    <option value="outreach">Direct Outreach</option>
                                    <option value="online">Online</option>
                                    <option value="out_home_media">Out of Home Media</option>
                                    <option value="print_material">Print Material</option>
                                    <option value="television">Television</option>
                                </Form.Control>
                                <Form.Label className="did-floating-label">Select Channel Category:</Form.Label>
                            </Form.Group>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-file" type="file" accept="image/*" onChange={handleChannelIcon} />
                                <Form.Label className="did-floating-label">Channel Icon</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {loading2 ? <button className="Active-bttn btn"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button> :

                        <button className="Active-bttn btn" onClick={addChannel}> Add Channel </button>}
                </Modal.Footer>
            </Modal>

            {/* Delete Modal Start From Here */}
            <Modal show={show12} onHide={handleClose12} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose12}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={deleteapidata}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal End Here */}

            {/* instrruction modal start here */}
            <Modal show={show03} onHide={handleClose03} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title"><TbMessage2 className="header-icon" />Instructions</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="Instructions-content">
                                <p>Add the domain using the new Domain button. After adding the domain, log in to your domain provider such as GoDaddy, Hostinger etc.. Configure the following in your domain provider's DNS settings</p>
                            </div>
                            <div className="Instructions-content">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h5>RECORD TYPE</h5>
                                        <h6>A</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h5>HOSTNAME</h5>
                                        <h6>@</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h5>VALUE</h5>
                                        <h6>{process.env.REACT_APP_SERVER_IP}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="Instructions-content">
                                <p>After configuring above settings, click on verify. If domain is pointing to the above IP address, then it will be marked as verified. <span> Note : It may take few time for the DNS settings to change. Please wait before trying again.</span></p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose03}> Confirm </button>
                </Modal.Footer>
            </Modal>
            {/* modal End here */}

            {/* new Servicezone modal start here */}
            <Modal show={show08} onHide={handleClose08} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Group</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-input" type="text" placeholder="" value={group_name} onChange={(e) => setGroupName(e.target.value)} />
                                <Form.Label className="did-floating-label">Group Name</Form.Label>
                            </Form.Group>
                        </div>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={color_code} onChange={(e) => setColorCode(e.target.value)} defaultValue="#7367f0" />
                                <Form.Label className="did-floating-label">Select Color</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    {
                        loading ? <button className="Active-bttn btn"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button>
                            : <button className="Active-bttn btn" onClick={handleAddGroup}> Create Group </button>

                    }


                </Modal.Footer>
            </Modal>
            {/* modal end here */}

            {/* new Servicezone modal start here */}
            <Modal show={show09} onHide={handleClose09} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Create New API</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicName">
                                <Form.Control className="did-floating-input" type="text" placeholder="Key Name" value={apiName} onChange={(e) => setApiName(e.target.value)} />
                                <Form.Label className="did-floating-label">API Key Name</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {loading2 ? <button className="Active-bttn btn"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button> :

                        <button className="Active-bttn btn" onClick={(e) => { handleClose09(); addApi(e) }}> Create API Key </button>
                    } </Modal.Footer>
            </Modal>
            {/* modal end here */}


            {/* new Servicezone modal start here */}
            <Modal show={show11} onHide={handleClose11} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title>Edit API</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-input" type="text" value={editapiName} onChange={
                                    (e) => {
                                        setEditApiName(e.target.value)
                                    }
                                } placeholder="Key Name" />
                                <Form.Label className="did-floating-label">API Key Name</Form.Label>
                            </Form.Group>
                        </div>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-textarea" as="textarea" value={editnote} onChange={
                                    (e) => {
                                        setEditNote(e.target.value)
                                    }
                                } rows={3} placeholder=" " />
                                <Form.Label className="did-floating-label">Note</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {loading2 ? <button className="Active-bttn btn"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button> :
                        <button className="Active-bttn btn" onClick={editApi}> Update </button>}
                </Modal.Footer>
            </Modal>
            {/* modal end here */}


            <Header />

            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="table-card-header add-btn d-flex pd-26">
                                <h5 className="main-card-title"><AiOutlineSetting className="header-icon" /> SETTINGS </h5>
                                <div className="theme-switch d-flex">
                                    <h6 style={{ color: isDarkMode ? '#fff' : '#000', marginTop: '7px', marginRight: '10px' }}>{textTheme} Mode</h6>

                                    <input type="checkbox" className="checkbox-theame-change" id="checkbox" checked={isDarkMode} />
                                    <label htmlFor="checkbox" className="checkbox-label" onClick={toggleTheme}>
                                        <FaMoon className="moon-icon" /> <FaSun className="sun-icon" />
                                        <span className="ball"></span>

                                    </label>
                                </div>
                            </div>
                            <div className="tab-content-body mobile-view-hide">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-2">
                                            <div className="tab-card">
                                                <div className="tab-card-body">
                                                    <div className="tab-content">
                                                        <Nav variant="pills" className="flex-column">
                                                            {Object.entries(tabs).map(tab =>
                                                            (
                                                                <Nav.Item >
                                                                    <Nav.Link eventKey={tab[0]}

                                                                        className={`technician-tab-link`}
                                                                        onClick={() => {
                                                                            toggle(tab[0]);
                                                                        }}>
                                                                        <div className="user-profile-box">{tab[1].icon}</div>
                                                                        <span className="tab-user-name" >{tab[1].title}</span>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            ))}
                                                        </Nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-8 col-10">
                                            <div className="tab-content bx-1">
                                                <Tab.Content activeTab={active_tab}>

                                                    {
                                                        Object.entries(tabs).map((tab) => (
                                                            <Tab.Pane eventKey={tab[0]} tabId={tab[0]}
                                                                className={`${active_tab === tab[0] ? "active show" : ""}`}
                                                            >
                                                                {tab[1].content}
                                                            </Tab.Pane>
                                                        ))
                                                    }
                                                </Tab.Content>
                                            </div>
                                        </div>
                                    </Row>
                                </Tab.Container>
                            </div>


                            <div className="row pd-20">
                                <div className="mobile-view-show">
                                    <div className="accordion-list">
                                        <Accordion defaultActiveKey="0">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><BiUserCircle /></div> <span className="tab-user-name" >Profile</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        {/* <div className="tab-content-header">
                                                                <h5 className="tab-content-title"><div className="tab-content-icon"><BiUserCircle /></div>Profile</h5>
                                                            </div> */}
                                                        <div className="tab-content-body">
                                                            <TabData1 />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><TbShieldLock /></div> <span className="tab-user-name" >Password Reset</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <TabData2 />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className="tab-list-header"> <div className="user-profile-box"><FiUsers /></div> <span className="tab-user-name" >Teams</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-header add-btn d-flex">

                                                            <div className="tab-content-header-right">
                                                                <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow08} > <RiAddFill />Add Group</Button>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content-body">
                                                            <Tabteam />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header className="tab-list-header"> <div className="user-profile-box"><TbKey /></div> <span className="tab-user-name" >API Setting </span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-header add-btn d-flex Add-margin">

                                                            <div className="tab-content-header-right">
                                                                <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow09} >Generate API Key</Button>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content-body">
                                                            <TabData5 apiCreateStatus={apiCreateStatus} setApiCreateStatus={setApiCreateStatus} />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>

                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><TbHierarchy /></div> <span className="tab-user-name" >Authentications</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <div className="table-card-body-inner">
                                                                <TabData7 />
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><FiServer /></div> <span className="tab-user-name" >My Domains</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-header add-btn d-flex Add-margin">
                                                            <div className="tab-content-header-right">
                                                                <Button className="btn btn-sm float-right add-usar-btn me-3 margin-imp" onClick={handleShow03} > <TbMessage2 />Instructions</Button>
                                                                <Button className="btn btn-sm float-right add-usar-btn" onClick={handleShow04} > <FiServer />New Dom</Button>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content-body">
                                                            <TabData8 />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;