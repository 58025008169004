// import React, { useState } from "react";
// import SingleImageModal from "../../../Helpers/customImagesModel";
// const { API_URL } = require("../../../BackendApi/Api/config");

// function ImageText(props) {
//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
    
//     return (
//         <>
//             <div className="image_with_text_content color_bg_text mb-3" onClick={handleShow}>
//                 <div className="brand_img_set" style={{ backgroundColor: props.data.backgroundColor }}>
//                     <a >
//                         <img src={props.type == "auto" ? props.data.imageLink : API_URL + "flowpage/" + props.data.imageLink} alt={props.data.imageAltText}  />
//                     </a>
//                 </div>
//                 <div className="image_text_content">
//                     <p className="image_text_content_title m-0">{ props.type == "auto" ? props.data.imageCaption : props.data.imageCaption}</p>
//                 </div>
//             </div>
//             <SingleImageModal show={show} onClose={handleClose} imagePreview={props.data.imageLink} link={props.data.link} type="template" />
//         </>
//     );
// }

// export default ImageText;


import React, { useState } from "react";
import { useBuilderContext } from "../../../builderContext";
import SingleImageModal from "../../../Helpers/customImagesModel";
const { API_URL } = require("../../../BackendApi/Api/config");

function ImageText(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="mb-3" onClick={handleShow} id={props.data.unique_id}>
                <div className="image_with_text_content" style={{ backgroundColor: props.data.backgroundColor }}>
                    <div className="brand_img_set" style={{ backgroundColor: props.data.backgroundColor }}>
                        {/* <a href={props.data.link}> */}
                        <img
                            src={
                                (props.data.imageLink?.startsWith("http://") || props.data.imageLink?.startsWith("https://"))
                                    ? props.data.imageLink
                                    : API_URL + "flowpage/" + props.data.imageLink
                            }
                            className="img-fluid" // Bootstrap class for responsive images
                            style={{ objectFit: props.data.imagePosition }}
                            alt={props.data.imageAltText || ""}
                        />
                        {/* </a> */}
                    </div>
                    <div className="image_text_content text-center">
                        <p className="image_text_content_title m-0">{props.data.imageCaption}</p>
                    </div>
                </div>
            </div>

            <SingleImageModal show={show} onClose={handleClose} imagePreview={props.data.imageLink} link={props.data.link} />
        </>
    );
}
export default ImageText;
