import React, { useContext, useState, useRef, useEffect } from "react";
import QRCodeStyling from 'qr-code-styling';
import Qr from "../../assets/images/Qr01.webp";
import { Accordion, Spinner } from "react-bootstrap";
import DomainType from "../Createtemplate/DomainType";
import SessionName from "../Createtemplate/SessionName";
import Choosepattern from "../Createtemplate/Choosepatterns";
import Chooseeye from "../Createtemplate/Chooseeye";
import Addlogo from "../Createtemplate/AddLogo";
import Setcolor from "../Createtemplate/SetColor";
import Chooseframe from "../Createtemplate/Chooseframe";
import ChoosePredefinedTemplate from "../Createtemplate/choosepredefinedtemplate"
import { getTemplatebyIdApi } from "../../BackendApi/Api/template";
import { TemplateContext } from "../../TemplateContext";
import { toJpeg } from 'html-to-image';

import { updateTemplate } from "../../BackendApi/Api/template";
import { updateChannelQr } from "../../BackendApi/Api/campaignApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';


function EditQrChannel({ channlData }) {


    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside, caption, setCaption, resetTemplateData] = useContext(TemplateContext);

    const qrCodeRef = useRef(null);
    const divRef = useRef(null);

    const [templateData, setTemplateData] = useState(null)
    const [name, setName] = useState("")
    const [ChannelData, setChannelData] = useState('')

    // console.log("pattern", pattern);



    useEffect(() => {

        if (patternColorType == "color") {
            var patternColorTypeValue = {
                "type": pattern,
                "color": patternDotsColor
            };
        } else {
            var patternColorTypeValue = {
                "type": pattern,
                "gradient": {
                    "type": "linear",
                    "rotation": 0,
                    "colorStops": [
                        {
                            "offset": 0,
                            "color": patternDotsColor
                        },
                        {
                            "offset": 1,
                            "color": patternGradientColor
                        }
                    ]
                }
            };
        }

        if (backgroundcolortype === "solid") {
            var backgroundcolortypeValue = {
                "color": backgroundcolorcode
            };
        } else {
            var backgroundcolortypeValue = {
                "color": "#00000000"
            };
        }


        const qrCode = new QRCodeStyling({
            width: width,
            height: height,
            data: channlData.domain_name + "/" + channlData.backhalf.backhalf + "?method=scan",
            margin: 0,
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q',
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
            },
            dotsOptions: patternColorTypeValue,
            backgroundOptions: backgroundcolortypeValue,
            image: logo,
            dotsOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: '0' },
            },
            cornersSquareOptions: {
                type: eye,
                color: eyeBallColor,
                gradient: null,
            },
            cornersSquareOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            cornersDotOptions: {
                type: eyeinside,
                color: EyeCenterColor,
                gradient: null,
            },
            cornersDotOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            backgroundOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#ffffff', color2: '#ffffff', rotation: '0' },
            },
        });

        qrCode.append(qrCodeRef.current);
        qrCode.update();
        //   return () => {
        //     qrCode.destroy();
        //   };
    }, [pattern, eye, eyeinside, logo, patternColorType, patternDotsColor, patternGradientColor, eyeBallColor, EyeCenterColor, backgroundcolortype, backgroundcolorcode, height, width, frame]);



    const getTemplateData = () => {
        // getTemplatebyIdApi({ id: channlData.templateId }).then((res) => {
        //     if (res.code === 200) {
        //         if (res.data.status === true) {
        //             const apiResponse = res.data.data;
        //             console.log("apiResponse 6", apiResponse);
        //             setTemplateData(apiResponse);
        //         }
        //         else {
        //             alert("Template data not found");
        //         }

        //     }
        //     else {
        //         alert("Something went wrong");
        //     }
        // }).catch((err) => {
        //     console.log(err);
        // })

        setTemplateData(channlData.backhalf)
    }

    useEffect(() => {
        getTemplateData();
    }, [])

    // useEffect(() => {

    //     if (templateData !== null) {
    //         setName(templateData.backhalf);

    //         const smartlinkJsonObj = JSON.parse(templateData.qr_design);


    //         if (smartlinkJsonObj.dotsOptions === null || smartlinkJsonObj.dotsOptions === undefined) {
    //             // smartlinkeditdata.styling.dotsOptions.type = "dots";
    //             setPattern("dots");
    //         }
    //         else {
    //             if (smartlinkJsonObj.type !== null) {
    //                 setPattern(smartlinkJsonObj.dotsOptions.type);
    //             }
    //             else {
    //                 setPattern("dots");
    //             }


    //             if (smartlinkJsonObj.dotsOptions.hasOwnProperty("gradient")) {

    //                 setPatternColorType("gradient");
    //                 setPatternDotsColor(smartlinkJsonObj.dotsOptions.gradient.colorStops[0].color);
    //                 setPatternGradientColor(smartlinkJsonObj.dotsOptions.gradient.colorStops[1].color);
    //             } else {
    //                 setPatternColorType("color");
    //                 setPatternDotsColor(smartlinkJsonObj.dotsOptions.color);
    //             }
    //             setPatternDotsColor(smartlinkJsonObj.dotsOptions.color);
    //             setPatternGradientColor(smartlinkJsonObj.dotsOptions.color);

    //         }

    //         if (smartlinkJsonObj.cornersSquareOptions === null || smartlinkJsonObj.cornersSquareOptions === undefined) {
    //             setEye("square");
    //         }
    //         else {
    //             if (smartlinkJsonObj.cornersSquareOptions.type !== null) {
    //                 setEye(smartlinkJsonObj.cornersSquareOptions.type);
    //             }
    //             else {
    //                 setEye("square");
    //             }

    //             setEyeBallColor(smartlinkJsonObj.cornersSquareOptions.color);

    //         }

    //         if (smartlinkJsonObj.cornersDotOptions === null || smartlinkJsonObj.cornersDotOptions === undefined) {
    //             setEyeinside("dot");
    //         }
    //         else {
    //             if (smartlinkJsonObj.cornersDotOptions.type !== null) {
    //                 setEyeinside(smartlinkJsonObj.cornersDotOptions.type);
    //             }
    //             else {
    //                 setEyeinside("dot");
    //             }
    //             setEyeCenterColor(smartlinkJsonObj.cornersDotOptions.color);

    //         }
    //         setLogo(smartlinkJsonObj.image);
    //         if (smartlinkJsonObj.frameOptions !== null && smartlinkJsonObj.frameOptions !== undefined) {
    //             if (smartlinkJsonObj.frameOptions.type !== null) {
    //                 setFrame(smartlinkJsonObj.frameOptions.type);
    //             }
    //             else {
    //                 setFrame("qr-bg-col");
    //             }
    //         }
    //         else {
    //             setFrame("qr-bg-col");
    //         }

    //         if (smartlinkJsonObj.backgroundOptions !== null && smartlinkJsonObj.backgroundOptions !== undefined) {
    //             //set background color property
    //             if (smartlinkJsonObj.backgroundOptions.hasOwnProperty("color")) {
    //                 // setBackgroundcolortype("solid");
    //                 setBackgroundcolorcode(smartlinkJsonObj.backgroundOptions.color);
    //             } else {
    //                 // setBackgroundcolortype("transparent");
    //                 setBackgroundcolorcode("#ffffff");
    //             }

    //         }
    //         else {
    //             setBackgroundcolorcode("#ffffff");
    //         }

    //         setCaption("hello");
    //         // setCaption(smartlinkeditdata.title);

    //     }
    // }, [templateData])



    useEffect(() => {


        // console.log("please show my smartlinkeditdata", (smartlinkeditdata.styling));



        if (templateData !== null) {
            setName(templateData.backhalf);

            const smartlinkJsonObj = JSON.parse(templateData.qr_design);

            // console.log("my template data",templateData)



            // var smartlinkJsonObj = JSON.parse(smartlinkeditdata.styling);

            // console.log("smartlinkJsonObj", smartlinkJsonObj);

            if (smartlinkJsonObj.dotsOptions === null || smartlinkJsonObj.dotsOptions === undefined) {
                // smartlinkeditdata.styling.dotsOptions.type = "dots";
                setPattern("dots");
            }
            else {
                if (smartlinkJsonObj.type !== null) {
                    setPattern(smartlinkJsonObj.dotsOptions.type);
                }
                else {
                    setPattern("dots");
                }


                if (smartlinkJsonObj.dotsOptions.gradient) {

                    // console.log("i am inside gradien be happy");

                    // console.log("this is my dot1 color",smartlinkJsonObj.dotsOptions.gradient.colorStops[0].color)

                    // console.log("this is my dot2 color",smartlinkJsonObj.dotsOptions.gradient.colorStops[1].color)

                    // console.log("this is my pattern grad",smartlinkJsonObj.dotsOptions.type)

                    setPatternColorType("gradient");
                    setPattern(smartlinkJsonObj.dotsOptions.type)
                    setPatternDotsColor(smartlinkJsonObj.dotsOptions.gradient.colorStops[0].color);
                    setPatternGradientColor(smartlinkJsonObj.dotsOptions.gradient.colorStops[1].color);
                } else {

                    // console.log("this is my pattern non",smartlinkJsonObj.dotsOptions.type)

                    setPatternColorType("color");
                    setPattern(smartlinkJsonObj.dotsOptions.type)
                    setPatternDotsColor(smartlinkJsonObj.dotsOptions.color);
                }
                // setPatternDotsColor(smartlinkJsonObj.dotsOptions.color);
                // setPatternGradientColor(smartlinkJsonObj.dotsOptions.color);

            }

            if (smartlinkJsonObj.cornersSquareOptions === null || smartlinkJsonObj.cornersSquareOptions === undefined) {
                setEye("square");
            }
            else {
                if (smartlinkJsonObj.cornersSquareOptions.type !== null) {
                    setEye(smartlinkJsonObj.cornersSquareOptions.type);
                }
                else {
                    setEye("square");
                }

                setEyeBallColor(smartlinkJsonObj.cornersSquareOptions.color);

            }

            if (smartlinkJsonObj.cornersDotOptions === null || smartlinkJsonObj.cornersDotOptions === undefined) {
                setEyeinside("dot");
            }
            else {
                if (smartlinkJsonObj.cornersDotOptions.type !== null) {
                    setEyeinside(smartlinkJsonObj.cornersDotOptions.type);
                }
                else {
                    setEyeinside("dot");
                }
                setEyeCenterColor(smartlinkJsonObj.cornersDotOptions.color);


            }
            setLogo(smartlinkJsonObj.image);
            if (smartlinkJsonObj.frameOptions !== null && smartlinkJsonObj.frameOptions !== undefined) {
                if (smartlinkJsonObj.frameOptions.type !== null) {
                    setFrame(smartlinkJsonObj.frameOptions.type);
                }
                else {
                    setFrame("qr-bg-col");
                }
            }
            else {
                setFrame("qr-bg-col");
            }

            if (smartlinkJsonObj.backgroundOptions !== null && smartlinkJsonObj.backgroundOptions !== undefined) {
                //set background color property
                if (smartlinkJsonObj.backgroundOptions.hasOwnProperty("color")) {
                    // setBackgroundcolortype("solid");
                    setBackgroundcolorcode(smartlinkJsonObj.backgroundOptions.color);
                } else {
                    // setBackgroundcolortype("transparent");
                    setBackgroundcolorcode("#ffffff");
                }

            }
            else {
                setBackgroundcolorcode("#ffffff");
            }

            setCaption(templateData.caption);
            // setQrLoded(true);

        }

    }, [templateData]);
    const campaignId = window.location.pathname.split("/")[2];
    const [UpQRLoading, setUpQRloading] = useState(false)
    const updateTemplateData = () => {
        setUpQRloading(true)
        if (patternColorType == "color") {
            var patternColorTypeValue = {
                "type": pattern,
                "color": patternDotsColor
            };
        } else {
            var patternColorTypeValue = {
                "type": pattern,
                "gradient": {
                    "type": "linear",
                    "rotation": 0,
                    "colorStops": [
                        {
                            "offset": 0,
                            "color": patternDotsColor
                        },
                        {
                            "offset": 1,
                            "color": patternGradientColor
                        }
                    ]
                }
            };
        }


        if (backgroundcolortype === "solid") {
            var backgroundcolortypeValue = {
                "color": backgroundcolorcode
            };
        } else {
            var backgroundcolortypeValue = {
                "color": "#00000000"
            };
        }

        const qrCode = {
            width: width,
            height: height,
            data: 'https://optisoft.in',
            margin: 0,
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q',
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
            },
            dotsOptions: patternColorTypeValue,
            backgroundOptions: backgroundcolortypeValue,
            image: logo,
            dotsOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: '0' },
            },
            cornersSquareOptions: {
                type: eye,
                color: eyeBallColor,
                gradient: null,
            },
            cornersSquareOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            cornersDotOptions: {
                type: eyeinside,
                color: EyeCenterColor,
                gradient: null,
            },
            cornersDotOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            backgroundOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#ffffff', color2: '#ffffff', rotation: '0' },
            },
            frameOptions: {
                type: frame,
            },
        };


        var qrToCapture = divRef.current

        if (frame === "qr-bg-col4") {
            console.log("say cheese")

            qrToCapture = qrCodeRef.current.children[0];

        }


        toJpeg(qrToCapture, { quality: 1.0 })
            .then(dataUrl => {
                const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

                // // Convert base64 string to Blob object
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/jpeg' });

                const userdata = localStorage.getItem("user");
                const user = JSON.parse(userdata);
                const userid = user._id;

                // console.log("this is caption",caption);

                const formdata = new FormData();
                formdata.append("id", channlData.backhalf._id);
                // formdata.append("created_by", userid);
                // formdata.append("template_name", name);
                formdata.append("styling", JSON.stringify(qrCode));
                formdata.append("image", blob);
                formdata.append("campaign_id", campaignId)
                formdata.append("updator_image", user.profile_image)
                formdata.append("updator_name", user.fname + "" + user.lname)
                setUpQRloading(true)
                updateChannelQr(formdata).then((res) => {
                    if (res.code === 200) {
                        if (res.data.status === true) {
                            PNotify.success({
                                title: 'Success',
                                text: 'Template Updated successfully',
                            });
                            resetTemplateData();
                            window.location.reload();
                        }
                        else {
                            PNotify.error({
                                title: 'Error',
                                text: 'Template not created',
                            });
                        }

                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: 'Something went wrong',
                        });
                    }
                }).catch((err) => {
                    console.log("err", err);
                }).finally((err) => {
                    setUpQRloading(false)
                });

            });


    }









    const [showText, setShowText] = React.useState(false);



    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <Accordion defaultActiveKey="" className="Create-Template-Accordion">
                                {/* <Accordion.Item eventKey="1">
                                    <DomainType />
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <SessionName />
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="3">
                                    <Choosepattern />
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Chooseeye />
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Addlogo />
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Setcolor />
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Chooseframe />
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <ChoosePredefinedTemplate />
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-md-12 p-0">
                            {/* <div className="form-group mb-3">
                                <input type="checkbox" className="form-check-input me-2" onClick={() => setShowText(!showText)} id="exampleCheck1" />
                                <label className="form-check-label" for="exampleCheck1">Save as a template</label>
                            </div> */}

                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    {/* <div className="Qr-image"> */}
                    <div ref={divRef} className={
                        frame === "qr-bg-col" ? "qr-box qr-bg-col" : frame === "qr-bg-col2" ? "qr-box qr-bg-col2" : frame === "qr-bg-col3" ? "qr-box qr-bg-col3" : frame === "qr-bg-col4" ? "qr-box qr-bg-col4" : "qr-box"
                    } >
                        <div className="canvas-title2"><h2>{caption}</h2></div>
                        <div className="Qr-image">
                            <div ref={qrCodeRef} />
                        </div>
                        <div className="canvas-title "><h2>{caption}</h2></div>
                        <div className="canvas-title3 "><h2>{caption}</h2></div>
                    </div>

                    {/* </div> */}
                </div>
                <div className="col-md-12">
                    <div className="d-flex justify-content-end mt-3">
                        <button className="Active-bttn btn" onClick={updateTemplateData} >{UpQRLoading ? <Spinner /> : "Save"}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditQrChannel;