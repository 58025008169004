import React, { useEffect, useState, useContext } from "react";
import Profile from "../../../assets/images/Avatar04.webp";
import { TbEdit, TbTrash } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import { RiAddFill } from "react-icons/ri";
import "./Memberlist.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { getMemberApi, deleteMemberApi, deleteTeamMember, resendMemberEmail, memberRegistereApi, resendMemberRequest } from "../../../BackendApi/Api/userApi";
import { UserContext } from "../../../UserContext";
import Role from "../role.json";
import memberimage from "./member.png";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { API_URL } from "../../../BackendApi/Api/config";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Form, Spinner } from "react-bootstrap";
import { GiCometSpark } from "react-icons/gi";
import { getmemberbranding } from "../../../BackendApi/Api/brandApi";
import { CgUserList } from 'react-icons/cg';
import { Tabs, Tab } from 'react-bootstrap';
import axios from "axios";



function Memberlist() {
    const { groupdata, setGroupData, groupaddstatus, setGroupAddStatus, memberData, setMemberData, memberaddstatus, setMemberAddStatus } = useContext(UserContext);

    const user = JSON.parse(localStorage.getItem('user'));

    const [rolee, setRolee] = useState(Role)

    const [radioclass, setRadioClass] = useState(false);
    const [memberLoding, setMemberLoding] = useState(false);

    const [value, setValue] = useState([2, 3]);
    const handleChange = (val) => setValue(val);

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');

    const [memberId, setMemberId] = useState('');

    const [ownerInfo, setOwnerInfo] = useState({})

    const [brandsForMember, setBrandsForMember] = useState([]);

    const [invitation_id, setInvitation_id] = useState('');

    const [selectedGroupIds, setSelectedGroupIds] = useState([])

    const [loadingBrands, setLoadingBrands] = useState(false); // Loading state for updating brands
    const [loadingGroups, setLoadingGroups] = useState(false);

    useEffect(() => {

        console.log(memberData)

        if (memberData !== null || memberData !== undefined || memberData !== '') {
            setOwnerInfo(memberData[0])
        }


    }, [memberData]

    )

    useEffect(() => {
        getBrandData();
    }, [])

    console.log('member ie------------------', memberId)

    // const [memberData, setMemberData] = useState([]);

    const radios = [
        { name: 'Sales', value: '1', className: 'radio-btn01' },
        { name: 'Marketing', value: '2', className: 'radio-btn02' },
        { name: 'Sales', value: '3', className: 'radio-btn03' },
        { name: 'Finance', value: '4', className: 'radio-btn04' },
    ];


    const changeRadio = (value) => {
        setRadioValue(value);
    };

    const [show03, setShow03] = useState(false);
    const handleClose03 = () => setShow03(false);
    const handleShow03 = (granted_brands, invitation_id, groups) => {

        setInvitation_id(invitation_id)

        setBrandsForMember(granted_brands)

        if(groups){

            setSelectedGroupIds(groups)
        }
        else{

            setSelectedGroupIds([])
        }

        // brand_for_member(granted_brands)
        setShow03(true);
    }

    const brand_for_member = (granted_brands) => {
        const updatedBrands = brandData.map(brand => {
            const isGranted = granted_brands.some(grantedBrand => grantedBrand.brand_id === brand._id);
            return {
                brand_id: brand._id,
                company_name: brand.company_name,
                is_granted: isGranted
            };
        });
        setBrandsForMember(updatedBrands);
    };



    const [show04, setShow04] = useState(false);
    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show08, setShow08] = useState(false);

    const handleClose08 = () => setShow08(false);
    const handleShow08 = () => {
        setSelectedGroupIds([])
        setShow08(true)
    }

    const [email, setEmail] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    // const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [groupId, setGroupId] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [brandData, setBrandData] = useState([]);

    const [chooseBrand, setChooseBrand] = useState([]);

    const transformedBrandData = brandData.map((item) => ({
        company_name: item.company_name,
        brand_id: item._id,
    }));
    console.log("selectedGroupId", selectedGroupId)
    const handleinviteteam = async () => {

        try {

            setLoading(true);

            let validateform = validate();

            console.log('validate', validate)

            if (!validateform) {
                setLoading(false);
                return;
            } else {

                const chosenbrands = chooseBrand.map((brand) => brand.brand_id);

                const data = {
                    fname: fname,
                    lname: lname,
                    email: email,
                    role: role,
                    brands: chosenbrands,
                    sender_id: user._id,
                    // group_id: selectedGroupId
                    groups: selectedGroupIds

                }


                const response = await fetch(`${API_URL}user/invitemember`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                });

                const result = await response.json();

                if (result.status) {
                    PNotify.success({ title: 'Success', text: result.message });
                    setLoading(false);
                    handleClose08();
                }
                else {
                    setLoading(false);
                    PNotify.error({ title: 'Error', text: result.message });
                }
            }

        }

        catch (error) {
            setLoading(false);
            PNotify.error({ title: 'Error', text: "some error occoured" });

            console.log(error);
        }

    }

    const validate = () => {
        if (fname === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter fname',
            });
            return false;
        }
        if (lname === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter lname',
            });
            return false;
        }
        if (email === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter email',
            });
            return false;
        }

        if (role === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please select role',
            });
            return false;
        }
        if (user.email === email) {
            PNotify.error({
                title: 'Error',
                text: "You can't invite yourself",
            });
            return false
        }

        if (selectedGroupIds.length === 0) {
            PNotify.error({
                title: 'Error',
                text: 'Please select group',
            });
            return false;
        }

        // if (chooseBrand.length === 0) {
        //     PNotify.error({
        //         title: 'Error',
        //         text: 'Please select brand',
        //     });
        //     return false;
        // }
        return true;
    }



    const randomPassword = () => {
        var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
        var pass = "";
        for (var x = 0; x < 8; x++) {
            var i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        console.log('passsssswwwwooorrrrdaaaaaaaa===========', pass);
        // setPassword(pass);
        return pass;


    }
    const handleInvite = (e) => {
        e.preventDefault();
        const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (!emailRegx.test(email)) {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter valid email',
            });
            return false;
        }
        if (email === "") {
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Please enter email',
            });
        } else if (fname === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter fname',
                delay: 2000,
            });
        } else if (lname === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter lname',
                delay: 2000,
            });
        } else if (role === "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter role',
                delay: 2000,
            });
        } else if (chooseBrand.length === 0) {
            PNotify.error({
                title: 'Error',
                text: 'Please select brand',
                delay: 2000,
            });
        } else if (radioValue === "1") {
            PNotify.error({
                title: 'Error',
                text: 'Please Select Group',
                delay: 2000,
            });
        } else {

            const pass = randomPassword();
            const userdata = localStorage.getItem("user");
            const user = JSON.parse(userdata);
            const owner_id = user._id;
            const userData = {
                email: email,
                fname: fname,
                lname: lname,
                password: pass,
                role: role,
                group_id: groupId,
                owner_id: owner_id,
                brands: chooseBrand
            }
            setLoading(true);
            memberRegistereApi(userData).then(response => {
                if (response.code === 200) {
                    setMemberAddStatus(!memberaddstatus);
                    PNotify.success({
                        title: 'Success',
                        delay: 2000,
                        text: response.data.message,
                        modules: { Desktop: { desktop: true } },
                        hide: true,
                    });
                    handleClose08();
                }
                else {
                    console.log(response);
                    PNotify.error({
                        title: 'Error',
                        text: response.data.message,
                    });
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const handleBrandSelect = (selected) => {

        console.log("the selected brands are ", selected)

        // const selectedBrands = selected;
        setChooseBrand(selected);
    }

    const getMemberData = () => {
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user._id;

        const data = {
            owner_id: owner_id
        }

        getMemberApi(data).then((res) => {
            console.log(res);
            setMemberData(res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const deleteMember = () => {
        const data = {
            teamRequest_id: memberId
        }
        deleteTeamMember(data).then((res) => {

            if (res.data.status == true) {
                PNotify.success({
                    title: 'Success',
                    text: 'Member Deleted Successfully',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
                getMemberData();
            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something Went Wrong',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
            }
        }).catch((err) => {
            console.log(err);
        })
        handleClose04();
    }

    useEffect(() => {
        getMemberData();
    }, [memberaddstatus])

    const resendcredentials = (id, email, lname, fname, sender_id) => {

        const formData = {
            _id: id,
            email: email,
            lname: lname,
            fname: fname,
            sender_id: sender_id
        }

        resendMemberRequest(formData).then((res) => {
            if (res.data.status == true) {
                PNotify.success({
                    title: 'Success',
                    text: 'Credentials Resend Successfully',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'Something Went Wrong',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                })

            }
        }).catch((err) => {

            PNotify.error({
                title: 'Error',
                text: 'Something Went Wrong',
                modules: {
                    Desktop: {
                        desktop: true
                    }
                },
            })
            console.log(err);
        })

    }




    const getBrandData = () => {
        const id = localStorage.getItem("user");
        const user = JSON.parse(id);
        const owner_id = user.owner_id;

        const data = {
            owner_id: owner_id
        }
        getmemberbranding(data).then((res) => {
            // console.log(res);
            setBrandData(res.data.data);
            // console.log(" branding data data data ",res.data.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const activatedeactivatemember = async (id, status, index) => {
        console.log(id, status, index)
        setMemberData((prev) => {
            const newchannels = [...prev];
            newchannels[index].is_Active = !newchannels[index].is_Active;
            return newchannels;

        })

        try {

            let data = JSON.stringify({
                "teamRequest_id": id,
                "status": status
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_URL}user/activatedeactivatemember`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const response = await axios.request(config)

            console.log(response.data);

            if (response.data.status) {
                console.log("updated channel status");

            }
            else {
                setMemberData((prev) => {
                    const newchannels = [...prev];
                    newchannels[index].is_Active = !newchannels[index].is_Active;
                    return newchannels;

                })
            }

        }
        catch (error) {
            console.log(error);
        }

    }




    const handleBrandToggle = (brandId) => {
        if (brandsForMember.includes(brandId)) {
            setBrandsForMember((prevSelected) =>
                prevSelected.filter((id) => id !== brandId)
            );
        } else {
            setBrandsForMember((prevSelected) => [...prevSelected, brandId]);
        }
    };

    const handleGroupToggle = (groupId) => {
        if (selectedGroupIds.includes(groupId)) {
          setSelectedGroupIds((prevSelected) =>
            prevSelected.filter((id) => id !== groupId)
          );
        } else {
          setSelectedGroupIds((prevSelected) => [...prevSelected, groupId]);
        }
      };

    // Function to update brands via API
    const updateBrandsForMember = async () => {
        setLoadingBrands(true);
        try {
            const response = await axios.post(`${API_URL}user/updateMemberGrantedBrands`, {
                teamRequest_id: invitation_id, // Replace with the actual member ID
                brands: brandsForMember,    // Send the updated array of brand IDs
            });

            if (response.status) {
                // alert('Brands updated successfully!');
                getMemberData();
                setShow03(false);
                // Optionally, update state or UI after successful update
            }
        } catch (error) {
            console.error('Error updating brands:', error);
            alert('Failed to update brands. Please try again.');
        } finally {
            setLoadingBrands(false);
            // setLoading(false); // Stop loading after API call completes
        }
    };

    const updateGroupsForMember = async () => {
        setLoadingGroups(true);
        try {
            const response = await axios.post(`${API_URL}user/updateMemberGrantedGroups`, {
                teamRequest_id: invitation_id, // Replace with the actual member ID
                groups: selectedGroupIds,    // Send the updated array of brand IDs
            });

            if (response.status) {
                // alert('Brands updated successfully!');
                getMemberData();
                setShow03(false);
                // Optionally, update state or UI after successful update
            }
        } catch (error) {
            console.error('Error updating brands:', error);
            alert('Failed to update brands. Please try again.');
        } finally {
            setLoadingGroups(false);
            // setLoading(false); // Stop loading after API call completes
        }
      };


    return (
        <div>


            <Modal show={show03} onHide={handleClose03} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="main-card-title">Edit Members</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custum-modal-body">
                    <Tabs defaultActiveKey="brands" id="edit-members-tabs" className="mb-3">
                        <Tab eventKey="brands" title="Brands">
                            {brandData && brandData.map((brand, index) => (
                                <div key={index}>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div>{brand.company_name}</div>
                                        <div className="member-status">
                                            <Form.Check
                                                checked={brandsForMember.includes(brand._id)}
                                                type="checkbox"
                                                id={`custom-checkbox-brand-${index}`}
                                                onChange={() => handleBrandToggle(brand._id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* Update Brands Button inside the Brands tab */}
                            <div className="d-flex justify-content-end mt-3">
                                <Button
                                    variant="primary"
                                    onClick={updateBrandsForMember}
                                    disabled={loadingBrands}
                                >
                                    {loadingBrands ? 'Updating...' : 'Update Brands'}
                                </Button>
                            </div>
                        </Tab>

                        <Tab eventKey="groups" title="Groups">
                            {groupdata && groupdata.map((group, index) => (
                                <div key={index}>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div>{group.group_name}</div>
                                        <div className="member-status">
                                            <Form.Check
                                                checked={selectedGroupIds.includes(group._id)}
                                                type="checkbox"
                                                id={`custom-checkbox-group-${index}`}
                                                onChange={() => handleGroupToggle(group._id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* Update Groups Button inside the Groups tab */}
                            <div className="d-flex justify-content-end mt-3">
                                <Button
                                    variant="primary"
                                    onClick={updateGroupsForMember}
                                    disabled={loadingGroups}
                                >
                                    {loadingGroups ? 'Updating...' : 'Update Groups'}
                                </Button>
                            </div>
                        </Tab>

                        <Tab eventKey="roles" title="Roles">
                            <p>Roles content goes here</p>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>


            {/* Delete Modal Start From Here */}
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash /> Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={deleteMember}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal End Here */}

            {/* Add Member Modal Start From Here */}
            <Modal show={show08} onHide={handleClose08} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title>Add Member</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    {
                        groupdata.length > 0 ?
                            (
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="content-body-form mb-3">
                                                <div className="form-group did-floating-label-content">
                                                    <input type="email" className="form-control did-floating-input" id="exampleFormControlInput1" placeholder="email"
                                                        value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    <label for="exampleFormControlInput1" className="form-label did-floating-label">Email</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="content-body-form mb-3">
                                                <div className="form-group did-floating-label-content">
                                                    <input type="email" className="form-control did-floating-input" id="exampleFormControlInput1" placeholder="fname"
                                                        value={fname} onChange={(e) => setFname(e.target.value)} />
                                                    <label for="exampleFormControlInput1" className="form-label did-floating-label">First Name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="content-body-form mb-3">
                                                <div className="form-group did-floating-label-content">
                                                    <input type="email" className="form-control did-floating-input" id="exampleFormControlInput1" placeholder="lname"
                                                        value={lname} onChange={(e) => setLname(e.target.value)} />
                                                    <label for="exampleFormControlInput1" className="form-label did-floating-label">Last Name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="content-body-form mb-3">
                                                <div className="form-group did-floating-label-content">
                                                    <select className="form-control did-floating-select" id="exampleFormControlSelect1" onChange={(e) =>
                                                        setRole(e.target.value)}>
                                                        <option value="0" disabled selected>Select Role</option>
                                                        {Role.map((item) => (
                                                            <option value={item.id} key={item.id}>{item.role}</option>
                                                        ))}
                                                    </select>
                                                    <label for="exampleFormControlInput1" className="form-label did-floating-label">Role</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="content-body-form mb-3">
                                                <div className="form-group did-floating-label-content">
                                                    <Typeahead
                                                        id="brandTypeahead"
                                                        labelKey="company_name" // Display label (company_name)
                                                        multiple
                                                        options={transformedBrandData} // Use the transformed data
                                                        selected={chooseBrand}
                                                        onChange={handleBrandSelect}
                                                        className='did-floating-select-custom'
                                                        placeholder='Select Brands'
                                                    />
                                                    {/* <label for="exampleFormControlInput1" className="form-label did-floating-label">Select Brands</label> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <div className="content-body-form mb-3">
                                                <div className="form-group col-md-12">
                                                    <label for="exampleFormControlInput1" className="form-label">Group</label>
                                                    <div className="group-toggle-select">

                                                        {/* <ButtonGroup className='flex-wrap'>
                                                            {groupdata && groupdata.map((radio, idx) => (
                                                                <ToggleButton key={radio._id} id={radio._id} type="radio" name="radio" value={radio._id}
                                                                    checked={radioValue === radio._id} onChange={(e) => {
                                                                        changeRadio(e.currentTarget.value);
                                                                        // setGroupId(e.target.value);
                                                                        setSelectedGroupId(radio?._id);
                                                                    }}
                                                                    className="group-toggle-btn"
                                                                    style={{ backgroundColor: radio.color_code }}

                                                                >
                                                                    {radio.group_name}
                                                                </ToggleButton>
                                                            ))}
                                                        </ButtonGroup> */}

                                                        <ButtonGroup className='flex-wrap'>
                                                            {groupdata && groupdata.map((item) => (
                                                                <ToggleButton
                                                                    key={item._id}
                                                                    id={item._id}
                                                                    type="checkbox" // changed from radio to checkbox
                                                                    name="checkbox"
                                                                    value={item._id}
                                                                    checked={selectedGroupIds.includes(item._id)} // check if item is in the selected array
                                                                    onChange={(e) => {
                                                                        const value = e.currentTarget.value;
                                                                        if (selectedGroupIds.includes(value)) {
                                                                            // Remove the item from the selected array if it's already selected
                                                                            setSelectedGroupIds(selectedGroupIds.filter(id => id !== value));
                                                                        } else {
                                                                            // Add the item to the selected array if it's not selected
                                                                            setSelectedGroupIds([...selectedGroupIds, value]);
                                                                        }
                                                                    }}
                                                                    className="group-toggle-btn"
                                                                    style={{ backgroundColor: item.color_code }}
                                                                >
                                                                    {item.group_name}
                                                                </ToggleButton>
                                                            ))}
                                                        </ButtonGroup>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <div className='form-btn'>
                                                <div className='form-btn-right'>

                                                    {
                                                        loading ? (
                                                            <button className="btn btn-sm float-right add-user-btn btn btn-primary"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button>

                                                        ) : (

                                                            <button type="button" className="btn btn-sm float-right add-user-btn btn btn-primary" onClick={(e) => {
                                                                // handleInvite(e);
                                                                handleinviteteam()
                                                            }

                                                            }>
                                                                <GiCometSpark className="icon" /> Invite Member
                                                            </button>
                                                        )

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='sec-bg-img'>
                                            <h1 className='create-group-title'>PLEASE CREATE GROUP</h1>
                                        </div>
                                    </div>
                                </div>

                            )
                    }
                </Modal.Body>


            </Modal>

            <div className="tab-content-header add-btn d-flex ms-0">
                <h5 className="tab-content-title" ><div className="tab-content-icon"><CgUserList /></div>Members List</h5>
                <div className="tab-content-header-right">
                    <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow08} > <RiAddFill /> Add Member</Button>
                </div>
            </div>



            <div className="Member-list">
                <ul className="list-group">

                    {/* <li className="list-group-item d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="member-img">
                                <img src={API_URL + 'profile/' + ownerInfo?.profile_image} alt="prf" className="img-fluid" />
                            </div>
                            <div className="member-content">
                                <h3>{ownerInfo?.fname} {ownerInfo?.lname}</h3>
                                <h4>Admin</h4>
                                <h4>{ownerInfo?.email}</h4>

                            </div>
                        </div>
                    </li> */}
                    {memberData && memberData.map((item, index) => {

                        console.log("profile mage ", item)
                        return (
                            <>
                                {/* {(item.role !== "0") ? ( */}
                                <li className="list-group-item d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="member-img">

                                            {item.member_details ? (
                                                <img src={API_URL + 'profile/' + item.member_details.profile_image} alt="prf" className="img-fluid" />

                                            ) : (
                                                <img src={API_URL + 'profile/' + 'defaultprofile.png'} alt="prf" className="img-fluid" />

                                            )}
                                        </div>
                                        <div className="member-content">

                                            {item.member_details &&

                                                <h3>{item.member_details.fname} {item.member_details.lname}</h3>
                                            }

                                            {/* <h4>{item.role}</h4> */}
                                            <h4>{item.recievers_email}</h4>

                                            <div>

                                                <div className="brand-Vanity-Tag d-flex align-items-center mt-5">
                                                    <h3 className="text-blue-dark me-2">Brands:</h3>
                                                    <div className="vanity-tag d-flex smartlink-tag">
                                                        {item?.brand_Details && item.brand_Details.slice(0, 3).map((brand, index) => (
                                                            <h4
                                                                key={index}
                                                                style={{
                                                                    color: '#3ed599',
                                                                    backgroundColor: '#e5f9ef'
                                                                }}
                                                            >
                                                                {brand.company_name}
                                                            </h4>
                                                        ))}
                                                    </div>
                                                </div>

                                            </div>

                                            <div>

                                                {/* <div className="brand-Vanity-Tag d-flex align-items-center mt-5">
                                                    <h3 className="text-blue-dark me-2">Groups:</h3>
                                                    <div className="vanity-tag d-flex smartlink-tag">
                                                        

                                                        {item.group_Details &&
                                                            <h4
                                                                style={{
                                                                    color: '#3ed599',
                                                                    backgroundColor: `${item.group_Details.color_code}`,
                                                                }}
                                                            >

                                                                {item.group_Details.group_name}
                                                            </h4>

                                                        }
                                                        {item.brands.slice(0, 3).map((brand, index) => (
                                                            <h4
                                                                key={index}
                                                                style={{
                                                                    color: '#3ed599',
                                                                    backgroundColor: '#e5f9ef'
                                                                }}
                                                            >
                                                                {brand.company_name}
                                                            </h4>
                                                        ))}
                                                    </div>
                                                </div> */}


                                                <div className="brand-Vanity-Tag d-flex align-items-center mt-5">
                                                    <h3 className="text-blue-dark me-2">Groups:</h3>
                                                    <div className="vanity-tag d-flex smartlink-tag">
                                                        {item?.group_Details && item.group_Details.slice(0, 3).map((group, index) => (
                                                            <h4
                                                                key={index}
                                                                style={{
                                                                    color: '#3ed599',
                                                                    backgroundColor: '#e5f9ef'
                                                                }}
                                                            >
                                                                {group.group_name}
                                                            </h4>
                                                        ))}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    {/* <div className="vanity-tag d-flex smartlink-tag">
                                        <h4 style={{ color: '#3ed599', backgroundColor: item.is_Active == true ? "#e5f9ef" : 'red' }}>{item.is_Active == true ? "Active" : "unActive"}</h4>
                                    </div> */}
                                    <div className="vanity-tag d-flex smartlink-tag">
                                        <h4 style={{ color: '#ffff', backgroundColor: item.is_verified == true ? 'green' : "red" }}>{item.is_verified == true ? "Verified" : "Unverified"}</h4>
                                    </div>
                                    <div className="member-action">

                                        <div className="d-flex align-items-center justify-content-end">


                                            <div className="member-status">
                                                <Form.Check // prettier-ignore
                                                    checked={item.is_Active}
                                                    type="switch"
                                                    id="custom-switch"
                                                    // label={item.is_Active==true?"Active":"unActive"}
                                                    onChange={
                                                        () => {

                                                            const id = item._id
                                                            const status = item.is_Active

                                                            activatedeactivatemember(id, status, index);


                                                        }
                                                    }

                                                />
                                            </div>

                                            <div className="member-status">
                                                {item.is_request_accepted ? (
                                                    <Button disabled className="resend-btn" onClick={() => {
                                                        resendcredentials(item._id, item.
                                                            member_details, item.lname, item.fname)
                                                    }}>
                                                        <p>Resend</p>
                                                    </Button>
                                                ) : (
                                                    <Button className="resend-btn" onClick={() => { resendcredentials(item.member_details._id, item.member_details.email, item.member_details.lname, item.member_details.fname, item._id) }}>
                                                        <p>Resend</p>
                                                    </Button>
                                                )}
                                            </div>

                                            <div className="button-group">
                                                <button type="button" className="btn btn-edit" onClick={

                                                    () => {
                                                        handleShow03(item.brands, item._id, item.groups)
                                                    }

                                                } >
                                                    <TbEdit />
                                                </button>
                                                <button type="button" className="btn btn-delete" onClick={() => { handleShow04(); setMemberId(item._id) }} >
                                                    <TbTrash />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </li>


                                {/* ) : ""

                                } */}
                            </>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Memberlist;