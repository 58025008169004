import React, { useLayoutEffect } from "react";
import Loginbg from "../../assets/images/Login/login-bg.png";
import Logo from "../../assets/images/logo/logo.png";
import './Login.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { redirect } from "react-router-dom";
import { loginApi } from '../../BackendApi/Api/auth'
import Button from 'react-bootstrap/Button';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";


import Spinner from 'react-bootstrap/Spinner';


function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();


        // check validation
        if (email === "") {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter email',
            });
            return false;
        }
        // check email validation
        const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (!emailRegx.test(email)) {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter valid email',
            });
            return false;
        }
        // check password validation
        if (password === "") {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter password',
            });
            return false;
        }
        const userData = {
            email: email,
            password: password
        }

        setLoading(true);
        loginApi(userData).then((response) => {
            if (response.code === 200) {
                console.log("login", response.data);
                if (response.data.status == false) {
                    PNotify.error({
                        title: 'Oh No!',
                        text: response.data.message,
                    });
                    return;
                } else {
                    console.log('Oh no!', response.data.data)
                    localStorage.setItem("user", JSON.stringify(response.data.data));
                    localStorage.setItem("adminData", JSON.stringify(response.data.data))
                 
                    localStorage.setItem("token", response.data.data.jwt);
                    if (response.data.data.role == "1") {
                        window.location.href = "/admindashboard";
                    } else {

                        window.location.href = "/dashboard";
                    }
                }
            }
            if (response.data.status == false) {
                PNotify.error({
                    title: 'Oh No!',
                    text: response.data.message,
                });
                return;
            }
        }).catch((error) => {
            PNotify.error({
                title: 'Oh No!',
                text: error,
            });
        }).finally(() => {
            setLoading(false);
        }
        )
    }
    const userData = JSON.parse(localStorage.getItem("user"));

    useLayoutEffect(() => {
        if (localStorage.getItem('user') != null) {
            if (userData.role == "1") {
                window.location.href = "/admindashboard";
            } else {

                window.location.href = "/dashboard";
            }
        }
    }, [])


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="Login">
            <section className="Login-section ">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center" style={{ height: '100vh' }} >
                        <div className="col col-xl-12">
                            <div className="Login-card" style={{ borderRadius: '1rem' }}>
                                <div className="row g-0 pt-1 pb-1">
                                    <div className="col-md-6 col-lg-6 d-none d-md-block">
                                        <div className="Login-img" >
                                            <img src={Loginbg} alt="login form" className="img-fluid" style={{ borderRadius: '1rem 0 0 1rem', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 d-flex align-items-center">
                                        <div className="card-body ps-4 pe-4">
                                            <form>
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    <span className="h1 fw-bold mb-0"><img src={Logo} alt="logo" className="logo-icon" style={{ width: '80%' }} /></span>
                                                </div>

                                                <h3 className=" login-head mb-3" style={{ letterSpacing: '1px' }}>Welcome 👋</h3>

                                                <div className="did-floating-label-content">
                                                    <input type="email" id="form2Example17" className="form-control did-floating-input dark-theam-input" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example17">Email address</label>
                                                </div>

                                                {/* <div className="did-floating-label-content">
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example27">Password</label>
                                                        <Link to="/forgotpassword" className="small" style={{ color: '#7367f0', textDecoration: 'none' }}>Forgot password?</Link>
                                                    </div>
                                                    <input type="password" id="form2Example27" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                </div> */}

                                                <div className="">
                                                    <div className="d-flex justify-content-between">
                                                        <Link to="/forgotpassword" className="small mb-3" style={{ color: '#7367f0', textDecoration: 'none' }}>Forgot password?</Link>
                                                    </div>
                                                    <div className="did-floating-label-content">

                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            id="form2Example27"
                                                            className="form-control did-floating-input dark-theam-input"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary eye-btn"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            {showPassword ? (
                                                                <FaEye />
                                                            ) : (

                                                                <FaEyeSlash />
                                                            )}
                                                        </button>

                                                        <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example27">Password</label>
                                                    </div>
                                                </div>

                                                <div className="form-check d-flex justify-content-start mb-4">
                                                    <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" defaultChecked />
                                                    <label className="form-check-label" htmlFor="form2Example3">
                                                        Remember me
                                                    </label>
                                                </div>
                                                {
                                                    loading ? (
                                                        <button className="btn custom-login-btn btn-lg btn-block mb-4">
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            Loading...
                                                        </button>
                                                    ) :
                                                        (< div className="pt-1 mb-4">
                                                            {/* <Link to="/" className="btn custom-login-btn btn-lg btn-block" type="button">Sign In</Link> */}
                                                            <button className="btn custom-login-btn btn-lg btn-block" type="submit" onClick={handleLogin}>Sign In</button>
                                                        </div>)
                                                }
                                                <p style={{ color: 'rgb(255 255 255)', textAlign: 'center' }}>New on our platform?
                                                    <Link to="/createaccount" style={{ color: '#7367f0', textDecoration: 'none', fontWeight: 'bold' }}> Create an account</Link>
                                                </p>
                                            </form>

                                            <div className="back-to-home">
                                                <Link to="/" style={{ color: '#7367f0', textDecoration: 'none', fontWeight: 'bold' }}><FaArrowLeft /> Back to home</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;